import * as apiGetDesiredEmploymentTypes from '#api/endpoints/onboarding/desiredEmploymentTypes';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  desiredEmploymentTypes = 'desired-employment-types',
}

type GetApiPayload = apiGetDesiredEmploymentTypes.Type['success']['payload'];
export const useGetDesiredEmploymentTypes = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>([KeysEnum.desiredEmploymentTypes], async () => await apiGetDesiredEmploymentTypes.request(), {
    ...options,
  });
