import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: never;
  success: {
    successStories: Array<{
      id: number;
      slug: string;
      companyImage: string;
      position: string;
      location: string;
      candidatesBackgrounds: Array<{ image: string; alt: string }>;
    }>;
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, 'public/success_stories/featured');
