import React, { FC } from 'react';

import { AnimationRow } from '#components/Index';

interface Props {
  title: string;
  logoList: React.ReactNode[];
}
const CompaniesCarousel: FC<Props> = ({ title, logoList }) => {
  return (
    <div className="py-16 bg-white">
      <h2 className="px-variable-lg text-display-md font-serif text-center">{title}</h2>
      <div className="mt-16 md:mb-16">
        <AnimationRow className="gap-8 md:gap-12">{logoList}</AnimationRow>
      </div>
    </div>
  );
};

export default CompaniesCarousel;
