import React, { FC } from 'react';
import { DateTime } from 'luxon';
import { Icon } from '../Index';

const DateTimeMeta: FC<{ datetime: string }> = ({ datetime }) => {
  const dt = DateTime.fromISO(datetime);

  return (
    <div className="flex gap-1.5 justify-start">
      <Icon.CalendarChecked size={5} className="shrink-0 text-green-500 mt-0.5" />
      <time className="text-gray-500" dateTime={datetime}>
        <span>{`${dt.toFormat('HH:mm')} - ${dt.plus({ hours: 1 }).toFormat('HH:mm')}, `}</span>
        <span>{dt.toLocaleString()} </span>
        <span>{`(${Intl.DateTimeFormat().resolvedOptions().timeZone})`}</span>
      </time>
    </div>
  );
};

export default DateTimeMeta;
