import React, { FC, Dispatch } from 'react';
import { useGetExperienceOptions } from './ExperienceOptionsData';
import { Selectable, Spinner } from '#components/Index';

interface ExperienceYearsFormProps {
  selectedExperienceYears: number | null;
  setSelectedExperienceYears: Dispatch<React.SetStateAction<number | null>>;
}

const ExperienceYearsForm: FC<ExperienceYearsFormProps> = ({ selectedExperienceYears, setSelectedExperienceYears }) => {
  const { data: experienceOptionsData, isLoading } = useGetExperienceOptions();

  if (isLoading) {
    return <Spinner className="py-32" />;
  }

  if (experienceOptionsData == null) {
    return null;
  }

  const experienceYears = experienceOptionsData.totalExperiences;

  return (
    <div className="rich-text text-hpblack w-full">
      <h1>
        How many years of <span className="text-green-900">full-time work experience</span> do you have?
      </h1>

      <div className="grid gap-4 mt-8 place-items-start">
        {experienceYears.map(({ value, label }) => {
          return (
            <Selectable
              type="checkbox"
              name="experience-years"
              id={`experience-years-${value}`}
              value={`experience-years-${label.toLowerCase()}`}
              size="lg"
              onChange={() => setSelectedExperienceYears(value)}
              checked={selectedExperienceYears === value}
              className=""
              key={value}
            >
              {label}
            </Selectable>
          );
        })}
      </div>
    </div>
  );
};

export default ExperienceYearsForm;
