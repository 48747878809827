import { Errors } from '#root/src/types';
import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    talent: { email: string; password: string };
    redirectPath?: string;
  };
  success: { redirectUrl: string; notice: string };
  fail: { errors: Errors<'base' | 'email' | 'password'>; status: number };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/sign_in');
