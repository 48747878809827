import React, { FC, useState } from 'react';
import { TalentWorkHistoryEntry, Employment } from '#src/types';
import { Button } from '#components/Index';
import EmploymentEntry from '../EmploymentEntry';
import EmploymentModal from '../modals/EmploymentModal/EmploymentModal';

const emptyEmploymentEntry = {
  id: 0,
  company: '',
  position: '',
  description: '',
  startDate: '',
  endDate: null,
  current: false,
  location: '',
};

interface ProfileWorkHistoryProps {
  workHistory: TalentWorkHistoryEntry[];
}

const ProfileWorkHistory: FC<ProfileWorkHistoryProps> = ({ workHistory }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employmentEntry, setEmploymentEntry] = useState<Employment>(emptyEmploymentEntry);

  const openModal = (employmentEntry: Employment = emptyEmploymentEntry) => {
    setIsModalOpen(true);
    setEmploymentEntry(employmentEntry);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEmploymentEntry(emptyEmploymentEntry);
  };

  return (
    <div>
      <EmploymentModal isOpen={isModalOpen} closeModal={closeModal} initialEmploymentValues={employmentEntry} />

      <Button variant="secondary" onClick={() => openModal()}>
        + Add New
      </Button>

      <div className="flex flex-col py-8 gap-y-8">
        {workHistory.map((workHistoryEntry) => (
          <EmploymentEntry
            key={`${workHistoryEntry.company}-${workHistoryEntry.dateRange}`}
            workHistoryEntry={workHistoryEntry}
            editSectionHandler={openModal}
            withEdit
          />
        ))}
      </div>
    </div>
  );
};

export default ProfileWorkHistory;
