import React, { FC } from 'react';
import { paths } from '#pages/paths';
import { Button, Icon } from '#components/Index';

const EmployerViewInfo: FC = () => {
  return (
    <div className="bg-blue-500 p-4 flex flex-row justify-center">
      <div className="flex items-center gap-x-4">
        <div className="flex items-center gap-x-2">
          <Icon.EyeFill size={5} className="text-blue-300" />

          <span className="text-white">This is what your profile looks like when viewed by an employer</span>
        </div>

        <Button variant="outline-white" to={paths.talentProfile()}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default EmployerViewInfo;
