import React, { FC } from 'react';
import Together from '#assets/about/together.webp';
import BlockQuote from '#components/BlockQuote/BlockQuote';
import KateClap from '#assets/people/kateClapp.webp';
import MonthlySocialsOne from '#assets/about/monthly-socials-one.webp';
import MonthlySocialsTwo from '#assets/about/monthly-socials-two.png';
import WeWorkOfficePNG from '#assets/about/we-work-office.png';

const Team: FC = () => {
  return (
    <div className="flex flex-col justify-center items-center max-w-5xl m-auto my-20">
      <div className="px-variable-sm text-center">
        <h2 className="text-display-md font-serif">The Hiperpool team</h2>
        <p className="max-w-3xl m-auto mt-8">
          A growing, international, diverse, hard-working, fun-loving, and dedicated team inspired by the positive
          impact we’re having on our industry and the amazing talent we work with.
        </p>
      </div>
      <img
        src={Together}
        alt="Annual Christmas party with Hiperpool, 2022"
        className="mt-16"
        loading="lazy"
        height="470"
        width="1024"
      />
      <p className="text-sm text-gray-400 mt-4 mb-8">Our annual Christmas party</p>
      <BlockQuote
        image={
          <img
            src={KateClap}
            alt="Kate Clapp, Talent Sponsor at Hiperpool"
            className="right-0 top-12 w-[211px] h-[211px]"
            height="211"
            width="211"
            loading="lazy"
          />
        }
        name="Kate Clapp"
        jobDescription="Talent Sponsor"
        className="sm:grid-cols-[auto_1fr] mx-6"
      >
        <p>
          {
            "What sets Hiperpool apart, and why I joined, was that we're not only setting a new standard for recruitment, but we provide a human and personalised experience that many online platforms lack. It's been exciting to be a part of our growth and I can't wait to see how we continue to innovate and evolve in the future!"
          }
        </p>
      </BlockQuote>
      <div className="flex flex-col md:flex-row mt-16 mb-6 gap-8 mx-6">
        <figure>
          <img
            src={MonthlySocialsOne}
            width="317"
            height="317"
            className="w-[317px] h-[317px] object-contain"
            alt="Hiperpool team is playing in extended reality games"
            loading="lazy"
          />
          <figcaption className="text-sm text-gray-400">Team socials</figcaption>
        </figure>
        <figure>
          <img
            src={MonthlySocialsTwo}
            width="317"
            height="317"
            className="w-[317px] h-[317px] object-contain"
            alt="Member of Hiperpool playing at Potters Fields Park"
            loading="lazy"
          />
        </figure>
        <figure>
          <img
            src={WeWorkOfficePNG}
            width="317"
            height="317"
            className="w-[317px] h-[317px] object-contain"
            alt=""
            loading="lazy"
          />
          <figcaption className="text-sm text-gray-400">Our WeWork office</figcaption>
        </figure>
      </div>
    </div>
  );
};

export default Team;
