import React, { FC } from 'react';
import { Selectable } from '#components/Index';
interface InterestedFirmsFormProps {
  firmTypeIdsAndNames: { [key: string]: string };
  selectedStepIds: string[];
  handleChange: (id: string) => void;
}

const InterestedFirmsForm: FC<InterestedFirmsFormProps> = ({ firmTypeIdsAndNames, handleChange, selectedStepIds }) => {
  return (
    <div className="rich-text text-hpblack w-full">
      <h1>
        What <span className="text-green-900">types of firms</span> are you interested in?
      </h1>
      <p>Select all that apply</p>

      <div className="flex flex-col gap-2 mt-8">
        {Object.entries(firmTypeIdsAndNames).map(([id, name]) => {
          return (
            <Selectable
              key={id}
              id={id}
              type="checkbox"
              size="lg"
              onChange={() => handleChange(id)}
              checked={selectedStepIds.includes(id)}
            >
              {name}
            </Selectable>
          );
        })}
      </div>
    </div>
  );
};

export default InterestedFirmsForm;
