import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: { slug: string };
  success: {
    regions: Array<{ id: string; name: string }>;
    privacyCompanies: Array<{ id: number; name: string }>;
    coachingTypes: Array<{ id: number; name: string }>;
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/public/coaches/filter_options');
