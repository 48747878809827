import React from 'react';
import { useStepController } from '../../StepControllerContext';
import { Step, TitleSlideBody } from '../../CurrentStep';
import { Icon, CircleGraphic } from '#components/Index';
import { OnboardingTypeCard } from './OnboardingTypeCard';
import * as apiLimitedOnboarding from '#api/endpoints/onboarding/limitedOnboarding';
import * as apiFullOnboarding from '#api/endpoints/onboarding/fullOnboarding';

const OnboardingType: Step = {
  id: 'user-type',
  displayName: 'User type',
  onDarkBackground: true,
  Body: () => {
    const { hideFullOnboardingSteps, showFullOnboardingSteps, next } = useStepController();

    return (
      <div>
        <TitleSlideBody title="Select level of access" surtitle="Access level" />

        <div className="grid gap-4 mt-16 lg:grid-cols-3 xl:grid-cols-9">
          <OnboardingTypeCard
            title="Limited member sign up"
            subtitle="Access to insights and coaching only"
            crossListItems={['No access to job opportunities']}
            checkmarkListItems={[
              'Access to career & interview coaching',
              'Access to exclusive member insights',
              'Free to join',
            ]}
            buttonText="Start limited sign up"
            hint={
              <>
                <Icon.Star size={3} className="text-green-800 mr-1" aria-hidden="true" />
                <span>Less than 1 min</span>
              </>
            }
            circleIcon={
              <CircleGraphic className="w-12 h-12 max-sm:hidden">
                <CircleGraphic.Quarter x={1} y={1} color="green-900" rotate={90} />
                <CircleGraphic.Quarter x={2} y={1} color="green-500" />
                <CircleGraphic.Quarter x={1} y={2} color="green-500" />
                <CircleGraphic.Quarter x={2} y={2} color="hpblack" rotate={-90} />
              </CircleGraphic>
            }
            action={async () => {
              await apiLimitedOnboarding.request();
              hideFullOnboardingSteps();
              next();
            }}
          />

          <div className="lg:col-span-3 xl:col-span-1 flex items-center justify-center">
            <span className="text-white text-lg font-semibold">OR</span>
          </div>

          <OnboardingTypeCard
            title="Full member sign up"
            subtitle="Full access to platform and job opportunities"
            checkmarkListItems={[
              'Access to job opportunities',
              'Access to career & interview coaching',
              'Access to exclusive member insights',
              'Free to join',
            ]}
            buttonText="Start full sign up"
            hint={
              <>
                <Icon.Star size={3} className="text-green-800 mr-1" aria-hidden="true" />
                <span>Approx. 2 mins</span>
              </>
            }
            circleIcon={
              <CircleGraphic className="w-12 h-12 max-sm:hidden">
                <CircleGraphic.Quarter x={1} y={1} color="hpblack" rotate={180} />
                <CircleGraphic.Quarter x={2} y={1} color="green-500" rotate={90} />
                <CircleGraphic.Quarter x={1} y={2} color="green-500" rotate={270} />
                <CircleGraphic.Quarter x={2} y={2} color="green-900" rotate={0} />
              </CircleGraphic>
            }
            action={async () => {
              await apiFullOnboarding.request();
              showFullOnboardingSteps();
              next();
            }}
          />
        </div>
      </div>
    );
  },
  Footer: () => null,
};

export default OnboardingType;
