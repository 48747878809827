import React, { FC } from 'react';
import { Button, CircleGraphic } from '#components/Index';
import HeroBanner from '#components/HeroBanner/HeroBanner';
import { paths } from '#pages/paths';
import Man from '#assets/homepage/interviewPrep/visuals-ufK.webp';
import Woman from '#assets/homepage/interviewPrep/linkedin-sales-solutions.webp';

const Image = () => (
  <div className="relative xl:self-end self-center">
    <CircleGraphic
      cols={4}
      rows={3}
      className="w-full sm:w-[50vw] max-w-xs sm:max-w-[720px] sm:-translate-x-[10vw] h-auto"
    >
      <CircleGraphic.Quarter x={2} y={1} color="green-500" rotate={90} />
      <CircleGraphic.CircleImage
        x={3}
        y={1}
        radius={1}
        color="green-500"
        src={Woman}
        alt="woman using a laptop to have a video call"
      />
      <CircleGraphic.CircleImage
        x={2}
        y={2}
        radius={0.5}
        color="green-500"
        src={Man}
        alt="webcam view of man smiling"
      />
      <CircleGraphic.Quarter x={1} y={3} color="green-900" />
      <CircleGraphic.Quarter x={2} y={3} color="green-500" rotate={-90} />
      <CircleGraphic.Quarter x={3} y={3} color="green-900" rotate={180} />
    </CircleGraphic>
  </div>
);

const Text = () => (
  <>
    <div className="text-display-md font-serif lg:text-display-lg xl:text-display-xl max-w-md">
      Interview & career coaching with the best in the business<span className="text-green-500">.</span>
    </div>
    <div className="text-lg lg:text-xl my-7 sm:my-10 max-w-lg">
      Access personal coaching with experts and top interviewers from McKinsey, BCG, Bain and more so you can land the
      offer of your dreams.
    </div>
    <Button to={paths.coaches()} size="large">
      Browse coaches
    </Button>
  </>
);

const Background = () => (
  <CircleGraphic className="absolute right-0 bottom-0 w-variable-lg translate-y-1/2 min-w-[6rem] max-w-[11.25rem] h-auto">
    <CircleGraphic.Quarter x={2} y={1} color="green-500" rotate={180} />
    <CircleGraphic.Circle x={1} y={1} color="green-900" radius={0.5} />
    <CircleGraphic.Quarter x={2} y={2} color="green-900" rotate={-90} />
  </CircleGraphic>
);

const Header: FC = () => (
  <HeroBanner imageContent={Image()} textContent={Text()} bgContent={Background()} direction="rtl" colorScheme="dark" />
);

export default Header;
