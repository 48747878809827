import React, { FC, useEffect, useState, useCallback } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { CustomProfileWorkAuthorization } from '#src/types';
import { LocationOptionProps } from '#components/Form/Index';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { request as apiUpdateLocations } from '#api/endpoints/talents/profile/updateLocations';
import { request as apiUpdateWorkAuthorizations } from '#api/endpoints/talents/profile/updateWorkAuthorizations';
import { useSteps } from '../../BaseProfileEditModal';
import WhereToWorkForm from '#components/TalentSharedForms/WhereToWork/Form';

const WhereToWorkStep: FC = () => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { setStepCallback, setNextDisabled } = useSteps();

  const [selectedLocations, setSelectedLocations] = useState<LocationOptionProps[]>([]);
  const [selectedWorkAuthorizations, setSelectedWorkAuthorizations] = useState<number[]>([]);
  const [invalidWorkAuthorizations, setInvalidWorkAuthorizations] = useState<boolean>(false);
  const [customProfileWorkAuthorization, setCustomProfileWorkAuthorization] = useState<CustomProfileWorkAuthorization>({
    workAuthorizationId: null,
    name: '',
  });

  // Set the selections based on the talent data
  useEffect(() => {
    if (profilePreferencesData == null) return;

    setSelectedLocations(
      profilePreferencesData.profilePreferences.locations.map((location) => ({ ...location, checked: true }))
    );
    setSelectedWorkAuthorizations(profilePreferencesData.profilePreferences.workAuthorizationIds);
    setCustomProfileWorkAuthorization(profilePreferencesData.profilePreferences.customProfileWorkAuthorization);
  }, [profilePreferencesData]);

  // Prevent the user from moving to the next step if they haven't selected any value or if the work authorizations are invalid (missing)
  useEffect(() => {
    setNextDisabled(selectedLocations.length === 0 || invalidWorkAuthorizations);
  }, [setNextDisabled, selectedLocations, invalidWorkAuthorizations]);

  const stepCallback = useCallback(async () => {
    const selectedPlaceIds = selectedLocations
      .filter((location) => location.checked)
      .map((location) => location.placeId);

    if (selectedPlaceIds.length === 0 || selectedWorkAuthorizations.length === 0) return false;

    try {
      await apiUpdateLocations({ placeIds: selectedPlaceIds });
      await apiUpdateWorkAuthorizations({
        workAuthorizations: {
          workAuthorizationIds: selectedWorkAuthorizations,
          customProfileWorkAuthorization,
        },
      });
      createSuccessToast({ title: 'Locations and work authorizations' });
      return true;
    } catch {
      createErrorToast({ title: 'Locations and work authorizations' });
      return false;
    }
  }, [selectedLocations, selectedWorkAuthorizations, customProfileWorkAuthorization]);

  useEffect(() => {
    setStepCallback(() => stepCallback);
  }, [setStepCallback, stepCallback]);

  return (
    <WhereToWorkForm
      selectedLocations={selectedLocations}
      setSelectedLocations={setSelectedLocations}
      selectedWorkAuthorizations={selectedWorkAuthorizations}
      setSelectedWorkAuthorizations={setSelectedWorkAuthorizations}
      customProfileWorkAuthorization={customProfileWorkAuthorization}
      setCustomProfileWorkAuthorization={setCustomProfileWorkAuthorization}
      setInvalidWorkAuthorizations={setInvalidWorkAuthorizations}
    />
  );
};

export default WhereToWorkStep;
