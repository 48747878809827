import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Step, TitleSlideBody, StepNextButton } from '../../CurrentStep';
import { Icon, Button } from '#components/Index';
import { useTalentDataController } from '../../TalentDataController';
import { useStepController } from '../../StepControllerContext';

import { OnboardingTalentRecentPosition, signupType } from '#root/src/types';

import { Linkedin } from './Linkedin';
import { RecentPosition } from './RecentPosition';
import { CvForm } from './CvForm';

const EmploymentHistory: Step = {
  id: 'work-history',
  onDarkBackground: true,
  Body: () => {
    const [nextStepDisabled, setNextStepDisabled] = useState<boolean>(true);
    const [selectedType, setSelectedType] = useState<signupType>('cv');

    const [linkedinUrl, setLinkedinUrl] = useState<string>('');
    const [cv, setCv] = useState<File | null>(null);
    const [recentWorkEntry, setRecentWorkEntry] = useState<OnboardingTalentRecentPosition>({
      id: null,
      company: '',
      position: '',
      location: '',
      startDate: '',
      endDate: '',
      description: null, // TODO: missing in the designs but present in the old version of the form
      current: false,
    });

    const { next, previous, isLimitedOnboarding } = useStepController();
    const { talentData, updateTalentData, sendConfirmationEmail, finishOnboarding } = useTalentDataController();

    useEffect(() => {
      if (talentData != null) {
        if (talentData.linkedinUrl != null) {
          setLinkedinUrl(talentData.linkedinUrl);
        }

        if (talentData.workHistory != null) {
          setRecentWorkEntry(talentData.workHistory);
        }
      }
    }, [talentData]);

    // Prevent the user from moving to the next step if they haven't selected any value
    useEffect(() => {
      const linkedinUrlPresent = linkedinUrl !== '';
      const workEntryValid =
        recentWorkEntry.position !== '' &&
        recentWorkEntry.company !== '' &&
        recentWorkEntry.location !== '' &&
        recentWorkEntry.startDate !== '' &&
        (recentWorkEntry.endDate !== '' || recentWorkEntry.current);

      setNextStepDisabled(
        (selectedType === 'linkedin' && !linkedinUrlPresent) ||
          (selectedType === 'work_history' && !workEntryValid) ||
          (selectedType === 'cv' && cv === null)
      );
    }, [selectedType, linkedinUrl, recentWorkEntry, cv, setNextStepDisabled]);

    const StepContent = useMemo(() => {
      switch (selectedType) {
        case 'cv':
          return <CvForm cv={cv} setCv={setCv} alternateAction={() => setSelectedType('linkedin')} />;
        case 'linkedin':
          return (
            <Linkedin
              linkedinUrl={linkedinUrl}
              setLinkedinUrl={setLinkedinUrl}
              alternateAction={() => setSelectedType('work_history')}
            />
          );
        case 'work_history':
          return <RecentPosition recentWorkEntry={recentWorkEntry} setRecentWorkEntry={setRecentWorkEntry} />;
        default:
          return null;
      }
    }, [selectedType, linkedinUrl, cv, recentWorkEntry]);

    const backAction = () => {
      if (selectedType === 'cv') {
        previous();
      } else if (selectedType === 'linkedin') {
        setSelectedType('cv');
      } else if (selectedType === 'work_history') {
        setSelectedType('linkedin');
      }
    };

    const handleConfirmationEmailDelivery = useCallback(async () => {
      if (talentData == null) return;

      if (!talentData.confirmed) {
        await sendConfirmationEmail();
        next();
      } else {
        await finishOnboarding();
      }
    }, [finishOnboarding, next, sendConfirmationEmail, talentData]);

    const onNextClick = useCallback(async () => {
      if (talentData == null) return;

      const newTalentData = {
        ...talentData,
        signupType: selectedType,
        linkedinUrl,
        workHistory: recentWorkEntry,
        cv,
      };

      const step = isLimitedOnboarding(newTalentData.onboardingStep) ? 'work_experience_limited' : 'work_experience';
      const result = await updateTalentData(newTalentData, step);

      if (result.success === true) {
        await handleConfirmationEmailDelivery();
      }
    }, [
      cv,
      selectedType,
      linkedinUrl,
      recentWorkEntry,
      isLimitedOnboarding,
      talentData,
      updateTalentData,
      handleConfirmationEmailDelivery,
    ]);

    return (
      <div>
        <TitleSlideBody title="Work History" surtitle="" />
        <div className="rich-text mt-16 flex flex-col items-center">
          <div>
            <Button variant="link" size="small" className="text-white -ml-4" onClick={backAction}>
              <Icon.ArrowLeftLine size={4} className="inline-block mr-2" />
              Back
            </Button>
            <div className="p-12 rounded-lg bg-gray-50 max-w-xl mx-auto text-sm sm:min-w-[36rem]">
              {StepContent}
              <div className="flex justify-center pt-4">
                <StepNextButton disabled={nextStepDisabled} text="Finish" onClick={onNextClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
  Footer: () => null,
};

export default EmploymentHistory;
