import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors } from '#src/types';

interface Payload {
  init: {
    opportunityId: number;
    feedback: {
      company: boolean;
      position: boolean;
      compensation: boolean;
      team: boolean;
      location: boolean;
      other: boolean;
      feedback_field: string;
    };
  };
  success: never;
  fail: { errors: Errors<'base' | 'candidate'>; status: number };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(POST, `/talents/opportunities/${params.opportunityId}/replies/maybe_interested`)(params);
