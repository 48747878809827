import React, { FC, ReactElement, useEffect } from 'react';
import { uniqueId } from 'lodash-es';
import usePreviousValue from '#src/lib/usePreviousValue';
import textBoxStyles from '#components/Form/styles/TextBox.module.css';
import formStyles from '#components/Form/styles/FormInput.module.css';
import selectStyles from './Select.module.css';

export interface Props
  extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  label?: string;
  error?: string;
  description?: string;
  children?: ReactElement[];
  className?: string;
}
// TODO: add tests for the Component
const Select: FC<Props> = ({ label, error, description, className, required = false, ...rest }) => {
  const uniqId = uniqueId('select');
  const hasError = error !== undefined && error !== '';
  const hasDescription = description !== undefined && description !== '';
  const prevChildren = usePreviousValue(rest.children);
  const classesToAddToWrapper = className ?? '';

  const renderDetails = () => {
    if (hasError) {
      return (
        <span className={formStyles.error} data-testid="error">
          {error}
        </span>
      );
    }
    if (hasDescription) {
      return (
        <span className={formStyles.description} data-testid="description">
          {description}
        </span>
      );
    }
    return <></>;
  };

  useEffect(
    function propagateFirstValueSelectedEvenWhileOptionsChanging() {
      const { children, onChange } = rest;
      if (children == null || children[0] == null) {
        return;
      }
      const areChildrenChanged =
        (prevChildren != null ? prevChildren : []).map((e) => e.key).join() !==
        (children != null ? children : []).map((e) => e.key).join();
      if (areChildrenChanged && children[0].props.value != null && onChange != null) {
        // eslint-disable-next-line eqeqeq
        const areValuesCompatible = children.find((a) => a.props.value == rest.value);
        if (areValuesCompatible == null) {
          onChange(children[0].props.value);
        }
      }
    },
    [rest, prevChildren]
  );

  return (
    <div className={`${formStyles['input-wrapper']} ${classesToAddToWrapper}`}>
      <label htmlFor={uniqId} className={formStyles.label}>
        <span>{label}</span>
        {required && (
          <span aria-hidden={true} className="text-gray-500 font-normal">
            *
          </span>
        )}
      </label>
      <select
        {...rest}
        id={uniqId}
        className={`${textBoxStyles.input} ${selectStyles.select} ${hasError ? textBoxStyles[`input--error`] : ''}`}
      >
        {rest.children}
      </select>
      {renderDetails()}
    </div>
  );
};

export default Select;
