import React, { FC, useState } from 'react';
import { TextInput, Success } from '#components/Form/Index';
import { Button, ErrorAlert, Icon } from '#components/Index';
import { paths } from '#pages/paths';
import { useSignInController } from './FormController';
import { Link } from 'react-router-dom';
import ActiveYourAccountMessage from '../activateAccount/Message';

const SignInForm: FC = () => {
  const [isNotActivated, setIsNotActivated] = useState(false);

  const {
    form: {
      register,
      handleSubmit,
      formState: { errors, isSubmitSuccessful },
      watch,
    },
    submitForm,
    baseErrors,
  } = useSignInController();
  const email = watch('email');

  const onSubmit = handleSubmit((values) =>
    submitForm(values).catch((e?: { status?: number }) => {
      if (e != null && e.status === 418) {
        setIsNotActivated(true);
      }
    })
  );

  if (isNotActivated) {
    return <ActiveYourAccountMessage email={email} />;
  }

  if (isSubmitSuccessful) {
    return (
      <Success title="Reset instructions have been sent" icon={<Icon.MailCheckLine size={6} />}>
        You will receive an email with instructions on how to reset your password in a few minutes.
      </Success>
    );
  }

  return (
    <>
      <h1 className="text-display-sm font-serif">Reset password</h1>
      <form onSubmit={onSubmit} className="w-full grid text-sm gap-6 text-gray-700 font-medium">
        <TextInput label="Email address" type="email" error={errors.email?.message} {...register('email')} />
        <ErrorAlert text={baseErrors} />
        <Button type="submit" className="xs:mx-auto" size="large">
          Send reset instructions
        </Button>
        <Link to={paths.signIn()} className="xs:mx-auto link">
          Back to sign in
        </Link>
      </form>
    </>
  );
};

export default SignInForm;
