import React, { FC, useEffect, Dispatch } from 'react';
import { useGetIndustryTypes } from './ExperienceIndustriesData';
import { OptionProps, SearchSelect } from '#components/Index';

interface ExperienceIndustriesFormProps {
  options: OptionProps[];
  setOptions: Dispatch<React.SetStateAction<OptionProps[]>>;
  initiallySelectedIds: number[];
}

const ExperienceIndustriesForm: FC<ExperienceIndustriesFormProps> = ({ options, setOptions, initiallySelectedIds }) => {
  const { data: industryTypesData } = useGetIndustryTypes();

  // Load the industry types options and set the selected ones
  useEffect(() => {
    if (industryTypesData == null) return;

    const industryTypes = industryTypesData.industryTypes;

    if (industryTypes != null) {
      setOptions(
        industryTypes.map((option) => ({
          id: option.id.toString(),
          name: option.name,
          checked: initiallySelectedIds.includes(option.id),
        }))
      );
    }
  }, [setOptions, initiallySelectedIds, industryTypesData]);

  const handleChange = (newOptions: OptionProps[]) => {
    setOptions(newOptions);
  };

  return (
    <div className="rich-text text-hpblack w-full">
      <h1>
        What are the main <span className="text-green-900">industries</span> you have experience in?
      </h1>
      <p>Search and select all that apply</p>

      <div className="flex flex-col mt-8 pb-4">
        <SearchSelect label="Select an industry" handleChange={handleChange} options={options} />
      </div>
    </div>
  );
};

export default ExperienceIndustriesForm;
