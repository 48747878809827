import React, { FC, JSXElementConstructor, ReactElement } from 'react';
import { useUserDetails } from '#src/AppData';

interface Props {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}

const UserWrapper: FC<Props> = ({ children }) => {
  const { user, isLoggedIn } = useUserDetails();

  return <>{React.Children.map(children, (child) => React.cloneElement(child, { user, isLoggedIn }, null))}</>;
};

export default UserWrapper;
