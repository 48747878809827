import React, { FC } from 'react';

import Layout from '../Layout';

import SignUpForm from './Form';

const SignUp: FC = () => {
  return (
    <Layout
      header="Take your career to the next level"
      subHeader="Join Hiperpool to access all our career-boosting resources. Membership is 100% free."
    >
      <SignUpForm />
    </Layout>
  );
};

export default SignUp;
