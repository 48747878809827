import { useQuery } from 'react-query';
import { useOutletContext } from 'react-router-dom';

import { ReactQueryOptions } from '#src/types';
import * as apiGetUserDetails from '#root/src/api/endpoints/session/getUserDetails';

import { User } from './types';

export enum KeysEnum {
  global = 'global',
  user = 'user',
  details = 'details',
}

type GetApiPayload = apiGetUserDetails.Type['success']['payload'];
export const useGetUserDetails = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.global, KeysEnum.user, KeysEnum.details],
    async () =>
      await apiGetUserDetails
        .request()
        .then((response) =>
          response.user != null ? { ...response, isLoggedIn: true } : { user: undefined, isLoggedIn: false }
        )
        .catch(async (e) => {
          return await Promise.resolve(e);
        }),
    {
      ...options,
    }
  );

export interface UserContext {
  user: User | undefined;
  isLoggedIn: boolean;
}
export const useUserDetails = () => useOutletContext<UserContext>();
