import React, { FC, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { RichTextInput } from '#components/Form/Index';
import { Button } from '#components/Index';
import { useSummaryController } from './FormController';

interface EditableSummaryProps {
  setEditMode: (editMode: boolean) => void;
  summary: string;
}

const EditableSummary: FC<EditableSummaryProps> = ({ summary, setEditMode }) => {
  const { form, submitForm } = useSummaryController({ closeEditMode: () => setEditMode(false) });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;

  useEffect(() => {
    setValue('summary', summary);
  }, [summary, setValue]);

  const onSubmit = handleSubmit((values) => submitForm(values));

  return (
    <form onSubmit={onSubmit} className="w-full">
      <Controller
        name="summary"
        control={control}
        render={({ field }) => (
          <RichTextInput
            label=""
            initialValue={summary}
            onChange={(e) => field.onChange(e.target.value)}
            error={errors.summary?.message}
          />
        )}
      />

      <Button type="submit" variant="primary" className="mt-4">
        Save
      </Button>
    </form>
  );
};

export default EditableSummary;
