import React, { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { Footer } from '#components/Index';

import { EmployerNavbar } from './elements/EmployerNavbar';
import { useUserDetails } from '#src/AppData';

interface Props {
  withFooter?: boolean;
  withGrayBackground?: boolean;
}
const EmployerLayout: FC<Props> = ({ withFooter = true, withGrayBackground = false }) => {
  const context = useUserDetails();
  const mainClasses = withGrayBackground ? 'bg-gray-50' : '';

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Hiperpool - For Employers</title>
          <meta
            name="description"
            content="Hiperpool's proven approach to recruitment expertly blends time-tested executive search methods with our dynamic online community of pre-vetted talent. This powerful combination ensures a consistent pipeline of highly engaged candidates for strategic roles in consulting, strategy, and investing. Our strategy excels in accessing a diverse talent pool, reaching both active job seekers and passive candidates. Choose Hiperpool for a reliable, effective solution in filling your critical vacancies with top-tier professionals."
          />
        </Helmet>
        <EmployerNavbar />
        <main className={mainClasses}>
          <Outlet context={context} />
        </main>
        {withFooter && <Footer />}
      </HelmetProvider>
    </>
  );
};

export default EmployerLayout;
