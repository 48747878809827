import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, NavLink, NavLinkProps, useLocation } from 'react-router-dom';

import styles from './Navbar.module.css';
import { ReactComponent as Logo } from '#assets/hiperpool-logo.svg';
import { paths } from '#pages/paths';
import { Button } from '../Button/Button';
import { ReactComponent as Account } from '#assets/account.svg';
import ProfileMenuCard from '../MenuCard/ProfileMenuCard';
import { UserWrapper } from '../Index';

interface Props {
  navItems: React.ReactNode;
  buttonItems: React.ReactNode;
  testid?: string;
}

export const Navbar: FC<Props> = ({ navItems, buttonItems, testid }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const menuButtonContainerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const handleMenuButtonClick = () => {
    setMobileNavOpen(!mobileNavOpen);
    setIsMobile(!mobileNavOpen);
  };

  useEffect(() => {
    setTimeout(() => setMobileNavOpen(false), 400);
  }, [location]);

  useEffect(function verifyIfNavHidden() {
    function resizeListener() {
      setIsMobile(getComputedStyle(menuButtonContainerRef.current as HTMLDivElement).display !== 'none');
    }

    addEventListener('resize', resizeListener);
    resizeListener();

    return () => {
      removeEventListener('resize', resizeListener);
    };
  });

  return (
    <header
      className={`flex items-center bg-hpblack text-white h-14 z-20 inset-0 ${mobileNavOpen ? 'sticky' : 'relative'}`}
      data-testid={testid}
    >
      <Link to={paths.root()} className={`${styles.link} h-full mr-5`}>
        <Logo aria-label="Hiperpool" />
      </Link>

      <nav
        id="header-nav"
        className={`${styles.nav} ${mobileNavOpen ? 'max-xl:h-full-content' : 'max-xl:h-0'}`}
        aria-hidden={isMobile ? !mobileNavOpen : undefined}
      >
        <div className="flex flex-col xl:flex-row row-gap-5 grow">{navItems}</div>
        <div className="flex gap-2 items-center justify-center h-14 max-xl:bg-blue-100/10 xl:mr-6">{buttonItems}</div>
      </nav>

      <div className="xl:hidden ml-auto mr-4" ref={menuButtonContainerRef}>
        <Button
          size="small"
          variant="link"
          circle
          aria-label="Menu"
          onClick={handleMenuButtonClick}
          className="[@media(hover:none)]:duration-500 [@media(hover:none)]:delay-500 hover:delay-[0ms]"
          aria-controls="header-nav"
          aria-expanded={mobileNavOpen}
        >
          <MenuButtonSVG open={mobileNavOpen} />
        </Button>
      </div>
    </header>
  );
};

export const NotificationIcon: FC<{ dot?: boolean }> = ({ dot = true }) => (
  <svg width="22" height="28" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path d="M18.33 18.58h1.84v1.84H1.83v-1.84h1.84v-6.41a7.33 7.33 0 0 1 14.66 0v6.41Zm-1.83 0v-6.41a5.5 5.5 0 1 0-11 0v6.41h11Zm-8.25 3.67h5.5v1.83h-5.5v-1.83Z" />
    {dot && <circle cx="18" cy="5" r="5" className="stroke-hpblack fill-green-500 stroke-2" />}
  </svg>
);

export const AccountButtonItem = () => {
  const profileMenuId = 'profile-menu';
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  return (
    <div className="relative flex justify-center">
      <Button
        variant="link"
        size="small"
        className="-mr-2"
        onClick={() => setProfileMenuOpen(!profileMenuOpen)}
        aria-expanded={profileMenuOpen}
        aria-controls={profileMenuId}
      >
        <div className="-ml-1">Account</div> <Account className="-my-1 -mr-3" />
      </Button>
      <div className="absolute max-xl:mx-auto max-xl:bottom-12 xl:top-12 xl:right-0">
        <UserWrapper>
          <ProfileMenuCard open={profileMenuOpen} id={profileMenuId} user={null} />
        </UserWrapper>
      </div>
    </div>
  );
};

export const NavItem: FC<NavLinkProps> = (props) => (
  <NavLink className={`${styles.link} ${styles['nav-item']}`} {...props}>
    {props.children}
  </NavLink>
);

export const NavItemLink: FC<{ to: string; children: React.ReactNode }> = ({ to, children }) => (
  <a href={to} className={`${styles.link} ${styles['nav-item']}`}>
    {children}
  </a>
);

const MenuButtonSVG: FC<{ open: boolean }> = ({ open }) => (
  <svg viewBox="0 0 10 10" width="20" height="20" stroke="currentColor" fill="none">
    <path d="M1 2h8" className={`transition origin-[50%_20%] ${open ? 'rotate-45 translate-y-[30%]' : ''}`} />
    <path d="M1 5h8" className={`transition origin-[50%_50%] ${open ? 'scale-x-0' : ''}`} />
    <path d="M1 8h8" className={`transition origin-[50%_80%] ${open ? '-rotate-45 translate-y-[-30%]' : ''}`} />
  </svg>
);
