import { Errors } from '#root/src/types';
import { createAction, PUT, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    employer: {
      firstName: string;
      lastName: string;
      city: string;
      country: string;
      phone?: string;
      position: string;
      email: string;
    };
    token: string;
  };
  success: { redirectUrl: string };
  fail: {
    errors: Errors<'base' | 'firstName' | 'lastName' | 'phone' | 'position' | 'city' | 'country' | 'email'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(PUT, '/public/team_members/activate');
