import React, { FC } from 'react';

import PlanCard from '#components/PlanCard/PlanCard';
import { Button, CircleGraphic } from '#components/Index';
import { paths } from '#pages/paths';

const PricingPlan: FC = () => {
  return (
    <div className="px-variable-sm pt-16 pb-6 bg-gray-50">
      <h2 className="text-display-md font-serif text-center">Tailored solutions based on your hiring needs</h2>
      <div className="mt-12 flex gap-8 max-lg:items-center justify-center flex-col lg:flex-row">
        <PlanCard
          title="Contingent"
          subtitle="For junior, generalist roles"
          icon={
            <CircleGraphic className="h-12 w-12">
              <CircleGraphic.Quarter color="hpblack" rotate={180} />
              <CircleGraphic.Quarter color="green-500" x={2} rotate={90} />
              <CircleGraphic.Quarter color="green-500" y={2} rotate={-90} />
              <CircleGraphic.Quarter color="green-900" x={2} y={2} />
            </CircleGraphic>
          }
          list={[
            'We leverage our platform’s talent pool to source candidates',
            'We screen each candidate and put forward only the best',
            'Support throughout interview and offer process',
            'Dedicated Client Success Manager',
          ]}
        />
        <PlanCard
          title="Retained"
          subtitle="For senior or specialist roles"
          icon={
            <CircleGraphic className="h-12 w-12">
              <CircleGraphic.Quarter color="green-900" rotate={90} />
              <CircleGraphic.Quarter color="green-500" x={2} />
              <CircleGraphic.Quarter color="green-500" y={2} />
              <CircleGraphic.Quarter color="hpblack" x={2} y={2} rotate={-90} />
            </CircleGraphic>
          }
          list={[
            'Consultation on job description, profiles, compensation',
            'Dedicated recruiter conducts a full-market search',
            'We leverage our platform’s talent pool',
            'In-depth vetting interviews with every candidate put forward',
            'Dedicated Client Success Manager',
            'Regular meetings to align on progress and market feedback',
          ]}
        />
        <PlanCard
          title="Project"
          subtitle="For multiple hires (2-50)"
          icon={
            <CircleGraphic className="h-12 w-12">
              <CircleGraphic.Quarter color="green-900" rotate={-90} />
              <CircleGraphic.Quarter color="green-500" x={2} />
              <CircleGraphic.Quarter color="green-500" y={2} rotate={-90} />
              <CircleGraphic.Quarter color="hpblack" x={2} y={2} />
            </CircleGraphic>
          }
          list={[
            'Dedicated recruiter (or more) conducts a full-market search',
            'We leverage our platform’s talent pool',
            'In-depth vetting interviews with every candidate put forward',
            'Dedicated Client Success Manager',
            'Regular meetings to align on progress and market feedback',
            'Consultation on JD, profiles, compensation and market  ',
            'Hiperpool will act as an extension of your internal recruitment team',
            'Support on topics such as employer branding and improving diversity',
          ]}
        />
      </div>
      <div className="flex justify-center mt-12">
        <Button variant="secondary" size="large" to={paths.signUpEmployer()}>
          Get started
        </Button>
      </div>
    </div>
  );
};

export default PricingPlan;
