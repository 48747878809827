import React, { FC, useMemo } from 'react';
import { JobHistoryEntry } from '#src/types';
import { formatDistanceToNow, parseISO, format, differenceInDays } from 'date-fns';
import DefaultHistoryEntry from './DefaultHistoryEntry';
import PutForwardHistoryEntry from './PutForwardHistoryEntry';
import CandidateMessage from './CandidateMessage';
import EmployerMessage from './EmployerMessage';

interface JobHistoryItemProps extends JobHistoryEntry {
  isLast: boolean;
  companyLogoUrl: string;
}

const JobHistoryItem: FC<JobHistoryItemProps> = ({ type, date, message, isLast, companyLogoUrl }) => {
  const parsedDate = useMemo(() => {
    if (date === null) return '';

    try {
      const currentDate = new Date();
      const isoDate = parseISO(date);

      // Check the difference in days between now and the given date
      const daysDiff = differenceInDays(currentDate, isoDate);

      // If the date is less than 14 days old, format it with a relative date string
      if (daysDiff < 14) {
        return formatDistanceToNow(isoDate, { addSuffix: true });
      } else {
        // Otherwise, format it in a 'MMM d' style (e.g., "Aug 5")
        return format(isoDate, 'MMM d');
      }
    } catch {
      return '';
    }
  }, [date]);

  switch (type) {
    case 'candidate_put_forward':
      return <PutForwardHistoryEntry date={parsedDate} message={message} isLast={isLast} />;
    case 'talent_message':
      return <CandidateMessage date={parsedDate} message={message} isLast={isLast} />;
    case 'employer_message':
      return <EmployerMessage date={parsedDate} message={message} isLast={isLast} companyLogoUrl={companyLogoUrl} />;
    default:
      return <DefaultHistoryEntry date={parsedDate} message={message} isLast={isLast} />;
  }
};

export default JobHistoryItem;
