import React, { FC } from 'react';
import { CircleGraphic } from '#components/Index';

const TopLeftCircleGraphic = () => (
  <div className="absolute left-0 top-0 flex">
    <CircleGraphic cols={3} rows={4} className="-translate-y-1/2 w-variable-lg max-w-[12rem] min-w-[6rem] h-auto">
      <CircleGraphic.Half x={1} y={2} color="green-900" rotate={-90} />
      <CircleGraphic.Quarter x={2} y={2} color="green-900" rotate={90} />
      <CircleGraphic.Quarter x={3} y={2} color="green-500" rotate={180} />
      <CircleGraphic.Quarter x={1} y={3} color="green-500" rotate={-90} />
      <CircleGraphic.Quarter x={2} y={3} color="green-500" />
      <CircleGraphic.Quarter x={3} y={3} color="green-900" rotate={90} />
      <CircleGraphic.Quarter x={1} y={4} color="green-500" rotate={180} />
      <CircleGraphic.Quarter x={2} y={4} color="green-900" rotate={90} />
      <CircleGraphic.Circle x={3} y={4} color="green-500" radius={0.5} />
    </CircleGraphic>
    <CircleGraphic cols={3} rows={2} className="-translate-y-1/2 w-variable-lg max-w-[12rem] min-w-[6rem] h-auto">
      <CircleGraphic.Circle color="green-900" />
    </CircleGraphic>
  </div>
);

const BottomRightCircleGraphic = () => (
  <CircleGraphic className="absolute right-0 bottom-0 w-variable-lg max-w-[12rem] min-w-[6rem] h-auto">
    <CircleGraphic.Circle x={1} y={1} color="green-900" radius={0.5} className="max-sm:hidden" />
    <CircleGraphic.Quarter x={1} y={2} color="green-500" rotate={-90} className="max-sm:hidden" />
    <CircleGraphic.Quarter x={2} y={2} color="green-500" className="max-sm:hidden" />
    <CircleGraphic.Quarter x={3} y={2} color="green-900" className="max-sm:hidden" />
    <CircleGraphic.Quarter x={1} y={3} color="green-900" className="max-sm:hidden" rotate={180} />
    <CircleGraphic.Quarter x={2} y={3} color="green-500" rotate={90} />
    <CircleGraphic.Quarter x={3} y={3} color="green-900" rotate={180} />
    <CircleGraphic.Circle x={1} y={4} color="green-500" radius={0.5} />
    <CircleGraphic.Quarter x={2} y={4} color="green-900" rotate={180} />
    <CircleGraphic.Quarter x={3} y={4} color="green-500" rotate={-90} />
  </CircleGraphic>
);

interface Props {
  text: React.ReactNode;
  button: React.ReactNode;
}
const CallToActionSection: FC<Props> = ({ text, button }) => {
  return (
    <div className="relative isolate py-20 flex flex-col items-center bg-hpblack px-variable-lg">
      <TopLeftCircleGraphic />
      <BottomRightCircleGraphic />
      <h2
        className={`text-display-sm md:text-display-md font-serif font-normal flex justify-center py-12 text-white text-center`}
      >
        {text}
      </h2>
      {button}
    </div>
  );
};

export default CallToActionSection;
