import React, { FC } from 'react';
import { paths } from '#pages/paths';
import { Button, Icon } from '#components/Index';
import ChosenForYouImage from '#assets/opportunities/ChosenForYouBigReverted.webp';

const UpdateYourPreferences: FC = () => {
  return (
    <div className="w-full bg-hpblack rounded-lg shadow-md-dark flex justify-end md:justify-between">
      <div className="hidden md:flex flex-col justify-end">
        <img loading="lazy" src={ChosenForYouImage} alt="" className="object-cover object-bottom" />
      </div>

      <div className="flex flex-col justify-end p-8">
        <div className="text-white max-w-xl mb-4">
          <h2 className="font-serif text-display-sm mb-4 text-right">Looking to update your job preferences?</h2>
          <p className="text-right font-light text-lg">
            If you want to tell us about your recent experience or update <br /> the kind of opportunities you’re
            looking for, this can be done
            <br /> in the Profile & Preferences section of Hiperpool
          </p>
        </div>

        <div className="flex justify-end">
          <Button to={paths.talentPreferences()}>
            Update Profile & Preferences
            <Icon.ArrowRightLine size={4} aria-hidden="true" className="shrink-0 right-5 max-2xs:absolute" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpdateYourPreferences;
