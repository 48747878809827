import { Errors } from '#root/src/types';
import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    prospect: {
      firstName: string;
      lastName: string;
      phone?: string;
      position: string;
      companyName: string;
      email: string;
      timeZone: string;
    };
  };
  success: never;
  fail: {
    errors: Errors<'base' | 'firstName' | 'lastName' | 'phone' | 'position' | 'companyName' | 'email'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/prospects');
