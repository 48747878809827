import React, { FC, useState } from 'react';
import MenuCard from './MenuCard';
import * as logoutAPI from '#api/endpoints/session/logout';
import { generateOriginAppUrl } from '#root/src/lib/generateOriginAppUrl';
import menuStyles from './MenuCard.module.css';
import { Icon } from '../Index';
import { User } from '#root/src/types';
import BundlesModal from './BundlesModal';
import { paths } from '#pages/paths';

interface Props {
  id: string;
  open: boolean;
  user: User | null;
}

const ProfileMenuCard: FC<Props> = ({ id, open, user }) => {
  const [isBundleModalOpen, setBundleModalOpen] = useState(false);

  if (user == null) return null;

  const logout = async () =>
    await logoutAPI.request({}).then(async (res) => {
      window.location.href = generateOriginAppUrl({ path: res.redirectUrl });
    });

  return (
    <MenuCard id={id} open={open}>
      {user.type === 'Talent' && (
        <a href={paths.talentProfile()} className={`${menuStyles.item} ${menuStyles.link} [&>*]:translate-y-px`}>
          <Icon.UserLine size={4} aria-hidden="true" />
          <span>View profile</span>
        </a>
      )}

      {user.type === 'Employer' && (
        <>
          <a
            href={generateOriginAppUrl({ path: paths.employerCompanyProfile() })}
            className={`${menuStyles.item} ${menuStyles.link}`}
          >
            <Icon.Building size={4} aria-hidden="true" />
            <span>Company profile</span>
          </a>
          <a
            href={generateOriginAppUrl({ path: paths.employerTeamSettings() })}
            className={`${menuStyles.item} ${menuStyles.link}`}
          >
            <Icon.UserLine size={4} aria-hidden="true" />
            <span>Team Settings</span>
          </a>
          <a
            href={generateOriginAppUrl({ path: paths.employerAccountSettings() })}
            className={`${menuStyles.item} ${menuStyles.link}`}
          >
            <Icon.Settings size={4} aria-hidden="true" />
            <span>Account Settings</span>
          </a>
        </>
      )}

      {user.type === 'Talent' && (
        <>
          <a href={paths.talentSettings()} className={`${menuStyles.item} ${menuStyles.link}`}>
            <Icon.Settings size={4} aria-hidden="true" />
            <span>Account Settings</span>
          </a>
          <hr></hr>
          <div className={menuStyles.item}>
            <Icon.UserFrame size={4} aria-hidden="true" />
            <div>
              <div>
                Coaching credits: <span>{user.coachingSessionsCredits}</span>
              </div>
              <button
                onClick={() => setBundleModalOpen(true)}
                className={`${menuStyles['inline-link']} ${menuStyles['inline-link-has-icon']} text-xs font-medium`}
              >
                <span>Buy more credits</span>
                <Icon.ArrowRightLine size={3} aria-hidden="true" className="ml-px transition" />
              </button>
            </div>
          </div>
          <BundlesModal isOpen={isBundleModalOpen} onClose={() => setBundleModalOpen(false)} />
        </>
      )}
      {user.type === 'SuperAdmin' && (
        <>
          <a
            href={generateOriginAppUrl({ path: paths.adminDashboard() })}
            className={`${menuStyles.item} ${menuStyles.link}`}
          >
            <Icon.Settings size={4} aria-hidden="true" />
            <span>Dashboard</span>
          </a>
        </>
      )}

      <hr></hr>
      <button onClick={logout} className={`${menuStyles.item} ${menuStyles.link}`}>
        <Icon.LogoutBox size={4} aria-hidden="true" />
        <span>Sign out</span>
      </button>
    </MenuCard>
  );
};

export default ProfileMenuCard;
