import React, { FC } from 'react';
import { useHiringDetails } from './data/HiringDetails';
import { SuccessAlert, Spinner } from '#components/Index';
import Header from './elements/Header';
import HiringContactForm from './elements/HiringContactForm/HiringContactForm';
import NotFound from '#pages/NotFound';

const Hiring: FC = () => {
  const { data, isLoading } = useHiringDetails();

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  const { prospect } = data;

  return (
    <div>
      <Header />
      <div className="grow flex flex-col max-w-6xl mx-auto py-6 px-8 2xl:px-0">
        <div className="w-full p-8 bg-white rounded-lg shadow-md-dark">
          <h2 className="hp-black font-serif text-display-xs">
            Are you looking to recruit high quality talent for your team or organization?
          </h2>
          <div className="text-gray-500 font-light mb-8">
            <p>
              Just let us know you’re interested by submitting the form below, and we’ll contact you within one business
              day.
            </p>
          </div>

          {prospect.alreadySent && (
            <div className="pb-8">
              <SuccessAlert text="You've already sent a request." />
            </div>
          )}

          <HiringContactForm email={prospect.email} alreadySent={prospect.alreadySent} />
        </div>
      </div>
    </div>
  );
};

export default Hiring;
