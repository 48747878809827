import { createAction, ApiPayloadsCreator, POST } from '#api/actionCreator';
import { Errors } from '#root/src/types';

interface Payload {
  init: {
    redirectPath: string;
  };
  success: never;
  fail: {
    errors: Errors<'base'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params?: Payload['init']) =>
  await createAction<Payload>(POST, '/talents/onboarding/send_email_confirmation')(params);
