import React, { FC, useMemo } from 'react';
import { useGetProfilePreferences } from '../../data/ProfilePreferences';
import { BaseProfileEditModal, Step, StepsProvider, QuestionGroupModalProps } from '../BaseProfileEditModal';
import JobSeekingStatusStep from './steps/JobSeekingStatusStep';
import EmploymentTypeStep from './steps/EmploymentTypeStep';
import ExperienceYearsStep from './steps/ExperienceYearsStep';
import ExperienceFirmsStep from './steps/ExperienceFirmsStep';
import ExperienceIndustriesStep from './steps/ExperienceIndustriesStep';
import StartupBranchesStep from './steps/firms/StartupBranchesStep';
import CorporateBranchesStep from './steps/firms/CorporateBranchesStep';
import ManagementConsultingBranchesStep from './steps/firms/ManagementConsultingBranchesStep';
import PrivateEquityBranchesStep from './steps/firms/PrivateEquityBranchesStep';
import InvestmentBankingBranchesStep from './steps/firms/InvestmentBankingBranchesStep';
import InterestedFirmsStep from './steps/InterestedFirmsStep';
import WhereToWorkStep from './steps/WhereToWorkStep';
import WorkPolicyStep from './steps/WorkPolicyStep';
import EarningsStep from './steps/EarningsStep';
import SalaryStep from './steps/SalaryStep';
import InterestedCompaniesStep from './steps/InterestedCompaniesStep';
import UnpreferredCompaniesStep from './steps/UnpreferredCompaniesStep';
import ExtraInformationStep from './steps/ExtraInformationStep';

const ReviewAllModal: FC<QuestionGroupModalProps> = ({ isOpen, close }) => {
  const { data: profilePreferencesData } = useGetProfilePreferences();

  const initiallySelectedExperienceFunctionTypeNames = useMemo(() => {
    if (profilePreferencesData == null) return [];

    return profilePreferencesData.profilePreferences.functionTypesExperience
      .filter((functionType) => functionType.selected)
      .map((functionType) => functionType.name);
  }, [profilePreferencesData]);

  const initiallySelectedInterestFunctionTypeNames = useMemo(() => {
    if (profilePreferencesData == null) return [];

    return profilePreferencesData.profilePreferences.functionTypesInterest
      .filter((functionType) => functionType.selected)
      .map((functionType) => functionType.name);
  }, [profilePreferencesData]);

  const steps: Step[] = [
    {
      name: JobSeekingStatusStep.name,
      content: () => <JobSeekingStatusStep />,
      visible: true,
    },
    {
      name: EmploymentTypeStep.name,
      content: () => <EmploymentTypeStep />,
      visible: true,
    },
    {
      name: ExperienceYearsStep.name,
      content: () => <ExperienceYearsStep />,
      visible: true,
    },
    {
      name: ExperienceFirmsStep.name,
      content: () => <ExperienceFirmsStep />,
      visible: true,
    },
    {
      name: `${StartupBranchesStep.name}Experience`,
      content: () => <StartupBranchesStep category="experience" />,
      visible: initiallySelectedExperienceFunctionTypeNames.includes('Start-up/Scale-up'),
    },
    {
      name: `${CorporateBranchesStep.name}Experience`,
      content: () => <CorporateBranchesStep category="experience" />,
      visible: initiallySelectedExperienceFunctionTypeNames.includes('Corporate'),
    },
    {
      name: `${ManagementConsultingBranchesStep.name}Experience`,
      content: () => <ManagementConsultingBranchesStep category="experience" />,
      visible: initiallySelectedExperienceFunctionTypeNames.includes('Management Consulting'),
    },
    {
      name: `${PrivateEquityBranchesStep.name}Experience`,
      content: () => <PrivateEquityBranchesStep category="experience" />,
      visible: initiallySelectedExperienceFunctionTypeNames.includes('Private Equity'),
    },
    {
      name: `${InvestmentBankingBranchesStep.name}Experience`,
      content: () => <InvestmentBankingBranchesStep category="experience" />,
      visible: initiallySelectedExperienceFunctionTypeNames.includes('Investment Banking'),
    },
    {
      name: ExperienceIndustriesStep.name,
      content: () => <ExperienceIndustriesStep />,
      visible: true,
    },
    {
      name: InterestedFirmsStep.name,
      content: () => <InterestedFirmsStep />,
      visible: true,
    },
    {
      name: `${StartupBranchesStep.name}Interest`,
      content: () => <StartupBranchesStep category="interest" />,
      visible: initiallySelectedInterestFunctionTypeNames.includes('Start-up/Scale-up'),
    },
    {
      name: `${CorporateBranchesStep.name}Interest`,
      content: () => <CorporateBranchesStep category="interest" />,
      visible: initiallySelectedInterestFunctionTypeNames.includes('Corporate'),
    },
    {
      name: `${ManagementConsultingBranchesStep.name}Interest`,
      content: () => <ManagementConsultingBranchesStep category="interest" />,
      visible: initiallySelectedInterestFunctionTypeNames.includes('Management Consulting'),
    },
    {
      name: `${PrivateEquityBranchesStep.name}Interest`,
      content: () => <PrivateEquityBranchesStep category="interest" />,
      visible: initiallySelectedInterestFunctionTypeNames.includes('Private Equity'),
    },
    {
      name: `${InvestmentBankingBranchesStep.name}Interest`,
      content: () => <InvestmentBankingBranchesStep category="interest" />,
      visible: initiallySelectedInterestFunctionTypeNames.includes('Investment Banking'),
    },
    {
      name: WhereToWorkStep.name,
      content: () => <WhereToWorkStep />,
      visible: true,
    },
    {
      name: WorkPolicyStep.name,
      content: () => <WorkPolicyStep />,
      visible: true,
    },
    {
      name: EarningsStep.name,
      content: () => <EarningsStep />,
      visible: true,
    },
    {
      name: SalaryStep.name,
      content: () => <SalaryStep />,
      visible: true,
    },
    {
      name: InterestedCompaniesStep.name,
      content: () => <InterestedCompaniesStep />,
      visible: true,
    },
    {
      name: UnpreferredCompaniesStep.name,
      content: () => <UnpreferredCompaniesStep />,
      visible: true,
    },
    {
      name: ExtraInformationStep.name,
      content: () => <ExtraInformationStep />,
      visible: true,
    },
  ];

  return (
    <StepsProvider>
      <BaseProfileEditModal isOpen={isOpen} close={close} initialSteps={steps} />
    </StepsProvider>
  );
};

export default ReviewAllModal;
