import { useRef, useEffect } from 'react';

// It assing previous values within Funcitonal Components,
// use case: "const prevData = usePreviousValue({ user, story });"
const usePreviousValue = <T,>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePreviousValue;
