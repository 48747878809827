import React, { FC, useState, useMemo, useCallback } from 'react';
import { createSuccessToast } from '#src/lib/Toasts';
import { transformToSnakeCase } from '#src/lib/caseTransformObject';
import { request as apiRejectCandidate } from '#api/endpoints/employers/candidates/rejectCandidate';
import { Icon, Modal, Button } from '#components/Index';
import { TextArea } from '#components/Form/Index';
import FilterBadgeGroup from '#components/FilterBadgeGroup/FilterBadgeGroup';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  candidateId: number;
}

const initialFeedbackOptions: Record<string, boolean> = {
  'Level of experience': false,
  'Type of experience': false,
  'Education': false,
  'Other': false,
};

const RejectCandidateModal: FC<Props> = ({ isOpen, onClose, refetch, candidateId }) => {
  const [feedbackOptions, setFeedbackOptions] = useState(initialFeedbackOptions);
  const [feedbackField, setFeedbackField] = useState('');

  const parsedFeedbackOptions = useMemo(() => {
    const transformedFeedbackOptions = transformToSnakeCase(feedbackOptions);
    transformedFeedbackOptions.other_employer = transformedFeedbackOptions.other;
    delete transformedFeedbackOptions.other;

    return transformedFeedbackOptions;
  }, [feedbackOptions]);

  const handleConfirm = useCallback(async () => {
    await apiRejectCandidate({
      candidateId,
      feedback: {
        ...parsedFeedbackOptions,
        feedbackField,
      },
    });

    refetch();
    onClose();

    createSuccessToast({ title: 'This candidate has been rejected', text: ' Thank you for your feedback!' });
  }, [candidateId, parsedFeedbackOptions, feedbackField, onClose, refetch]);

  const selectedOptionsCount = useMemo(() => {
    return Object.keys(feedbackOptions).filter((option) => feedbackOptions[option]).length;
  }, [feedbackOptions]);

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="p-16 pt-8 flex flex-col items-center w-screen max-w-4xl gap-8">
        <button
          onClick={onClose}
          className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline self-center"
          aria-label="Close"
        >
          <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
        </button>

        <div className="w-full grid gap-6 text-sm font-medium">
          <div className="flex flex-col gap-y-3 text-center">
            <h2 className="text-display-xs font-serif hp-black">Not the right fit? Tell us why</h2>
            <p className="hp-black font-light text-md">
              Please let us know why this candidate wasn’t right for this opportunity. With your feedback, we can
              improve our matching and screening in the future.
            </p>
          </div>

          <FilterBadgeGroup
            withoutAllButton
            id="feedbackOptions"
            filters={feedbackOptions}
            setFilter={setFeedbackOptions}
            className="justify-center !gap-3 px-4"
          />

          <TextArea
            label=""
            placeholder="Please share more details about why this candidate wasn’t right."
            rows={3}
            onChange={(e) => {
              setFeedbackField(e.target.value);
            }}
            description="Please note: The information you include here is only visible to Hiperpool, and will not be shared with
                the candidate."
          />

          <div className="flex items-center justify-center">
            <Button variant="link" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirm} disabled={selectedOptionsCount === 0}>
              Send
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RejectCandidateModal;
