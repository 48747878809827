import React, { FC, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { format } from 'date-fns';
import { Controller } from 'react-hook-form';
import { Employment } from '#src/types';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { useEmploymentController } from './FormController';
import { request as apiAutocompletePrivacyCompanies } from '#api/endpoints/autocomplete/privacyCompanies';
import { request as apiRemoveEmployment } from '#src/api/endpoints/talents/profile/employments/removeEmployment';
import { KeysEnum as ProfileDetailsKeys } from '../../../data/ProfileDetails';
import { TextInput, RHF, LocationInput, DateInput, Checkbox, RichTextInput } from '#components/Form/Index';
import { Button, Modal, Icon } from '#components/Index';

const queryKeys = [ProfileDetailsKeys.talents, ProfileDetailsKeys.profile, ProfileDetailsKeys.details];

interface WorkHistoryModalProps {
  isOpen: boolean;
  closeModal: () => void;
  initialEmploymentValues: Employment;
}

const EmploymentModal: FC<WorkHistoryModalProps> = ({ isOpen, closeModal, initialEmploymentValues }) => {
  const queryClient = useQueryClient();

  const successCallback = useCallback(() => {
    void queryClient.invalidateQueries(queryKeys);
    closeModal();
  }, [closeModal, queryClient]);

  const removeEmployment = async (employmentId: number) => {
    try {
      await apiRemoveEmployment({ id: employmentId });
      successCallback();
      createSuccessToast({ title: 'Employment', text: 'Employment has been successfully deleted.' });
    } catch (error) {
      createErrorToast({ title: 'Employment', text: 'Failed to delete employment.' });
    }
  };

  const parseDate = (isoDateString: string | null) => {
    if (isoDateString == null || isoDateString === '') return '';

    const date = new Date(isoDateString);
    return format(date, 'yyyy-MM-dd');
  };

  const { form, submitForm } = useEmploymentController({
    ...initialEmploymentValues,
    successCallback,
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = form;

  const isCurrent = watch('current');
  const onSubmit = handleSubmit((values) => submitForm(values));

  useEffect(() => {
    setValue('startDate', parseDate(initialEmploymentValues.startDate));
    setValue('endDate', parseDate(initialEmploymentValues.endDate));
  }, [initialEmploymentValues, setValue]);

  useEffect(() => {
    if (isCurrent) {
      setValue('endDate', '');
    }
  }, [isCurrent, setValue]);

  const isExistingEmployment = initialEmploymentValues.id !== 0;
  const headerText = isExistingEmployment ? 'Edit work history entry' : 'Add work history entry';

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col bg-gray-50 lg:w-[72rem]">
          <div className="pt-8 px-8">
            <div className="flex justify-around items-center pb-2">
              <div className="flex items-center gap-x-8 text-gray-500 text-sm">Work History</div>

              <button
                onClick={closeModal}
                className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline cursor-pointer"
                aria-label="Close"
              >
                <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
              </button>
            </div>

            <div className="flex items-center justify-between">
              <h1 className="text-hpblack text-display-sm font-serif mb-4">{headerText}</h1>
              {isExistingEmployment && (
                <div>
                  <Button
                    variant="negative-full"
                    size="small"
                    onClick={async () => await removeEmployment(initialEmploymentValues.id)}
                  >
                    Remove <Icon.DeleteBinLine size={5} />
                  </Button>
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-y-4 flex-col px-8 pb-12">
            <TextInput
              label="Position"
              type="text"
              placeholder="E.g. Consultant"
              error={errors.position?.message}
              {...register('position')}
            />

            <RHF.AutocompleteInput
              label="Company"
              placeholder="E. g. ACME Corp."
              autocompleteRequest={apiAutocompletePrivacyCompanies}
              control={control}
              error={errors.company?.message}
              name="company"
            />

            <LocationInput
              label="Location"
              placeholder="Search locations..."
              error={errors.location?.message}
              {...register('location')}
            />

            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <RichTextInput
                  label="Description"
                  initialValue={initialEmploymentValues.description}
                  onChange={(e) => field.onChange(e.target.value)}
                  error={errors.description?.message}
                />
              )}
            />

            <div className="grid grid-cols-2 gap-x-4">
              <div>
                <DateInput label="From" error={errors.startDate?.message} {...register('startDate')} />
              </div>

              <div>
                <DateInput label="To" disabled={isCurrent} error={errors.endDate?.message} {...register('endDate')} />
              </div>
            </div>

            <div className="flex justify-end mt-1">
              <Checkbox className="mt-4" error={errors.current?.message} {...register('current')}>
                I currently work here
              </Checkbox>
            </div>
          </div>
        </div>

        <div className="bg-white flex gap-x-8 justify-center px-8 py-6">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EmploymentModal;
