import React, { FC } from 'react';

interface DefaultHistoryEntryProps {
  date: string;
  message: string;
  isLast: boolean;
}

const DefaultHistoryEntry: FC<DefaultHistoryEntryProps> = ({ date, message, isLast }) => {
  return (
    <li>
      <div className="relative pb-8">
        {!isLast && <span className="absolute left-2.5 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true"></span>}
        <div className="relative flex space-x-3">
          <div>
            <span className="flex h-4 w-4 ml-0.5 text-xs items-center justify-center rounded-full bg-green-900 text-white"></span>
          </div>
          <div className="flex flex-col text-md -mt-1 pl-4">
            <p className="text-hpblack text-md font-light"> {message}</p>
            <p className="text-sm text-gray-500 font-light">{date}</p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default DefaultHistoryEntry;
