import { uniqueId } from 'lodash-es';
import React, { useEffect, useState, FocusEvent, useRef, ChangeEvent, FC } from 'react';
import textBoxStyles from '#components/Form/styles/TextBox.module.css';
import searchSelectStyles from '#components/Form/SearchSelect/SearchSelect.module.css';
import { Icon } from '#components/Index';
import SearchSelectBadge from './SearchSelectBadge';
import MultiLocationSelectOption from './MultiLocationSelectOption';

export interface LocationOptionProps {
  placeId: string;
  name: string;
  city?: string;
  country?: string;
  open?: boolean;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

interface Props extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  id?: string;
  label: string;
  hasError?: boolean;
  selectedValues: LocationOptionProps[];
  setSelectedValues: (options: LocationOptionProps[]) => void;
}

const MultiLocationInput: FC<Props> = ({
  id: idProp,
  label,
  hasError = false,
  selectedValues,
  setSelectedValues,
  disabled = false,
}) => {
  const id = idProp ?? uniqueId('select');
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<LocationOptionProps[]>([]);
  const autocompleteServiceRef = useRef<google.maps.places.AutocompleteService | null>(null);
  const placesServiceRef = useRef<google.maps.places.PlacesService | null>(null);

  const searchSelectRef = useRef<HTMLDivElement>(null);
  const searchSelectSearchInputRef = useRef<HTMLInputElement>(null);

  const loadGoogleMaps = async () => {
    if (import.meta.env.VITE_GOOGLE_MAPS_KEY == null) return;

    const { Loader } = await import('@googlemaps/js-api-loader');

    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAPS_KEY,
      version: 'weekly',
      libraries: ['places'],
      language: 'en',
    });

    void loader.load().then(() => {
      autocompleteServiceRef.current = new google.maps.places.AutocompleteService();
      placesServiceRef.current = new google.maps.places.PlacesService(document.createElement('div'));
    });
  };

  // Load Google Maps API
  useEffect(() => {
    void (async () => {
      await loadGoogleMaps();
    })();
  }, [autocompleteServiceRef, placesServiceRef]);

  const loadPlacePredictions = async (input: string) => {
    if (autocompleteServiceRef.current != null) {
      await autocompleteServiceRef.current.getPlacePredictions(
        { input, types: ['(regions)'], language: 'en' },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && predictions != null) {
            setOptions(
              predictions.map((prediction) => ({
                placeId: prediction.place_id,
                name: prediction.description,
                checked: selectedValues.some((option) => option.placeId === prediction.place_id),
              }))
            );
          }
        }
      );
    }
  };

  const handleSearchInput = async (newSearchValue: string) => {
    setSearchValue(newSearchValue);

    if (newSearchValue === '') {
      setOptions([]);
    } else {
      await loadPlacePredictions(newSearchValue);
    }
  };

  const getPlaceDetails = async (placeId: string) => {
    return await new Promise<{ city?: string; country?: string }>((resolve) => {
      if (placesServiceRef.current != null) {
        placesServiceRef.current.getDetails({ placeId }, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && place != null) {
            const city = place.address_components?.find((component) => component.types.includes('locality'))?.long_name;
            const country = place.address_components?.find((component) =>
              component.types.includes('country')
            )?.long_name;
            resolve({ city, country });
          } else {
            resolve({});
          }
        });
      } else {
        resolve({});
      }
    });
  };

  const handleSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const placeId = event.target.dataset.id;

    if (placeId == null) return;

    // Add selection mark on options array
    const newOptions = options.map((option) => {
      if (option.placeId === placeId) {
        return { ...option, checked: event.target.checked };
      }
      return option;
    });

    setOptions(newOptions);

    // Add item to selected options
    if (event.target.checked) {
      const { city, country } = await getPlaceDetails(placeId);
      const selectedOption = newOptions.find((option) => option.placeId === placeId);
      if (selectedOption != null) {
        selectedOption.city = city;
        selectedOption.country = country;
        setSelectedValues([...selectedValues, selectedOption]);
      }
    } else {
      setSelectedValues(selectedValues.filter((option) => option.placeId !== placeId));
    }

    // Close the options list and clear the search input
    setOpen(false);
    setSearchValue('');
  };

  const handleOptionRemoval = (event: React.MouseEvent<HTMLButtonElement> & { target: HTMLInputElement }) => {
    const newValues = selectedValues.filter((value) => value.placeId !== event.target.dataset.id);
    setSelectedValues(newValues);

    // Remove selection mark on options array if the option is still visible
    const option = options.find((option) => option.placeId === event.target.dataset.id);

    if (option != null) {
      const newOptions = options.map((option) => {
        if (option.placeId === event.target.dataset.id) {
          return { ...option, checked: false };
        }
        return option;
      });

      setOptions(newOptions);
    }
  };

  const handleClearClick = () => {
    setSearchValue('');
    setOptions([]);
    searchSelectSearchInputRef.current?.focus(); // Pass focus from the button back to the search input
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (searchSelectRef.current !== null && !searchSelectRef.current.contains(event.relatedTarget)) {
      setOpen(false);
    }
  };

  return (
    <>
      <div
        id={id}
        aria-labelledby={label}
        aria-disabled={disabled}
        className="inline-grid relative"
        ref={searchSelectRef}
        onBlur={handleBlur}
      >
        <div aria-expanded={open} aria-controls={`search-box-${id}`} className="flex items-center">
          <Icon.Search size={5} className="absolute mx-3.5 my-2.5 text-gray-500 pointer-events-none" />
          <input
            type="search"
            id={`search-input-{$id}`}
            onFocus={() => setOpen(true)}
            className={`${textBoxStyles.input} ${searchSelectStyles['search-input']} ${
              hasError ? textBoxStyles[`input--error`] : ''
            }`}
            autoComplete="off"
            disabled={disabled}
            onChange={async (e) => await handleSearchInput(e.target.value)}
            placeholder="Start typing…"
            ref={searchSelectSearchInputRef}
            value={searchValue}
          />
          {searchValue !== '' && (
            <button
              aria-label="Clear search"
              title="Clear search"
              aria-hidden={searchValue === ''}
              className={searchSelectStyles['clear-search-button']}
              onClick={handleClearClick}
            >
              <Icon.X size={5} className="" />
            </button>
          )}
        </div>

        <div aria-hidden={!open} className={searchSelectStyles['option-container']}>
          {options.map((option) => (
            <MultiLocationSelectOption {...option} key={option.placeId} onChange={handleSelect} open={open} />
          ))}

          {options.length === 0 && (
            <div className={searchSelectStyles.option} tabIndex={-1}>
              <span className={searchSelectStyles.option__label}>No options found matching your search term</span>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-wrap mt-6 max-w-full">
        {selectedValues.map((option) => (
          <SearchSelectBadge
            key={option.placeId}
            searchSelectId={id}
            negative={false}
            option={option}
            handleBadgeClick={handleOptionRemoval}
          />
        ))}
      </div>
    </>
  );
};

export default MultiLocationInput;
