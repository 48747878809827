import React, { FC, useState, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { request as apiToggleBookmark } from '#api/endpoints/talents/opportunities/toggleBookmark';
import { KeysEnum as opportunityCountersQueryKeys } from '#pages/talentPages/opportunities/index/data/OpportunityCountersData';
import { Icon } from '#components/Index';
import { OpportunityThumbnail } from '#src/types';
import styles from './OpportunityCard.module.css';

interface BookmarkButtonProps {
  opportunityId: number;
  candidacy: OpportunityThumbnail['candidacy'];
}

const BookmarkButton: FC<BookmarkButtonProps> = ({ opportunityId, candidacy }) => {
  const queryClient = useQueryClient();
  const [isBookmarked, setIsBookmarked] = useState(candidacy?.savedByTalent ?? false);

  const toggleBookmark = useCallback(async () => {
    await apiToggleBookmark({ id: opportunityId });
    await queryClient.refetchQueries([opportunityCountersQueryKeys.opportunityCounters]);
    setIsBookmarked((prev) => !prev);
  }, [opportunityId, queryClient]);

  if (candidacy == null) return null;

  return (
    <div className={styles.bookmarkIcon}>
      {isBookmarked ? (
        <Icon.BookmarkFill size={6} onClick={toggleBookmark} />
      ) : (
        <Icon.BookmarkOutline size={6} onClick={toggleBookmark} />
      )}
    </div>
  );
};

export default BookmarkButton;
