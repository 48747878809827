import React, { FC } from 'react';
import { Icon } from '#components/Index';

interface SingleLineAnswerProps {
  children: React.ReactNode;
}

const SingleLineAnswer: FC<SingleLineAnswerProps> = ({ children }) => {
  return (
    <div className="flex items-center gap-x-2 font-medium text-md">
      <Icon.FeaturedCircleLight size={5} />
      <div>{children}</div>
    </div>
  );
};

export default SingleLineAnswer;
