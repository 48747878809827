import React, { FC } from 'react';
import { paths } from '#src/pages/paths';
import { Button } from '#src/components/Index';
import ChosenForYouImage from '#assets/opportunities/ChosenForYou.webp';

const HandpickedCard: FC = () => {
  return (
    <div className="bg-hpblack p-5 rounded-lg relative">
      <div className="text-white pb-32">
        <h5 className="text-display-xs font-serif mb-5">What is &apos;hand-picked&apos;?</h5>
        <p>
          You have been matched specifically to these roles by a member of our team based on your preferences and
          experiences. It is vital that you keep your information up-to-date so that we can hand-pick the most relevant
          opportunities for you.
        </p>
        <Button variant="primary" size="small" className="mt-3" href={paths.talentPreferences()}>
          Update my preferences
        </Button>
      </div>

      <img loading="lazy" src={ChosenForYouImage} alt="" className="absolute right-0 bottom-0" />
    </div>
  );
};

export default HandpickedCard;
