import React, { FC } from 'react';
import { paths } from '#pages/paths';
import { generateOriginAppUrl } from '#root/src/lib/generateOriginAppUrl';
import { Icon, Button } from '#src/components/Index';

const LockedRejected: FC = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center z-10">
      <div className="w-full max-w-2xl bg-white p-10 max-2xs:py-16 max-2xs:px-variable-lg 2xs:rounded-lg grid gap-6 max-2xs:border-b shadow-xl border-2 border-gray-200">
        <Icon.LockedCircle size={12} className="mx-auto" aria-hidden="true" />
        <h3 className="font-serif text-display-xs text-center">You do not currently have access to jobs</h3>

        <div className="text-gray-800 text-sm">
          <p className="mb-4">
            To ensure our members receive the best possible experience, we allow full access to jobs based on your
            experience and its alignment with roles posted on Hiperpool. If you believe there has been a mistake please
            email{' '}
            <a href="mailto:info@hiperpool.com" className="text-blue-500">
              info@hiperpool.com
            </a>
            . Alternatively, if you have gained additional relevant experience please feel free to reapply via the link
            below.
          </p>

          <div className="flex justify-center">
            <a href={generateOriginAppUrl({ path: paths.talentStartOnboarding() })}>
              <Button size="large">
                Reapply <Icon.ArrowRightLine size={4} aria-hidden="true" />
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockedRejected;
