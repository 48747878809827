import { useQuery } from 'react-query';
import { ReactQueryOptions, OpportunitySearchParams } from '#src/types';
import * as apiPostFilteredOpportunities from '#api/endpoints/talents/opportunities/filteredOpportunities';

type filteredOpportunitiesPayload = apiPostFilteredOpportunities.Type['success']['payload'];

export enum KeysEnum {
  talents = 'talents',
  filteredOpportunities = 'get-filtered-opportunities',
}

export const useGetFilteredOpportunities = (
  search: OpportunitySearchParams,
  options?: ReactQueryOptions<filteredOpportunitiesPayload>
) =>
  useQuery<filteredOpportunitiesPayload>(
    [KeysEnum.talents, KeysEnum.filteredOpportunities, search],
    async ({ signal }) => await apiPostFilteredOpportunities.request({ search }, signal),
    {
      ...options,
    }
  );
