import React, { FC, useMemo } from 'react';
import { TalentLanguage } from '#src/types';
import ProfileBadge from '../../elements/ProfileBadge';

const proficiencyOrder = ['Native', 'Fluent', 'Business', 'Conversational', 'Basic'];

interface GroupedLanguagesProps {
  languages: TalentLanguage[];
}

const GroupedLanguages: FC<GroupedLanguagesProps> = ({ languages }) => {
  const languagesGroupedByProficiency = useMemo(() => {
    const proficiencies = [...new Set(languages.map((language) => language.proficiency))];

    const sortedProficiencies = proficiencies.sort((a, b) => {
      return proficiencyOrder.indexOf(a) - proficiencyOrder.indexOf(b);
    });

    return sortedProficiencies.map((proficiency) => {
      return {
        name: proficiency,
        languages: languages
          .filter((language) => language.proficiency === proficiency)
          .map((language) => language.language)
          .sort(),
      };
    });
  }, [languages]);

  return (
    <div className="flex flex-wrap gap-x-12 gap-y-6">
      {languagesGroupedByProficiency.map((group) => (
        <div key={group.name}>
          <h4 className="hp-black font-medium pb-2 text-md">{group.name}</h4>

          <div className="flex gap-x-4">
            {group.languages.map((language) => (
              <ProfileBadge key={language} withoutIcon>
                {language}
              </ProfileBadge>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GroupedLanguages;
