import React, { FC, ChangeEvent } from 'react';
import { Button, Icon } from '#components/Index';

interface Props {
  cv: File | null;
  setCv: (cv: File) => void;
  alternateAction: () => void;
}

const CvProgress: FC<{ file: File | null }> = ({ file }) => {
  if (file == null) return null;

  return (
    <div className="flex gap-x-4 bg-white rounded-lg border-2 border-blue-500 mt-8 p-4">
      <div className="bg-blue-200 rounded-full w-9 h-9 flex justify-center items-center">
        <Icon.File size={5} aria-hidden="true" />
      </div>
      <div className="grow">
        <p className="font-medium">{file.name}</p>
        <span className="text-gray-500">{(file.size / (1024 * 1024)).toFixed(2)} MB</span>
        <div className="flex flex-row gap-x-2">
          <div className="grow h-2 bg-blue-500 rounded-md mt-2"></div>
          <span>100%</span>
        </div>
      </div>
      <div>
        <Icon.CheckBoxCircleFill size={5} className="text-blue-600" />
      </div>
    </div>
  );
};

const CvInput: FC<{ setCv: (cv: File) => void; file: File | null }> = ({ setCv, file }) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile == null) return;
    setCv(selectedFile);
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <label className="flex flex-col items-center justify-center w-full border-2 border-gray-200 rounded-lg cursor-pointer bg-white py-6">
          <Icon.ImportCircle size={12} className="mx-auto" aria-hidden="true" />
          <p className="text-sm text-gray-500 dark:text-gray-500">
            <span className="text-blue-500">Click to upload</span>
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-500">PDF (MAX. 5MB)</p>

          <input type="file" className="hidden" onChange={handleFileChange} />
        </label>
      </div>

      <CvProgress file={file} />
    </>
  );
};

export const CvForm: FC<Props> = ({ cv, setCv, alternateAction }) => {
  return (
    <div className="">
      <div>
        <h2 className="text-display-md font-serif text-hpblack text-center">Upload your CV</h2>
      </div>
      <CvInput setCv={setCv} file={cv} />

      <div className="pt-4">
        <Button variant="link" className="link !font-normal !p-0" onClick={alternateAction}>
          My CV isn’t ready right now
        </Button>
      </div>
    </div>
  );
};
