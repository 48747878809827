import React, { FC, SVGProps } from 'react';

import { ReactComponent as AlertCircleIcon } from '#assets/icons/alert-circle.svg';
import { ReactComponent as ArrowLeftLineIcon } from '#assets/icons/arrow-left-line.svg';
import { ReactComponent as ArrowRightLineIcon } from '#assets/icons/arrow-right-line.svg';
import { ReactComponent as ArrowRightSLineIcon } from '#assets/icons/arrow-right-s-line.svg';
import { ReactComponent as AwardIcon } from '#assets/icons/award.svg';
import { ReactComponent as CalendarCheckIcon } from '#assets/icons/calendar-check-fill.svg';
import { ReactComponent as CheckboxCircleIcon } from '#assets/icons/checkbox-circle-line.svg';
import { ReactComponent as CheckBoxCircleFillIcon } from '#assets/iconsToBe/checkbox-circle-fill.svg';
import { ReactComponent as CheckLineIcon } from '#assets/icons/check.svg';
import { ReactComponent as CheckLineThinIcon } from '#assets/icons/check-thin.svg';
import { ReactComponent as DoubleQuoteLIcon } from '#assets/icons/double-quotes-l.svg';
import { ReactComponent as FacebookIcon } from '#assets/icons/social-facebook.svg';
import { ReactComponent as InstagramIcon } from '#assets/icons/social-instagram.svg';
import { ReactComponent as LinkedinIcon } from '#assets/icons/social-linkedin.svg';
import { ReactComponent as LogoutBoxIcon } from '#assets/icons/logout-box.svg';
import { ReactComponent as MailCheckLineIcon } from '#assets/icons/mail-check-line.svg';
import { ReactComponent as MapPinFillIcon } from '#assets/icons/map-pin-2-fill.svg';
import { ReactComponent as MapPinIcon } from '#assets/icons/map-pin-fill.svg';
import { ReactComponent as NotificationIcon } from '#assets/icons/notification-3-line.svg';
import { ReactComponent as PadlockIcon } from '#assets/icons/padlock.svg';
import { ReactComponent as RatingStarIcon } from '#assets/icons/star-rating.svg';
import { ReactComponent as RatingStarHalfIcon } from '#assets/icons/star-rating-half.svg';
import { ReactComponent as RatingStarHalfIconDark } from '#assets/icons/star-rating-half-dark.svg';
import { ReactComponent as SearchIcon } from '#assets/icons/search.svg';
import { ReactComponent as SettingsIcon } from '#assets/icons/settings.svg';
import { ReactComponent as StarIcon } from '#assets/icons/star.svg';
import { ReactComponent as TimeLineIcon } from '#assets/icons/time-line.svg';
import { ReactComponent as TwitterIcon } from '#assets/icons/social-twitter.svg';
import { ReactComponent as UserFollowLineIcon } from '#assets/icons/user-follow-line.svg';
import { ReactComponent as UserFrameIcon } from '#assets/icons/user-frame.svg';
import { ReactComponent as UserLineIcon } from '#assets/icons/user-line.svg';
import { ReactComponent as XIcon } from '#assets/icons/x.svg';
import { ReactComponent as LinkedinCircleIcon } from '#assets/icons/linkedin-circle.svg';
import { ReactComponent as FileIcon } from '#assets/icons/file.svg';
import { ReactComponent as ImportCircleIcon } from '#assets/icons/import-circle.svg';
import { ReactComponent as UserCheckmarkIcon } from '#assets/icons/user-checkmark.svg';
import { ReactComponent as XCircleIcon } from '#assets/icons/x-circle.svg';
import { ReactComponent as BankCardFillIcon } from '#assets/icons/bank-card-fill.svg';
import { ReactComponent as BookmarkFillIcon } from '#assets/icons/bookmark-fill.svg';
import { ReactComponent as BookmarkOutlineIcon } from '#assets/icons/bookmark-outline.svg';
import { ReactComponent as FeaturedCircleIcon } from '#assets/icons/featured-circle.svg';
import { ReactComponent as FeaturedCircleLightIcon } from '#assets/icons/featured-circle-light.svg';
import { ReactComponent as DislikeCircleIcon } from '#assets/icons/dislike-circle.svg';
import { ReactComponent as ClockCircleIcon } from '#assets/icons/clock-circle.svg';
import { ReactComponent as OptionsIcon } from '#assets/icons/options.svg';
import { ReactComponent as EmptyBookmarkedIcon } from '#assets/icons/empty-bookmarked.svg';
import { ReactComponent as EmptySearchIcon } from '#assets/icons/empty-search.svg';
import { ReactComponent as LockedCircleIcon } from '#assets/icons/locked-circle.svg';
import { ReactComponent as MailFillIcon } from '#assets/icons/mail-fill.svg';
import { ReactComponent as FilterFillIcon } from '#assets/icons/filter-fill.svg';
import { ReactComponent as QuestionMarkFillIcon } from '#assets/icons/question-fill.svg';
import { ReactComponent as PauseCircleFillIcon } from '#assets/icons/pause-circle-fill.svg';
import { ReactComponent as DeleteBinLineIcon } from '#assets/icons/delete-bin-line.svg';
import { ReactComponent as MoreFillIcon } from '#assets/icons/more-fill.svg';
import { ReactComponent as PagesLineIcon } from '#assets/icons/pages-line.svg';
import { ReactComponent as EyeFillIcon } from '#assets/icons/eye-fill.svg';
import { ReactComponent as EyeOffFillIcon } from '#assets/icons/eye-off-fill.svg';
import { ReactComponent as EditPencilIcon } from '#assets/icons/edit-pencil.svg';
import { ReactComponent as SchoolIcon } from '#assets/icons/school.svg';
import { ReactComponent as DownloadFillIcon } from '#assets/icons/download-fill.svg';
import { ReactComponent as CheckCircleIcon } from '#assets/icons/check-circle.svg';
import { ReactComponent as PDFIcon } from '#assets/icons/pdf.svg';
import { ReactComponent as ExclamationMarkCircleIcon } from '#assets/icons/exclamation-mark-circle.svg';
import { ReactComponent as BuildingIcon } from '#assets/icons/building.svg';

import { ReactComponent as IndentDecreaseIcon } from '#assets/icons/indent-decrease.svg';
import { ReactComponent as IndentIncreaseIcon } from '#assets/icons/indent-increase.svg';
import { ReactComponent as ListOrderedIcon } from '#assets/icons/list-ordered.svg';
import { ReactComponent as ListUnorderedIcon } from '#assets/icons/list-unordered.svg';

interface Props extends SVGProps<SVGSVGElement> {
  size: number;
  className?: string;
}

// Keep in mind that if you want to use a specific size outside of 2-12 you need to safelist it in tailwind config
const createIconFromSVG =
  (SvgComponent: FC<SVGProps<SVGSVGElement>>) =>
  // eslint-disable-next-line react/display-name
  ({ size, className: classNameProp = '', ...rest }: Props) => {
    const className = ['inline-block', 'align-middle', `w-${size}`, `h-${size}`, classNameProp].join(' ').trim();

    return <SvgComponent {...rest} className={className} />;
  };

export const AlertCircle = createIconFromSVG(AlertCircleIcon);
export const ArrowLeftLine = createIconFromSVG(ArrowLeftLineIcon);
export const ArrowRightLine = createIconFromSVG(ArrowRightLineIcon);
export const ArrowRightSLine = createIconFromSVG(ArrowRightSLineIcon);
export const Award = createIconFromSVG(AwardIcon);
export const CalendarChecked = createIconFromSVG(CalendarCheckIcon);
export const CheckboxCircle = createIconFromSVG(CheckboxCircleIcon);
export const CheckBoxCircleFill = createIconFromSVG(CheckBoxCircleFillIcon);
export const CheckLine = createIconFromSVG(CheckLineIcon);
export const CheckLineThin = createIconFromSVG(CheckLineThinIcon);
export const DoubleQuoteL = createIconFromSVG(DoubleQuoteLIcon);
export const Facebook = createIconFromSVG(FacebookIcon);
export const Instagram = createIconFromSVG(InstagramIcon);
export const Linkedin = createIconFromSVG(LinkedinIcon);
export const LogoutBox = createIconFromSVG(LogoutBoxIcon);
export const MailCheckLine = createIconFromSVG(MailCheckLineIcon);
export const MapPin = createIconFromSVG(MapPinIcon);
export const MapPinFill = createIconFromSVG(MapPinFillIcon);
export const Notification = createIconFromSVG(NotificationIcon);
export const Padlock = createIconFromSVG(PadlockIcon);
export const RatingStar = createIconFromSVG(RatingStarIcon);
export const RatingStarHalf = createIconFromSVG(RatingStarHalfIcon);
export const RatingStarHalfDark = createIconFromSVG(RatingStarHalfIconDark);
export const Search = createIconFromSVG(SearchIcon);
export const Settings = createIconFromSVG(SettingsIcon);
export const Star = createIconFromSVG(StarIcon);
export const TimeLine = createIconFromSVG(TimeLineIcon);
export const Twitter = createIconFromSVG(TwitterIcon);
export const UserFollowLine = createIconFromSVG(UserFollowLineIcon);
export const UserFrame = createIconFromSVG(UserFrameIcon);
export const UserLine = createIconFromSVG(UserLineIcon);
export const X = createIconFromSVG(XIcon);
export const LinkedinCircle = createIconFromSVG(LinkedinCircleIcon);
export const File = createIconFromSVG(FileIcon);
export const ImportCircle = createIconFromSVG(ImportCircleIcon);
export const UserCheckmark = createIconFromSVG(UserCheckmarkIcon);
export const XCircle = createIconFromSVG(XCircleIcon);
export const BankCardFill = createIconFromSVG(BankCardFillIcon);
export const BookmarkFill = createIconFromSVG(BookmarkFillIcon);
export const BookmarkOutline = createIconFromSVG(BookmarkOutlineIcon);
export const FeaturedCircle = createIconFromSVG(FeaturedCircleIcon);
export const FeaturedCircleLight = createIconFromSVG(FeaturedCircleLightIcon);
export const DislikeCircle = createIconFromSVG(DislikeCircleIcon);
export const ClockCircle = createIconFromSVG(ClockCircleIcon);
export const Options = createIconFromSVG(OptionsIcon);
export const EmptyBookmarked = createIconFromSVG(EmptyBookmarkedIcon);
export const EmptySearch = createIconFromSVG(EmptySearchIcon);
export const LockedCircle = createIconFromSVG(LockedCircleIcon);
export const MailFill = createIconFromSVG(MailFillIcon);
export const FilterFill = createIconFromSVG(FilterFillIcon);
export const QuestionMarkFill = createIconFromSVG(QuestionMarkFillIcon);
export const PauseCircleFill = createIconFromSVG(PauseCircleFillIcon);
export const DeleteBinLine = createIconFromSVG(DeleteBinLineIcon);
export const MoreFill = createIconFromSVG(MoreFillIcon);
export const PagesLine = createIconFromSVG(PagesLineIcon);
export const EyeFill = createIconFromSVG(EyeFillIcon);
export const EyeOffFill = createIconFromSVG(EyeOffFillIcon);
export const EditPencil = createIconFromSVG(EditPencilIcon);
export const School = createIconFromSVG(SchoolIcon);
export const DownloadFill = createIconFromSVG(DownloadFillIcon);
export const CheckCircle = createIconFromSVG(CheckCircleIcon);
export const PDF = createIconFromSVG(PDFIcon);
export const ExclamationMarkCircle = createIconFromSVG(ExclamationMarkCircleIcon);
export const Building = createIconFromSVG(BuildingIcon);
export const IndentDecrease = createIconFromSVG(IndentDecreaseIcon);
export const IndentIncrease = createIconFromSVG(IndentIncreaseIcon);
export const ListOrdered = createIconFromSVG(ListOrderedIcon);
export const ListUnordered = createIconFromSVG(ListUnorderedIcon);
