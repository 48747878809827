import React, { FC, useMemo, useState } from 'react';
import { TalentPreferences } from '#src/types';
import { useGetExperienceOptions } from '#root/src/components/TalentSharedForms/ExperienceYears/ExperienceOptionsData';
import { FormContentGroup } from '#components/Index';
import WorkHistoryModal from './modals/WorkHistoryModal';
import MissingAnswerCard from './MissingAnswerCard';
import SingleLineAnswer from './SingleLineAnswer';
import ProfileBadge from '../../elements/ProfileBadge';
import FirmsTree from './FirmsTree';

interface WorkHistoryProps {
  profilePreferences: TalentPreferences;
}

const WorkHistory: FC<WorkHistoryProps> = ({ profilePreferences }) => {
  const [isWorkHistoryModalOpen, setIsWorkHistoryModalOpen] = useState(false);

  const { data: experienceOptionsData } = useGetExperienceOptions();

  const { experienceTimeId, functionTypesExperience, experienceIndustryTypes } = profilePreferences;

  const selectedExperienceTime = useMemo(() => {
    if (experienceOptionsData == null) return null;

    return experienceOptionsData.totalExperiences.find((time) => time.value === experienceTimeId);
  }, [experienceTimeId, experienceOptionsData]);

  const experienceTimeOrMissingCard = () => {
    if (selectedExperienceTime == null) return <MissingAnswerCard onClick={() => setIsWorkHistoryModalOpen(true)} />;

    return <SingleLineAnswer>{selectedExperienceTime.label}</SingleLineAnswer>;
  };

  const experienceIndustryTypesOrMissingCard = () => {
    if (experienceIndustryTypes.length === 0)
      return <MissingAnswerCard onClick={() => setIsWorkHistoryModalOpen(true)} />;

    return (
      <div className="flex flex-wrap gap-2">
        {experienceIndustryTypes.map((experienceIndustry) => (
          <ProfileBadge key={experienceIndustry.id}>{experienceIndustry.name}</ProfileBadge>
        ))}
      </div>
    );
  };

  return (
    <FormContentGroup
      title="Work History"
      groupName="Experience"
      description="Where have you worked in the past?"
      editSectionHandler={() => setIsWorkHistoryModalOpen(!isWorkHistoryModalOpen)}
    >
      <WorkHistoryModal isOpen={isWorkHistoryModalOpen} close={() => setIsWorkHistoryModalOpen(false)} />
      <div className="flex flex-col gap-y-6">
        <div>
          <h5 className="text-gray-500 text-sm pb-2">Number of years of full-time work experience I have</h5>

          {experienceTimeOrMissingCard()}
        </div>

        <div>
          <h5 className="text-gray-500 text-sm pb-2">Types of firms I have full-time experience in</h5>
          <FirmsTree
            functionTypes={functionTypesExperience}
            type="experience"
            openModal={() => setIsWorkHistoryModalOpen(true)}
          />
        </div>

        <div>
          <h5 className="text-gray-500 text-sm pb-2">The main industries I have experience in are</h5>

          {experienceIndustryTypesOrMissingCard()}
        </div>
      </div>
    </FormContentGroup>
  );
};

export default WorkHistory;
