import React, { FC } from 'react';
import DOMPurify from 'dompurify';

interface Props {
  header: string;
  text: string;
}
const TextSegment: FC<Props> = ({ header, text }) => {
  return (
    <div className="rich-text rich-text-lists md:text-lg flex justify-center mx-variable-lg py-12">
      <div className="w-full max-w-3xl">
        <h2>{header}</h2>
        <p className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />
      </div>
    </div>
  );
};

export default TextSegment;
