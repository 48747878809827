import React, { FC, useState, useCallback, useMemo } from 'react';
import { generateOriginAppUrl } from '#root/src/lib/generateOriginAppUrl';
import { paths } from '#pages/paths';
import DOMPurify from 'dompurify';
import { TalentProfile } from '#root/src/types';
import { request as apiInviteCandidate } from '#api/endpoints/employers/candidates/inviteCandidate';
import { Icon, Modal, Button } from '#components/Index';
import { RichTextInput } from '#components/Form/Index';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  candidateId: number;
  profile: TalentProfile;
}

const InviteCandidateModal: FC<Props> = ({ isOpen, onClose, refetch, candidateId, profile }) => {
  const [messageContent, setMessageContent] = useState('');
  const [displaySuccessContent, setDisplaySuccessContent] = useState(false);

  const handleConfirm = useCallback(async () => {
    await apiInviteCandidate({
      candidateId,
      message: {
        content: messageContent,
      },
    });
    refetch();
    setDisplaySuccessContent(true);
  }, [messageContent, candidateId, refetch]);

  const saveButtonDisabled = useMemo(() => {
    return DOMPurify.sanitize(messageContent, { ALLOWED_TAGS: [] }).trim().length === 0;
  }, [messageContent]);

  const inviteCandidateForm = useMemo(() => {
    return (
      <>
        <div className="flex flex-col gap-y-3">
          <h2 className="text-display-xs font-serif hp-black text-center">
            Contact{' '}
            <span className="text-green-900">
              {profile.firstName} {profile.lastName}
            </span>
          </h2>

          <div className="hp-black text-md rich-text-lists font-light">
            <p className="mb-4">
              Let {profile.firstName} {profile.lastName} know you’d like to move forward with their candidacy.
            </p>

            <div className="text-gray-500">
              <p className="mb-2">We recommend that you include the following:</p>
              <ul>
                <li>Thank the candidate for their interest</li>
                <li>Describe the next steps</li>
                <li>Suggest times you’re available for an upcoming call or interview</li>
              </ul>
            </div>
          </div>
        </div>

        <RichTextInput label="" initialValue="" onChange={(e) => setMessageContent(e.target.value)} />

        <div className="flex items-center justify-center">
          <Button variant="link" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm} disabled={saveButtonDisabled}>
            Send
          </Button>
        </div>
      </>
    );
  }, [handleConfirm, onClose, profile, saveButtonDisabled]);

  const successContent = useMemo(() => {
    return (
      <>
        <div className="flex flex-col gap-y-3 text-center">
          <h2 className="text-display-xs font-serif hp-black">Your message has been sent</h2>
          <p className="hp-black font-light text-md">
            You can view all the candidates interviewing and your communication with them in the{' '}
            <a className="underline text-blue-500" href={generateOriginAppUrl({ path: paths.employerTalentPool() })}>
              Interviewing section
            </a>{' '}
            of your Talent Pool.
          </p>
        </div>

        <div className="flex items-center justify-center">
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </>
    );
  }, [onClose]);

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="p-16 pt-8 flex flex-col items-center w-screen max-w-4xl gap-8">
        <button
          onClick={onClose}
          className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline self-center"
          aria-label="Close"
        >
          <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
        </button>

        <div className="w-full grid gap-6 text-sm font-medium">
          {displaySuccessContent ? successContent : inviteCandidateForm}
        </div>
      </div>
    </Modal>
  );
};

export default InviteCandidateModal;
