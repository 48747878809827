import React, { FC } from 'react';
import { TeamMember } from '#src/types';

const TruncatedContent: FC<TeamMember> = (teamMember) => {
  return (
    <div className="rounded-lg shadow-md-dark bg-white" key={teamMember.name}>
      <div className="flex gap-x-4 p-4">
        <img
          height="90"
          width="90"
          className="w-[90px] h-[90px] rounded-full"
          src={teamMember.avatarUrl}
          alt={teamMember.name}
        />
        <div className="flex flex-col gap-y-1">
          <h4 className="font-serif text-display-xs text-hpblack">{teamMember.name}</h4>
          <h5 className="text-gray-500 text-md font-light">{teamMember.position}</h5>
        </div>
      </div>

      <div className="bg-gray-50 rounded-b-lg flex py-4">
        <div className="flex gap-5 w-fit mx-auto h-10">
          {teamMember.backgroundUrls.map((background) => (
            <img
              key={background}
              src={background}
              className="mix-blend-multiply"
              height="40"
              width="40"
              loading="lazy"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TruncatedContent;
