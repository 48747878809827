import React, { FC } from 'react';
import DOMPurify from 'dompurify';

import styles from './Content.module.css';

interface Props {
  content: string;
  author: {
    avatar: string;
    name: string;
    title: string;
  };
}
const Content: FC<Props> = ({ content, author }) => {
  return (
    <>
      <div
        className="rich-text rich-text-lists [&>*]:max-w-3xl md:text-lg px-variable-lg mx-auto mb-8"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
      ></div>
      <div className={styles.author}>
        <img src={author.avatar} alt={`Photo of ${author.name}`} loading="eager" />
        <span className="uppercase">Author</span>
        <h4 className="whitespace-nowrap">{author.name}</h4>
        <h5 className="whitespace-nowrap">{author.title}</h5>
      </div>
    </>
  );
};

export default Content;
