import React, { FC } from 'react';
import styles from './MenuCard.module.css';

interface Props {
  id: string;
  open: boolean;
  children?: React.ReactNode;
}

const MenuCard: FC<Props> = ({ id, open, children }) => (
  <div className={styles.card} id={id} aria-hidden={!open}>
    {children}
  </div>
);

export default MenuCard;
