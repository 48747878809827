import React, { FC, useState, useEffect } from 'react';
import { TalentProfile } from '#src/types';
import { request as apiCandidateContactedDirectly } from '#api/endpoints/employers/candidates/candidateContactedDirectly';
import ContactedDirectlyConfirmationModal from './modals/ContactedDirectlyConfirmationModal';
import InviteCandidateModal from './modals/InviteCandidateModal';
import RejectCandidateModal from './modals/RejectCandidateModal';

export type CandidateReplyModalType = 'reject' | 'invite' | 'contactedDirectly' | null;

interface Props {
  candidateId: number;
  profile: TalentProfile;
  selectedModal: CandidateReplyModalType;
  setSelectedModal: (modal: CandidateReplyModalType) => void;
  refetch?: () => void;
}

const CandidateRepliesModalManager: FC<Props> = ({
  candidateId,
  profile,
  selectedModal,
  setSelectedModal,
  refetch = () => {},
}) => {
  const [contactedDirectlyModalOpen, setContactedDirectlyModalOpen] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);

  useEffect(() => {
    void (async () => {
      switch (selectedModal) {
        case 'contactedDirectly':
          await apiCandidateContactedDirectly({ candidateId });
          setContactedDirectlyModalOpen(true);
          refetch();
          break;
        case 'invite':
          setInviteModalOpen(true);
          break;
        case 'reject':
          setRejectModalOpen(true);
          break;
        default:
          setRejectModalOpen(false);
          setInviteModalOpen(false);
          setContactedDirectlyModalOpen(false);
      }
    })();
  }, [selectedModal, candidateId, refetch]);

  const onClose = () => {
    setSelectedModal(null);
  };

  return (
    <>
      <ContactedDirectlyConfirmationModal isOpen={contactedDirectlyModalOpen} onClose={onClose} />
      <InviteCandidateModal
        isOpen={inviteModalOpen}
        onClose={onClose}
        refetch={refetch}
        candidateId={candidateId}
        profile={profile}
      />
      <RejectCandidateModal isOpen={rejectModalOpen} onClose={onClose} refetch={refetch} candidateId={candidateId} />
    </>
  );
};

export default CandidateRepliesModalManager;
