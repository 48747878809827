import React, { FC, cloneElement, SVGProps } from 'react';
import { CircleGraphic } from '#components/Index';

interface Props {
  now: number;
  max: number;
  onDarkBackground?: boolean;
}

const ProgressCircleGraphic: FC<Props & SVGProps<SVGSVGElement>> = ({
  now,
  max,
  onDarkBackground = false,
  ...args
}) => {
  const segments = [
    // Segments are cloned, with keys & colors included based on their order & the current progress
    /* eslint-disable react/jsx-key */
    <CircleGraphic.Circle x={4} y={2} radius={0.5} color="green-500" />,
    <CircleGraphic.Quarter x={4} y={4} rotate={90} color="green-900" />,
    <CircleGraphic.Quarter x={2} y={3} rotate={0} color="green-500" />,
    <CircleGraphic.Quarter x={2} y={2} rotate={90} color="green-900" />,
    <CircleGraphic.Quarter x={1} y={4} rotate={90} color="green-500" />,
    <CircleGraphic.Quarter x={3} y={3} rotate={-90} color="green-900" />,
    <CircleGraphic.Quarter x={4} y={3} rotate={0} color="green-500" />,
    <CircleGraphic.Quarter x={2} y={4} rotate={180} color="green-900" />,
    <CircleGraphic.Quarter x={3} y={1} rotate={90} color="green-500" />,
    <CircleGraphic.Quarter x={3} y={4} rotate={180} color="green-500" />,
    <CircleGraphic.Quarter x={3} y={2} rotate={0} color="green-500" />,
    /* eslint-enable react/jsx-key */
  ];

  const getColor = (index: number, color: string) => {
    if (index / segments.length < now / max) {
      return color;
    }
  };

  return (
    <CircleGraphic
      className="absolute right-0 bottom-0 [&>*]:transition [&>*]:duration-500"
      color={onDarkBackground ? 'gray-700' : 'white'}
      {...args}
    >
      {segments.map((segment, index) =>
        cloneElement(segment, { key: index, color: getColor(index, segment.props.color) })
      )}
    </CircleGraphic>
  );
};

export default ProgressCircleGraphic;
