import React, { FC, useCallback } from 'react';
import { User } from '#src/types';
import { OpportunityReplyModalType } from '#pages/talentPages/opportunities/modals/OpportunityRepliesModalManager';
import { Button, Icon } from '#components/Index';

interface Props {
  user: User | undefined;
  message: string;
  openModal: (modal: OpportunityReplyModalType) => void;
  withRequirements: boolean;
  isHandpicked: boolean;
}

const AwaitingResponse: FC<Props> = ({ user, message, openModal, isHandpicked, withRequirements }) => {
  const interestedAction = useCallback(() => {
    if (withRequirements) {
      openModal('requirements');
      return;
    }
    openModal('interested');
  }, [withRequirements, openModal]);

  return (
    <div className="flex flex-col text-md text-gray-500 font-light">
      <div className="flex flex-col px-6 gap-y-4 pb-6">
        <p>
          Hi {user?.firstName}, If you&apos;d like to move forward with this opportunity, click CONNECT ME and I&apos;ll
          reach out to share more information and connect you to the Hiring Manager.
        </p>

        <p>If you’re unsure and want more info, go ahead and ask me your questions.</p>

        <div className="flex">
          <Button variant="secondary" className="flex" onClick={() => openModal('questions')}>
            Ask a question
          </Button>
        </div>

        <div className="flex">
          <Button variant="primary" className="flex" onClick={() => interestedAction()}>
            I’m interested. Connect me
          </Button>
        </div>

        <p>Not interested? Let me know so we can improve our matching in the future.</p>

        <div className="flex">
          <Button variant="negative-full" size="small" onClick={() => openModal('notInterested')}>
            I’m not interested
          </Button>
        </div>
      </div>

      {isHandpicked && (
        <div className="bg-green-200 flex gap-x-4 p-3 items-center">
          <Icon.FeaturedCircle size={9} />
          <div className="flex flex-col">
            <span className="text-sm font-bold text-hpblack">{message}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AwaitingResponse;
