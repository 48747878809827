import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors } from '#src/types';

interface Payload {
  init: { opportunityId: number };
  success: never;
  fail: { errors: Errors<'base'>; status: number };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(POST, `/talents/opportunities/${params.opportunityId}/replies/interested`)(params);
