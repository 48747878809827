import React, { FC, useState } from 'react';
import { TalentProfile } from '#src/types';
import ProfileDetails from '../ProfileDetails';
import ProfileDetailsForm from './ProfileDetailsForm/ProfileDetailsForm';

interface ProfileDetailsEditableProps {
  profile: TalentProfile;
}

const ProfileDetailsEditable: FC<ProfileDetailsEditableProps> = ({ profile }) => {
  const [editMode, setEditMode] = useState(false);

  if (editMode) {
    return <ProfileDetailsForm initialProfile={profile} setEditMode={setEditMode} />;
  }

  return <ProfileDetails profile={profile} editSectionHandler={() => setEditMode(true)} />;
};

export default ProfileDetailsEditable;
