import React, { FC } from 'react';
import { Link, To } from 'react-router-dom';

import { ReactComponent as Logo } from '#assets/hiperpool-logo.svg';
import { Icon } from '../Index';
import { paths } from '#pages/paths';

const linkSections: Record<'platform' | 'company' | 'legal', Array<{ children: React.ReactNode; to: To }>> = {
  platform: [
    { children: 'Resources', to: paths.blog() },
    { children: 'Success Stories', to: paths.stories() },
    { children: 'For Professionals', to: paths.candidates() },
  ],
  company: [
    { children: 'FAQ', to: paths.supportForCandidate() },
    { children: 'About us', to: paths.about() },
    { children: 'Careers', to: { pathname: paths.about(), hash: 'open-positions' } },
    { children: 'Contact us', to: paths.contactUs() },
  ],
  legal: [
    { children: 'Privacy', to: paths.privacy() },
    { children: 'Terms & Conditions', to: paths.terms() },
    { children: 'Cookies', to: paths.cookies() },
  ],
};

export const Footer: FC = () => {
  const socials = {
    Facebook: { icon: <Icon.Facebook size={5} aria-hidden="true" />, url: 'https://www.facebook.com/hiperpool' },
    Instagram: { icon: <Icon.Instagram size={5} aria-hidden="true" />, url: 'https://www.instagram.com/hiperpool/' },
    LinkedIn: {
      icon: <Icon.Linkedin size={5} aria-hidden="true" />,
      url: 'https://www.linkedin.com/company/hiperpool/',
    },
    Twitter: { icon: <Icon.Twitter size={5} aria-hidden="true" />, url: 'https://twitter.com/Hiperpool' },
  };

  return (
    <footer className="bg-gray-50 px-variable-md py-6">
      <div className="mx-auto max-w-5xl">
        <div className="flex flex-wrap flex-col xs:flex-row gap-8 md:gap-14 my-10 lg:mb-16">
          <div className="grow">
            <Logo aria-label="Hiperpool" />
            <div className="font-serif text-xl mt-6 leading-snug text-green-900">
              <div>Empowering</div>
              <div>extraordinary</div>
              <div className="-translate-y-0.5">careers</div>
            </div>
          </div>

          <div className="flex flex-wrap flex-col xs:flex-row gap-12 sm:gap-8 md:gap-16 lg:gap-20 gap-y-8">
            {Object.entries(linkSections).map(([sectionName, sectionLinks]) => (
              <div key={sectionName} className="flex flex-col gap-2">
                <div className="uppercase font-bold text-xs text-green-900" id={sectionName}>
                  {sectionName}
                </div>

                <ul aria-labelledby={sectionName} className="grid gap-1">
                  {sectionLinks.map((link, idx) => (
                    <li key={idx}>
                      <Link {...link} className="large-link" />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <hr className="border-gray-300"></hr>

        <div className="flex flex-wrap gap-3 justify-between mt-6 items-center">
          <div className="text-sm font-semibold">© {new Date().getFullYear()} Hiperpool Ltd. All Rights Reserved.</div>
          <div className="flex -m-1">
            {Object.entries(socials).map(([socialName, socialInfo]) => (
              <a
                href={socialInfo.url}
                key={socialName}
                className="w-7 h-7 grid place-items-center rounded hover:text-blue-500 -outline-offset-2 outline-blue-300 focus-visible:outline"
                aria-label={`Hiperpool on ${socialName}`}
              >
                {socialInfo.icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};
