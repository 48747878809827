import { Errors } from '#root/src/types';
import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    token: string;
  };
  success: {
    teamMember: {
      id: number;
      firstName: string;
      lastName: string;
      email: string;
    };
    companyName: string;
    countries: string[];
  };
  fail: {
    errors: Errors<'base'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(GET, `/public/team_members/${params.token}`)(params);
