import React, { FC } from 'react';

import DarkBackgroundWithPeopleLayout from '#pages/DarkBackgroundWithPeopleLayout';
import ResetPassowrdForm from './Form';

const ResetPassword: FC = () => {
  return (
    <DarkBackgroundWithPeopleLayout>
      <div className="w-full max-w-lg justify-start 2xs:my-16 2xs:mx-variable-lg p-10 max-2xs:py-16 max-2xs:px-variable-lg 2xs:self-start bg-gray-50 text-hpblack 2xs:rounded-lg flex flex-col gap-6 items-center">
        <ResetPassowrdForm />
      </div>
    </DarkBackgroundWithPeopleLayout>
  );
};

export default ResetPassword;
