import React, { FC } from 'react';
import HiperpoolLogo from '#assets/logo/hiperpool.webp';
import { Button, Icon } from '#components/Index';
import { useGetOpenPositions } from './JoinTeamData';

const JoinTeam: FC = () => {
  const { data } = useGetOpenPositions();

  const renderContent = () => {
    if (data == null) {
      return <></>;
    }
    return (
      <div className="py-20 max-w-5xl m-auto">
        <h2 className="text-display-md font-serif text-center mb-7">Join our team!</h2>
        <p className="max-w-3xl m-auto text-center">
          Check out some of the roles we’re currently recruiting for, we look forward to hearing from you!
        </p>
        <div className="grid md:grid-cols-2 gap-8 justify-center items-center md:items-stretch my-12 max-w-5xl mx-auto">
          {data.openPositions.map((elm) => (
            <div key={elm.workableLink} className="bg-white rounded-xl overflow-clip grid shadow-xl">
              <div className="flex p-6 gap-6 h-full">
                <img
                  src={HiperpoolLogo}
                  width="63"
                  height="63"
                  className="w-[63px] h-[63px] rounded-full"
                  alt="Hiperpool logo"
                  loading="lazy"
                />
                <div className="flex flex-col justify-between">
                  <div>
                    <h3 className="text-normal font-serif text-display-xs">{elm.title}</h3>
                    <span className="text-xl text-gray-500">{elm.field}</span>
                  </div>
                  <p className="text-gray-500 mt-3">
                    <Icon.MapPinFill size={4} className="text-green-500 mr-1 -mt-1" />
                    {elm.location}
                  </p>
                </div>
              </div>
              <div className="flex bg-gray-50 justify-end p-3">
                <Button href={elm.workableLink} size="small" className="group">
                  Find out more
                  <Icon.ArrowRightLine size={4} className="transition duration-300 group-hover:translate-x-0.5" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div id="open-positions" className="bg-gray-50 px-variable-sm">
      {renderContent()}
    </div>
  );
};

export default JoinTeam;
