import React, { FC, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Modal, Icon } from '#components/Index';
import * as apiGetPurchaseDetails from '#api/endpoints/checkouts/getBoughtPackages';

const BoughtPackagesConfirmationModal: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const csId = searchParams.get('cs_id');

  const [isOpen, setIsOpen] = useState(false);
  const [packages, setPackages] = useState<Array<{ name: string; quantity: number }>>([]);

  const closeModal = () => {
    setIsOpen(false);
    navigate(window.location.pathname, { replace: true });
  };

  useEffect(() => {
    void (async () => {
      if (csId != null) {
        const payload = await apiGetPurchaseDetails.request({ csId });
        setPackages(payload.packages);
      }
    })();

    setIsOpen(true);
  }, [csId]);

  if (csId === null) return null;

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="py-16 flex flex-col items-center w-screen max-w-3xl gap-8">
        <div className="flex gap-8">
          <h2 className="text-display-md font-serif">Confirmation of your purchase</h2>

          <button
            onClick={closeModal}
            className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline self-center"
            aria-label="Close"
          >
            <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
          </button>
        </div>

        <div>
          <h5 className="font-semibold text-lg">Purchase details:</h5>
          <ul className="list-disc">
            {packages.map((packageItem, index) => (
              <li key={index} className="">
                {packageItem.name} package (x{packageItem.quantity})
              </li>
            ))}
          </ul>
        </div>

        <div className="text-sm text-center">
          <p>Thank you for choosing Hiperpool for your personal development journey.</p>
        </div>
      </div>
    </Modal>
  );
};

export default BoughtPackagesConfirmationModal;
