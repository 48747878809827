import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors } from '#root/src/types';

interface Payload {
  init: {
    slug: string;
    startDateUtc: string; // start_time mustn't be past date start_time must be ISO 8601 format, utc timezone date example: 2023-04-17T23:00:00.000000Z
    endDateUtc: string; // utc timezone date example: 2023-04-17T23:00:00.000000Z
  };
  success: {
    availability: Record<string, string[]>; // { "2020-01-07": ["1500", "1630", "1730"], "2020-01-08": [...] }
  };
  fail: Errors<'calendly'>; // "calendly": "The supplied parameters are invalid."
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(
    GET,
    `/public/coaches/${params.slug}/availability?start_time=${params.startDateUtc}&end_time=${params.endDateUtc}`
  )();
