import React, { FC, useEffect, useState, useCallback } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { request as apiRemoveExperienceGroups } from '#api/endpoints/talents/profile/employmentBranches/removeExperienceGroups';
import { useSteps } from '../../BaseProfileEditModal';
import { firmTypeIdsAndNames } from '../WorkHistoryModal';
import ExperienceFirmsForm from '#components/TalentSharedForms/ExperienceFirms/Form';

const ExperienceFirmsStep: FC = () => {
  const { setStepCallback, setNextDisabled, setStepVisibility, isStepVisible } = useSteps();

  // We need to keep the initial state of checked options based on the current visibility of the steps
  // to avoid losing the user's selection when the user goes back and forth between steps
  const currentlyVisibleOptions = Object.entries(firmTypeIdsAndNames)
    .map(([id, name]) => {
      if (isStepVisible(id)) {
        return id;
      }

      return '';
    })
    .filter((id) => id !== '');

  const [selectedStepIds, setSelectedStepIds] = useState<string[]>(currentlyVisibleOptions);

  // Prevent the user from moving to the next step if they haven't selected any value
  useEffect(() => {
    setNextDisabled(selectedStepIds.length === 0);
  }, [setNextDisabled, selectedStepIds]);

  // If user deselects some parent employment branches, we need to remove the corresponding child employment branches
  const stepCallback = useCallback(async () => {
    if (selectedStepIds.length === 0) return false;
    if (selectedStepIds.length === Object.entries(firmTypeIdsAndNames).length) return true;

    const selectedParentEmploymentBranches = selectedStepIds.map((id) => firmTypeIdsAndNames[id]);

    try {
      await apiRemoveExperienceGroups({ selectedParentEmploymentBranches });
      createSuccessToast({ title: 'Experience firms' });
      return true;
    } catch {
      createErrorToast({ title: 'Experience firms' });
      return false;
    }
  }, [selectedStepIds]);

  useEffect(() => {
    setStepCallback(() => stepCallback);
  }, [setStepCallback, stepCallback]);

  const handleChange = (stepId: string) => {
    if (selectedStepIds.includes(stepId)) {
      setSelectedStepIds(selectedStepIds.filter((id) => id !== stepId));
      setStepVisibility(stepId, false);
    } else {
      setSelectedStepIds([...selectedStepIds, stepId]);
      setStepVisibility(stepId, true);
    }
  };

  return (
    <ExperienceFirmsForm
      selectedStepIds={selectedStepIds}
      firmTypeIdsAndNames={firmTypeIdsAndNames}
      handleChange={handleChange}
    />
  );
};

export default ExperienceFirmsStep;
