import React, { FC } from 'react';
import { EmployerCandidate } from '#root/src/types';
import { CandidateReplyModalType } from '#pages/employerPages/candidateModals/CandidateRepliesModalManager';
import { Button } from '#components/Index';

interface ResponseBoxProps {
  candidate: EmployerCandidate;
  setSelectedModal: (modal: CandidateReplyModalType) => void;
}

const ResponseBox: FC<ResponseBoxProps> = ({ candidate, setSelectedModal }) => {
  if (candidate == null || !candidate.waitingForEmployerResponse) return null;

  return (
    <>
      <div className="grow flex flex-col max-w-6xl xl:max-w-8xl mx-auto py-6 px-8 2xl:px-0">
        <div className="relative w-full p-12 bg-white rounded-lg shadow-md-dark flex flex-col md:flex-row items-center">
          <div className="flex md:absolute md:left-1/2 md:transform md:-translate-x-1/2 space-x-4 mb-4 md:mb-0">
            <Button onClick={() => setSelectedModal('invite')}>Contact</Button>
            <Button variant="secondary" onClick={() => setSelectedModal('reject')}>
              Reject
            </Button>
          </div>
          <div className="md:absolute md:right-10">
            <Button variant="secondary" onClick={() => setSelectedModal('contactedDirectly')}>
              Already contacted
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResponseBox;
