import React, { FC } from 'react';
import AntonioLeone from '#assets/people/antonioLeoneGray.webp';
import MohamadElRafei from '#assets/people/mohamadElRafeiGray.webp';
import BainCompanyLogo from '#assets/logo/bain-and-company.png';
import LondonBusinessSchoolLogo from '#assets/logo/london-business-school.png';
import BoozAndCoLogo from '#assets/logo/booz-and-co.png';

const FounderCard: FC<{
  name: string;
  position: string;
  image: React.ReactNode;
  children: React.ReactNode;
}> = ({ name, position, image, children }) => (
  <div className="border-4 border-gray-50 max-w-sm relative flex flex-col justify-between">
    <div className="p-6 text-center">
      {image}
      <h3 className="text-display-sm font-serif uppercase">{name}</h3>
      <div className="text-gray-500 text-display-xs font-serif">{position}</div>
    </div>
    <div className="bg-gray-50 p-7 flex justify-center gap-8">{children}</div>
  </div>
);

const Founders: FC = () => {
  return (
    <div className="py-20 px-variable-md text-center">
      <h2 className="max-w-2xl mx-auto text-display-md font-serif mb-8">Why we created Hiperpool</h2>
      <div className="max-w-2xl mx-auto rich-text text-gray-600">
        <p>
          We launched Hiperpool to create a transparent, effective and modern approach to recruitment and career
          development. We combine the most valuable features from traditional recruitment with technology to give both
          candidates and employers the best experience and results.
        </p>
        <p>
          We experienced first-hand the frustrations with recruitment during our time as strategy consultants. We wanted
          to build an online platform that would leave all of that behind. Hiperpool was launched in 2015 in London and
          now supports professionals and employers across all major business hubs.
        </p>
      </div>
      <div className="mt-16 inline-grid md:grid-cols-2 gap-x-7 md:gap-x-8 gap-y-12 md:gap-y-14 mx-auto">
        {[
          {
            name: 'Mohamad El Rafei',
            position: 'Co-Founder',
            image: (
              <img
                className="rounded-full m-auto mb-3 w-[150px] h-[150px]"
                width="150"
                height="150"
                src={MohamadElRafei}
                alt="Mohamad El Rafei Co-Founder of Hiperpool"
                loading="lazy"
              />
            ),
            children: (
              <>
                <img
                  src={BainCompanyLogo}
                  alt="Bain and Company logo"
                  height="66"
                  className="object-contain h-[66px]"
                  width="auto"
                  loading="lazy"
                />
                <img
                  src={LondonBusinessSchoolLogo}
                  alt="London Business School logo"
                  height="66"
                  className="object-contain h-[66px]"
                  width="auto"
                  loading="lazy"
                />
              </>
            ),
          },
          {
            name: 'Antonio Leone',
            position: 'Co-Founder',
            image: (
              <img
                className="rounded-full m-auto mb-3 w-[150px] h-[150px]"
                width="150"
                height="150"
                src={AntonioLeone}
                alt="Antonio Leone Co-Founder of Hiperpool"
                loading="lazy"
              />
            ),
            children: (
              <>
                <img
                  src={BoozAndCoLogo}
                  alt="Booz and Co logo"
                  height="66"
                  className="object-contain h-[66px]"
                  width="auto"
                  loading="lazy"
                />
                <img
                  src={LondonBusinessSchoolLogo}
                  alt="London Business School logo"
                  height="66"
                  className="object-contain h-[66px]"
                  width="auto"
                  loading="lazy"
                />
              </>
            ),
          },
        ].map((founder) => (
          <FounderCard key={founder.name} {...founder} />
        ))}
      </div>
    </div>
  );
};

export default Founders;
