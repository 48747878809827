import React, { FC } from 'react';
import { SuccessCard } from '#components/Index';
import { paths } from '#pages/paths';

interface Story {
  id: number;
  slug: string;
  company: string;
  companyImage: string;
  position: string;
  location: string;
  candidatesBackgrounds: Array<{ image: string; alt: string }>;
}
const OtherStories: FC<{ stories: Story[] }> = ({ stories }) => {
  return (
    <div className="pt-16 pb-24 lg:pb-32 px-variable-sm grid gap-12 justify-center">
      <h3 className="text-center max-w-2xl lg:max-w-6xl text-display-xs font-serif lg:text-display-sm xl:text-display-md m-auto">
        Other success stories
      </h3>
      <div className="grid lg:grid-cols-3 gap-x-7 lg:gap-x-8 gap-y-12 lg:gap-y-14 max-w-sm lg:max-w-6xl">
        {stories.map((story, idx) => (
          <SuccessCard
            key={`${story.slug} - ${idx}`}
            link={paths.story({ slug: story.slug })}
            position={story.position}
            location={story.location}
            logo={<img src={story.companyImage} alt={story.company} loading="lazy" className="object-contain" />}
            backgrounds={story.candidatesBackgrounds}
          />
        ))}
      </div>
    </div>
  );
};

export default OtherStories;
