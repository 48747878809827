import React, { FC, Dispatch } from 'react';
import { useGetDesiredEmploymentTypes } from './DesiredEmploymentTypesData';
import { Selectable, Spinner } from '#components/Index';

interface TypeOfEmploymentFormProps {
  selectedDesiredEmploymentTypes: number[];
  setSelectedDesiredEmploymentTypes: Dispatch<React.SetStateAction<number[]>>;
}

const TypeOfEmploymentForm: FC<TypeOfEmploymentFormProps> = ({
  selectedDesiredEmploymentTypes,
  setSelectedDesiredEmploymentTypes,
}) => {
  const { data: employmentTypeData, isLoading } = useGetDesiredEmploymentTypes();

  if (isLoading) {
    return <Spinner className="py-32" />;
  }

  if (employmentTypeData == null) {
    return null;
  }

  const { desiredEmploymentTypes } = employmentTypeData;

  const handleChangeDesiredEmploymentType = ({ id }: { id: number }) => {
    if (selectedDesiredEmploymentTypes.includes(id)) {
      // If the desired employment types already includes the ID, remove it
      setSelectedDesiredEmploymentTypes(selectedDesiredEmploymentTypes.filter((typeId) => typeId !== id));
    } else {
      // Else add the id to the desired employment types
      setSelectedDesiredEmploymentTypes([...selectedDesiredEmploymentTypes, id]);
    }
  };

  return (
    <div className="rich-text text-hpblack w-full">
      <h1>
        What <span className="text-green-900">type of employment</span> are you interested in?
      </h1>
      <p>Select all that apply</p>

      <div className="flex max-xs:flex-wrap gap-6 mt-8">
        {desiredEmploymentTypes.map(({ id, name }) => {
          return (
            <Selectable
              type="checkbox"
              name="employment-type"
              id={`employment-type-${id}`}
              value={`employment-type-${name.toLowerCase()}`}
              size="xl"
              onChange={() => handleChangeDesiredEmploymentType({ id })}
              checked={selectedDesiredEmploymentTypes.includes(id)}
              className="grow basis-1/2 text-center [&>*]:justify-center"
              key={id}
            >
              {name}
            </Selectable>
          );
        })}
      </div>
    </div>
  );
};

export default TypeOfEmploymentForm;
