import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import contentList from './content';

const Privacy: FC = () => {
  return (
    <>
      <Helmet>
        <title>Hiperpool - Privacy Notice</title>
      </Helmet>
      <div className="px-variable-md py-16 flex items-start justify-center gap-14 text-sm">
        <aside className="sticky max-sm:hidden top-0 pt-10 min-w-[10rem] max-w-[18rem] max-h-screen overflow-y-auto">
          <h2 className="uppercase text-gray-600 mb-5 leading-6">List of contents</h2>
          <ol>
            {contentList.map((content) => (
              <li key={content.id} className="my-2">
                <Link to={`#${content.id}`} className="large-link flex !leading-5">
                  {content.heading}
                </Link>
              </li>
            ))}
          </ol>
        </aside>
        <section className="max-w-screen-sm">
          <div className="pb-10">
            <h1 className="font-serif text-display-xs md:text-display-sm !leading-10">Privacy Notice</h1>

            <div className="rich-text rich-text-lists mt-8 md:mt-12">
              <p>
                <strong>Who should read this notice:</strong> The information in this document will be relevant to you
                if you:
              </p>
              <ul>
                <li>
                  visit our website at <Link to="/">hiperpool.com</Link> (<strong>Website</strong>) or interact with our
                  corporate accounts on social media platforms,
                </li>
                <li>
                  are an individual seeking employment and use our recruitment services (a <strong>Professional</strong>
                  ),
                </li>
                <li>
                  work for an organisation that has engaged us to provide recruitment services (a{' '}
                  <strong>Client</strong>
                  ),
                </li>
                <li>register to use our web portal (whether as a Professional or a Client),</li>
                <li>interact with our corporate social media accounts.</li>
              </ul>

              <p>
                <strong>What does this document do:</strong> This notice sets out what information we collect about you,
                what we use it for and who we share it with. It also explains your rights and what to do if you have any
                concerns about your personal data.
              </p>

              <p>
                <strong>Who to contact about this notice:</strong> If you have any questions about this privacy notice,
                how we handle your personal data or you are looking to exercise one of your rights, please contact{' '}
                <a href="mailto:info@hiperpool.com">info@hiperpool.com</a>
              </p>

              <p>
                <strong>Latest updates:</strong> This notice was last updated [March 2023]. We may sometimes need to
                update this notice to reflect changes to the way we provide our services or to comply with updates to
                data protection law. Where possible, we will notify you of any important changes before they take
                effect.
              </p>

              <p>
                <strong>Who checks this notice is enforced:</strong> As a company based in the UK, how we use people’s
                information is regulated by the Information Commissioner’s Office (<strong>ICO</strong>) – the UK
                regulator responsible for checking that businesses comply with UK data protection law. We are registered
                with the ICO under registration number ZA776565.
              </p>
              <p>
                Whenever we collect, use or share information about people located outside the UK we comply with any
                additional local laws that apply.
              </p>
            </div>
          </div>
          {contentList.map((sec) => (
            <section
              key={sec.id}
              id={sec.id}
              className="border-t-2 border-gray-200 py-10 -scroll-mt-0.5 rich-text rich-text-lists rich-text-tables"
            >
              <h2>{sec.heading}</h2>
              {sec.content}
            </section>
          ))}
        </section>
      </div>
    </>
  );
};

export default Privacy;
