import React, { FC } from 'react';
import { useGetCoaches } from '../../../homepage/interview/elements/CoachesData';
import UpcomingSessions from '../../../homepage/interview/elements/UpcomingSessions';
import CoachesList from './CoachesList';
import { useSearchParams } from 'react-router-dom';
import CoachFilters from './CoachFilters';
import { Spinner } from '#components/Index';

const SessionsAndCoaches: FC = () => {
  const [searchParams] = useSearchParams();
  const regionFromQuery = searchParams.get('region') ?? '';
  const privacyCompanyFromQuery = searchParams.get('privacyCompany') ?? '';
  const coachingTypeFromQuery = searchParams.get('coachingType') ?? '';

  const { data, isLoading } = useGetCoaches(regionFromQuery, privacyCompanyFromQuery, coachingTypeFromQuery);

  if (isLoading) {
    return <Spinner className="py-32" />;
  }

  return (
    <section className="py-16 lg:py-20 px-variable-md bg-gray-50">
      <div className="max-w-6xl m-auto">
        {/* FIXME: After adding filters some of the upcoming/pending sessions may be not visible to user */}
        <UpcomingSessions coaches={data?.coaches} />
        <div className="text-center">
          <h2 className="text-display-md font-serif mx-auto">Our coaches</h2>
        </div>
        <CoachFilters />
        <CoachesList coaches={data?.coaches} />
      </div>
    </section>
  );
};

export default SessionsAndCoaches;
