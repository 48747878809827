import React, { FC } from 'react';
import { CircleGraphic } from '#components/Index';

const HeroBannerTextContent: FC = () => (
  <div className="xl:mt-8">
    <h1 className="text-display-md font-serif lg:text-display-lg xl:text-display-2xl">
      Insights & Resources<span className="text-green-500">.</span>
    </h1>
    <p className="text-md mt-7 sm:mt-10 lg:text-xl max-w-lg">
      Exclusive tips, tools and industry insights to help you make your next career move, or push the boundaries in your
      current role.
    </p>
  </div>
);

const HeroBannerImageContent: FC = () => (
  <div className="max-sm:px-variable-sm">
    <CircleGraphic className="sm:absolute h-auto w-[57%] max-w-[500px] sm:w-[120%] sm:ml-auto">
      <CircleGraphic.Circle x={2} y={1} radius={0.5} color="green-500" />
      <CircleGraphic.Quarter x={1} y={2} rotate={-90} color="green-900" />
      <CircleGraphic.Quarter x={1} y={3} rotate={-90} color="green-500" />
      <CircleGraphic.Half x={2} y={3} rotate={-90} color="green-900" />
      <CircleGraphic.Quarter x={3} y={2} rotate={0} color="green-500" />
      <CircleGraphic.Quarter x={4} y={2} rotate={180} color="green-900" />
    </CircleGraphic>
  </div>
);

const Header: FC = () => {
  return (
    <div className="relative isolate z-10 grid sm:grid-cols-[7fr_4fr] bg-hpblack text-white">
      <div className="mt-8 my-16 sm:mb-24 sm:mt-16 mx-variable-lg sm:mr-0">
        <div className="max-w-4xl ml-auto sm:pr-8">
          <HeroBannerTextContent />
        </div>
      </div>
      <div className="max-sm:order-first relative isolate sm:overflow-hidden">
        <HeroBannerImageContent />
      </div>
    </div>
  );
};
export default Header;
