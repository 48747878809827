import React, { FC } from 'react';
import { Button, CallToActionSection } from '#components/Index';
import { paths } from '#pages/paths';

const WantToLearnMore: FC = () => {
  return (
    <CallToActionSection
      text={
        <>
          Want to learn more? <br />
          Set up a call with our Client Success Team
        </>
      }
      button={
        <Button size="large" to={paths.signUpEmployer()}>
          Let’s talk
        </Button>
      }
    />
  );
};

export default WantToLearnMore;
