import React, { FC } from 'react';
import { Education } from '#src/types';
import { Button, Icon } from '#src/components/Index';

interface EducationEntryProps {
  education: Education;
  editSectionHandler?: (education: Education) => void;
}

const EducationEntry: FC<EducationEntryProps> = ({ education, editSectionHandler = null }) => {
  return (
    <div className="flex gap-x-8">
      <div>
        <div className="bg-gray-50 h-[60px] w-[60px] md:h-[100px] md:w-[100px] rounded-full overflow-clip justify-self-start shrink-0">
          <img
            src={education.avatarUrl}
            alt={education.college}
            className="mix-blend-multiply max-h-[60px] md:max-h-[100px]"
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-y-2 justify-between flex-grow">
        <div className="flex flex-col gap-y-1">
          <h2 className="font-semibold text-xl">{education.college}</h2>
          <h3 className="font-medium text-md">
            {education.degree} in {education.field}
          </h3>
          <span className="text-gray-500 text-md">{education.graduationYear}</span>
        </div>

        {editSectionHandler != null && (
          <div>
            <div className="flex gap-x-2">
              <Button variant="secondary" size="small" className="flex" onClick={() => editSectionHandler(education)}>
                <Icon.EditPencil size={3} />
                Edit
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EducationEntry;
