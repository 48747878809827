import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { Opportunity, Errors } from '#root/src/types';

interface Payload {
  init: { secretId: string };
  success: {
    opportunity: Opportunity;
  };
  fail: {
    errors: Errors<'base'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(GET, `/public/opportunities/${params.secretId}`)();
