/* eslint-disable react/display-name */
import React, { forwardRef, InputHTMLAttributes } from 'react';
import { uniqueId } from 'lodash-es';
import { Icon } from '#components/Index';
import checkboxStyles from './Checkbox.module.css';
import formStyles from '#components/Form/styles/FormInput.module.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  className?: string;
  children?: React.ReactNode;
}
const Checkbox = forwardRef<HTMLInputElement, Props>(({ error, className = '', children, ...rest }, ref) => {
  const uniqId = uniqueId('checkbox');
  const hasError = error !== undefined && error !== '';

  return (
    <div className={`${formStyles['input-wrapper']} ${className}`}>
      <div className="relative">
        <input {...rest} id={uniqId} type="checkbox" ref={ref} className={checkboxStyles.input} />
        <label htmlFor={uniqId} className={checkboxStyles.label}>
          <div className={checkboxStyles['checkbox-wrapper']}>
            <div className={checkboxStyles.checkbox}>
              <Icon.CheckLine size={3} className={checkboxStyles.line} />
            </div>
          </div>
          {children}
        </label>
      </div>
      {hasError && <span className={formStyles.error}>{error}</span>}
    </div>
  );
});

export default Checkbox;
