import React, { FC, useEffect, useState, useCallback } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { AutocompleteOption, TalentPreferences } from '#root/src/types';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { request as apiUpdateUnpreferredCompanies } from '#root/src/api/endpoints/talents/profile/companyPreferences/updateUnpreferredCompanies';
import * as apiAutocompletePrivacyCompanies from '#api/endpoints/autocomplete/privacyCompanies';
import { useSteps } from '../../BaseProfileEditModal';
import { AutocompleteInput } from '#components/Form/Index';
import OptionBadge from '#components/Form/Inputs/MultiStringInput/OptionBadge';

const InterestedCompaniesStep: FC = () => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { setStepCallback, setNextDisabled } = useSteps();

  const [unpreferredCompanies, setUnpreferredCompanies] = useState<TalentPreferences['unpreferredCompanies']>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<string>('');

  useEffect(() => {
    if (profilePreferencesData != null) {
      setUnpreferredCompanies(profilePreferencesData.profilePreferences.unpreferredCompanies);
    }
  }, [profilePreferencesData]);

  const stepCallback = useCallback(async () => {
    try {
      await apiUpdateUnpreferredCompanies({
        companyPreference: { companyNames: unpreferredCompanies.map((company) => company.name) },
      });
      createSuccessToast({ title: 'Unpreferred companies' });
      return true;
    } catch {
      createErrorToast({ title: 'Unpreferred companies' });
      return false;
    }
  }, [unpreferredCompanies]);

  useEffect(() => {
    setNextDisabled(false); // This step is optional
    setStepCallback(() => stepCallback);
  }, [setStepCallback, setNextDisabled, stepCallback]);

  // NOTE: This component is almost 1:1 with src/pages/talentPages/profileAndPreferences/preferences/elements/modals/steps/InterestedCompaniesStep.tsx
  // And will be moved to a shared component in a future PR

  const handleBadgeClick = (id: number) => {
    setUnpreferredCompanies(unpreferredCompanies.filter((company) => company.id !== id));
  };

  const selectHandler = (selected: AutocompleteOption) => {
    setAutocompleteValue('');

    if (unpreferredCompanies.find((company) => company.id === selected.id) != null) {
      setUnpreferredCompanies(unpreferredCompanies.filter((company) => company.id !== selected.id));
      return;
    }

    setUnpreferredCompanies([
      ...unpreferredCompanies,
      {
        id: selected.id,
        name: selected.name,
      },
    ]);
  };

  return (
    <div className="rich-text text-hpblack w-full">
      <h1>
        What companies are you <span className="text-red-600">NOT</span> interested in?
      </h1>

      <AutocompleteInput
        label="Company"
        placeholder="E. g. ACME Corp."
        value={autocompleteValue}
        onChange={(name) => setAutocompleteValue(name)}
        autocompleteRequest={apiAutocompletePrivacyCompanies.request}
        selectHandler={selectHandler}
        recordsLimit={5}
      />

      <div className="flex flex-wrap max-w-full py-8">
        {unpreferredCompanies.map((unpreferredCompany) => (
          <OptionBadge
            key={unpreferredCompany.id}
            option={unpreferredCompany.name}
            handleBadgeClick={() => handleBadgeClick(unpreferredCompany.id)}
            negative
          />
        ))}
      </div>
    </div>
  );
};

export default InterestedCompaniesStep;
