import { Errors } from '#root/src/types';
import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    talent: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      timeZone: string;
      location: string;
      phone?: string;
      marketingEmailConsent: boolean;
    };
    redirectPath?: string;
  };
  success: { redirectUrl: string };
  fail: {
    errors: Errors<'base' | 'firstName' | 'lastName' | 'email' | 'password' | 'location' | 'phone'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/talents');
