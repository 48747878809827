import React, { FC } from 'react';

import styles from '../Coach.module.css';
import { CircleGraphic, Icon, UserWrapper } from '#components/Index';
import { Link } from 'react-router-dom';
import { paths } from '../../../paths';
import 'react-datepicker/dist/react-datepicker.css';
import FloatingBookSessionSection from './FloatingBookSessionSection';
import { StarRating } from '#components/StarRating/StarRating';

interface Props {
  coachSlug: string;
  firstName: string;
  lastName?: string;
  displayName?: string;
  subtitle?: string;
  currentLocation?: string;
  workLocation?: string;
  avatar?: React.ReactNode;
  rating?: number;
  reviewCount: number;
}

const Header: FC<Props> = ({
  coachSlug,
  firstName,
  displayName,
  subtitle,
  currentLocation,
  workLocation,
  avatar,
  rating = 5,
  reviewCount,
}) => {
  return (
    <>
      <div className="row-start-1 row-span-2 col-span-full bg-hpblack relative overflow-clip -z-10 pointer-events-none">
        <CircleGraphic cols={4} className="absolute left-0 opacity-20">
          <CircleGraphic.Quarter x={1} y={1} color="green-500" rotate={180} />
          <CircleGraphic.Quarter x={2} y={1} color="green-500" rotate={90} />
          <CircleGraphic.Half x={3} y={1} color="green-900" rotate={0} />
          <CircleGraphic.Half x={1} y={3} color="green-900" rotate={-90} />
          <CircleGraphic.Quarter x={2} y={2} color="green-900" />
          <CircleGraphic.Quarter x={3} y={2} color="green-500" rotate={-90} />
        </CircleGraphic>

        <CircleGraphic className="absolute -right-4 transition max-sm:opacity-0 max-md:opacity-20">
          <CircleGraphic.Circle x={1} y={1} color="green-900" radius={0.5} className="transition max-lg:opacity-0" />
          <CircleGraphic.Quarter x={3} y={1} color="green-900" className="transition max-md:opacity-0" />
          <CircleGraphic.Circle x={4} y={1} color="green-500" radius={0.5} />
          <CircleGraphic.Quarter x={2} y={2} color="green-500" rotate={180} className="transition max-lg:opacity-0" />
          <CircleGraphic.Quarter x={3} y={2} color="green-500" rotate={90} />
          <CircleGraphic.Quarter x={4} y={2} color="green-900" />
          <CircleGraphic.Quarter x={2} y={3} color="green-900" rotate={-90} />
          <CircleGraphic.Quarter x={3} y={3} color="green-900" />
          <CircleGraphic.Quarter x={4} y={3} color="green-500" rotate={-90} />
        </CircleGraphic>
      </div>

      <div className="bg-white row-start-3 col-span-full"></div>

      <Link
        to={paths.coaches()}
        className="large-link-light inline-flex items-center gap-2 text-white row-start-1 col-start-3 justify-self-start self-start my-5"
      >
        <Icon.ArrowLeftLine size={5} aria-hidden="true" />
        Back to coaches
      </Link>

      <div className={styles.avatar}>{avatar}</div>
      {reviewCount !== 0 && reviewCount > 0 && (
        <div className="row-start-2 col-start-4 max-2xs:col-end-5 self-end my-6 flex gap-x-2 flex-wrap items-center 2xs:ml-2 md:ml-0 -mr-4 transition-all">
          <StarRating rating={rating} variant="dark" />
          <span className="sr-only">{`${rating} star rating`}</span>

          <span className="text-gray-300 xs:text-gray-400 inline-block translate-y-px text-sm font-medium leading-tight transition">
            {reviewCount} reviews
          </span>
        </div>
      )}

      <div className="row-start-3 col-start-3 max-lg:col-end-5 lg:col-start-4 my-6 max-sm:mt-16 max-lg:mt-20 max-md:transition-[margin]">
        <h1 className="text-display-md font-serif">{displayName}</h1>
        <p className="text-xl font-medium mt-2 leading-7">{subtitle}</p>
        <div className="flex flex-wrap gap-y-1 gap-x-6 mt-2.5 text-gray-500 text-sm -ml-px leading-6">
          {currentLocation != null && (
            <span className="inline-flex gap-2 items-center">
              <Icon.MapPinFill size={5} className="text-green-600" aria-hidden="true" />
              {`Based in ${currentLocation}`}
            </span>
          )}
          {workLocation != null && (
            <span className="inline-flex gap-2 items-center">
              <Icon.MapPinFill size={5} className="text-green-600" aria-hidden="true" />
              {`Worked in ${workLocation}`}
            </span>
          )}
        </div>
      </div>
      <UserWrapper>
        <FloatingBookSessionSection firstName={firstName} coachSlug={coachSlug} isLoggedIn={false} user={null} />
      </UserWrapper>
    </>
  );
};

export default Header;
