import React, { FC } from 'react';
import badgeStyles from '#components/Form/SearchSelect/SearchSelectBadge.module.css';
import { Icon } from '#src/components/Index';

interface BadgesProps {
  searchSelectId?: string;
  negative: boolean;
  option: string;
  handleBadgeClick: (event: React.MouseEvent<HTMLButtonElement> & { target: HTMLInputElement }) => void;
}

const OptionBadge: FC<BadgesProps> = ({ searchSelectId = '', negative, option, handleBadgeClick }) => {
  return (
    <div key={option} className={`${badgeStyles.badge} ${negative ? badgeStyles['badge--negative'] : ''}`}>
      <div className={badgeStyles.badge__inner}>
        <button
          id={`${searchSelectId}-badge-${option}`}
          className={`${badgeStyles.badge__button}`}
          onClick={handleBadgeClick}
        >
          <div className={`${badgeStyles.badge__button__inner}`}>
            {option}
            <Icon.X size={5} aria-label="(remove)" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default OptionBadge;
