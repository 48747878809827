import React, { FC, useState, useEffect } from 'react';
import { Select } from '#components/Form/Index';
import { Button } from '#components/Index';
import { useSearchParams } from 'react-router-dom';
import { useGetCoachFilterOptions } from './CoachFilltersData';

const CoachFilters: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // The filter options available
  const [regions, setRegions] = useState<Array<{ id: string; name: string }>>([]);
  const [coachingTypes, setCoachingTypes] = useState<Array<{ id: number; name: string }>>([]);
  const [privacyCompanies, setPrivacyCompanies] = useState<Array<{ id: number; name: string }>>([]);

  const { data: filtersData } = useGetCoachFilterOptions();

  useEffect(() => {
    if (filtersData != null) {
      setRegions(filtersData.regions);
      setCoachingTypes(filtersData.privacyCompanies);
      setPrivacyCompanies(filtersData.coachingTypes);
    }
  }, [filtersData]);

  // The actual selections on the page
  const [selectedRegion, setSelectedRegion] = useState<string>(searchParams.get('region') ?? '');
  const [selectedPrivacyCompanies, setSelectedPrivacyCompanies] = useState<string>(
    searchParams.get('privacyCompany') ?? ''
  );
  const [selectedCoachingType, setSelectedCoachingType] = useState<string>(searchParams.get('coachingType') ?? '');

  const handleRegionChange = (value: string) => {
    if (value === '') return;
    setSelectedRegion(value);
  };
  const handleCompanyChange = (value: string) => {
    if (value === '') return;
    setSelectedPrivacyCompanies(value);
  };
  const handleCoachingTypeChange = (value: string) => {
    if (value === '') return;
    setSelectedCoachingType(value);
  };

  const clearFilters = () => {
    setSelectedRegion('');
    setSelectedCoachingType('');
    setSelectedPrivacyCompanies('');
  };

  useEffect(
    function syncPropsToUrlParams() {
      const possibleHashEntries = {
        region: selectedRegion,
        privacyCompany: selectedPrivacyCompanies,
        coachingType: selectedCoachingType,
        cs_id: searchParams.get('cs_id') ?? '', // We need to keep the cs_id - otherwise confirmation modal won't work
      };
      const queryHash = Object.fromEntries(Object.entries(possibleHashEntries).filter(([, value]) => value !== ''));

      setSearchParams(queryHash);
    },
    [selectedRegion, selectedCoachingType, selectedPrivacyCompanies, searchParams, setSearchParams]
  );

  return (
    <div className="my-8">
      <legend className="contents">
        <div className="flex flex-col gap-y-4 items-center md:gap-x-8 md:flex-row">
          <span className="uppercase text-gray-500 font-semibold text-xs leading-tight">Filter by:</span>

          <Select
            className="flex-auto w-96 md:w-48"
            onChange={(event) => handleRegionChange(event.target.value)}
            value={selectedRegion === undefined ? '' : selectedRegion}
          >
            {[
              <option disabled value="" key="">
                Coach Location
              </option>,
              ...regions.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              )),
            ]}
          </Select>

          <Select
            className="flex-auto w-96 md:w-48"
            onChange={(e) => handleCompanyChange(e.target.value)}
            value={selectedPrivacyCompanies === undefined ? '' : selectedPrivacyCompanies}
          >
            {[
              <option disabled value="" key="">
                Company Background
              </option>,
              ...coachingTypes.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              )),
            ]}
          </Select>

          <Select
            className="flex-auto w-96 md:w-48"
            onChange={(e) => handleCoachingTypeChange(e.target.value)}
            value={selectedCoachingType === undefined ? '' : selectedCoachingType}
          >
            {[
              <option disabled value="" key="">
                Coaching Type
              </option>,
              ...privacyCompanies.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              )),
            ]}
          </Select>

          <Button variant="secondary" onClick={clearFilters}>
            Clear filters
          </Button>
        </div>
      </legend>
    </div>
  );
};

export default CoachFilters;
