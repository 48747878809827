import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { paths } from '#pages/paths';
import { Button } from '#src/components/Index';
import styles from '#pages/talentPages/opportunities/index/elements/OpportunityNavbar.module.css';

const ProfileSubNavigation: FC = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="flex justify-between border-b-2 border-gray-200 mb-6">
      <div className="flex gap-x-4 justify-center w-full md:gap-x-8 md:justify-normal">
        <Button
          variant="link"
          className={`${styles.navLink} ${pathname === paths.talentProfile() ? styles.navLinkActive : ''}`}
          to={paths.talentProfile()}
        >
          My Profile
        </Button>
        <Button
          variant="link"
          className={`${styles.navLink} ${pathname === paths.talentPreferences() ? styles.navLinkActive : ''}`}
          to={paths.talentPreferences()}
        >
          My Experience & Preferences
        </Button>
      </div>
    </div>
  );
};

export default ProfileSubNavigation;
