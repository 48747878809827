import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: never;
  success: {
    desiredEmploymentTypes: Array<{
      id: number;
      name: string;
    }>;
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/public/desired_employment_types');
