import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    sessionPackageId: number;
  };
  success: { redirectUrl: string };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/talents/checkouts');
