import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import Select, { Props as SelectProps } from './Select';

interface RHFSelectOption {
  text: string;
  key?: string | number;
  value: string | number;
  disabled?: boolean;
  hidden?: boolean;
}

interface Props extends SelectProps {
  control: Control<any>;
  name: string;
  options: RHFSelectOption[];
  defaultValue?: string | number;
}

const SelectRHF: FC<Props> = ({
  name: propsName,
  control,
  options,
  onChange: propsOnChange,
  onBlur: propsOnBlur,
  defaultValue = '',
  ...rest
}) => {
  return (
    <Controller
      name={propsName}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, name, onBlur } }) => (
        <Select
          {...rest}
          name={name}
          value={value ?? ''}
          onChange={(e) => {
            onChange(e);
            if (propsOnChange != null) {
              propsOnChange(e);
            }
          }}
          onBlur={(e) => {
            onBlur();
            if (propsOnBlur != null) {
              propsOnBlur(e);
            }
          }}
        >
          {options.map((option) => (
            <option
              key={option.key ?? option.value}
              value={option.value}
              disabled={option.disabled}
              hidden={option.hidden}
            >
              {option.text}
            </option>
          ))}
        </Select>
      )}
    />
  );
};

export default SelectRHF;
