import { AutocompleteOption } from '#root/src/types';
import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: { query: string };
  success: AutocompleteOption[];
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(GET, '/autocomplete/privacy_companies')(params);
