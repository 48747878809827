import * as apiGetWorkPolicies from '#api/endpoints/onboarding/workPolicies';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  workPolicies = 'work-policies',
}

type GetApiPayload = apiGetWorkPolicies.Type['success']['payload'];
export const useGetWorkPolicies = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>([KeysEnum.workPolicies], async () => await apiGetWorkPolicies.request(), {
    ...options,
  });
