import { useQuery } from 'react-query';

import { ReactQueryOptions } from '#src/types';
import * as apiGetListOfPosts from '#api/endpoints/posts/getList';

export enum KeysEnum {
  blog = 'blog',
  posts = 'posts',
  list = 'list',
}

type GetApiPayload = apiGetListOfPosts.Type['success']['payload'];
export const useGetPosts = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.blog, KeysEnum.posts, KeysEnum.list],
    async () => await apiGetListOfPosts.request(),
    {
      ...options,
    }
  );
