import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors } from '#root/src/types';

interface Payload {
  init: {
    id: number;
    reply: {
      content: string;
    };
  };
  success: never;
  fail: {
    errors: Errors<'base'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(POST, `/talents/offers/${params.id}/reply`)(params);
