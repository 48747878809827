import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from '#components/Index';
import { CandidateNavbar } from '../homepage/candidate/elements/CandidateNavbar';
import { useUserDetails } from '#src/AppData';

interface Props {
  withFooter?: boolean;
}
const TalentLayout: FC<Props> = ({ withFooter = false }) => {
  const context = useUserDetails();

  return (
    <>
      <CandidateNavbar />
      <main className="bg-gray-50">
        <Outlet context={context} />
      </main>
      {withFooter && <Footer />}
    </>
  );
};

export default TalentLayout;
