import React, { FC } from 'react';
import { CoachBundles, Modal } from '#components/Index';

import * as apiGetBundleLink from '#api/endpoints/checkouts/buyBundleWithCoachRedirect';
import { DateTime } from 'luxon';
import { DATETIME_URL_FORMAT } from './CalendarController';
import { User } from '#root/src/types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  timezone: string;
  dateTime: DateTime;
  coachSlug: string;
  isLoggedIn: boolean;
  user: User | null;
}
const BundlesModal: FC<Props> = ({ isOpen, onClose, dateTime, timezone, coachSlug, isLoggedIn, user }) => {
  const onClick =
    isLoggedIn && user?.type === 'Talent'
      ? async (sessionPackageId: number) =>
          await apiGetBundleLink
            .request({
              sessionPackageId,
              calendly: { date: dateTime.toUTC().toFormat(DATETIME_URL_FORMAT), timezone },
              coachSlug,
            })
            .then((payload) => (window.location.href = payload.redirectUrl))
      : undefined;

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="my-20 px-variable-sm grid gap-12">
        <h2 className="text-display-md font-serif text-center">Book a bundle of sessions and save</h2>
        <CoachBundles onClick={onClick} />
      </div>
    </Modal>
  );
};

export default BundlesModal;
