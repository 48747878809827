import React, { FC } from 'react';
import { TextInput, Success } from '#components/Form/Index';
import { Button, ErrorAlert, Icon } from '#components/Index';
import { useUpdatePasswordController, FormValues } from './FormController';
import { paths } from '#pages/paths';
import { Link } from 'react-router-dom';
import ResendInstructionsExpiredTokenNotification from '../ResendInstructionsExpiredTokenNotification';

interface Props {
  isTokenExpired: boolean;
  defaultValues?: Partial<FormValues>;
}

const UpdatePasswordForm: FC<Props> = ({ defaultValues, isTokenExpired }) => {
  const {
    form: {
      register,
      handleSubmit,
      formState: { errors, isSubmitSuccessful },
    },
    submitForm,
    baseErrors,
  } = useUpdatePasswordController(defaultValues);

  const getPasswordErrors = () => {
    if (errors.resetPasswordToken != null) return errors.resetPasswordToken.message;
    return errors.password?.message;
  };

  if (isTokenExpired) {
    return <ResendInstructionsExpiredTokenNotification />;
  }

  if (isSubmitSuccessful) {
    return (
      <Success title="Your password has been changed" icon={<Icon.UserFollowLine size={6} />}>
        You can{' '}
        <Link to={paths.signIn()} className="link">
          sign in
        </Link>{' '}
        now with your new password.
      </Success>
    );
  }

  return (
    <>
      <h1 className="text-display-sm font-serif">Change your password</h1>
      <form onSubmit={handleSubmit(submitForm)} className="w-full grid text-sm gap-6 text-gray-700 font-medium">
        <TextInput
          label="New password"
          type="password"
          autoComplete="new-password"
          error={getPasswordErrors()}
          {...register('password')}
        />
        <ErrorAlert text={baseErrors} />
        <Button type="submit" className="xs:mx-auto" size="large">
          Change password
        </Button>
      </form>
    </>
  );
};

export default UpdatePasswordForm;
