import React, { FC, ReactNode } from 'react';
import { CircleGraphic, Icon } from '../Index';

interface Props {
  title?: string;
  icon?: ReactNode;
  children?: ReactNode;
}
// TODO: change the default icon to something universal
const Success: FC<Props> = ({ title, children, icon }) => {
  return (
    <div className="flex flex-col items-center gap-4">
      <div className="relative flex flex-col items-center">
        <CircleGraphic className="w-12 h-12 mx-auto">
          <CircleGraphic.Quarter color="green-200" rotate={0} />
          <CircleGraphic.Quarter color="green-300" x={2} rotate={180} />
          <CircleGraphic.Quarter color="green-300" y={2} />
          <CircleGraphic.Quarter color="green-400" x={2} y={2} rotate={-90} />
        </CircleGraphic>
        <div className="absolute top-2.5">{icon != null ? icon : <Icon.UserFollowLine size={6} />}</div>
      </div>
      {title != null && <h2 className="font-serif text-display-xs text-hpblack text-center">{title}</h2>}
      {children != null && <div className="text-gray-600 text-center">{children}</div>}
    </div>
  );
};

export default Success;
