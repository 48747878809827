import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { OptionProps, SearchSelect } from '#components/Index';
import { useGetInterestedFirmsOptions } from '#pages/jobsOnboarding/steps/preferences/interestedFirms/InterestedFirmsOptions';
import { request as apiUpdateExperienceBranches } from '#api/endpoints/talents/profile/employmentBranches/updateExperienceBranches';
import { request as apiUpdateInterestBranches } from '#api/endpoints/talents/profile/employmentBranches/updateInterestBranches';

export interface BranchStepProps {
  category: 'interest' | 'experience';
}

interface BaseBranchesStepProps {
  firmType: string;
  category: 'interest' | 'experience';
  setStepCallback: React.Dispatch<React.SetStateAction<() => Promise<boolean>>>;
  setNextDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const BaseBranchesStep: FC<BaseBranchesStepProps> = ({ firmType, category, setStepCallback, setNextDisabled }) => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { data } = useGetInterestedFirmsOptions();

  const [options, setOptions] = useState<OptionProps[]>([]);

  const initiallySelectedFunctionTypeBranchIds = useMemo(() => {
    if (profilePreferencesData == null) return [];

    const functionTypes =
      category === 'interest'
        ? profilePreferencesData.profilePreferences.functionTypesInterest
        : profilePreferencesData.profilePreferences.functionTypesExperience;

    return functionTypes
      .filter((functionType) => functionType.selected)
      .map((functionType) => functionType.branches.filter((branch) => branch.selected).map((branch) => branch.id))
      .flat();
  }, [profilePreferencesData, category]);

  useEffect(() => {
    if (data == null) return;

    const stepFunctionTypes = data.employmentBranches.find(
      (employmentBranch) => employmentBranch.name === firmType
    )?.children;

    if (stepFunctionTypes != null) {
      setOptions(
        stepFunctionTypes.map((option) => ({
          id: option.id.toString(),
          name: option.name,
          checked: initiallySelectedFunctionTypeBranchIds.includes(option.id),
        }))
      );
    }
  }, [data, initiallySelectedFunctionTypeBranchIds, firmType]);

  const stepCallback = useCallback(async () => {
    const requestFunction = category === 'interest' ? apiUpdateInterestBranches : apiUpdateExperienceBranches;
    const selectedIds = options.filter((option) => option.checked).map((option) => Number(option.id));

    try {
      await requestFunction({ employmentBranches: { employmentBranchIds: selectedIds, parentName: firmType } });
      createSuccessToast({ title: `${firmType} ${category}` });
      return true;
    } catch {
      createErrorToast({ title: `${firmType} ${category}` });
      return false;
    }
  }, [firmType, options, category]);

  const handleChange = (newOptions: OptionProps[]) => {
    setOptions(newOptions);
  };

  const question = category === 'interest' ? 'are you interested in?' : 'do you have full-time experience in?';

  useEffect(() => {
    setNextDisabled(options.every((option) => option.checked !== true));
  }, [setNextDisabled, options]);

  useEffect(() => {
    setStepCallback(() => stepCallback);
  }, [setStepCallback, stepCallback]);

  return (
    <div className="rich-text text-hpblack w-full pb-16">
      <div className="rich-text">
        <h1>
          What types of functions in <span className="text-green-900">{firmType}</span> {question}
        </h1>
        <p>Select all that apply</p>
      </div>

      <div className="flex flex-col mt-8">
        <SearchSelect label="Select an industry" handleChange={handleChange} options={options} />
      </div>
    </div>
  );
};

export default BaseBranchesStep;
