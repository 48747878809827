import React, { FC } from 'react';
import { uniqueId } from 'lodash-es';
import styles from './FilterBadge.module.css';

interface Props {
  checked?: boolean;
  id?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  disabled?: boolean;
  children?: React.ReactNode;
  secondary?: boolean;
}

const FilterBadge: FC<Props> = ({
  id = uniqueId('filterBadge'),
  checked,
  handleChange,
  disabled,
  children,
  secondary = false,
}) => {
  return (
    <div className="inline-flex">
      <input
        type="checkbox"
        id={id}
        className={styles.input}
        onChange={(event) => handleChange?.(event, id)}
        checked={checked}
        disabled={disabled}
        {...(secondary ? { 'data-secondary': secondary } : {})}
      />
      <label htmlFor={id} className={styles.label} {...(secondary ? { 'data-secondary': secondary } : {})}>
        {children}
      </label>
    </div>
  );
};

export default FilterBadge;
