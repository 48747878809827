import React, { FC, useRef, useState, ChangeEvent, KeyboardEvent } from 'react';
import { uniqueId } from 'lodash-es';
import textBoxStyles from '#components/Form/styles/TextBox.module.css';
import searchSelectStyles from '#components/Form/SearchSelect/SearchSelect.module.css';
import { Icon } from '#components/Index';
import OptionBadge from './OptionBadge';

interface MultiStringInputProps {
  negative?: boolean;
  onChange?: (values: string[]) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}

const MultiStringInput: FC<MultiStringInputProps> = ({
  className = '',
  placeholder,
  negative = false,
  onChange,
  disabled = false,
}) => {
  const inputId = uniqueId('multi-text-input');
  const [inputValue, setInputValue] = useState('');
  const [values, setValues] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (values.includes(inputValue.trim())) return;

    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const newValues = [...values, inputValue.trim()];

      setValues(newValues);
      setInputValue('');
      onChange?.(newValues);
    }
  };

  const handleBadgeClick = (optionId: string) => {
    const newValues = values.filter((value) => value !== optionId);

    setValues(newValues);
    onChange?.(newValues);
  };

  return (
    <div className={`inline-grid relative gap-y-4 ${className}`}>
      <div className="flex items-center">
        <Icon.Search size={5} className="absolute mx-3.5 my-2.5 text-gray-500 pointer-events-none" />
        <input
          type="text"
          id={inputId}
          className={`${textBoxStyles.input} ${searchSelectStyles['search-input']} `}
          autoComplete="off"
          disabled={disabled}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
          placeholder={placeholder}
          ref={inputRef}
          value={inputValue}
        />
      </div>

      <div className="flex flex-wrap max-w-full">
        {values.map((option) => (
          <OptionBadge
            key={option}
            searchSelectId={inputId}
            negative={negative}
            option={option}
            handleBadgeClick={() => handleBadgeClick(option)}
          />
        ))}
      </div>
    </div>
  );
};

export default MultiStringInput;
