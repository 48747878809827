import React, { FC, useMemo, useEffect } from 'react';
import { Selectable } from '#components/Index';
import { TextInput } from '#components/Form/Index';
import { useGetWorkAuthorizationGroups } from '../../../pages/jobsOnboarding/steps/preferences/whereToWork/WorkAuthorizationsData';
import { CustomProfileWorkAuthorization } from '#src/types';

interface Props {
  selectedWorkAuthorizations: number[];
  setSelectedWorkAuthorizations: (workAuthorizations: number[]) => void;
  requiredWorkAuthorizationGroups: string[];
  setInvalidWorkAuthorizations: (valid: boolean) => void;
  customProfileWorkAuthorization: CustomProfileWorkAuthorization;
  setCustomProfileWorkAuthorization: (customProfileWorkAuthorization: CustomProfileWorkAuthorization) => void;
}

const WorkAuthorizationQuestions: FC<Props> = ({
  selectedWorkAuthorizations,
  setSelectedWorkAuthorizations,
  requiredWorkAuthorizationGroups,
  setInvalidWorkAuthorizations,
  customProfileWorkAuthorization,
  setCustomProfileWorkAuthorization,
}) => {
  // Load the work authorization groups
  const { data: workAuthorizationsGroupsData } = useGetWorkAuthorizationGroups();

  const authorizationGroups = useMemo(() => {
    if (workAuthorizationsGroupsData == null) return [];

    return workAuthorizationsGroupsData.workAuthorizationGroups;
  }, [workAuthorizationsGroupsData]);

  const handleChangeWorkAuthorization = ({ id }: { id: number }) => {
    // Remove the work authorization if it is already selected
    if (selectedWorkAuthorizations.some((workAuthorizationId) => workAuthorizationId === id)) {
      setSelectedWorkAuthorizations(
        selectedWorkAuthorizations.filter((workAuthorizationId) => workAuthorizationId !== id)
      );
      return;
    }

    const waGroup = authorizationGroups.find((group) => group.workAuthorizations.some((wa) => wa.id === id));

    if (waGroup != null) {
      const waGroupAuthorizationIds = waGroup.workAuthorizations.map((wa) => wa.id);

      // Remove all work authorizations from the same group
      const newSelectedWorkAuthorizations = selectedWorkAuthorizations.filter(
        (workAuthorizationId) => !waGroupAuthorizationIds.includes(workAuthorizationId)
      );

      setSelectedWorkAuthorizations([...newSelectedWorkAuthorizations, id]);
    }
  };

  const workAuthorizationGroupQuestion = (name: string) => {
    const groupPrefix = name === 'Europe' ? '' : `the`;

    return (
      <h5>
        What is your work authorization status in {groupPrefix} <span className="text-green-900">{name}</span>?
      </h5>
    );
  };

  const answerIdsWithCustomQuestion = useMemo(() => {
    const allAnswers = authorizationGroups.flatMap((group) => group.workAuthorizations);
    return allAnswers.filter((answer) => answer.withCustomQuestion).map((answer) => answer.id);
  }, [authorizationGroups]);

  const allRequiredWorkAuthorizationsSelected = useMemo(() => {
    return requiredWorkAuthorizationGroups.every((requiredGroup) => {
      const group = authorizationGroups.find((group) => group.name === requiredGroup);

      if (group == null) {
        return false;
      }

      return group.workAuthorizations.some((wa) => selectedWorkAuthorizations.includes(wa.id));
    });
  }, [authorizationGroups, requiredWorkAuthorizationGroups, selectedWorkAuthorizations]);

  const customWorkAuthorizationFulfilled = useMemo(() => {
    const selectedWaWithCustomQuestion =
      selectedWorkAuthorizations.filter((wa) => answerIdsWithCustomQuestion.includes(wa)).length > 0;

    if (!selectedWaWithCustomQuestion) {
      return true;
    }

    return customProfileWorkAuthorization.name !== null;
  }, [answerIdsWithCustomQuestion, selectedWorkAuthorizations, customProfileWorkAuthorization]);

  useEffect(() => {
    const isValid = allRequiredWorkAuthorizationsSelected && customWorkAuthorizationFulfilled;

    setInvalidWorkAuthorizations(!isValid);
  }, [setInvalidWorkAuthorizations, allRequiredWorkAuthorizationsSelected, customWorkAuthorizationFulfilled]);

  return (
    <>
      {authorizationGroups.map((group) => {
        if (!requiredWorkAuthorizationGroups.includes(group.name)) {
          return null;
        }

        return (
          <div key={group.name} className="grid gap-2 rich-text">
            {workAuthorizationGroupQuestion(group.name)}
            {group.workAuthorizations.map(({ id, name, withCustomQuestion }) => {
              return (
                <div key={id}>
                  <Selectable
                    key={id}
                    id={`work-policy-${id}`}
                    type="checkbox"
                    size="lg"
                    onChange={() => handleChangeWorkAuthorization({ id })}
                    checked={selectedWorkAuthorizations.includes(id)}
                    className="w-full mb-2"
                  >
                    {name}
                  </Selectable>
                  {withCustomQuestion && selectedWorkAuthorizations.includes(id) && (
                    <TextInput
                      label="Which visa?"
                      value={customProfileWorkAuthorization.name ?? ''}
                      onChange={(e) =>
                        setCustomProfileWorkAuthorization({ name: e.target.value, workAuthorizationId: id })
                      }
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default WorkAuthorizationQuestions;
