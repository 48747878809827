import React, { FC, useEffect, useState, useCallback } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { request as apiUpdateExtraDetails } from '#api/endpoints/talents/profile/answers/updateExtraDetails';
import { useSteps } from '../../BaseProfileEditModal';
import { TextArea } from '#root/src/components/Form/Index';

const ExtraInformationStep: FC = () => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { setStepCallback, setNextDisabled } = useSteps();

  const [additionalInformation, setAdditionalInformation] = useState<string>('');

  useEffect(() => {
    if (profilePreferencesData != null) {
      setAdditionalInformation(profilePreferencesData.profilePreferences.extraDetails);
    }
  }, [profilePreferencesData]);

  const stepCallback = useCallback(async () => {
    if (additionalInformation.trim() === '') return false;

    try {
      await apiUpdateExtraDetails({ answer: { answer: additionalInformation } });
      createSuccessToast({ title: 'Additional Information' });
      return true;
    } catch {
      createErrorToast({ title: 'Additional Information' });
      return false;
    }
  }, [additionalInformation]);

  useEffect(() => {
    setNextDisabled(additionalInformation.trim() === '');
  }, [setNextDisabled, additionalInformation]);

  useEffect(() => {
    setStepCallback(() => stepCallback);
  }, [setStepCallback, stepCallback]);

  return (
    <div className="rich-text text-hpblack w-full">
      <div className="pb-4">
        <h1>Additional Information</h1>
        <p>Please add any additional information that will better help us pick great opportunities for you.</p>
      </div>

      <TextArea
        label=""
        rows={5}
        placeholder="Please add any additional information that will better help us pick great opportunities for you."
        value={additionalInformation}
        onChange={(e) => setAdditionalInformation(e.target.value)}
      />
    </div>
  );
};

export default ExtraInformationStep;
