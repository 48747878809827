import React, { FC, useState } from 'react';
import { Education } from '#src/types';
import { Button } from '#components/Index';
import EducationEntry from '../EducationEntry';
import EducationModal from '../modals/EducationModal/EducationModal';

const emptyEducationEntry = {
  id: 0,
  college: '',
  graduationYear: '',
  degree: '',
  field: '',
};

interface ProfileEducationProps {
  educations: Education[];
}

const ProfileEducation: FC<ProfileEducationProps> = ({ educations }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employmentEntry, setEducationEntry] = useState<Education>(emptyEducationEntry);

  const openModal = (educationEntry: Education = emptyEducationEntry) => {
    setIsModalOpen(true);
    setEducationEntry(educationEntry);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEducationEntry(emptyEducationEntry);
  };

  return (
    <div>
      <EducationModal isOpen={isModalOpen} closeModal={closeModal} initialEducationValues={employmentEntry} />

      <Button variant="secondary" onClick={() => openModal()}>
        + Add New
      </Button>

      <div className="flex flex-col py-8 gap-y-8">
        {educations.map((education) => (
          <EducationEntry key={education.id} education={education} editSectionHandler={openModal} />
        ))}
      </div>
    </div>
  );
};

export default ProfileEducation;
