import React, { FC } from 'react';
import { generateOriginAppUrl } from '#root/src/lib/generateOriginAppUrl';
import { Navbar, NavItem, NavItemLink, AccountButtonItem } from '#components/Navbar/Navbar';
import { Button } from '#components/Index';
import { paths } from '#pages/paths';
import { useUserDetails } from '#src/AppData';

// Unsigned user navigation items
export const ProspectNavItems = () => (
  <>
    <NavItem to={paths.blog()}>Insights & Resources</NavItem>
    <NavItem to={paths.stories()}>Success Stories</NavItem>
    <div className="xl:hidden">
      <NavItem to={paths.candidates()}>For Professionals</NavItem>
    </div>
  </>
);

export const EmployerNavItems = () => (
  <>
    <NavItemLink to={generateOriginAppUrl({ path: paths.employerDashboard() })}>Dashboard</NavItemLink>
    <NavItemLink to={generateOriginAppUrl({ path: paths.employerTalentPool() })}>Talent Pool</NavItemLink>
    <div className="xl:hidden">
      <NavItem to={paths.candidates()}>For Professionals</NavItem>
    </div>
  </>
);

export const ProspectButtonItems = () => (
  <>
    <div className="max-xl:hidden">
      <Button variant="link" size="small" navTo={paths.candidates()}>
        For Professionals
      </Button>
    </div>
    <Button variant="link" size="small" navTo={paths.signIn()}>
      Sign in
    </Button>
    <Button variant="primary" size="small" navTo={paths.signUpEmployer()}>
      {`Let's talk`}
    </Button>
  </>
);

export const SignedInButtonItems = () => (
  <>
    <div className="max-xl:hidden">
      <Button variant="link" size="small" navTo={paths.candidates()}>
        For Professionals
      </Button>
    </div>
    <AccountButtonItem />
  </>
);

export const EmployerNavbar: FC = () => {
  const { user, isLoggedIn } = useUserDetails();

  return (
    <Navbar
      testid="employer-navbar"
      navItems={isLoggedIn && user?.type === 'Employer' ? <EmployerNavItems /> : <ProspectNavItems />}
      buttonItems={isLoggedIn ? <SignedInButtonItems /> : <ProspectButtonItems />}
    />
  );
};
