import React, { FC } from 'react';
import { useUserDetails } from '#src/AppData';
import MyActivity from './MyActivity';
import MyActivityLocked from './MyActivityLocked';

const MyActivityPage: FC = () => {
  const { user } = useUserDetails();

  if (user == null) return null;

  if (user.moderationState === 'limited') {
    return <MyActivityLocked lockType={user.rejected ? 'rejected' : 'limited'} />;
  }

  return <MyActivity />;
};

export default MyActivityPage;
