import { useState } from 'react';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';

import * as hireContactAPI from '#api/endpoints/talents/hiring/hireContact';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';

export interface FormValues extends FieldValues {
  contactMethod: string;
  phone: string;
  phonePrefix: string;
  email: string;
  question: string;
}

const defaultFormValues = (email: string): FormValues => ({
  email,
  phone: '',
  contactMethod: 'phone',
  phonePrefix: '',
  question: '',
});

const validationSchema = object().shape({
  contactMethod: string().required('Preferred contact method is required'),
  phone: string().required('Phone is required'),
  phonePrefix: string().required('Phone prefix is required'),
  question: string().optional(),
});

interface ControllerProps {
  email: string;
}

export const useHiringContactController = ({ email }: ControllerProps) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const closeSuccessMessage = () => setSuccessMessage(null);

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues(email),
  });

  const { parseErrors, baseErrors } = useParseFormErrors<hireContactAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) =>
    await hireContactAPI
      .request({
        prospect: {
          ...values,
          phone: `${values.phonePrefix}${values.phone}`,
        },
      })
      .then(() => setSuccessMessage('Your message has been sent'))
      .catch(parseErrors);

  return { form: formMethods, submitForm, baseErrors, successMessage, closeSuccessMessage };
};
