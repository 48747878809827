import { Errors } from '#root/src/types';
import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    employment: {
      company: string;
      position: string;
      location: string;
      startDate: string;
      endDate: string | null;
      description: string;
      current: boolean;
    };
  };
  success: never;
  fail: {
    errors: Errors<'company' | 'position' | 'location' | 'startDate' | 'endDate' | 'description' | 'current'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/talents/profile/employments');
