import React, { FC } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

const DatePicker: FC<ReactDatePickerProps> = ({ className, ...props }) => (
  <ReactDatePicker inline calendarClassName={className} {...props} useWeekdaysShort />
);

export default DatePicker;
