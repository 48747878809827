import React, { FC } from 'react';
import { TextInput } from '#components/Form/Index';
import { useTalentDataController } from '../../TalentDataController';
import { Button, Icon } from '#components/Index';

interface Props {
  linkedinUrl: string;
  setLinkedinUrl: (linkedinUrl: string) => void;
  alternateAction: () => void;
}

export const Linkedin: FC<Props> = ({ linkedinUrl, setLinkedinUrl, alternateAction }) => {
  const { getError } = useTalentDataController();

  return (
    <div className="flex flex-col">
      <Icon.LinkedinCircle className="mx-auto" size={12} aria-hidden="true" />
      <h2 className="text-display-md font-serif text-hpblack text-center">Share your LinkedIn profile link</h2>
      <p className="text-gray-700">
        Go to your profile from{' '}
        <a href="https://www.linkedin.com/profile/view" target="_blank" rel="noreferrer">
          here
        </a>
      </p>
      <TextInput
        label=""
        type="text"
        placeholder="https://linkedin.com/..."
        value={linkedinUrl}
        onChange={(e) => setLinkedinUrl(e.target.value)}
        error={getError('linkedinUrl')}
      />
      <div className="pt-4">
        <Button
          variant="link"
          className="link !font-normal !p-0"
          onClick={() => {
            alternateAction();
          }}
        >
          Manually add my work experience instead
        </Button>
      </div>
    </div>
  );
};
