import React, { FC, Dispatch } from 'react';
import { useGetWorkPolicies } from './WorkPolicyData';
import { Selectable, Spinner } from '#root/src/components/Index';

interface WorkPoliciesFormProps {
  selectedWorkPolicies: number[];
  setSelectedWorkPolicies: Dispatch<React.SetStateAction<number[]>>;
}

const WorkPoliciesForm: FC<WorkPoliciesFormProps> = ({ selectedWorkPolicies, setSelectedWorkPolicies }) => {
  const { data: workPolicyData, isLoading } = useGetWorkPolicies();

  if (isLoading) {
    return <Spinner className="py-32" />;
  }

  if (workPolicyData == null) {
    return null;
  }

  const { workPolicies } = workPolicyData;

  const handleChangeWorkPolicies = ({ id }: { id: number }) => {
    if (selectedWorkPolicies.includes(id)) {
      // If the selected work policies list already includes the policy ID, remove it
      setSelectedWorkPolicies(selectedWorkPolicies.filter((policyId) => policyId !== id));
    } else {
      // Else add the policy ID to the list of selected work policy IDs
      setSelectedWorkPolicies([...selectedWorkPolicies, id]);
    }
  };

  return (
    <div className="rich-text text-hpblack w-full">
      <h1>
        What <span className="text-green-900">work policy</span> do you prefer?
      </h1>
      <p>Select all that apply</p>

      <div className="flex flex-col gap-2 mt-8">
        {workPolicies.map(({ id, name }) => {
          return (
            <Selectable
              key={id}
              id={`work-policy-${id}`}
              type="checkbox"
              size="lg"
              onChange={() => handleChangeWorkPolicies({ id })}
              checked={selectedWorkPolicies.includes(id)}
            >
              {name}
            </Selectable>
          );
        })}
      </div>
    </div>
  );
};

export default WorkPoliciesForm;
