import React, { useState } from 'react';
import { Button } from '#src/components/Index';

interface TruncatedContentProps {
  gradientClass: string;
  heightClass?: string;
  children: React.ReactNode;
}

const TruncatedContent: React.FC<TruncatedContentProps> = ({ gradientClass, heightClass = 'h-64', children }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  return (
    <div className="flex flex-col gap-y-4">
      <div className={`relative overflow-hidden ${isTruncated ? heightClass : 'h-auto'}`}>
        {children}

        {isTruncated && (
          <div className={`absolute top-0 w-full h-full bg-gradient-to-t ${gradientClass} to-transparent`}></div>
        )}
      </div>

      {isTruncated && (
        <div className="text-center mt-4">
          <Button variant="secondary" size="small" onClick={() => setIsTruncated(false)}>
            Show more
          </Button>
        </div>
      )}
    </div>
  );
};

export default TruncatedContent;
