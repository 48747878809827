import AutocompleteInput from './Inputs/AutocompleteInput/AutocompleteInput';
import AutocompleteInputRHF from './Inputs/AutocompleteInput/AutocompleteInputRHF';
import Checkbox from './Checkbox/Checkbox';
import Success from './Success';
import LocationInput from './Inputs/LocationInput/LocationInput';
import MultiLocationInput, { LocationOptionProps } from './Inputs/MultiLocationInput/MultiLocationInput';
import MultiStringInput from './Inputs/MultiStringInput/MultiStringInput';
import MiniSelect from './MiniSelect/MiniSelect';
import PhoneInput from './Inputs/PhoneInput/PhoneInput';
import TextInput from './Inputs/TextInput/TextInput';
import DateInput from './Inputs/DateInput/DateInput';
import TextArea from './TextArea/TextArea';
import Select from './Select/Select';
import SelectRHF from './Select/SelectRHF';
import RadioItem from './RadioItem/RadioItem';
import RichTextInput from './Inputs/RichTextInput/RichTextInput';

const RHF = Object.assign({}, { Select: SelectRHF, AutocompleteInput: AutocompleteInputRHF });

export {
  AutocompleteInput,
  Checkbox,
  Success,
  LocationInput,
  MiniSelect,
  PhoneInput,
  MultiStringInput,
  MultiLocationInput,
  TextInput,
  DateInput,
  TextArea,
  Select,
  RHF,
  RadioItem,
  RichTextInput,
};

export type { LocationOptionProps };
