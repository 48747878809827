import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: never;
  success: {
    sessionPackages: Array<{
      id: number;
      title: string;
      description: string;
      sessionType: 'regular' | 'popular' | 'best_value';
    }>;
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/public/session_packages');
