import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Step, StepBackButton, StepNextButton } from '../../CurrentStep';
import { useTalentDataController } from '../../TalentDataController';
import { useStepController } from '../../StepControllerContext';
import TypeOfEmploymentForm from '#components/TalentSharedForms/EmploymentType/Form';

const TypeOfEmployment: Step = {
  id: 'employment-type',
  Body: ({
    setNextDisabled,
    setHandleNextClick,
  }: {
    setNextDisabled?: Dispatch<SetStateAction<boolean>>;
    setHandleNextClick?: Dispatch<SetStateAction<() => void>>;
  }) => {
    const { talentData, updateTalentData } = useTalentDataController();
    const { next } = useStepController();

    const [selectedDesiredEmploymentTypes, setSelectedDesiredEmploymentTypes] = useState<number[]>([]);

    // Set the selected desired type of employment type based on the talent data
    useEffect(() => {
      if (talentData == null) return;

      setSelectedDesiredEmploymentTypes(talentData.desiredEmploymentTypeIds);
    }, [talentData]);

    // Prevent the user from moving to the next step if they haven't selected any value
    useEffect(() => {
      if (setNextDisabled == null) return;

      setNextDisabled(selectedDesiredEmploymentTypes.length === 0);
    }, [selectedDesiredEmploymentTypes, setNextDisabled]);

    useEffect(() => {
      const handleNext = async () => {
        if (talentData == null) return;

        const newTalentData = { ...talentData, desiredEmploymentTypeIds: selectedDesiredEmploymentTypes };

        const result = await updateTalentData(newTalentData, 'employment_type');

        if (result.success === true) {
          next();
        }
      };

      if (setHandleNextClick != null) {
        setHandleNextClick(() => handleNext);
      }
    }, [setHandleNextClick, selectedDesiredEmploymentTypes, talentData, next, updateTalentData]);

    return (
      <TypeOfEmploymentForm
        setSelectedDesiredEmploymentTypes={setSelectedDesiredEmploymentTypes}
        selectedDesiredEmploymentTypes={selectedDesiredEmploymentTypes}
      />
    );
  },
  Footer: ({ nextDisabled, onNextClick }: { nextDisabled?: boolean; onNextClick?: () => void }) => (
    <>
      <StepBackButton />
      <StepNextButton disabled={nextDisabled} onClick={onNextClick} />
    </>
  ),
};

export default TypeOfEmployment;
