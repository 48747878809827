/* eslint-disable react/display-name */
import React, { useRef, useEffect, forwardRef, Ref, ReactElement } from 'react';
import { Plugin } from 'intl-tel-input';
import { FieldValues, Path, PathValue, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import styles from './PhoneInput.module.css';
import { useForwardRef } from '#root/src/lib/useForwardRef';
import InputWrapper, { InputProps } from '../InputWrapper';
import textBoxStyles from '#components/Form/styles/TextBox.module.css';

// Partial<Pick<UseFormMethods, 'register' | 'setValue'>> {
interface Props<T extends FieldValues> extends React.InputHTMLAttributes<HTMLInputElement>, InputProps {
  className?: string;
  setValue: UseFormSetValue<T>;
  register: UseFormRegister<T>;
}

const PhoneInput = <T extends FieldValues>(
  { label, error, description, className, register, setValue, required, ...rest }: Props<T>,
  ref: Ref<HTMLInputElement>
) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const combinedRef = useForwardRef<HTMLInputElement>(ref, inputRef);
  const itiRef = useRef<Plugin>();

  const hiddenInputName = ((rest.name != null ? rest.name : 'phone') + 'Prefix') as Path<T>;

  useEffect(function initializeLibraryInputAndSetInitialPrefixValue() {
    void (async () => {
      const { current } = combinedRef;
      if (itiRef.current != null) {
        return;
      }
      if (current != null) {
        import('intl-tel-input/build/css/intlTelInput.css');
        await import('intl-tel-input').then((intlTelInput) => {
          if (itiRef.current != null) {
            return;
          }
          itiRef.current = intlTelInput.default(current, {
            customContainer: styles['intl-tel-input'],
            initialCountry: 'GB',
            separateDialCode: true,
            hiddenInput: hiddenInputName,
          });
          register(hiddenInputName);
          const { dialCode } = itiRef.current.getSelectedCountryData();
          setValue(hiddenInputName, dialCode as PathValue<T, Path<T>>);
        });
      }
    })();

    return () => {
      if (itiRef.current != null) {
        itiRef.current.destroy();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(function setValueOnCountryChange() {
    combinedRef.current.addEventListener('countrychange', function () {
      setValue(hiddenInputName, itiRef.current?.getSelectedCountryData().dialCode as PathValue<T, Path<T>>);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InputWrapper label={label} description={description} error={error} required={required}>
      {({ id, hasError }) => (
        <input
          ref={combinedRef}
          id={id}
          className={[textBoxStyles.input, hasError ? textBoxStyles[`input--error`] : '', className].join(' ')}
          {...rest}
          type="tel"
          required={required}
        />
      )}
    </InputWrapper>
  );
};

export default forwardRef(PhoneInput) as <T extends FieldValues>(
  p: Props<T> & { ref?: Ref<HTMLInputElement> }
) => ReactElement;
