import { Errors } from '#root/src/types';
import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    prospect: {
      contactMethod: string;
      email: string;
      phone: string;
      question?: string;
    };
  };
  success: never;
  fail: {
    errors: Errors<'base' | 'contactMethod' | 'email' | 'phone' | 'question'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/talents/hiring');
