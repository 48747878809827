import { ReactQueryOptions } from '#src/types';
import { useQuery } from 'react-query';

// Talent_s_ here is a possesive 's' not a plural 's', i.e. the data belongs to the talent
// We removed the 's'  at this stage because it's kind of confusing!
import * as apiGetTalentsData from '#api/endpoints/onboarding/talentsData';

export enum KeysEnum {
  talentData = 'talent-data',
}

type GetApiPayload = apiGetTalentsData.Type['success']['payload'];
export const useGetTalentData = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>([KeysEnum.talentData], async () => await apiGetTalentsData.request(), {
    ...options,
  });
