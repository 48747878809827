import React, { FC } from 'react';
import { BaseProfileEditModal, Step, StepsProvider, QuestionGroupModalProps } from '../BaseProfileEditModal';
import JobSeekingStatusStep from './steps/JobSeekingStatusStep';
import EmploymentTypeStep from './steps/EmploymentTypeStep';

const CurrentSituationModal: FC<QuestionGroupModalProps> = ({ isOpen, close }) => {
  const steps: Step[] = [
    {
      name: JobSeekingStatusStep.name,
      content: () => <JobSeekingStatusStep />,
      visible: true,
    },
    {
      name: EmploymentTypeStep.name,
      content: () => <EmploymentTypeStep />,
      visible: true,
    },
  ];

  return (
    <StepsProvider>
      <BaseProfileEditModal isOpen={isOpen} close={close} initialSteps={steps} />
    </StepsProvider>
  );
};

export default CurrentSituationModal;
