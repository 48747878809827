import React, { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Button } from '#components/Index';
import { paths } from '#pages/paths';
import CandidateFAQ from './CandidateFAQ';

const SupportForCandidate: FC = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Hiperpool - Candidate FAQ</title>
          <meta
            name="description"
            content="Find answers to all your questions on Hiperpool's FAQ page for talent. Get detailed insights on how our platform can enhance your career in consulting, strategy, and investing. Learn about our job matching process, interview preparation services, and how we support you at every step of your career journey. Hiperpool is here to help you navigate your path to an extraordinary career."
          />
        </Helmet>
        <div className="bg-gray-50 px-variable-sm">
          <div className="py-14 m-auto max-w-4xl">
            <h1 className="text-display-md font-serif text-center">Frequently asked questions</h1>
            <p className="text-center mt-6 mb-8">
              Are you an employer with questions about listing your job on Hiperpool? <br />
              <Link className="link" to={paths.supportForEmployer()}>
                Visit the employer FAQs
              </Link>
            </p>
            <CandidateFAQ />
            <div className="text-center my-12">
              <Button to={paths.contactUs()} variant="secondary">
                Still have questions? Contact us
              </Button>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};

export default SupportForCandidate;
