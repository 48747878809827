import { Errors } from '#root/src/types';
import { createAction, PUT, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    talent: {
      email: string;
      currentPassword: string;
      password: string;
      passwordConfirmation: string;
    };
  };
  success: never;
  fail: {
    errors: Errors<'email' | 'password' | 'passwordConfirmation' | 'currentPassword'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(PUT, '/talents/settings/account');
