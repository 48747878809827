import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { BuildCareer } from '#components/Index';
import Header from './Header';
import Stories from './Stories';

const SuccessStories: FC = () => {
  return (
    <>
      <Helmet>
        <title>Hiperpool - Success Stories</title>
        <meta
          name="description"
          content="Discover the success stories of hundreds of professionals placed by Hiperpool in mission-critical roles at the world's leading consulting firms, corporate giants and exciting startups. Read inspiring journeys of candidates who found extraordinary career opportunities in consulting, strategy, and investment through our dedicated platform. Join our community to be the next success story and shape your ambitious career path with Hiperpool."
        />
      </Helmet>
      <Header />
      <Stories />
      <BuildCareer />
    </>
  );
};

export default SuccessStories;
