import React, { FC } from 'react';
import { TextInput } from '#components/Form/Index';
import { Button, ErrorAlert } from '#components/Index';
import { useActivateAccountController, FormValues } from './FormController';
import { paths } from '#pages/paths';
import { Link } from 'react-router-dom';
import ResendInstructionsExpiredTokenNotification from '../ResendInstructionsExpiredTokenNotification';

interface Props {
  isTokenExpired: boolean;
  defaultValues?: Partial<FormValues>;
}

const ActivateAccountForm: FC<Props> = ({ defaultValues, isTokenExpired }) => {
  const {
    form: {
      register,
      handleSubmit,
      formState: { errors },
    },
    submitForm,
    baseErrors,
  } = useActivateAccountController(defaultValues);

  const getPasswordErrors = () => {
    if (errors.resetPasswordToken != null) return errors.resetPasswordToken.message;
    return errors.password?.message;
  };

  if (isTokenExpired) {
    return <ResendInstructionsExpiredTokenNotification />;
  }

  return (
    <>
      <h1 className="text-display-sm font-serif">Activate your account</h1>
      <form onSubmit={handleSubmit(submitForm)} className="w-full grid text-sm gap-6 text-gray-700 font-medium">
        <TextInput
          label="Password"
          type="password"
          autoComplete="new-password"
          error={getPasswordErrors()}
          {...register('password')}
        />
        <p>
          By signing up, I agree to Hiperpool’s{' '}
          <Link to={paths.terms()} className="link">
            Terms
          </Link>
          ,{' '}
          <Link to={paths.cookies()} className="link">
            Cookies
          </Link>{' '}
          and{' '}
          <Link to={paths.privacy()} className="link">
            Privacy Policy
          </Link>
          .
        </p>
        <ErrorAlert text={baseErrors} />
        <Button type="submit" className="xs:mx-auto" size="large">
          Activate
        </Button>
      </form>
    </>
  );
};

export default ActivateAccountForm;
