import React, { FC, ReactNode } from 'react';
import { ReactComponent as BookMark } from '#assets/homepage/interviewPrep/book-mark.svg';
import { ReactComponent as UserStar } from '#assets/homepage/interviewPrep/user-star.svg';
import { ReactComponent as Award } from '#assets/homepage/interviewPrep/award.svg';
import { ReactComponent as Questionnaire } from '#assets/homepage/interviewPrep/questionnaire.svg';

interface ItemProps {
  circleGraphic: ReactNode;
  title: string;
  text: string;
}

const Item: FC<ItemProps> = ({ circleGraphic, title, text }) => (
  <li>
    {circleGraphic}
    <div>
      <div className="font-serif text-display-xs mt-5 mb-4">{title}</div>
      <div className="mt-5 text-gray-600 text-sm">{text}</div>
    </div>
  </li>
);

const FindYourNextRole: FC = () => {
  return (
    <div className="py-16 px-variable-md text-center">
      <h2 className="text-display-md font-serif max-w-xl mx-auto">
        Plan your next step and ace your interview with Hiperpool
      </h2>
      <ul className="mx-auto mt-14 max-w-5xl grid xs:grid-cols-2 md:grid-cols-4 gap-y-12 gap-x-4">
        <Item
          circleGraphic={<Award className="mx-auto" aria-hidden="true" />}
          title="Any experience level"
          text="Whether you’re a recent graduate or an experienced professional, our coaches can help you interview with confidence"
        />
        <Item
          circleGraphic={<UserStar className="mx-auto" aria-hidden="true" />}
          title="Top coaches only"
          text="We handpick all Hiperpool coaches to make sure you’re only working with the best of the best"
        />
        <Item
          circleGraphic={<Questionnaire className="mx-auto" aria-hidden="true" />}
          title="Up-to-date insights "
          text="Learn what to expect on the day and how to excel. We work directly with the top firms to make sure all our guidance is  up-to-date "
        />
        <Item
          circleGraphic={<BookMark className="mx-auto" aria-hidden="true" />}
          title="Exclusive free resources"
          text="Access case libraries, sample frameworks, how-to guides, and additional resources for self-study"
        />
      </ul>
    </div>
  );
};

export default FindYourNextRole;
