import { Errors } from '#root/src/types';
import { createFormDataAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    avatar: File;
  };
  success: never;
  fail: {
    errors: Errors<'cv'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createFormDataAction<Payload>(POST, '/talents/profiles/avatar');
