import React, { FC, useState, useMemo, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { LanguageWithProficiencyOption, TalentProfile } from '#src/types';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { request as apiUpdateLanguages } from '#api/endpoints/talents/profile/updateLanguages';
import { KeysEnum as ProfileDetailsKeys } from '../../data/ProfileDetails';
import { Modal, Button, Icon } from '#components/Index';
import LanguagesForm from '#components/TalentSharedForms/Languages/Form';

const queryKeys = [ProfileDetailsKeys.talents, ProfileDetailsKeys.profile, ProfileDetailsKeys.details];

interface LanguagesModalProps {
  isOpen: boolean;
  closeModal: () => void;
  profile: TalentProfile;
}

const LanguagesModal: FC<LanguagesModalProps> = ({ isOpen, closeModal, profile }) => {
  const queryClient = useQueryClient();
  const [selectedLanguages, setSelectedLanguages] = useState<LanguageWithProficiencyOption[]>(profile.languages);

  const updateLanguages = useCallback(async () => {
    const newLanguages: Array<{ language: string; proficiency: string }> = [];

    // Filter out entries with undefined values before mapping to ensure only valid TalentLanguage objects are processed,
    // preventing TypeScript error: Type '{ ... }' is not assignable to type 'TalentLanguage' due to potential 'undefined' values.
    selectedLanguages.forEach(({ language, proficiency, approvedLanguageId }) => {
      if (language === undefined || proficiency === undefined || approvedLanguageId === undefined) {
        return;
      }

      newLanguages.push({
        language,
        proficiency,
      });
    });

    try {
      await apiUpdateLanguages({
        languages: newLanguages,
      });
      createSuccessToast({ title: 'Languages' });
      void queryClient.invalidateQueries(queryKeys);
      closeModal();
    } catch {
      createErrorToast({ title: 'Languages' });
      return false;
    }
  }, [selectedLanguages, closeModal, queryClient]);

  const saveDisabled = useMemo(() => {
    const hasEmptyLanguage = selectedLanguages.some((language) => language.language === undefined);
    const hasEmptyProficiency = selectedLanguages.some((language) => language.proficiency === undefined);

    return hasEmptyLanguage || hasEmptyProficiency;
  }, [selectedLanguages]);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="flex flex-col bg-gray-50 lg:w-[72rem]">
        <div className="pt-8 px-8">
          <div className="flex justify-around items-center pb-2">
            <div className="flex items-center gap-x-8 text-gray-500 text-sm">Languages</div>

            <button
              onClick={closeModal}
              className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline cursor-pointer"
              aria-label="Close"
            >
              <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
            </button>
          </div>
        </div>

        <div className="flex gap-y-4 flex-col p-8 pb-12">
          <LanguagesForm selectedLanguages={selectedLanguages} setSelectedLanguages={setSelectedLanguages} />
        </div>
      </div>

      <div className="bg-white flex gap-x-8 justify-center px-8 py-6">
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={updateLanguages} disabled={saveDisabled}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default LanguagesModal;
