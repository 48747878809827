import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { paths } from '#pages/paths';
import { useQueryClient } from 'react-query';
import { request as apiWithdrawInterest } from '#api/endpoints/talents/opportunities/replies/withdrawInterest';
import { KeysEnum as jobsQueryKeys } from '#pages/talentPages/activity/data/JobsData';
import { Icon } from '#src/components/Index';
import MenuCard from '#src/components/MenuCard/MenuCard';

interface HeaderOptionsDropdownProps {
  opportunityId: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  canWithdrawInterest: boolean;
}

const HeaderOptionsDropdown: FC<HeaderOptionsDropdownProps> = ({
  opportunityId,
  isOpen,
  setIsOpen,
  canWithdrawInterest,
}) => {
  const queryClient = useQueryClient();

  const withdrawInterest = useCallback(async () => {
    await apiWithdrawInterest({ opportunityId });
    await queryClient.invalidateQueries([jobsQueryKeys.talents, jobsQueryKeys.activity, jobsQueryKeys.jobs]);
    setIsOpen(false);
  }, [opportunityId, setIsOpen, queryClient]);

  return (
    <MenuCard id="profile-menu" open={isOpen}>
      <Link
        to={paths.talentOpportunity({ id: opportunityId })}
        className="px-4 py-3 text-gray-700 text-sm flex gap-3 leading-4 focus-visible-outline hover:bg-gray-50 active:bg-gray-100"
      >
        <Icon.PagesLine size={4} /> See original listing
      </Link>
      {canWithdrawInterest && (
        <button
          onClick={withdrawInterest}
          className="px-4 py-3 text-red-600 text-sm flex gap-3 leading-4 focus-visible-outline hover:bg-gray-50 active:bg-gray-100"
        >
          <Icon.DeleteBinLine size={4} /> Withdraw interest
        </button>
      )}
    </MenuCard>
  );
};

export default HeaderOptionsDropdown;
