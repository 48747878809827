import React, { FC, Dispatch } from 'react';
import { useGetWorkInterestSituationTypes } from './WorkInterestSituationTypesData';
import { Selectable, Spinner } from '#root/src/components/Index';

interface CurrentSituationFormProps {
  selectedWorkInterestSituationType: number | null;
  setSelectedWorkInterestSituationType: Dispatch<React.SetStateAction<number | null>>;
}

const CurrentSituationForm: FC<CurrentSituationFormProps> = ({
  setSelectedWorkInterestSituationType,
  selectedWorkInterestSituationType = null,
}) => {
  const { data: workInterestSituationTypeData, isLoading } = useGetWorkInterestSituationTypes();

  if (isLoading) {
    return <Spinner className="py-32" />;
  }

  if (workInterestSituationTypeData == null) {
    return null;
  }

  const workInterestOptions = workInterestSituationTypeData.workInterestSituations;

  return (
    <div className="rich-text text-hpblack w-full">
      <h1>
        How would you describe your <span className="text-green-900">current situation?</span>
      </h1>
      <p>Select the most appropriate option</p>

      <div className="flex flex-col gap-2 mt-8">
        {workInterestOptions.map(({ value, label }) => {
          return (
            <Selectable
              key={value}
              id={label}
              type="radio"
              name="current-situation"
              size="lg"
              value={value}
              onChange={() => setSelectedWorkInterestSituationType(value)}
              checked={selectedWorkInterestSituationType === value}
            >
              {label}
            </Selectable>
          );
        })}
      </div>
    </div>
  );
};

export default CurrentSituationForm;
