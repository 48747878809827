import React, { FC, useRef, useEffect, cloneElement, Children } from 'react';
import styles from './AnimationRow.module.css';

interface Props {
  className?: string;
  overlayClassName?: string;
  children: React.ReactNode;
}

const AnimationRow: FC<Props> = ({ className = '', overlayClassName = '', children }) => {
  const trackRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const resizeListener = () => {
      if (trackRef.current != null) {
        const gap = parseInt(window.getComputedStyle(trackRef.current).columnGap);
        const width = (trackRef.current.scrollWidth + (isNaN(gap) ? 0 : gap)) / 2;
        trackRef.current?.style.setProperty('--width', `${width}`);
      }
    };

    window.addEventListener('resize', resizeListener);
    resizeListener();

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [trackRef]);

  const ariaHiddenChildren = Children.map(children, (child) =>
    cloneElement(child as React.ReactElement, { 'aria-hidden': true })
  );

  return (
    <div className={styles.slider}>
      <div className={`${styles['slide-track']} ${className}`} ref={trackRef}>
        {children}
        {ariaHiddenChildren}
      </div>
      <div className={`${styles.overlay} text-white ${overlayClassName}`} />
    </div>
  );
};

export default AnimationRow;
