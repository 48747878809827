import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { TalentData } from '#root/src/types';

interface Payload {
  init: never;
  success: TalentData;
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/talents/onboarding');
