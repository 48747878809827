import React, { FC } from 'react';

import { Button } from '#components/Index';
import { paths, parseParamsToLinkSearch } from '#pages/paths';

const FilteredCoachesButtons: FC = () => {
  // TODO: This should be fetched from the backend
  const filters = [
    {
      displayName: 'Career Planning',
      coachingTypeId: 1,
    },
    {
      displayName: 'Resume & CV Review',
      coachingTypeId: 2,
    },
    {
      displayName: 'Behavioral /  Fit Interview',
      coachingTypeId: 3,
    },
    {
      displayName: 'Case Interview Prep',
      coachingTypeId: 4,
    },
    {
      displayName: 'General Interview Coaching',
      coachingTypeId: 5,
    },
    {
      displayName: 'Take-home assignment review',
      coachingTypeId: 6,
    },
    {
      displayName: 'Job offer / salary negoiation',
      coachingTypeId: 7,
    },
  ];

  return (
    <div className="flex flex-col gap-y-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:ltr">
      {filters.map(({ displayName, coachingTypeId }) => (
        <Button
          variant="alternate"
          key={displayName}
          to={paths.coaches() + parseParamsToLinkSearch({ coachingType: coachingTypeId })}
          className="w-100 text-center"
        >
          {displayName}
        </Button>
      ))}
    </div>
  );
};

export default FilteredCoachesButtons;
