import React, { FC } from 'react';
import { Button, HeroBanner, CircleGraphic } from '#components/Index';
import CareerWoman from '#assets/homepage/careerWoman.webp';
import CareerMan from '#assets/homepage/careerMan.webp';
import { paths } from '#pages/paths';

const HeroBannerImageContent = () => (
  <>
    <div className="relative lg:max-w-[min((30vw-6rem)-6vw,18rem)]">
      <CircleGraphic cols={2} className="w-full h-auto">
        <CircleGraphic.Half color="green-500" />
      </CircleGraphic>
      <img
        loading="eager"
        src={CareerWoman}
        alt="woman in blue tank top standing beside white wall"
        height="auto"
        width="100%"
        className="rounded-full max-sm:aspect-square object-cover object-top"
      />
    </div>

    <div className="flex flex-col sm:justify-end lg:max-w-[min((30vw-6rem)-6vw,18rem)]">
      <img
        loading="eager"
        src={CareerMan}
        alt="man in suit sitting in a chair"
        height="auto"
        width="100%"
        className="rounded-full max-sm:rounded-t-none max-sm:aspect-square object-cover object-[50%_25%]"
      />
      <CircleGraphic className="max-sm:hidden -mb-1/2 w-full h-auto">
        <CircleGraphic.Half x={2} color="green-900" rotate={180} />
        <CircleGraphic.Quarter x={2} y={2} color="green-500" rotate={-90} className="sm:hidden lg:block" />
      </CircleGraphic>
    </div>
  </>
);

const HeroBannerTextContent = () => (
  <>
    <h1 className="text-display-md font-serif lg:text-display-lg xl:text-display-2xl">
      Extraordinary careers are built here<span className="text-green-500">.</span>
    </h1>
    <p className="my-7 sm:my-10 lg:text-xl max-w-lg">
      Welcome to Hiperpool, the career platform for consulting, strategy and investing professionals. Membership is 100%
      free.
    </p>
    <Button to={paths.signUp()}>
      <span>
        Join 50,000+ professionals<span className="max-md:hidden"> today</span>
      </span>
    </Button>
  </>
);

const HeroBannerBgContent = () => (
  <>
    <CircleGraphic className="absolute left-0 bottom-0 w-variable-lg translate-y-1/3 max-md:hidden max-w-[12rem] h-auto">
      <CircleGraphic.Quarter x={1} y={1} color="green-900" rotate={90} />
      <CircleGraphic.Quarter x={1} y={2} color="green-500" />
      <CircleGraphic.Half x={3} y={2} color="green-900" rotate={180} />
      <CircleGraphic.Quarter x={1} y={3} color="green-900" rotate={90} />
      <CircleGraphic.Circle x={2} y={3} color="green-500" radius={0.5} />
    </CircleGraphic>
    <CircleGraphic className="absolute sm:hidden right-0 bottom-0 w-[7rem] translate-y-1/2">
      <CircleGraphic.Half x={2} color="green-900" rotate={180} />
      <CircleGraphic.Quarter x={2} y={2} color="green-500" rotate={-90} />
    </CircleGraphic>
  </>
);

const CandidateHeroBanner: FC = () => {
  return (
    <HeroBanner
      imageContent={HeroBannerImageContent()}
      textContent={HeroBannerTextContent()}
      bgContent={HeroBannerBgContent()}
      direction="ltr"
      colorScheme="dark"
    />
  );
};

export default CandidateHeroBanner;
