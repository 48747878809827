import React, { FC } from 'react';
import { kebabCase } from 'lodash-es';
import { Link, LinkProps } from 'react-router-dom';

interface Props {
  link: LinkProps['to'];
  title: string;
  description?: string;
  image?: string;
  category?: string;
  simple?: boolean;
}

const ArticleCard: FC<Props> = ({ link, title, description, image, category, simple = false }) => {
  const titleElementId = `article-card-title-${kebabCase(title)}`;

  return (
    <Link
      className={`overflow-clip rounded-lg scroll-m-2 focus-visible-outline ${
        simple
          ? 'bg-white shadow-md hover:shadow-lg active:shadow-card-md'
          : 'bg-gray-50 hover:bg-gray-50/60 active:bg-gray-50 flex flex-col'
      }`}
      to={link}
      aria-labelledby={titleElementId}
    >
      <div className="aspect-[4/3] bg-gray-100 relative">
        {image != null && <img src={image} loading="lazy" aria-hidden="true" />}
        {category != null && !simple && (
          <span className="absolute top-4 mr-4 text-green-900 bg-green-200 px-2.5 py-1 rounded-r text-sm font-medium">
            {category}
          </span>
        )}
      </div>
      <div className={`my-8 grid gap-2 grow ${simple ? 'text-center mx-4' : 'mx-6'}`}>
        <h3
          id={titleElementId}
          className={`font-serif line-clamp-3 ${simple ? 'text-lg' : 'text-xl lg:text-display-xs'}`}
        >
          {title}
        </h3>
        {description != null && !simple && <p className="text-sm text-gray-500 mt-auto">{description}</p>}
      </div>
    </Link>
  );
};

export default ArticleCard;
