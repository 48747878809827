import React, { FC } from 'react';

import UpdatePasswordForm from './Form';
import { useSearchParams } from 'react-router-dom';
import DarkBackgroundWithPeopleLayout from '#pages/DarkBackgroundWithPeopleLayout';
import { useCheckTokenExpiration } from '../ExpirationOfTokenData';

const UpdatePassword: FC = () => {
  const [searchParams] = useSearchParams();

  const resetPasswordToken = searchParams.get('reset_password_token');

  const { data } = useCheckTokenExpiration({ token: resetPasswordToken });

  return (
    <DarkBackgroundWithPeopleLayout>
      <div className="w-full max-w-lg justify-start 2xs:my-16 2xs:mx-variable-lg p-10 max-2xs:py-16 max-2xs:px-variable-lg 2xs:self-start bg-gray-50 text-hpblack 2xs:rounded-lg flex flex-col gap-6 items-center">
        {data != null && (
          <UpdatePasswordForm
            defaultValues={resetPasswordToken != null ? { resetPasswordToken } : undefined}
            isTokenExpired={data.isTokenExpired}
          />
        )}
      </div>
    </DarkBackgroundWithPeopleLayout>
  );
};

export default UpdatePassword;
