import * as apiGetLanguageProficiencyLevels from '#api/endpoints/onboarding/languageProficiencyLevels';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  languageProficiencyLevels = 'language-proficiency-levels',
}

type GetApiPayload = apiGetLanguageProficiencyLevels.Type['success']['payload'];
export const useGetLanguageProficiencyLevels = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.languageProficiencyLevels],
    async () => await apiGetLanguageProficiencyLevels.request(),
    {
      ...options,
    }
  );
