/* eslint-disable @typescript-eslint/no-throw-literal */
import { useCallback, useState } from 'react';
import { Path, UseFormSetError } from 'react-hook-form';

import { Errors } from '#src/types';

const useParseBaseErrors = <T extends { errors: Errors; status: number }>() => {
  const [baseErrors, setBaseErrors] = useState<string>();

  const formatErrorMessage = useCallback((errors: string[]): string => errors.join('. '), []);

  const parseErrors = useCallback(
    (e: T) => {
      if (e.status !== 422) {
        throw e;
      }
      for (const [key, value] of Object.entries(e.errors)) {
        const message = formatErrorMessage(value);
        if (key === 'base') {
          setBaseErrors(message);
        }
      }
    },
    [formatErrorMessage]
  );

  return { baseErrors, setBaseErrors, parseErrors, formatErrorMessage };
};

export const useParseFormErrors = <
  T extends { errors: Errors; status: number },
  TFormValues extends Partial<Record<keyof T['errors'], any>>
>(props: {
  setError: UseFormSetError<TFormValues>;
}) => {
  const { setError } = props;
  const { formatErrorMessage, setBaseErrors, baseErrors } = useParseBaseErrors<T>();

  const parseErrors = useCallback(
    (e: T) => {
      if (e.status !== 422) {
        throw e;
      }
      for (const [key, value] of Object.entries(e.errors)) {
        const message = formatErrorMessage(value);
        if (key === 'base') {
          setBaseErrors(message);
        } else {
          setError(key as Path<TFormValues>, { type: 'manual', message });
        }
      }
    },
    [formatErrorMessage, setBaseErrors, setError]
  );

  return { baseErrors, setBaseErrors, parseErrors, formatErrorMessage };
};

class ParsedError {
  errors: Errors;

  constructor(errors: Errors) {
    this.errors = errors;
  }

  showFirst(key: string) {
    return this.errors[key] != null ? this.errors[key][0] : null;
  }
}

export const parseErrors = (errors: any) => {
  const typedError = errors as { errors: Errors };

  return new ParsedError(typedError.errors);
};
