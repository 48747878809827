import React, { FC } from 'react';
import { Icon } from '#root/src/components/Index';

interface StarRatingProps {
  rating: number;
  maxRating?: number;
  reviewCount?: number;
  showReviewCount?: boolean;
  size?: number;
  variant?: 'light' | 'dark';
}

export const StarRating: FC<StarRatingProps> = ({
  rating,
  maxRating = 5,
  size = 6,
  variant = 'light',
  reviewCount = 0,
  showReviewCount = false,
}) => {
  const counterClassName = `w-${size} h-${size}`;
  const missingStarsClassName = variant === 'light' ? 'text-gray-200' : 'text-gray-400';
  const HalfStar = variant === 'dark' ? Icon.RatingStarHalfDark : Icon.RatingStarHalf;

  const starComponents = [...Array(maxRating)].map((_, i) => {
    if (i - rating <= -1) {
      return (
        <span key={i} className="flex text-green-500">
          <Icon.RatingStar size={size} aria-hidden="true" />
        </span>
      );
    }
    if (i - rating < 0) {
      return (
        <span key={i} className="flex text-green-500">
          <HalfStar size={size} aria-hidden="true" />
        </span>
      );
    }
    return (
      <span key={i} className={`flex ${missingStarsClassName}`}>
        <Icon.RatingStar size={size} aria-hidden="true" />
      </span>
    );
  });

  return (
    <div className="flex justify-center items-center gap-x-1 whitespace-nowrap">
      <div className="flex">{starComponents}</div>

      {showReviewCount && (
        <div className={`text-gray-500  ${counterClassName}`}>
          ({reviewCount}
          <span className="sr-only">reviews</span>)
        </div>
      )}
    </div>
  );
};
