import { Errors } from '#root/src/types';
import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    education: {
      college: string;
      degree: string;
      field: string;
      graduationYear: string;
    };
  };
  success: never;
  fail: {
    errors: Errors<'college' | 'degree' | 'field' | 'graduationYear'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/talents/profile/educations');
