import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { KeysEnum as ProfileDetailsKeysEnum } from '#src/pages/talentPages/profileAndPreferences/profile/data/ProfileDetails';
import * as updateLinkedinUrlAPI from '#api/endpoints/talents/profile/updateLinkedinUrl';

const queryKeys = [ProfileDetailsKeysEnum.talents, ProfileDetailsKeysEnum.profile, ProfileDetailsKeysEnum.details];

export interface FormValues extends FieldValues {
  linkedinUrl: string;
}

const defaultFormValues = (linkedinUrl: string): FormValues => ({
  linkedinUrl,
});

const validationSchema = object().shape({
  linkedinUrl: string().required('Linkedin URL is required'),
});

interface ControllerProps {
  linkedinUrl?: string;
  closeEditMode: () => void;
}

export const useLinkedinUrlController = ({ closeEditMode, linkedinUrl = '' }: ControllerProps) => {
  const queryClient = useQueryClient();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues(linkedinUrl),
  });

  const { parseErrors } = useParseFormErrors<updateLinkedinUrlAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) =>
    await updateLinkedinUrlAPI
      .request({
        linkedinUrl: values.linkedinUrl,
      })
      .then(() => {
        closeEditMode();
        createSuccessToast({ title: 'Linkedin URL' });
        void queryClient.invalidateQueries(queryKeys);
      })
      .catch((errors) => {
        createErrorToast({ title: 'Linkedin URL' });
        parseErrors(errors);
      });

  return { form: formMethods, submitForm };
};
