import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from '#components/Index';
import { StarRating } from '#components/StarRating/StarRating';

interface Props {
  name: string;
  companyLogos: React.ReactNode[];
  image?: React.ReactNode;
  location: string; // Should be typed as it will be sortable?
  description?: string;
  rating: number;
  reviewCount: number;
  price: string;
  link: string;
}

const CoachCardLarge: FC<Props> = ({
  name,
  companyLogos,
  image,
  location,
  description = '',
  rating,
  reviewCount,
  price,
  link,
}) => {
  const navigate = useNavigate();

  return (
    <div className="grid rounded-lg w-full h-full shadow-md-dark bg-white overflow-clip">
      <div className="cursor-pointer grid p-6 text-center" onClick={() => navigate(link)}>
        <div className="row-span-3 bg-gray-50 rounded-full overflow-clip mx-auto">{image}</div>
        <div className="font-serif text-display-sm/8 h-8 overflow-ellipses mt-6">{name}</div>
        <div className="h-6 mt-2">
          <StarRating rating={rating} reviewCount={reviewCount} showReviewCount />
        </div>
        <p className="text-gray-500 h-12 mt-5">{description}</p>
        <div className="h-6 inline-flex gap-1.5 items-center justify-center mt-4">
          <Icon.MapPinFill size={5} className="text-green-500" />
          <span className="text-gray-500">{location}</span>
        </div>
        <div className="mt-2">From {price}</div>
      </div>

      <Button to={link} className="mt-6 mx-auto">
        Book a session
      </Button>
      <div className="text-center my-2">From {price}</div>

      {companyLogos.length > 0 && (
        <div className="bg-gray-50 min-h-[8rem] grid gap-4 p-6">
          <div className="text-center uppercase font-semibold text-gray-500 text-xs tracking-widest">
            Coach Background
          </div>
          <div className="flex gap-4 items-center justify-center">{companyLogos}</div>
        </div>
      )}
    </div>
  );
};

export default CoachCardLarge;
