import React, { FC } from 'react';
import DOMPurify from 'dompurify';
import { TalentWorkHistoryEntry, Employment } from '#src/types';
import { Button, Icon } from '#src/components/Index';

interface EmploymentEntryProps {
  workHistoryEntry: TalentWorkHistoryEntry;
  editSectionHandler: (employment: Employment) => void;
  withDescription?: boolean;
  withEdit?: boolean;
}

const MultipleEmploymentsEntry: FC<EmploymentEntryProps> = ({
  workHistoryEntry,
  withDescription,
  withEdit,
  editSectionHandler,
}) => {
  const employmentItemClasses = (index: number) => {
    const isLast = index === workHistoryEntry.employments.length - 1;

    return isLast ? '' : 'pb-8';
  };

  return (
    <div className="flex gap-x-8">
      <div className="hidden md:block">
        <div className="bg-gray-50 h-[100px] w-[100px] rounded-full overflow-clip justify-self-start shrink-0">
          <img
            src={workHistoryEntry.avatarUrl}
            alt={workHistoryEntry.company}
            className="mix-blend-multiply max-h-[100px]"
          />
        </div>
      </div>

      <div className="flex flex-col gap-y-1 flex-grow">
        <div className="flex gap-4">
          <div className="block md:hidden">
            <div className="bg-gray-50 h-[60px] w-[60px] rounded-full overflow-clip justify-self-start shrink-0">
              <img
                src={workHistoryEntry.avatarUrl}
                alt={workHistoryEntry.company}
                className="mix-blend-multiply max-h-[60px]"
              />
            </div>
          </div>

          <div className="flex flex-col">
            <h2 className="font-semibold text-xl">{workHistoryEntry.company}</h2>
            <span className="text-gray-500 text-sm">{workHistoryEntry.dateRangeHumanized}</span>
          </div>
        </div>

        <div className="flex flex-col mt-2">
          {workHistoryEntry.employments.map((employment, index) => (
            <div
              key={employment.position}
              className={`ml-8 md:ml-0 relative flex flex-col space-x-3 ${employmentItemClasses(index)} `}
            >
              <span className="absolute left-0 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
              <span className="absolute -left-4 top-2 h-2 w-2 rounded-full bg-green-500 m-0"></span>

              <div className="flex flex-col md:flex-row gap-y-2 justify-between flex-grow">
                <div className="flex flex-col">
                  <h3 className="font-medium text-md">{employment.position}</h3>
                  <span className="text-gray-500 text-sm">
                    {employment.dateRange} ({employment.dateRangeHumanized})
                  </span>
                  {withDescription === true && (
                    <div
                      className="rich-text rich-text-lists rich-text-lists-gray text-gray-500 font-light text-sm mt-2"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(employment.description) }}
                    />
                  )}
                </div>

                {withEdit === true && (
                  <div>
                    <div className="flex gap-x-2">
                      <Button
                        variant="secondary"
                        size="small"
                        className="flex"
                        onClick={() => editSectionHandler(employment)}
                      >
                        <Icon.EditPencil size={3} />
                        Edit
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const SingleEmploymentEntry: FC<EmploymentEntryProps> = ({
  workHistoryEntry,
  withDescription,
  withEdit,
  editSectionHandler,
}) => {
  const employment = workHistoryEntry.employments[0];

  return (
    <div className="flex flex-col md:flex-row justify-between flex-grow">
      <div className="flex gap-x-8">
        <div className="hidden md:block">
          <div className="bg-gray-50 h-[100px] w-[100px] rounded-full overflow-clip justify-self-start shrink-0">
            <img
              src={workHistoryEntry.avatarUrl}
              alt={workHistoryEntry.company}
              className="mix-blend-multiply max-h-[100px]"
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-1">
          <div className="flex gap-4">
            <div className="block md:hidden">
              <div className="bg-gray-50 h-[60px] w-[60px] rounded-full overflow-clip justify-self-start shrink-0">
                <img
                  src={workHistoryEntry.avatarUrl}
                  alt={workHistoryEntry.company}
                  className="mix-blend-multiply max-h-[60px]"
                />
              </div>
            </div>

            <div className="flex flex-col">
              <h2 className="font-semibold text-xl">{employment.position}</h2>
              <h3 className="font-medium text-md">{workHistoryEntry.company}</h3>
              <span className="text-gray-500 text-sm">
                {employment.dateRange} ({employment.dateRangeHumanized})
              </span>

              {withEdit === true && (
                <div>
                  <div className="flex md:hidden pt-2 gap-x-2">
                    <Button
                      variant="secondary"
                      size="small"
                      className="flex"
                      onClick={() => editSectionHandler(employment)}
                    >
                      <Icon.EditPencil size={3} />
                      Edit
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {withDescription === true && (
            <div
              className="rich-text rich-text-lists rich-text-lists-gray rich-text-without-paragraph-margin text-gray-500 font-light text-sm mt-2"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(employment.description) }}
            />
          )}
        </div>
      </div>

      {withEdit === true && (
        <div className="hidden md:flex flex-col md:items-end grow">
          <div className="flex gap-x-2">
            <Button variant="secondary" size="small" className="flex" onClick={() => editSectionHandler(employment)}>
              <Icon.EditPencil size={3} />
              Edit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const EmploymentEntry: FC<EmploymentEntryProps> = ({
  workHistoryEntry,
  withDescription = false,
  withEdit = false,
  editSectionHandler = (employment: Employment) => null,
}) => {
  if (workHistoryEntry.employments.length === 1) {
    return (
      <SingleEmploymentEntry
        workHistoryEntry={workHistoryEntry}
        editSectionHandler={editSectionHandler}
        withDescription={withDescription}
        withEdit={withEdit}
      />
    );
  }

  return (
    <MultipleEmploymentsEntry
      workHistoryEntry={workHistoryEntry}
      editSectionHandler={editSectionHandler}
      withDescription={withDescription}
      withEdit={withEdit}
    />
  );
};

export default EmploymentEntry;
