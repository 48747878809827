import React, { FC, Fragment, useRef, useState, ChangeEvent, FocusEvent, useEffect } from 'react';
import { uniqueId } from 'lodash-es';
import textBoxStyles from '#components/Form/styles/TextBox.module.css';
import searchSelectStyles from './SearchSelect.module.css';
import { Icon, SearchSelectBadge } from '#components/Index';

export interface OptionProps {
  id: string;
  name: string;
  checked?: boolean;
  hidden?: boolean;
  open?: boolean;
  negative?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SearchSelectOption: FC<OptionProps> = ({
  id,
  name,
  checked = false,
  open = false,
  negative = false,
  onChange,
}) => {
  return (
    <div className={`${searchSelectStyles.option} ${negative ? searchSelectStyles['option--negative'] : ''}`}>
      <input
        type="checkbox"
        id={`checkbox-${id}`}
        data-id={id}
        checked={checked}
        onChange={onChange}
        className={searchSelectStyles.option__input}
        tabIndex={open ? undefined : -1}
      />
      <label
        htmlFor={`checkbox-${id}`}
        className={searchSelectStyles.option__label}
        // tabIndex required so that event.relatedTarget is not null when clicked: https://stackoverflow.com/a/42764495
        tabIndex={-1}
      >
        {name}
      </label>
      <div className={searchSelectStyles.option__icon}>
        <Icon.CheckLine size={5} className="mr-4" />
      </div>
    </div>
  );
};

interface Props extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  id?: string;
  label: string;
  options: OptionProps[];
  hasError?: boolean;
  negative?: boolean;
  handleChange?: (values: OptionProps[]) => void;
}

const SearchSelect: FC<Props> = ({
  id: idProp,
  label,
  hasError = false,
  negative = false,
  options: initialOptions,
  handleChange,
  disabled,
  placeholder = 'Click for options',
}) => {
  const id = idProp ?? uniqueId('select');
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [values, setValues] = useState(initialOptions);
  const searchSelectRef = useRef<HTMLDivElement>(null);
  const searchSelectSearchInputRef = useRef<HTMLInputElement>(null);

  // The options array being passed in may initially be an empty array, and then updated later
  // this component could show some sort of "loading..." indicator while this is happening.
  useEffect(() => {
    setValues(initialOptions);
  }, [initialOptions]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = values.map((value) => {
      if (value.id === event.target.dataset.id) {
        value.checked = event.target.checked;
      }

      return value;
    });

    setValues(newValues);
    handleChange?.(newValues);
  };

  const handleBadgeClick = (event: React.MouseEvent<HTMLButtonElement> & { target: HTMLInputElement }) => {
    const newValues = values.map((value) => {
      if (value.id === event.target.dataset.id) {
        value.checked = false;
      }

      return value;
    });

    setValues(newValues);
    handleChange?.(newValues);
  };

  const updateHiddenValueProps = (newSearchValue: string) => {
    setValues(
      values.map((value) => {
        const searchIsEmpty = newSearchValue === '';
        const itemIncludesSearchTerm = value.name.toLowerCase().includes(newSearchValue.toLowerCase());
        return { ...value, hidden: !searchIsEmpty && !itemIncludesSearchTerm };
      })
    );
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    updateHiddenValueProps(event.target.value);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (searchSelectRef.current !== null && !searchSelectRef.current.contains(event.relatedTarget)) {
      setOpen(false);
    }
  };

  const handleClearClick = () => {
    setSearchValue('');
    updateHiddenValueProps('');
    searchSelectSearchInputRef.current?.focus(); // Pass focus from the button back to the search input
  };

  return (
    <>
      <div
        id={id}
        aria-labelledby={label}
        aria-disabled={disabled}
        className="inline-grid relative"
        ref={searchSelectRef}
        onBlur={handleBlur}
      >
        <div aria-expanded={open} aria-controls={`search-box-${id}`} className="flex items-center">
          <Icon.Search size={5} className="absolute mx-3.5 my-2.5 text-gray-500 pointer-events-none" />
          <input
            type="search"
            id={`search-input-{$id}`}
            onFocus={() => setOpen(true)}
            className={`${textBoxStyles.input} ${searchSelectStyles['search-input']} ${
              hasError ? textBoxStyles[`input--error`] : ''
            }`}
            autoComplete="off"
            disabled={disabled}
            onChange={handleSearchChange}
            placeholder={placeholder}
            ref={searchSelectSearchInputRef}
            value={searchValue}
          />
          {searchValue !== '' && (
            <button
              aria-label="Clear search"
              title="Clear search"
              aria-hidden={searchValue === ''}
              className={searchSelectStyles['clear-search-button']}
              onClick={handleClearClick}
            >
              <Icon.X size={5} className="" />
            </button>
          )}
        </div>

        <div aria-hidden={!open} className={searchSelectStyles['option-container']}>
          {values.map((option) =>
            option.hidden === false || option.hidden === undefined ? (
              <SearchSelectOption
                {...option}
                key={option.id}
                onChange={handleOptionChange}
                open={open}
                negative={negative}
              />
            ) : (
              <Fragment key={option.id} />
            )
          )}
          {values.every((option) => option.hidden === true) && (
            <div className={searchSelectStyles.option} tabIndex={-1}>
              <span className={searchSelectStyles.option__label}>No options found matching your search term</span>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-wrap mt-6 max-w-full">
        {values.map((option) => (
          <SearchSelectBadge
            key={option.id}
            searchSelectId={id}
            negative={negative}
            option={option}
            handleBadgeClick={handleBadgeClick}
          />
        ))}
      </div>
    </>
  );
};

export default SearchSelect;
