import { createAction, PATCH, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors } from '#root/src/types';

interface Payload {
  init: never;
  success: {
    redirectUrl: string;
  };
  fail: {
    errors: Errors<'base'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async () => await createAction<Payload>(PATCH, '/talents/onboarding/finish_onboarding')();
