import React, { FC } from 'react';
import { Button, Icon } from '#components/Index';

interface Props {
  title: string;
  subtitle: string;
  crossListItems?: string[];
  checkmarkListItems: string[];
  buttonText: string;
  hint: React.ReactElement;
  circleIcon: React.ReactElement;
  action?: () => void;
}

export const OnboardingTypeCard: FC<Props> = ({
  title,
  subtitle,
  crossListItems,
  checkmarkListItems,
  buttonText,
  hint,
  circleIcon,
  action,
}) => {
  return (
    <div className="col-span-4 flex flex-col rounded-lg bg-white overflow-clip cursor-pointer" onClick={action}>
      <div className="flex justify-between pt-8">
        <span className="pl-8">{circleIcon}</span>
        <span className="text-green-900 h-fit bg-green-200 px-2.5 py-0.5 rounded-l text-xs font-medium flex items-center gap-1">
          {hint}
        </span>
      </div>
      <div className="px-8 pb-8">
        <div className="py-4">
          <h2 className="text-display-md font-serif text-hpblack">{title}</h2>
          <h3 className="text-green-900 font-semibold">{subtitle}</h3>
        </div>
        {/* TODO: Style those lists properly */}
        <ul className="text-md text-gray-500 gap-4">
          {crossListItems?.map((item) => (
            <li key={item}>
              <div className="flex">
                <Icon.XCircle size={5} className="shrink-0 text-red-500 mr-2 mt-0.5" />
                {item}
              </div>
            </li>
          ))}
        </ul>
        <ul className="text-md text-gray-500 gap-4">
          {checkmarkListItems.map((item) => (
            <li key={item}>
              <div className="flex">
                <Icon.CheckBoxCircleFill size={5} className="shrink-0 text-green-500 mr-2 mt-1" />
                {item}
              </div>
            </li>
          ))}
        </ul>
        <div className="flex mt-8">
          <Button onClick={action}>{buttonText}</Button>
        </div>
      </div>
    </div>
  );
};
