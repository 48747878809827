import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import Success from '#components/Form/Success';
import { Icon, Button } from '#components/Index';
import * as sendActivateInstructionAPI from '#api/endpoints/session/sendActivateInstruction';

interface Props {
  email: string;
}
const ActiveYourAccountMessage: FC<Props> = ({ email }) => {
  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm({ defaultValues: { email } });

  const [resendTime, setResendTime] = useState<Date>();

  if (isSubmitSuccessful) {
    return (
      <Success
        title={`Instructions have been ${resendTime == null ? 'sent' : 'resent'} to your inbox`}
        icon={<Icon.MailCheckLine size={6} />}
      >
        Check your inbox (and spam folder) for a link to activate your account.{' '}
        {resendTime == null
          ? `We’ve emailed the following address: ${email}`
          : `We emailed the address ${email} at ${resendTime.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}`}
        <form
          onSubmit={handleSubmit(async (values) => await sendActivateInstructionAPI.request({ talent: values }))}
          className="mt-8"
        >
          <Button type="submit" size="large" disabled={isSubmitting} onClick={() => setResendTime(new Date())}>
            Click here to resend
          </Button>
        </form>
      </Success>
    );
  }

  return (
    <Success title="Great news, you’re almost set!" icon={<Icon.UserFollowLine size={6} />}>
      <div className="rich-text">
        <p>Your Hiperpool account is ready and waiting for you. Just have to confirm your email first.</p>
      </div>
      <div className="mt-8">
        <form onSubmit={handleSubmit(async (values) => await sendActivateInstructionAPI.request({ talent: values }))}>
          <Button type="submit" size="large" disabled={isSubmitting}>
            Send email confirmation
          </Button>
        </form>
      </div>
    </Success>
  );
};

export default ActiveYourAccountMessage;
