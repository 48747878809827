import React, { FC } from 'react';

import Success from '#components/Form/Success';
import { Icon } from '#components/Index';

const ResendInstructionsExpiredTokenNotification: FC = () => {
  return (
    <Success title="Oops, this link expired" icon={<Icon.MailCheckLine size={6} />}>
      You will receive an email with instructions on how to set up your password in a few minutes.
    </Success>
  );
};

export default ResendInstructionsExpiredTokenNotification;
