/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import InputWrapper, { InputProps } from '../InputWrapper';
import textBoxStyles from '#components/Form/styles/TextBox.module.css';
import richTextInputStyles from './RichTextInput.module.css';
import { Icon } from '#components/Index';

export interface RichTextInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    InputProps {
  className?: string;
  initialValue?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RichTextInput = forwardRef<HTMLInputElement, RichTextInputProps>(
  ({ error, description, label, className, required, placeholder, onChange, initialValue = '', ...rest }, ref) => {
    const hasError = error !== undefined && error !== '';

    const editorClasses = [
      'rich-text rich-text-lists bg-white !font-light min-h-[200px]',
      textBoxStyles.input,
      hasError ? textBoxStyles[`input--error`] : '',
      className,
    ].join(' ');

    const editor = useEditor({
      extensions: [StarterKit, Underline],
      content: initialValue,
      editorProps: {
        attributes: {
          class: editorClasses,
        },
      },
      onUpdate: ({ editor }: any) => {
        const html = editor.getHTML();
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const event = {
          target: {
            value: html,
          },
        } as React.ChangeEvent<HTMLInputElement>;
        onChange(event);
      },
    });

    if (editor == null) return null;

    return (
      <InputWrapper label={label} error={error} description={description} required={required}>
        {({ id }) => (
          <div>
            <div className="flex flex-col items-start gap-x-4 py-4">
              <div className={`flex flex-wrap gap-1 ${richTextInputStyles.toolbar}`}>
                <button
                  type="button"
                  onClick={() => editor.chain().focus().toggleBold().run()}
                  className={editor.isActive('bold') ? richTextInputStyles.active : ''}
                >
                  <strong>Bold</strong>
                </button>

                <button
                  type="button"
                  onClick={() => editor.chain().focus().toggleItalic().run()}
                  className={editor.isActive('italic') ? richTextInputStyles.active : ''}
                >
                  <i>Italic</i>
                </button>

                <button
                  type="button"
                  onClick={() => editor.chain().focus().toggleUnderline().run()}
                  className={editor.isActive('underline') ? richTextInputStyles.active : ''}
                >
                  <u>Underline</u>
                </button>

                <button
                  type="button"
                  onClick={() => editor.chain().focus().toggleBulletList().run()}
                  className={editor.isActive('bulletList') ? richTextInputStyles.active : ''}
                >
                  <Icon.ListUnordered size={5} />
                </button>

                <button
                  type="button"
                  onClick={() => editor.chain().focus().toggleOrderedList().run()}
                  className={editor.isActive('orderedList') ? richTextInputStyles.active : ''}
                >
                  <Icon.ListOrdered size={5} />
                </button>

                <button
                  type="button"
                  onClick={() => editor.chain().focus().setBlockquote().run()}
                  disabled={!editor.can().setBlockquote()}
                >
                  <Icon.IndentIncrease size={5} />
                </button>

                <button
                  type="button"
                  onClick={() => editor.chain().focus().unsetBlockquote().run()}
                  disabled={!editor.can().unsetBlockquote()}
                >
                  <Icon.IndentDecrease size={5} />
                </button>
              </div>
            </div>

            <EditorContent id={id} editor={editor} ref={ref} />
          </div>
        )}
      </InputWrapper>
    );
  }
);

export default RichTextInput;
