export class TreeNode {
  key: string;
  parent?: TreeNode;
  children: TreeNode[];
  hidden: boolean | null;

  constructor({
    key,
    parent = undefined,
    options = { hidden: false },
  }: {
    key: string;
    parent?: TreeNode;
    options?: { hidden: boolean | null };
  }) {
    this.key = key;
    this.parent = parent;
    this.children = [];
    this.hidden = options.hidden;
  }

  get isLeaf() {
    return this.children.length === 0;
  }

  get hasChildren() {
    return !this.isLeaf;
  }

  get isVisible() {
    return this.hidden === false;
  }
}

export class Tree {
  root: TreeNode;

  constructor(key: string) {
    this.root = new TreeNode({ key });
  }

  *preOrderTraversal(node = this.root): IterableIterator<TreeNode> {
    yield node;

    if (node.isVisible && node.children.length > 0) {
      for (const child of node.children) {
        yield* this.preOrderTraversal(child);
      }
    }
  }

  insert(parent: string, key: string, options?: { hidden: boolean | null }) {
    for (const node of this.preOrderTraversal()) {
      if (node.key === parent) {
        node.children.push(new TreeNode({ key, parent: node, options }));
        return true;
      }
    }
    return false;
  }

  remove(key: string) {
    for (const node of this.preOrderTraversal()) {
      const filtered = node.children.filter((c) => c.key !== key);
      if (filtered.length !== node.children.length) {
        node.children = filtered;
        return true;
      }
    }
    return false;
  }

  find(key: string) {
    for (const node of this.preOrderTraversal()) {
      if (node.key === key) return node;
    }
    return undefined;
  }

  setVisible(key: string) {
    const node = this.find(key);
    if (node != null) {
      node.hidden = false;
      return true;
    }
    return false;
  }

  setHidden(key: string) {
    const node = this.find(key);
    if (node != null) {
      node.hidden = true;
      return true;
    }
    return false;
  }

  setStepVisibility(key: string, value: boolean) {
    const node = this.find(key);
    if (node != null) {
      node.hidden = value;
      return true;
    }
    return false;
  }
}
