import React, { FC } from 'react';
import JobsNavigation from './elements/navigation/JobsList';
import { useGetDummyJobsList } from './data/DummyJobsData';
import Sidebar from './elements/sidebar/Sidebar';
import JobContent from './elements/content/JobContent';
import LockedLimited from '#pages/talentPages/opportunities/index/elements/LockedLimited';
import LockedRejected from '#pages/talentPages/opportunities/index/elements/LockedRejected';
import { Spinner } from '#src/components/Index';

interface MyActivityLockedProps {
  lockType: 'limited' | 'rejected';
}

const MyActivityLocked: FC<MyActivityLockedProps> = ({ lockType }) => {
  const { data, isLoading } = useGetDummyJobsList();

  const lockedComponent = lockType === 'limited' ? <LockedLimited /> : <LockedRejected />;

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) return null;

  const dummySelectedJob = data.jobs[0];

  return (
    <div className="relative">
      {lockedComponent}
      <div className="blur-sm z-10">
        <div className="flex flex-col lg:flex-row">
          <div className="bg-white border-r border-gray-300 min-h-full-content flex-col hidden lg:flex lg:basis-1/5">
            <JobsNavigation jobs={data.jobs} />
          </div>

          <div className="flex flex-col lg:basis-3/5">
            <JobContent selectedJob={dummySelectedJob} />
          </div>

          <div className="bg-white border-l border-gray-300 p-4 flex-col hidden lg:flex lg:basis-1/5 lg:min-h-full-content">
            <Sidebar selectedJob={dummySelectedJob} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyActivityLocked;
