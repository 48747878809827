import { useQuery } from 'react-query';

import * as apiGetCoachProfile from '#api/endpoints/coaches/getCoach';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  homepage = 'homepage',
  interviewPrep = 'interview-prep',
  coaches = 'coaches',
  show = 'show',
}

type GetApiPayload = apiGetCoachProfile.Type['success']['payload'];
export const useGetCoachProfile = (slug: string | undefined, options?: ReactQueryOptions<GetApiPayload>) => {
  if (slug == null) {
    throw Error('Slug is not provided');
  }
  return useQuery<GetApiPayload>(
    [KeysEnum.homepage, KeysEnum.interviewPrep, KeysEnum.coaches, KeysEnum.show, slug],
    async () => await apiGetCoachProfile.request({ slug }),
    {
      ...options,
    }
  );
};
