import React, { FC } from 'react';
import { User } from '#src/types';
import { paths, parseParamsToLinkSearch } from '#src/pages/paths';
import { Button, Icon } from '#components/Index';

interface Props {
  user: User | undefined;
  parsedDate: string;
  message: string;
  opportunityId: number;
}

const Interested: FC<Props> = ({ user, parsedDate, message, opportunityId }) => {
  return (
    <div className="flex flex-col gap-y-4 text-md text-gray-500 font-light">
      <div className="px-6">
        <p className="mb-4">
          Hi {user?.firstName}. You’ve already expressed interest in this opportunity. Check your My Jobs page to see
          the current status and latest activity for this role.
        </p>

        <div className="flex">
          <Button
            variant="primary"
            to={paths.talentActivity() + parseParamsToLinkSearch({ opportunityId })}
            className="flex"
          >
            See latest activity <Icon.ArrowRightLine size={4} aria-hidden="true" className="" />
          </Button>
        </div>
      </div>

      <div className="bg-green-200 flex gap-x-4 p-3 items-center">
        <Icon.FeaturedCircle size={9} />
        <div className="flex flex-col">
          <span className="text-sm font-bold text-hpblack">{message}</span>
          <span className="font-light text-xs">{parsedDate}</span>
        </div>
      </div>
    </div>
  );
};

export default Interested;
