import React, { FC, useState } from 'react';
import { Checkbox } from '#root/src/components/Form/Index';
import { Icon } from '#root/src/components/Index';

interface OpportunityFilterGroupProps {
  title: string;
  items: string[];
  filterKey: 'firms' | 'workPolicies' | 'regions' | 'contractTypes';
  searchFilterSelected: (filterKey: 'firms' | 'workPolicies' | 'regions' | 'contractTypes', value: string) => boolean;
  handleFilterChange: (filterKey: 'firms' | 'workPolicies' | 'regions' | 'contractTypes', value: string) => void;
  parsedOption?: (option: string) => string;
}

const OpportunityFilterGroup: FC<OpportunityFilterGroupProps> = ({
  title,
  filterKey,
  items,
  searchFilterSelected,
  handleFilterChange,
  parsedOption = (item) => item,
}) => {
  const [isExpanded, setExpanded] = useState(true);

  if (items.length === 0) return null;

  return (
    <div className="b-gray-300 border-t-2 pt-3 my-4">
      <h5 className="text-hpblack font-normal my-2">
        <button
          className="flex gap-2 w-full text-left justify-between focus:outline-none"
          onClick={() => setExpanded(!isExpanded)}
        >
          <span>{title}</span>
          <div
            className={`w-8 h-8 grid p-1.5 text-gray-500 transition rounded-full outline outline-transparent bg-gray-50 ${
              isExpanded ? '-rotate-90' : 'rotate-90'
            }`}
          >
            <Icon.ArrowRightSLine size={5} aria-hidden="true" />
          </div>
        </button>
      </h5>
      <div className={`flex flex-col text-sm ${isExpanded ? 'block' : 'hidden'}`}>
        {items.map((item) => (
          <Checkbox
            key={item}
            checked={searchFilterSelected(filterKey, item)}
            onChange={() => handleFilterChange(filterKey, item)}
          >
            {parsedOption(item)}
          </Checkbox>
        ))}
      </div>
    </div>
  );
};

export default OpportunityFilterGroup;
