import React, { FC } from 'react';
import { useSteps } from '../../../BaseProfileEditModal';
import BaseBranchesStep, { BranchStepProps } from '../BaseBranchesStep';

const PrivateEquityBranchesStep: FC<BranchStepProps> = ({ category }) => {
  const { setStepCallback, setNextDisabled } = useSteps();

  return (
    <BaseBranchesStep
      firmType="Private Equity"
      setStepCallback={setStepCallback}
      setNextDisabled={setNextDisabled}
      category={category}
    />
  );
};

export default PrivateEquityBranchesStep;
