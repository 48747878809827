import React, { FC } from 'react';

import { TalentDataControllerProvider } from './TalentDataController';
import { StepControllerProvider } from './StepControllerContext';
import { Tree, TreeNode } from './Tree';

import OnboardingType from './steps/onboardingType/OnboardingType';
import PreferencesIntro from './steps/preferences/Intro';
import CurrentSituation from './steps/preferences/CurrentSituation';
import TypeOfEmployment from './steps/preferences/TypeOfEmployment';
import InterestedFirms from './steps/preferences/interestedFirms/InterestedFirms';
import InterestedInStartups from './steps/preferences/interestedFirms/Startup';
import InterestedInCorporate from './steps/preferences/interestedFirms/Corporate';
import InterestedInManagementConsulting from './steps/preferences/interestedFirms/ManagementConsulting';
import InterestedInPrivateEquity from './steps/preferences/interestedFirms/PrivateEquity';
import InterestedInInvestmentBanking from './steps/preferences/interestedFirms/InvestmentBanking';
import WorkPolicy from './steps/preferences/WorkPolicy';
import WhereToWork from './steps/preferences/whereToWork/WhereToWork';
import HistoryIntro from './steps/history/Intro';
import ExperienceFirms from './steps/history/experienceFirms/ExperienceFirms';
import ExperienceInStartups from './steps/history/experienceFirms/Startup';
import ExperienceInCorporate from './steps/history/experienceFirms/Corporate';
import ExperienceInManagementConsulting from './steps/history/experienceFirms/ManagementConsulting';
import ExperienceInPrivateEquity from './steps/history/experienceFirms/PrivateEquity';
import ExperienceInInvestmentBanking from './steps/history/experienceFirms/InvestmentBanking';
import ExperienceIndustries from './steps/history/experienceFirms/ExperienceIndustries';
import ExperienceYears from './steps/history/ExperienceYears';
import Languages from './steps/history/Languages';

import EmploymentHistory from './steps/employmentHistory/EmploymentHistory';
import EmailConfirmation from './steps/EmailConfirmation';

import { CurrentStep } from './CurrentStep';

const buildTree = () => {
  const tree = new Tree(OnboardingType.id);
  tree.insert(OnboardingType.id, PreferencesIntro.id);

  // Full onboarding - Preferences
  tree.insert(PreferencesIntro.id, CurrentSituation.id);
  tree.insert(PreferencesIntro.id, TypeOfEmployment.id);
  tree.insert(PreferencesIntro.id, InterestedFirms.id);
  tree.insert(InterestedFirms.id, InterestedInStartups.id, { hidden: true });
  tree.insert(InterestedFirms.id, InterestedInCorporate.id, { hidden: true });
  tree.insert(InterestedFirms.id, InterestedInManagementConsulting.id, { hidden: true });
  tree.insert(InterestedFirms.id, InterestedInPrivateEquity.id, { hidden: true });
  tree.insert(InterestedFirms.id, InterestedInInvestmentBanking.id, { hidden: true });
  tree.insert(PreferencesIntro.id, WorkPolicy.id);
  tree.insert(PreferencesIntro.id, WhereToWork.id);

  // Full onboarding - History
  tree.insert(OnboardingType.id, HistoryIntro.id);
  tree.insert(HistoryIntro.id, ExperienceYears.id);
  tree.insert(HistoryIntro.id, ExperienceFirms.id);
  tree.insert(ExperienceFirms.id, ExperienceInStartups.id, { hidden: true });
  tree.insert(ExperienceFirms.id, ExperienceInCorporate.id, { hidden: true });
  tree.insert(ExperienceFirms.id, ExperienceInManagementConsulting.id, { hidden: true });
  tree.insert(ExperienceFirms.id, ExperienceInPrivateEquity.id, { hidden: true });
  tree.insert(ExperienceFirms.id, ExperienceInInvestmentBanking.id, { hidden: true });
  tree.insert(HistoryIntro.id, ExperienceIndustries.id);
  tree.insert(HistoryIntro.id, Languages.id);

  // Shared for both onboarding flows
  tree.insert(OnboardingType.id, EmploymentHistory.id);
  tree.insert(OnboardingType.id, EmailConfirmation.id);

  const listOfAllNodes = () => [...tree.preOrderTraversal()];
  const listOfActiveNodes = () => [...tree.preOrderTraversal()].filter((node) => node.hidden === null || !node.hidden);
  const getIndexOfActiveNode = (node: TreeNode) => listOfActiveNodes().findIndex((n) => n.key === node.key);
  const fullOnboardingStepIds = [PreferencesIntro.id, HistoryIntro.id];

  return {
    tree,
    listOfAllNodes,
    listOfActiveNodes,
    getIndexOfActiveNode,
    fullOnboardingStepIds,
  };
};

const JobsOnboarding: FC = () => {
  const { tree, listOfAllNodes, listOfActiveNodes, getIndexOfActiveNode, fullOnboardingStepIds } = buildTree();

  return (
    <TalentDataControllerProvider>
      <StepControllerProvider
        tree={tree}
        listOfAllNodes={listOfAllNodes}
        listOfActiveNodes={listOfActiveNodes}
        getIndexOfActiveNode={getIndexOfActiveNode}
        fullOnboardingStepIds={fullOnboardingStepIds}
      >
        {/* Depending on the current step ID... render a different step */}
        <CurrentStep />
      </StepControllerProvider>
    </TalentDataControllerProvider>
  );
};
export default JobsOnboarding;
