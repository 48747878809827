import React from 'react';
import { Step } from '../CurrentStep';
import { Button, Icon } from '#components/Index';
import { useTalentDataController } from '../TalentDataController';

const displayName = 'Email Confirmation';

const EmailConfirmation: Step = {
  id: 'email-confirmation',
  displayName,
  onDarkBackground: true,
  Body: () => {
    const { talentData, sendConfirmationEmail } = useTalentDataController();

    return (
      <div className="flex flex-col text-center p-16 mt-16 rounded-lg bg-gray-50 overflow-clip text-gray-600 mx-auto text-sm">
        <div>
          <Icon.UserCheckmark size={12} className="mx-auto" aria-hidden="true" />
          <h2 className="text-display-sm my-4 font-serif text-hpblack">You’re now a Hiperpool Member!</h2>
          <h3 className="font-semibold text-md max-w-sm">
            Almost there! Please confirm your email address to complete your application
          </h3>

          <div className="my-4">
            <p>Your confirmation has been sent to:</p>
            <span className="font-semibold">{talentData?.email}</span>
          </div>
        </div>

        <div className="border-t-2 border-gray-200">
          <p className="font-semibold mt-8 text-md">Not receiving the email?</p>
          <p className="mt-4">Check your junk folder.</p>
          <Button variant="link" className="link !font-normal" onClick={async () => await sendConfirmationEmail()}>
            Resend confirmation email.
          </Button>
        </div>
      </div>
    );
  },
  Footer: () => null,
};

export default EmailConfirmation;
