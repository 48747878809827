import React, { FC, useState } from 'react';
import { useGetProfileDetails } from './data/ProfileDetails';
import { Spinner, FormContentGroup } from '#components/Index';
import NotFound from '#pages/NotFound';
import ProfileSubNavigation from '../elements/ProfileSubNavigation';
import ProfileVisibilityInfo from './elements/profile/ProfileVisibilityInfo';
import ProfileDetailsEditable from './elements/profile/ProfileDetailsEditable';
import ProfileEducation from './elements/profile/ProfileEducation';
import ProfileWorkHistory from './elements/profile/ProfileWorkHistory';
import ProfileLinkedin from './elements/profile/ProfileLinkedin';
import ProfileCv from './elements/profile/ProfileCv';
import GroupedLanguages from './elements/GroupedLanguages';
import LanguagesModal from './elements/modals/LanguagesModal';
import ProfileSummary from './elements/profile/ProfileSummary';

const Profile: FC = () => {
  const { data, isLoading } = useGetProfileDetails();
  const [isLanguagesModalOpen, setIsLanguagesModalOpen] = useState(false);

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  const { profile } = data;

  return (
    <div className="grow flex flex-col max-w-6xl xl:max-w-8xl mx-auto py-6">
      <LanguagesModal
        isOpen={isLanguagesModalOpen}
        closeModal={() => setIsLanguagesModalOpen(false)}
        profile={profile}
      />

      <ProfileSubNavigation />

      <div className="px-8 mb-16 2xl:px-0">
        <ProfileVisibilityInfo />

        <div className="w-full px-8 bg-white rounded-lg shadow-md-dark mb-8 divide-y-2">
          <ProfileDetailsEditable profile={profile} />

          <FormContentGroup title="LinkedIn">
            <ProfileLinkedin linkedinUrl={profile.linkedinUrl} />
          </FormContentGroup>

          <FormContentGroup title="CV/Resume">
            <ProfileCv resumeUrl={profile.resumeUrl} firstName={profile.firstName} lastName={profile.lastName} />
          </FormContentGroup>

          <FormContentGroup title="Summary">
            <ProfileSummary summary={profile.summary ?? ''} />
          </FormContentGroup>

          <FormContentGroup title="Work History">
            <ProfileWorkHistory workHistory={profile.workHistory} />
          </FormContentGroup>

          <FormContentGroup title="Education">
            <ProfileEducation educations={profile.educations} />
          </FormContentGroup>

          <FormContentGroup
            title="Languages"
            editSectionHandler={() => setIsLanguagesModalOpen(true)}
            editSectionText="Edit"
          >
            <GroupedLanguages languages={profile.languages} />
          </FormContentGroup>
        </div>
      </div>
    </div>
  );
};

export default Profile;
