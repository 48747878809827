import { useEffect } from 'react';
import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './CookieConsent.css';
import { paths } from '#root/src/pages/paths';

export default function CookieConsent() {
  useEffect(() => {
    if (document.getElementById('cc--main') !== undefined) {
      window.CookieConsentApi = window.initCookieConsent();
      window.CookieConsentApi.run({
        page_scripts: true,
        languages: {
          en: {
            consent_modal: {
              title: 'Cookies',
              description: `Our website uses cookies to ensure its proper operation and to understand how you interact with it. For more information, see our <a class="link" href="${paths.cookies()}">Cookies Policy</a>`,
              primary_btn: {
                text: 'Accept all',
                role: 'accept_all', // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Accept necessary',
                role: 'accept_necessary', // 'settings' or 'accept_necessary'
              },
            },
            settings_modal: {
              title: 'Cookie preferences',
              save_settings_btn: 'Save settings',
              accept_all_btn: 'Accept all',
              reject_all_btn: 'Reject all', // optional, [v.2.5.0 +]
              cookie_table_headers: [
                { col1: 'Name' },
                { col2: 'Domain' },
                { col3: 'Expiration' },
                { col4: 'Description' },
                { col5: 'Type' },
              ],
              blocks: [
                {
                  title: 'Cookie usage',
                  description:
                    'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.',
                },
                {
                  title: 'Strictly necessary cookies',
                  description:
                    'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true,
                  },
                },
                {
                  title: 'Analytics cookies',
                  description:
                    'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.',
                  toggle: {
                    value: 'analytics',
                    enabled: false,
                    readonly: false,
                  },
                },
                {
                  title: 'More information',
                  description:
                    'For any queries in relation to my policy on cookies and your choices, please <a class="cc-link" href="#yourwebsite">contact me</a>.',
                },
              ],
            },
          },
        },
        delay: 300, // 300ms delay is enough to load fonts etc.
      });
    }
  }, []);

  return null;
}
