import React, { FC, useEffect, useState, Dispatch } from 'react';
import { CustomProfileWorkAuthorization } from '#src/types';
import * as apiGetRequiredWorkAuthorizationGroups from '#api/endpoints/onboarding/workAuthorizationGroupsByCountries';
import { MultiLocationInput, LocationOptionProps } from '#root/src/components/Form/Index';
import WorkAuthorizationQuestions from './WorkAuthorizationQuestions';

interface WhereToWorkFormProps {
  selectedLocations: LocationOptionProps[];
  setSelectedLocations: Dispatch<React.SetStateAction<LocationOptionProps[]>>;
  selectedWorkAuthorizations: number[];
  setSelectedWorkAuthorizations: Dispatch<React.SetStateAction<number[]>>;
  customProfileWorkAuthorization: CustomProfileWorkAuthorization;
  setCustomProfileWorkAuthorization: Dispatch<React.SetStateAction<CustomProfileWorkAuthorization>>;
  setInvalidWorkAuthorizations: Dispatch<React.SetStateAction<boolean>>;
}

const WhereToWorkForm: FC<WhereToWorkFormProps> = ({
  selectedLocations,
  setSelectedLocations,
  selectedWorkAuthorizations,
  setSelectedWorkAuthorizations,
  customProfileWorkAuthorization,
  setCustomProfileWorkAuthorization,
  setInvalidWorkAuthorizations,
}) => {
  const [requiredWorkAuthorizationGroups, setRequiredWorkAuthorizationGroups] = useState<string[]>([]);

  // Display the work authorization questions based on the selected locations
  useEffect(() => {
    async function loadRequiredWorkAuthorizationGroups() {
      const countries = selectedLocations
        .map((location) => location.country)
        .filter((country): country is string => country !== undefined);

      if (countries.length === 0) {
        setRequiredWorkAuthorizationGroups([]);
        return;
      }

      try {
        const { workAuthorizationGroups } = await apiGetRequiredWorkAuthorizationGroups.request({
          countries,
        });

        setRequiredWorkAuthorizationGroups(workAuthorizationGroups);
      } catch {
        setRequiredWorkAuthorizationGroups([]);
      }
    }

    void loadRequiredWorkAuthorizationGroups(); // Using the void operator to explicitly ignore the returned promise
  }, [selectedLocations]);

  return (
    <div className="rich-text text-hpblack w-full">
      <h1>
        What <span className="text-green-900">locations</span> are you open to?
      </h1>
      <p>You can choose countries or specific cities</p>

      <div className="flex flex-col gap-6 mt-8">
        <div className="grid gap-2">
          <div className="grid">
            <MultiLocationInput
              label="Locations"
              selectedValues={selectedLocations}
              setSelectedValues={setSelectedLocations}
            />
          </div>
        </div>

        <WorkAuthorizationQuestions
          selectedWorkAuthorizations={selectedWorkAuthorizations}
          setSelectedWorkAuthorizations={setSelectedWorkAuthorizations}
          requiredWorkAuthorizationGroups={requiredWorkAuthorizationGroups}
          setInvalidWorkAuthorizations={setInvalidWorkAuthorizations}
          customProfileWorkAuthorization={customProfileWorkAuthorization}
          setCustomProfileWorkAuthorization={setCustomProfileWorkAuthorization}
        />
      </div>
    </div>
  );
};

export default WhereToWorkForm;
