import { ReactQueryOptions } from '#src/types';
import { useQuery } from 'react-query';

import * as apiGetJobsList from '#api/endpoints/talents/activity/jobsList';

export enum KeysEnum {
  talents = 'talents',
  activity = 'activity',
  jobs = 'jobs',
}

type GetApiPayload = apiGetJobsList.Type['success']['payload'];

export const useGetJobsList = (filter = { filter: 'all' }, options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.talents, KeysEnum.activity, KeysEnum.jobs, filter],
    async ({ signal }) => await apiGetJobsList.request({ ...filter }, signal),
    {
      ...options,
    }
  );
