import React, { FC, ReactNode } from 'react';
import { ReactComponent as People } from '#assets/homepage/jobs/people.svg';
import { ReactComponent as Mail } from '#assets/homepage/jobs/mail.svg';
import { ReactComponent as Message } from '#assets/homepage/jobs/message.svg';
import { ReactComponent as Checklist } from '#assets/homepage/jobs/checklist.svg';

interface ItemProps {
  orderNumber: number;
  circleGraphic: ReactNode;
  title: string;
  text: string;
}

const Item: FC<ItemProps> = ({ circleGraphic, title, text, orderNumber }) => (
  <li>
    {circleGraphic}
    <div className="font-serif text-display-xs mt-5 mb-4">
      <div className="text-green-900">{orderNumber}</div>
      <div className="inline-block max-w-[14rem]">{title}</div>
    </div>
    <div className="mt-5 text-gray-600 text-sm">{text}</div>
  </li>
);

const HowItWorks: FC = () => {
  return (
    <div className="my-20 px-variable-md text-center">
      <h2 className="text-display-md font-serif max-w-xl mx-auto">How it works</h2>
      <ol className="mx-auto mt-12 max-w-5xl grid xs:grid-cols-2 md:grid-cols-4 gap-y-12 gap-x-4">
        <Item
          orderNumber={1}
          circleGraphic={<Checklist className="mx-auto" aria-hidden="true" />}
          title="Browse coaches"
          text="Browse from a list of top, experienced coaches with a track record of helping candidates ace their interviews. Pick those with the work experience and availability that you need."
        />
        <Item
          orderNumber={2}
          circleGraphic={<People className="mx-auto" aria-hidden="true" />}
          title="Purchase sessions"
          text="Buy a single session or a bundle. Use your sessions with one or multiple coaches. Once you book a time, you will be connected to the coach so you can clarify your objectives upfront."
        />
        <Item
          orderNumber={3}
          circleGraphic={<Message className="mx-auto" aria-hidden="true" />}
          title="Do personalised interviews"
          text="During a 1h video call you’ll be able to go through a realistic mock interview. Whether you need to focus on the case, fit or resume elements; our experts can provide actionable feedback."
        />
        <Item
          orderNumber={4}
          circleGraphic={<Mail className="mx-auto" aria-hidden="true" />}
          title="Get an offer!"
          text="Landing a top consulting offer is hard work. Most successful candidates do 30+ mock interviews to get an offer. But coaching is a small investment when you think about the career gains!"
        />
      </ol>
    </div>
  );
};

export default HowItWorks;
