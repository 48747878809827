import React, { FC } from 'react';

import EmployerTeamMemberForm from './Form';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import Layout from '../Layout';
import { useEditEmployerTeamMemberData } from './EmployerTeamMemberEditData';
import { paths } from '../../paths';

interface Props {
  token: string;
}
const EmployerTeamMemberComponent: FC<Props> = ({ token }) => {
  const { data } = useEditEmployerTeamMemberData(token);

  if (data == null) {
    return <></>;
  }
  const {
    teamMember: { firstName, lastName, email },
    companyName,
    countries,
  } = data;

  return (
    <Layout
      header={
        <>
          You have been invited to the <span className="text-green-500">{companyName}</span> account on Hiperpool
        </>
      }
      subHeader=""
    >
      <EmployerTeamMemberForm defaultValues={{ token, firstName, lastName, email }} countries={countries} />
    </Layout>
  );
};

const EmployerTeamMember: FC = () => {
  const [searchParams] = useSearchParams(useLocation().search);
  const navigate = useNavigate();

  const token = searchParams.get('token');

  if (token == null) {
    navigate(paths.root());
    return <></>;
  }
  return <EmployerTeamMemberComponent token={token} />;
};

export default EmployerTeamMember;
