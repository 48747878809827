import React, { FC, useMemo, Dispatch, Fragment } from 'react';
import { LanguageWithProficiencyOption } from '#src/types';
import { useGetLanguageProficiencyLevels } from './LanguagesProficiencyLevelsData';
import { useGetLanguages } from './LanguagesData';
import { Button, Icon, Select, Spinner } from '#components/Index';

interface LanguagesFormProps {
  selectedLanguages: LanguageWithProficiencyOption[];
  setSelectedLanguages: Dispatch<React.SetStateAction<LanguageWithProficiencyOption[]>>;
}

const LanguagesForm: FC<LanguagesFormProps> = ({ selectedLanguages, setSelectedLanguages }) => {
  const { data: proficiencyLevelsData, isLoading: proficienciesLoading } = useGetLanguageProficiencyLevels();
  const { data: languagesData, isLoading: languagesLoading } = useGetLanguages();

  const selectedLanguageIds = useMemo(() => {
    return selectedLanguages.map((language) => language.approvedLanguageId);
  }, [selectedLanguages]);

  if (proficienciesLoading || languagesLoading) {
    return <Spinner className="py-32" />;
  }

  if (proficiencyLevelsData == null || languagesData == null) {
    return null;
  }

  const { languages } = languagesData;
  const { proficiencyLevels } = proficiencyLevelsData;

  const handleAddButtonClick = () => {
    setSelectedLanguages([
      ...selectedLanguages,
      {
        language: undefined,
        proficiency: undefined,
        approvedLanguageId: undefined,
      },
    ]);
  };

  const handleLanguageChange = (listIndex: number, value: string) => {
    if (value === undefined) {
      return;
    }

    setSelectedLanguages(
      selectedLanguages.map((languageEntry, index) => ({
        ...languageEntry,
        language: listIndex === index ? value : languageEntry.language,
        approvedLanguageId: languages.find((l) => l.name === value)?.id,
      }))
    );
  };

  const handleProficiencyChange = (listIndex: number, value: string) => {
    if (value === undefined) {
      return;
    }

    setSelectedLanguages((selectedLanguages) =>
      selectedLanguages.map((languageEntry, index) => {
        if (index === listIndex) {
          return {
            ...languageEntry,
            proficiency: value !== '' ? value : languageEntry.proficiency,
          };
        }
        return languageEntry;
      })
    );
  };

  const handleRemoveButtonClick = (listIndex: number) => {
    setSelectedLanguages(selectedLanguages.filter((_, index) => listIndex !== index));
  };

  return (
    <div className="rich-text text-hpblack w-full">
      <h1>
        What <span className="text-green-900">languages</span> are you proficient in?
      </h1>
      <p>Add as many languages as you need</p>

      <div className="grid grid-cols-[1fr_1fr_auto] gap-x-6 gap-y-8 mt-8">
        {selectedLanguages.map(({ language: selectedLanguage, proficiency: selectedProficiency }, listIndex) => {
          return (
            <Fragment key={listIndex}>
              <Select
                label="Language"
                value={selectedLanguage === undefined ? '' : selectedLanguage}
                onChange={(event) => handleLanguageChange(listIndex, event.target?.value)}
              >
                {[
                  <option value="" disabled hidden key="-1">
                    Please select
                  </option>,
                  ...languages.map(({ id, name }) => (
                    <option key={id} hidden={selectedLanguageIds.includes(id) && name !== selectedLanguage}>
                      {name}
                    </option>
                  )),
                ]}
              </Select>
              <Select
                label="Proficiency"
                value={selectedProficiency === undefined ? '' : selectedProficiency}
                onChange={(event) => handleProficiencyChange(listIndex, event.target?.value)}
              >
                {[
                  <option value="" disabled hidden key="-1">
                    Please select
                  </option>,
                  ...proficiencyLevels.map((proficiency, index) => (
                    <option key={index} value={proficiency}>
                      {proficiency}
                    </option>
                  )),
                ]}
              </Select>
              <Button
                variant="negative"
                size="small"
                circle={true}
                title="Remove"
                onClick={() => handleRemoveButtonClick(listIndex)}
                className={`self-end ${listIndex === 0 ? 'invisible' : ''}`}
              >
                <Icon.X size={5} />
              </Button>
            </Fragment>
          );
        })}
      </div>
      <div className="mt-8">
        <Button variant="secondary" size="medium" onClick={handleAddButtonClick} className="xs:justify-self-start">
          + Add another language
        </Button>
      </div>
    </div>
  );
};

export default LanguagesForm;
