import React, { FC, useEffect, useState, useCallback } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { request as apiUpdateWorkPolicies } from '#root/src/api/endpoints/talents/profile/updateWorkPolicies';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { useSteps } from '../../BaseProfileEditModal';
import WorkPoliciesForm from '#components/TalentSharedForms/WorkPolicies/Form';

const WorkPolicyStep: FC = () => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { setStepCallback, setNextDisabled } = useSteps();

  const [selectedWorkPolicies, setSelectedWorkPolicies] = useState<number[]>([]);

  // Set the selected work policies based on the talent data
  useEffect(() => {
    if (profilePreferencesData == null) return;

    setSelectedWorkPolicies(profilePreferencesData.profilePreferences.workPolicyIds);
  }, [profilePreferencesData]);

  // Prevent the user from moving to the next step if they haven't selected any value
  useEffect(() => {
    setNextDisabled(selectedWorkPolicies.length === 0);
  }, [setNextDisabled, selectedWorkPolicies]);

  const stepCallback = useCallback(async () => {
    if (selectedWorkPolicies.length === 0) return false;

    try {
      await apiUpdateWorkPolicies({ workPolicyIds: selectedWorkPolicies });
      createSuccessToast({ title: 'Work policies' });
      return true;
    } catch {
      createErrorToast({ title: 'Work policies' });
      return false;
    }
  }, [selectedWorkPolicies]);

  useEffect(() => {
    setStepCallback(() => stepCallback);
  }, [setStepCallback, stepCallback]);

  return (
    <WorkPoliciesForm setSelectedWorkPolicies={setSelectedWorkPolicies} selectedWorkPolicies={selectedWorkPolicies} />
  );
};

export default WorkPolicyStep;
