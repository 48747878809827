import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: never;
  success: {
    workAuthorizationGroups: Array<{
      name: string;
      workAuthorizations: Array<{
        id: number;
        name: string;
        withCustomQuestion: boolean;
      }>;
    }>;
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/public/work_authorization_groups');
