import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useTalentDataController } from '../../TalentDataController';
import { useStepController } from '../../StepControllerContext';
import { Step, StepBackButton, StepNextButton } from '../../CurrentStep';
import CurrentSituationForm from '#components/TalentSharedForms/CurrentSituation/Form';

const CurrentSituation: Step = {
  id: 'current-situation',
  Body: ({
    setNextDisabled,
    setHandleNextClick,
    setCanCancelOnboarding,
  }: {
    setNextDisabled?: Dispatch<SetStateAction<boolean>>;
    setHandleNextClick?: Dispatch<SetStateAction<() => void>>;
    setCanCancelOnboarding?: Dispatch<SetStateAction<boolean>>;
  }) => {
    const { talentData, updateTalentData } = useTalentDataController();
    const { next } = useStepController();

    const [selectedWorkInterestSituationType, setSelectedWorkInterestSituationType] = useState<number | null>(null);

    // Set the selected work interest situation type based on the talent data
    useEffect(() => {
      if (talentData == null) return;

      setSelectedWorkInterestSituationType(talentData.workInterestSituationId);
    }, [talentData, setSelectedWorkInterestSituationType]);

    // Prevent the user from moving to the next step if they haven't selected any value
    useEffect(() => {
      if (setNextDisabled == null) return;

      setNextDisabled(selectedWorkInterestSituationType === 0);
    }, [selectedWorkInterestSituationType, setNextDisabled]);

    // Enable the cancel onboarding button if the user can cancel the onboarding
    useEffect(() => {
      if (talentData == null || setCanCancelOnboarding == null) return;

      setCanCancelOnboarding(talentData.cancelPossible);
    }, [talentData, setCanCancelOnboarding]);

    useEffect(() => {
      const handleNext = async () => {
        if (talentData == null || selectedWorkInterestSituationType == null) return;

        const newTalentData = { ...talentData, workInterestSituationId: selectedWorkInterestSituationType };

        const result = await updateTalentData(newTalentData, 'current_situation');

        if (result.success === true) {
          next();
        }
      };

      if (setHandleNextClick != null) {
        setHandleNextClick(() => handleNext);
      }
    }, [setHandleNextClick, selectedWorkInterestSituationType, talentData, next, updateTalentData]);

    return (
      <CurrentSituationForm
        setSelectedWorkInterestSituationType={setSelectedWorkInterestSituationType}
        selectedWorkInterestSituationType={selectedWorkInterestSituationType}
      />
    );
  },
  Footer: ({
    nextDisabled,
    onNextClick,
    canCancelOnboarding,
  }: {
    nextDisabled?: boolean;
    onNextClick?: () => void;
    canCancelOnboarding?: boolean;
  }) => (
    <>
      <StepBackButton cancelOnboarding={canCancelOnboarding} />
      <StepNextButton disabled={nextDisabled} onClick={onNextClick} />
    </>
  ),
};

export default CurrentSituation;
