import React, { FC } from 'react';
import { LocationOptionProps } from './MultiLocationInput';
import badgeStyles from '#components/Form/SearchSelect/SearchSelectBadge.module.css';
import { Icon } from '#components/Index';

interface BadgesProps {
  searchSelectId: string;
  negative: boolean;
  option: LocationOptionProps;
  handleBadgeClick: (event: React.MouseEvent<HTMLButtonElement> & { target: HTMLInputElement }) => void;
}

const SearchSelectBadge: FC<BadgesProps> = ({ searchSelectId, negative, option, handleBadgeClick }) => {
  return (
    <div
      key={option.placeId}
      aria-hidden={option.checked !== true}
      className={`${badgeStyles.badge} ${negative ? badgeStyles['badge--negative'] : ''}`}
    >
      <div className={badgeStyles.badge__inner}>
        <button
          id={`${searchSelectId}-badge-${option.placeId}`}
          data-id={option.placeId}
          className={`${badgeStyles.badge__button}`}
          onClick={handleBadgeClick}
          tabIndex={option.checked === true ? 0 : -1}
        >
          <div className={`${badgeStyles.badge__button__inner}`}>
            {option.name}
            <Icon.X size={5} aria-label="(remove)" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default SearchSelectBadge;
