import React, { FC } from 'react';

interface CandidateMessageProps {
  date: string;
  message: string;
  isLast: boolean;
}

const CandidateMessage: FC<CandidateMessageProps> = ({ date, message, isLast }) => {
  return (
    <li>
      <div className="relative pb-4">
        {!isLast && <span className="absolute left-2.5 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true"></span>}
        <div className="relative flex justify-end space-x-3">
          <div className="flex flex-col max-w-lg items-end">
            <div className="bg-green-900 text-white font-light rounded-md p-4 flex w-fit">{message}</div>
            <span className="text-right text-sm font-light text-gray-500">{date}</span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CandidateMessage;
