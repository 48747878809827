import React, { FC, useMemo, useState } from 'react';
import { TalentPreferences } from '#src/types';
import { useGetWorkPolicies } from '#components/TalentSharedForms/WorkPolicies/WorkPolicyData';
import { useGetWorkAuthorizationGroups } from '#pages/jobsOnboarding/steps/preferences/whereToWork/WorkAuthorizationsData';
import { FormContentGroup } from '#components/Index';
import LocationsModal from './modals/LocationsModal';
import MissingAnswerCard from './MissingAnswerCard';
import SingleLineAnswer from './SingleLineAnswer';
import ProfileBadge from '../../elements/ProfileBadge';

interface LocationsProps {
  profilePreferences: TalentPreferences;
}

const Locations: FC<LocationsProps> = ({ profilePreferences }) => {
  const [isLocationsModalOpen, setIsLocationsModalOpen] = useState(false);

  const { data: workPolicyData } = useGetWorkPolicies();
  const { data: workAuthorizationData } = useGetWorkAuthorizationGroups();

  const { locations, workAuthorizationIds, workPolicyIds } = profilePreferences;

  const selectedWorkAuthorizations = useMemo(() => {
    if (workAuthorizationData == null) return [];

    return workAuthorizationData.workAuthorizationGroups
      .map((group) => group.workAuthorizations)
      .flat()
      .filter((workAuthorization) => workAuthorizationIds.includes(workAuthorization.id));
  }, [workAuthorizationIds, workAuthorizationData]);

  const selectedWorkPolicies = useMemo(() => {
    if (workPolicyData == null) return [];

    return workPolicyData.workPolicies.filter((workPolicy) => workPolicyIds.includes(workPolicy.id));
  }, [workPolicyIds, workPolicyData]);

  if (workPolicyData == null || workAuthorizationData == null) return null;

  const locationsOrMissingCard = () => {
    if (locations.length === 0) return <MissingAnswerCard onClick={() => setIsLocationsModalOpen(true)} />;

    return (
      <div className="flex flex-col gap-2 mt-2">
        <div className="flex flex-wrap gap-2">
          {locations.map((location) => (
            <ProfileBadge key={location.id}>{location.name}</ProfileBadge>
          ))}
        </div>

        {selectedWorkAuthorizations.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-4">
            {selectedWorkAuthorizations.map((workAuthorization) => (
              <SingleLineAnswer key={workAuthorization.id}>{workAuthorization.name}</SingleLineAnswer>
            ))}
          </div>
        )}
      </div>
    );
  };

  const workPoliciesOrMissingCard = () => {
    if (selectedWorkPolicies.length === 0) return <MissingAnswerCard onClick={() => setIsLocationsModalOpen(true)} />;

    return (
      <div className="flex flex-wrap gap-2">
        {selectedWorkPolicies.map((workPolicy) => (
          <ProfileBadge key={workPolicy.id}>{workPolicy.name}</ProfileBadge>
        ))}
      </div>
    );
  };

  return (
    <FormContentGroup
      title="Locations"
      groupName="Preferences"
      description="Where in the world would you like/be open to working?"
      editSectionHandler={() => setIsLocationsModalOpen(!isLocationsModalOpen)}
    >
      <LocationsModal isOpen={isLocationsModalOpen} close={() => setIsLocationsModalOpen(false)} />

      <div className="flex flex-col gap-y-6">
        <div>
          <h5 className="text-gray-500 text-sm pb-2">Locations I’m open to</h5>

          {locationsOrMissingCard()}
        </div>

        <div>
          <h5 className="text-gray-500 text-sm pb-2">Work policies I prefer</h5>

          {workPoliciesOrMissingCard()}
        </div>
      </div>
    </FormContentGroup>
  );
};

export default Locations;
