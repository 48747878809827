import React, { FC } from 'react';
import { Button, CircleGraphic } from '#components/Index';
import { paths } from '#pages/paths';
import JobWoman from '#assets/homepage/jobs/jobWoman.webp';
import JobMan from '#assets/homepage/jobs/jobMan.webp';
import KKRLogo from '#assets/homepage/jobs/kkr.svg';
import BCGLogo from '#assets/homepage/jobs/bcg.svg';

const LeftImages = () => (
  <CircleGraphic cols={5} rows={5} className="w-full h-auto mt-5 max-md:hidden" aria-hidden="false" role="group">
    <CircleGraphic.Quarter x={3} y={1} color="green-500" rotate={90} />
    <CircleGraphic.Quarter x={4} y={1} color="green-900" rotate={180} />
    <CircleGraphic.CircleImage x={3} y={2} src={BCGLogo} alt="BCG - Boston Consulting Group" />
    <CircleGraphic.Quarter x={3} y={4} color="green-500" rotate={-90} />
    <CircleGraphic.CircleImage x={1} y={3} src={JobMan} alt="Headshot of a man" />
    <CircleGraphic.Half x={1} y={5} color="green-900" />
  </CircleGraphic>
);

const Content = () => (
  <div className="md:text-center my-auto py-20 max-w-lg mx-auto">
    <h1 className="font-serif text-display-md lg:text-display-lg">
      Hand<b>-</b>picked jobs <br className="max-2xs:hidden" />
      without the noise
    </h1>
    <p className="text-lg lg:text-xl my-8 max-w-[26rem] md:mx-auto">
      Browse top opportunities and receive hand‑picked roles directly to your inbox
    </p>
    <Button to={paths.signUp()} size="large">
      Join today, free
    </Button>
  </div>
);

const RightImages = () => (
  <CircleGraphic
    cols={5}
    rows={4}
    className="md:w-full h-auto self-end max-md:absolute max-md:w-[max(14rem,50%)] right-0 bottom"
    aria-hidden="false"
    role="group"
  >
    <CircleGraphic.Quarter x={4} y={1} color="green-900" rotate={180} className="max-xs:hidden" />
    <CircleGraphic.Quarter x={5} y={1} color="green-500" rotate={180} className="max-xs:hidden" />
    <CircleGraphic.CircleImage x={4} y={2} src={KKRLogo} alt="KKR & Co. Inc. - Kohlberg Kravis Roberts & Co" />
    <CircleGraphic.Quarter x={1} y={3} color="green-900" rotate={180} />
    <CircleGraphic.Quarter x={1} y={4} color="green-500" />
    <CircleGraphic.CircleImage x={2} y={3} src={JobWoman} alt="Headshot of a woman" />
    <CircleGraphic.Quarter x={4} y={4} color="green-500" rotate={-90} />
    <CircleGraphic.Quarter x={5} y={4} color="green-900" rotate={180} />
  </CircleGraphic>
);

const CandidateHeroBanner: FC = () => {
  return (
    <div className="bg-hpblack text-white px-variable-sm">
      <div className="max-w-7xl grid md:grid-cols-[4fr_5fr_4fr] max-w-screen-xl mx-auto relative max-md:justify-start max-md:pb-16">
        <LeftImages />
        <Content />
        <RightImages />
      </div>
    </div>
  );
};

export default CandidateHeroBanner;
