import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors } from '#root/src/types';
interface Payload {
  init: { slug: string };
  success: {
    credits: number;
  };
  fail: {
    errors: Errors<'base'>; //  "no talent is signed in"
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(GET, `/talents/coaches/${params.slug}/session_credits_balance`)(params);
