import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { OpportunityThumbnail } from '#src/types';

interface Payload {
  init: never;
  success: { opportunities: OpportunityThumbnail[] };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/talents/opportunities/handpicked');
