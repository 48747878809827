import React, { FC } from 'react';
import { OptionProps } from './SearchSelect';
import badgeStyles from './SearchSelectBadge.module.css';
import { Icon } from '../../Index';

interface BadgesProps {
  searchSelectId: string;
  negative: boolean;
  option: OptionProps;
  handleBadgeClick: (event: React.MouseEvent<HTMLButtonElement> & { target: HTMLInputElement }) => void;
}

const Badge: FC<BadgesProps> = ({ searchSelectId, negative, option, handleBadgeClick }) => {
  const classNames = [badgeStyles.badge];
  if (negative) classNames.push(badgeStyles['badge--negative']);
  if (option.checked !== true) classNames.push('hidden');

  return (
    <div key={option.id} className={classNames.join(' ')}>
      <div className={badgeStyles.badge__inner}>
        <button
          id={`${searchSelectId}-badge-${option.id}`}
          data-id={option.id}
          className={`${badgeStyles.badge__button}`}
          onClick={handleBadgeClick}
          tabIndex={option.checked === true ? 0 : -1}
        >
          <div className={`${badgeStyles.badge__button__inner}`}>
            {option.name}
            <Icon.X size={5} aria-label="(remove)" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Badge;
