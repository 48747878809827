import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: never;
  success: {
    openPositions: Array<{ id: number; field: string; title: string; location: string; workableLink: string }>;
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/public/open_positions');
