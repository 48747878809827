import { createAction, PUT, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    totalExperienceId: number;
  };
  success: never;
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(PUT, '/talents/profiles/total_experience');
