import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Step, StepBackButton, StepNextButton } from '../../CurrentStep';
import { useTalentDataController } from '../../TalentDataController';
import { useStepController } from '../../StepControllerContext';
import ExperienceYearsForm from '#components/TalentSharedForms/ExperienceYears/Form';

const ExperienceYears: Step = {
  id: 'experience-years',
  Body: ({
    setNextDisabled,
    setHandleNextClick,
  }: {
    setNextDisabled?: Dispatch<SetStateAction<boolean>>;
    setHandleNextClick?: Dispatch<SetStateAction<() => void>>;
  }) => {
    const { talentData, updateTalentData } = useTalentDataController();
    const { next } = useStepController();

    const [selectedExperienceYears, setSelectedExperienceYears] = useState<number | null>(null);

    // Set the selected experience years based on the talent data
    useEffect(() => {
      if (talentData != null) {
        setSelectedExperienceYears(talentData.totalExperienceId);
      }
    }, [talentData]);

    // Prevent the user from moving to the next step if they haven't selected any value
    useEffect(() => {
      if (setNextDisabled != null) {
        setNextDisabled(selectedExperienceYears === null);
      }
    }, [selectedExperienceYears, setNextDisabled]);

    useEffect(() => {
      const handleNext = async () => {
        if (talentData == null) {
          return;
        }

        const newTalentData = { ...talentData, totalExperienceId: selectedExperienceYears };

        const result = await updateTalentData(newTalentData, 'experience_timeline');

        if (result.success === true) {
          next();
        }
      };

      if (setHandleNextClick != null) {
        setHandleNextClick(() => handleNext);
      }
    }, [setHandleNextClick, selectedExperienceYears, talentData, next, updateTalentData]);

    return (
      <ExperienceYearsForm
        setSelectedExperienceYears={setSelectedExperienceYears}
        selectedExperienceYears={selectedExperienceYears}
      />
    );
  },
  Footer: ({ nextDisabled, onNextClick }: { nextDisabled?: boolean; onNextClick?: () => void }) => (
    <>
      <StepBackButton />
      <StepNextButton disabled={nextDisabled} onClick={onNextClick} />
    </>
  ),
};

export default ExperienceYears;
