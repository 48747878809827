import { uniqueId } from 'lodash-es';
import React, { FC, ReactElement, SVGProps } from 'react';

// Fetching the correct type for the colors object from tailwindcss was needlessly complex
// @ts-expect-error
import colors from '../../styles/colors';

interface FillColorProp {
  color?: string;
}

interface SegmentProps extends FillColorProp {
  x: number;
  y: number;
  rotate: -90 | 0 | 90 | 180 | 270;
  radius: 0.5 | 1 | 1.5;
  className?: string;
}

const segmentDefaults: SegmentProps = {
  x: 1,
  y: 1,
  rotate: 0,
  radius: 1,
};

const getFillColor = (color: string | undefined) => {
  if (color !== undefined) {
    return color.split('-').reduce((accumulator, currentValue) => {
      if (accumulator === undefined) {
        console.error(`Invalid color for CircleGraphic: "${color}"`);
        return undefined;
      }

      return accumulator[currentValue];
    }, colors);
  }
};

const getPathTransform = (props: Partial<SegmentProps>) => {
  const { x, y, rotate } = { ...segmentDefaults, ...props };
  const transformTranslate =
    x === segmentDefaults.x && y === segmentDefaults.y ? '' : `translate(${(x - 1) * 2} ${(y - 1) * 2})`;
  const transformRotate = rotate === segmentDefaults.rotate ? '' : `rotate(${rotate} 1 1)`;
  const transformString = [transformTranslate, transformRotate].filter(Boolean).join('');

  return transformString !== '' ? transformString : undefined;
};

const Circle: FC<Partial<SegmentProps>> = (props) => {
  const { className, x, y, radius, color } = { ...segmentDefaults, ...props };

  return (
    <circle
      className={className}
      fill={getFillColor(color)}
      cx={(x - 1) * 2 + radius * 2}
      cy={(y - 1) * 2 + radius * 2}
      r={radius * 2}
    />
  );
};

const CircleImage: FC<{ src: string; alt: string; preserveAspectRatio?: string } & Partial<SegmentProps>> = (props) => {
  const { radius, src, alt, preserveAspectRatio = 'xMidYMid slice' } = { ...segmentDefaults, ...props };

  const id = uniqueId('circle-image-');

  return (
    <g transform={getPathTransform(props)}>
      <defs>
        <clipPath id={id}>
          <circle cx={radius * 2} cy={radius * 2} r={radius * 2} />
        </clipPath>
      </defs>
      <image
        clipPath={`url(#${id})`}
        width={radius * 4}
        height={radius * 4}
        xlinkHref={src}
        role="img"
        aria-label={alt}
        preserveAspectRatio={preserveAspectRatio}
      />
    </g>
  );
};

const Half: FC<Partial<SegmentProps>> = (props) => (
  <path
    className={props.className}
    fill={getFillColor(props.color)}
    transform={getPathTransform(props)}
    d="M0 0a2 2 0 0 0 4 0Z"
  />
);

const Quarter: FC<Partial<SegmentProps>> = (props) => (
  <path
    className={props.className}
    fill={getFillColor(props.color)}
    transform={getPathTransform(props)}
    d="M0 0a2 2 0 0 0 2 2v-2Z"
  />
);

interface CircleGraphicProps extends Partial<FillColorProp> {
  cols?: number;
  rows?: number;
  children: ReactElement<SegmentProps> | Array<ReactElement<SegmentProps>>;
}

const CircleGraphic: FC<CircleGraphicProps & SVGProps<SVGSVGElement>> = ({ cols, rows, color, children, ...args }) => {
  const xMax: number =
    cols ??
    (Array.isArray(children)
      ? Math.max(...children.map((s) => s.props.x ?? segmentDefaults.x))
      : children.props.x ?? segmentDefaults.x);

  const yMax: number =
    rows ??
    (Array.isArray(children)
      ? Math.max(...children.map((s) => s.props.y ?? segmentDefaults.y))
      : children.props.y ?? segmentDefaults.y);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${xMax * 2} ${yMax * 2}`}
      width={xMax * 100}
      height={yMax * 100}
      fill={color !== undefined ? getFillColor(color) : undefined}
      aria-hidden="true" // Assume all CircleGraphics are screen-reader-hidden. Overridden with aria-hidden="false"
      {...args}
    >
      {children}
    </svg>
  );
};

export default Object.assign(CircleGraphic, {
  Half,
  Quarter,
  Circle,
  CircleImage,
});
