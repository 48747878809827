import React, { FC, useState } from 'react';
import { OpportunitySearchParams } from '#src/types';
import { useGetOpportunityFilterOptions } from './data/FilterOptionsData';
import OpportunityFilters from './elements/OpportunityFilters';
import { NoMatchingOpportunities } from './AllOpportunities';
import { OpportunitiesList } from './elements/OpportunitiesList';
import { useGetFilteredOpportunities } from './data/FilteredOpportunitiesData';

const emptySearchState = { filters: { bookmarked: true }, keywords: [] };

const Bookmarked: FC = () => {
  const [search, setSearch] = useState<OpportunitySearchParams>(emptySearchState);

  const { data: filterOptions } = useGetOpportunityFilterOptions();
  const { data: opportunitiesData, isLoading, refetch } = useGetFilteredOpportunities(search);

  return (
    <div className="flex flex-col lg:flex-row gap-x-8 px-4">
      <div className="basis-1/4">
        <OpportunityFilters
          search={search}
          setSearch={setSearch}
          regions={filterOptions?.regions ?? []}
          firms={filterOptions?.firms ?? []}
          workPolicies={filterOptions?.workPolicies ?? []}
          contractTypes={filterOptions?.contractTypes ?? []}
        />
      </div>
      <OpportunitiesList
        isLoading={isLoading}
        opportunities={opportunitiesData?.opportunities ?? []}
        emptyState={<NoMatchingOpportunities clearSearch={() => setSearch(emptySearchState)} />}
        refetchOpportunities={refetch}
      />
    </div>
  );
};

export default Bookmarked;
