import { useQuery } from 'react-query';
import { DateTime } from 'luxon';

import { ReactQueryOptions } from '#src/types';
import * as apiGetCalendarAvailability from '#api/endpoints/coaches/getCalendarAvailability';

export enum KeysEnum {
  coaches = 'coaches',
  bookingSession = 'booking-session',
  availability = 'availability',
}

type GetApiPayload = apiGetCalendarAvailability.Type['success']['payload'];
export const useCalendarAvailability = (
  coachSlug: string,
  fromDateTime: DateTime,
  options?: ReactQueryOptions<GetApiPayload>
) =>
  useQuery<GetApiPayload>(
    [KeysEnum.coaches, coachSlug, KeysEnum.bookingSession, KeysEnum.availability],
    async () => {
      return await apiGetCalendarAvailability.request({
        slug: coachSlug,
        startDateUtc: fromDateTime.toUTC().toISO() as string,
        endDateUtc: fromDateTime.endOf('month').toUTC().toISO() as string,
      });
    },
    {
      ...options,
    }
  );
