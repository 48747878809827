import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './elements/Header';
import Consulting from './elements/Consulting';
import Coaching from './elements/Coaching';
import Bundles from '../../coaches/index/elements/Bundles';
import Resources from './elements/Resources';
import ReadyToStart from './elements/ReadyToStart';

const InterviewPrepSite: FC = () => {
  return (
    <>
      <Helmet>
        <title>Hiperpool - Coaching</title>
        <meta
          name="description"
          content="Hiperpool's Interview Prep offers personal coaching and exclusive content from top interviewers at firms like McKinsey, BCG, and Bain, starting at just $150 per session. Don't risk going unprepared – whether you're new or experienced in consulting, our top coaches provide up-to-date insights and exclusive resources to ensure you excel. Secure your future by investing in coaching that adapts to your schedule and equips you to land your dream job."
        />
      </Helmet>
      <Header />
      <Coaching />
      <Consulting />
      {/* FIXME: There should be PersonalizedSessions component, but it is broken when any of the coaches has more than 2 companies */}
      <div className="bg-gray-50">
        <Bundles />
        <Resources />
      </div>
      <ReadyToStart />
    </>
  );
};

export default InterviewPrepSite;
