import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors, Job } from '#root/src/types';

interface Payload {
  init: never;
  success: {
    jobs: Job[];
  };
  fail: {
    errors: Errors<'base'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async () => await createAction<Payload>(GET, `/talents/activities/dummy`)();
