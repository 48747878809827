import * as apiGetListOfFeaturedPosts from '#api/endpoints/posts/getFeaturedList';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  homepage = 'homepage',
  candidate = 'candidate',
  featuredPosts = 'featured-posts',
}

type GetApiPayload = apiGetListOfFeaturedPosts.Type['success']['payload'];
export const useGetFeaturedPosts = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.homepage, KeysEnum.candidate, KeysEnum.featuredPosts],
    async () => await apiGetListOfFeaturedPosts.request(),
    {
      ...options,
    }
  );
