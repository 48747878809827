import * as apiGetIndustryTypes from '#api/endpoints/onboarding/industryTypes';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  industryTypes = 'industry-types',
}

type GetApiPayload = apiGetIndustryTypes.Type['success']['payload'];
export const useGetIndustryTypes = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>([KeysEnum.industryTypes], async () => await apiGetIndustryTypes.request(), {
    ...options,
  });
