import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { merge } from 'lodash-es';

import * as contactAPI from '#api/endpoints/public/contacts';
import { useParseFormErrors } from '#root/src/lib/parseFormsErrors';

export interface FormValues {
  email: string;
  subject: string;
  message: string;
}

export const defaultFormValues: FormValues = {
  email: '',
  subject: '',
  message: '',
};

const validationSchema = object().shape({
  email: string().required('Email is required'),
  subject: string().required('Subject is required'),
  message: string().required('Message is required'),
});

export const useContactUsController = ({ defaultValues }: { defaultValues?: Partial<FormValues> }) => {
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: merge(defaultFormValues, defaultValues),
  });
  const { parseErrors, baseErrors } = useParseFormErrors<contactAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    return await contactAPI.request({ contact: values }).catch(parseErrors);
  };

  return { form: formMethods, submitForm, baseErrors };
};
