import { useQuery } from 'react-query';

import { ReactQueryOptions } from '#src/types';
import * as apiCheckExpirationOfToken from '#api/endpoints/session/checkExpirationOfToken';

export enum KeysEnum {
  session = 'session',
  token = 'token',
  expiration = 'expiration',
}

type GetApiPayload = apiCheckExpirationOfToken.Type['success']['payload'];
export const useCheckTokenExpiration = (
  params: { token: string | undefined | null },
  options?: ReactQueryOptions<GetApiPayload>
) => {
  const { token } = params;
  if (token == null) {
    throw new Error('Token needs to be provided');
  }
  return useQuery<GetApiPayload>(
    [KeysEnum.session, KeysEnum.token, KeysEnum.expiration, params.token],
    async () => await apiCheckExpirationOfToken.request({ token }),
    {
      ...options,
    }
  );
};
