/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { uniqueId } from 'lodash-es';
import styles from './MiniSelect.module.css';

interface Props extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  label: string;
  title?: string;
  selectClassName?: string;
}
const MiniSelect = forwardRef<HTMLSelectElement, Props>(
  ({ label, title, className = '', selectClassName = '', ...rest }, ref) => {
    const uniqId = uniqueId('MiniSelect');

    return (
      <div className={`grid gap-1 ${className}`} title={title}>
        <label htmlFor={uniqId} className="text-xs font-medium leading-snug text-gray-700">
          {label}
        </label>
        <select {...rest} id={uniqId} className={[styles.select, selectClassName].join(' ')} ref={ref}></select>
      </div>
    );
  }
);

export default MiniSelect;
