import React, { FC } from 'react';
import { useGetProfileDetails } from './data/ProfileDetails';
import { Spinner } from '#components/Index';
import NotFound from '#pages/NotFound';
import EmployerViewInfo from './elements/EmployerViewInfo';
import ProfileForEmployer from './elements/ProfileForEmployer';

const EmployerViewProfile: FC = () => {
  const { data, isLoading } = useGetProfileDetails();

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  const { profile } = data;

  return (
    <div>
      <EmployerViewInfo />
      <ProfileForEmployer profile={profile} />
    </div>
  );
};

export default EmployerViewProfile;
