import React, { FC } from 'react';

import SignUpForm from './Form';
import Layout from '../Layout';

const SignUp: FC = () => {
  return (
    <Layout
      header="Hire the best for your team"
      subHeader="We will reach out to discuss your hiring needs and how we can help"
    >
      <SignUpForm />
    </Layout>
  );
};

export default SignUp;
