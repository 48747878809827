import React, { FC, useEffect, useState, useCallback } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { request as apiUpdateWorkInterestSituation } from '#api/endpoints/talents/profile/updateWorkInterestSituation';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { useSteps } from '../../BaseProfileEditModal';
import CurrentSituationForm from '#components/TalentSharedForms/CurrentSituation/Form';

const JobSeekingStatusStep: FC = () => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { setStepCallback, setNextDisabled } = useSteps();

  const [selectedWorkInterestSituationType, setSelectedWorkInterestSituationType] = useState<number | null>(null);

  // Set the selected work interest situation type based on the talent data
  useEffect(() => {
    if (profilePreferencesData == null) return;

    setSelectedWorkInterestSituationType(profilePreferencesData.profilePreferences.workInterestSituationId);
  }, [profilePreferencesData, setSelectedWorkInterestSituationType]);

  // Prevent the user from moving to the next step if they haven't selected any value
  useEffect(() => {
    setNextDisabled(selectedWorkInterestSituationType === null);
  }, [selectedWorkInterestSituationType, setNextDisabled]);

  const stepCallback = useCallback(async () => {
    if (selectedWorkInterestSituationType == null) return false;

    try {
      await apiUpdateWorkInterestSituation({ workInterestSituationId: selectedWorkInterestSituationType });
      createSuccessToast({ title: 'Job seeking status' });
      return true;
    } catch {
      createErrorToast({ title: 'Job seeking status' });
      return false;
    }
  }, [selectedWorkInterestSituationType]);

  useEffect(() => {
    setStepCallback(() => stepCallback);
  }, [stepCallback, setStepCallback]);

  return (
    <CurrentSituationForm
      setSelectedWorkInterestSituationType={setSelectedWorkInterestSituationType}
      selectedWorkInterestSituationType={selectedWorkInterestSituationType}
    />
  );
};

export default JobSeekingStatusStep;
