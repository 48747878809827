import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { request as apiUpdateIndustryTypesExperience } from '#api/endpoints/talents/profile/industryTypes/updateIndustryTypesExperience';
import { useSteps } from '../../BaseProfileEditModal';
import { OptionProps } from '#components/Index';
import ExperienceIndustriesForm from '#components/TalentSharedForms/ExperienceIndustries/Form';

const ExperienceIndustriesStep: FC = () => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { setStepCallback, setNextDisabled } = useSteps();

  const [options, setOptions] = useState<OptionProps[]>([]);
  const initiallySelectedIds = useMemo(() => {
    return (
      profilePreferencesData?.profilePreferences.experienceIndustryTypes.map((industryType) => industryType.id) ?? []
    );
  }, [profilePreferencesData]);

  // Prevent the user from moving to the next step if they haven't selected any value
  useEffect(() => {
    setNextDisabled(options.filter((option) => option.checked).length === 0);
  }, [options, setNextDisabled]);

  const stepCallback = useCallback(async () => {
    const checkedOptions = options.filter((option) => option.checked);
    if (checkedOptions.length === 0) return false;

    try {
      await apiUpdateIndustryTypesExperience({
        industryTypeIds: checkedOptions.map((option) => Number(option.id)),
      });
      createSuccessToast({ title: 'Experience industries' });
      return true;
    } catch {
      createErrorToast({ title: 'Experience industries' });
      return false;
    }
  }, [options]);

  useEffect(() => {
    setStepCallback(() => stepCallback);
  }, [setStepCallback, stepCallback]);

  return (
    <ExperienceIndustriesForm options={options} setOptions={setOptions} initiallySelectedIds={initiallySelectedIds} />
  );
};

export default ExperienceIndustriesStep;
