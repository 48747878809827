import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircleGraphic, Button, Icon } from '#src/components/Index';
import { paths } from '#src/pages/paths';

const Header: FC<{ withLink?: boolean }> = ({ withLink = false }) => {
  const navigate = useNavigate();

  const goBackOrDefault = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate(paths.talentOpportunities());
    }
  };

  return (
    <div>
      {withLink && (
        <Button variant="link" onClick={goBackOrDefault} className="text-white absolute left-10 md:left-20 xl:left-30">
          <Icon.ArrowLeftLine size={4} aria-hidden="true" className="shrink-0" />
          Back to My Jobs
        </Button>
      )}

      <div className="flex justify-between max-w-[100vw]">
        <CircleGraphic>
          <CircleGraphic.Circle x={1} y={1} color="green-500" radius={0.5} />
          <CircleGraphic.Quarter x={2} y={1} color="green-900" rotate={-90} />
          <CircleGraphic.Circle x={4} y={1} color="green-900" radius={0.5} />

          <CircleGraphic.Quarter x={1} y={2} color="green-900" rotate={-90} />
          <CircleGraphic.Quarter x={2} y={2} color="green-500" rotate={180} />
          <CircleGraphic.Quarter x={3} y={2} color="green-500" rotate={90} />

          <CircleGraphic.Quarter x={1} y={3} color="green-500" rotate={0} />
          <CircleGraphic.Quarter x={2} y={3} color="green-900" rotate={-90} />
          <CircleGraphic.Quarter x={3} y={3} color="green-900" rotate={90} />
        </CircleGraphic>

        <CircleGraphic className="opacity-20">
          <CircleGraphic.Quarter x={1} y={1} color="green-900" rotate={0} />
          <CircleGraphic.Quarter x={2} y={1} color="green-900" rotate={-90} />
          <CircleGraphic.Quarter x={3} y={1} color="green-500" rotate={180} />
          <CircleGraphic.Quarter x={4} y={1} color="green-500" rotate={90} />

          <CircleGraphic.Quarter x={2} y={2} color="green-500" rotate={0} />
          <CircleGraphic.Quarter x={3} y={2} color="green-900" rotate={-90} />
          <CircleGraphic.Quarter x={4} y={2} color="green-900" rotate={90} />

          <CircleGraphic.Quarter x={4} y={3} color="green-900" rotate={0} />
        </CircleGraphic>
      </div>
    </div>
  );
};

export default Header;
