import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

const Cookies: FC = () => {
  return (
    <>
      <Helmet>
        <title>Hiperpool - Cookies</title>
      </Helmet>
      <div className="px-variable-md py-16">
        <div className="max-w-screen-sm mx-auto text-sm rich-text rich-text-lists rich-text-tables">
          <h1 className="text-display-md font-serif mb-8">Cookie Policy</h1>
          <p>Our Website uses cookies and similar technologies.</p>
          <p>
            <strong>Cookies</strong> are small text files that are downloaded to your device. Cookies contain uniquely
            generated references which are used to distinguish you from other users. They allow information gathered on
            one webpage to be stored until it is needed for use on another, allowing our Website to provide you with a
            personalised experience (like remembering your favourites) and provide us with statistics about how you
            interact with our Website.
          </p>
          <p>
            Cookies are not harmful to your devices (like a virus or malicious code) but some individuals prefer not to
            share their information (for example, to avoid targeted advertising).
          </p>

          <h3>Different types of cookies</h3>
          <p>
            <strong>Session vs. persistent cookies:</strong> cookies have a limited lifespan. Cookies which only last a
            short time or end when you close your browser are called <strong>session cookies</strong>. Cookies which
            remain on your device for longer are called <strong>persistent cookies</strong> (these are the type of
            cookies allow websites to remember your details when you log back onto them).
          </p>
          <p>
            <strong>First party vs third party cookies:</strong> cookies placed on your device by the website owner are
            called <strong>first party</strong> cookies. When the website owner uses other businesses’ technology to
            help them manage and monitor their website, the cookies added by the other business are called{' '}
            <strong>third party cookies</strong>.
          </p>
          <p>
            <strong>Categories of cookies:</strong> cookies can be grouped by what they help the website or website
            owner do (the <strong>Purpose</strong>).
          </p>
          <ul>
            <li>
              <strong>Necessary cookies</strong> are cookies which help the Website to run properly (when they are
              strictly necessary cookies it means their only function is to help the website work).
            </li>
            <li>
              <strong>Performance cookies</strong> help a Website owner understand and analyse how Website visitors use
              their website.
            </li>
            <li>
              <strong>Analytical cookies</strong> are used to understand how visitors interact with the Website. These
              cookies help provide information on metrics the number of visitors, bounce rate, etc.
            </li>
            <li>
              <strong>Marketing cookies</strong> tailor online adverts to reflect the content you have previously browse
              and help inform companies about your interests so they can show you relevant adverts.
            </li>
          </ul>

          <h3>What do we use cookies for?</h3>
          <p>We use cookies to:</p>
          <ul className="!mt-0">
            <li>to track how visitors use our Website</li>
            <li>to record whether you have seen specific messages we display on our Website</li>
            <li>to keep you signed into our Website</li>
            <li>
              where we post content and links to content, we use cookies to capture and analyse information such as
              number of views and shares
            </li>
          </ul>
          <p>The cookies we use are:</p>

          <div className="w-0 min-w-full overflow-x-auto">
            {/* Cookie info generated & copy-pasted from https://www.cookieserve.com/scan-summary/?url=hiperpool.com */}
            <table>
              <thead>
                <tr>
                  <th>Cookie</th>
                  <th>Domain</th>
                  <th>Description</th>
                  <th>Duration</th>
                  <th>Type</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className="break-all">_recruitd_session</td>
                  <td>hiperpool.com</td>
                  <td>No&nbsp;description</td> {/* TODO: Give this cookie a description */}
                  <td>session</td>
                  <td>Other</td>
                </tr>

                <tr>
                  <td>_ga_*</td>
                  <td>hiperpool.com</td>
                  <td>Google Analytics sets this cookie to store and count page views.</td>
                  <td>1&nbsp;year 1&nbsp;month 4&nbsp;days</td>
                  <td>Analytics</td>
                </tr>

                <tr>
                  <td>_ga</td>
                  <td>hiperpool.com</td>
                  <td>
                    The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and
                    also keeps track of site usage for the site&apos;s analytics report. The cookie stores information
                    anonymously and assigns a randomly generated number to recognize unique visitors.
                  </td>
                  <td>1&nbsp;year 1&nbsp;month 4&nbsp;days</td>
                  <td>Analytics</td>
                </tr>

                <tr>
                  <td className="break-all">UserMatchHistory</td>
                  <td>linkedin.com</td>
                  <td>LinkedIn sets this cookie for LinkedIn Ads ID syncing.</td>
                  <td>1 month</td>
                  <td>Functional</td>
                </tr>

                <tr>
                  <td className="break-all">AnalyticsSyncHistory</td>
                  <td>linkedin.com</td>
                  <td>
                    LinkedIn set this cookie to store information about the time a sync took place with the
                    lms_analytics cookie.
                  </td>
                  <td>1 month</td>
                  <td>Analytics</td>
                </tr>

                <tr>
                  <td>bcookie</td>
                  <td>linkedin.com</td>
                  <td>LinkedIn sets this cookie from LinkedIn share buttons and ad tags to recognize browser ID.</td>
                  <td>1 year</td>
                  <td>Functional</td>
                </tr>

                <tr>
                  <td>lidc</td>
                  <td>linkedin.com</td>
                  <td>LinkedIn sets the lidc cookie to facilitate data center selection.</td>
                  <td>1 day</td>
                  <td>Functional</td>
                </tr>

                <tr>
                  <td>__rafm</td>
                  <td>hiperpool.com</td>
                  <td>No&nbsp;description available.</td>
                  <td>session</td>
                  <td>Other</td>
                </tr>

                <tr>
                  <td>ln_or</td>
                  <td>hiperpool.com</td>
                  <td>
                    LinkedIn sets this cookie to registers statistical data on users&apos; behaviour on the website for
                    internal analytics.
                  </td>
                  <td>1 day</td>
                  <td>Analytics</td>
                </tr>

                <tr>
                  <td>bscookie</td>
                  <td>linkedin.com</td>
                  <td>LinkedIn sets this cookie to store performed actions on the website.</td>
                  <td>1 year</td>
                  <td>Functional</td>
                </tr>

                <tr>
                  <td>li_gc</td>
                  <td>linkedin.com</td>
                  <td>
                    LinkedIn set this cookie for storing visitor&apos;s consent regarding using cookies for
                    non-essential purposes.
                  </td>
                  <td>6 months</td>
                  <td>Functional</td>
                </tr>

                <tr>
                  <td>__rasesh</td>
                  <td>hiperpool.com</td>
                  <td>No&nbsp;description available.</td> {/* TODO: Give this cookie a description */}
                  <td>1&nbsp;year 1&nbsp;month 4&nbsp;days</td>
                  <td>Other</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>
            We can only use cookies with your permission (you will be prompted by a message when you first visit our
            Website, also known as a cookie banner, where you can choose to accept or decline our cookies).
          </p>
          <p>
            You can choose to decline cookies but if you turn off necessary cookies, some pages and functions on our
            Website may not work properly. You can also manage cookies through your browser settings or device settings
            (your user manual should contain additional information).
          </p>
          <p>
            You can also delete cookies directly with the relevant third parties (for example, you can disable Google
            Analytics on their website).
          </p>
        </div>
      </div>
    </>
  );
};

export default Cookies;
