import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import ContactForm from './ContactForm';
import { CircleGraphic } from '#components/Index';
import { paths } from '#pages/paths';

export enum Source {
  interviewPrep = 'interview-prep',
}

const ContactUs: FC = () => {
  return (
    <div className="relative isolate bg-hpblack sm:min-h-full-content h-full flex items-start justify-center">
      <CircleGraphic className="absolute bottom-0 right-0 -z-10 overflow-hidden opacity-20">
        <CircleGraphic.Quarter x={2} y={2} color="green-500" rotate={90} />
        <CircleGraphic.Half x={3} y={1} color="green-900" rotate={90} />
        <CircleGraphic.Circle x={4} y={2} color="green-500" radius={0.5} />
        <CircleGraphic.Quarter x={2} y={3} color="green-900" rotate={0} />
        <CircleGraphic.Quarter x={3} y={3} color="green-500" rotate={-90} />
        <CircleGraphic.Quarter x={4} y={3} color="green-900" rotate={0} />
        <CircleGraphic.Half x={2} y={4} color="green-900" rotate={180} />
        <CircleGraphic.Quarter x={3} y={4} color="green-500" rotate={180} />
      </CircleGraphic>

      <div className="w-full max-w-xl bg-gray-50 2xs:my-16 p-10 max-2xs:py-16 max-2xs:px-variable-lg 2xs:rounded-lg grid gap-6 2xs:mx-variable-lg max-2xs:border-b">
        <ContactForm
          title="Get in touch"
          description={
            <>
              Have a question that’s not in{' '}
              <Link to={paths.supportForCandidate()} className="link">
                our FAQs
              </Link>
              ? Send us a message below and a member of our team will get back to you shortly
            </>
          }
        />
      </div>
    </div>
  );
};

export default ContactUs;
