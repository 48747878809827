import React, { FC, useState } from 'react';
import { TalentPreferences } from '#src/types';
import { FormContentGroup } from '#components/Index';
import CompaniesModal from './modals/CompaniesModal';
import MissingAnswerCard from './MissingAnswerCard';
import ProfileBadge from '../../elements/ProfileBadge';

interface CompensationAndCompaniesProps {
  profilePreferences: TalentPreferences;
}

const Companies: FC<CompensationAndCompaniesProps> = ({ profilePreferences }) => {
  const [isCompaniesModalOpen, setCompaniesModalOpen] = useState(false);

  const { companyPreferences, unpreferredCompanies } = profilePreferences;

  const companyPreferencesOrMissingCard = () => {
    if (companyPreferences.length === 0) return <MissingAnswerCard onClick={() => setCompaniesModalOpen(true)} />;

    return (
      <div className="flex flex-wrap gap-2">
        {companyPreferences.map((company) => (
          <ProfileBadge key={company.id}>{company.name}</ProfileBadge>
        ))}
      </div>
    );
  };

  const unpreferredCompaniesOrMissingCard = () => {
    if (unpreferredCompanies.length === 0) return <MissingAnswerCard onClick={() => setCompaniesModalOpen(true)} />;

    return (
      <div className="flex flex-wrap gap-2">
        {unpreferredCompanies.map((company) => (
          <ProfileBadge key={company.id} withoutIcon negative>
            {company.name}
          </ProfileBadge>
        ))}
      </div>
    );
  };

  return (
    <FormContentGroup
      title="Companies"
      groupName="Preferences"
      description="Are there any companies you're particularly interested in or want to avoid?"
      editSectionHandler={() => setCompaniesModalOpen(!isCompaniesModalOpen)}
    >
      <CompaniesModal isOpen={isCompaniesModalOpen} close={() => setCompaniesModalOpen(false)} />

      <div className="flex flex-col gap-y-6">
        <div>
          <h5 className="text-gray-500 text-sm pb-2">Companies I’m interested in</h5>
          {companyPreferencesOrMissingCard()}
        </div>

        <div>
          <h5 className="text-gray-500 text-sm pb-2">
            Companies I’m <span className="font-semibold text-red-700">not</span> interested in
          </h5>
          {unpreferredCompaniesOrMissingCard()}
        </div>
      </div>
    </FormContentGroup>
  );
};

export default Companies;
