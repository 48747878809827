import React, { FC, useEffect, useState, useCallback } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { request as apiUpdateExpectedSalary } from '#api/endpoints/talents/profile/answers/updateExpectedSalary';
import { useSteps } from '../../BaseProfileEditModal';
import { TextInput } from '#components/Form/Index';

const SalaryStep: FC = () => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { setStepCallback, setNextDisabled } = useSteps();

  const [salary, setSalary] = useState<string>('');

  useEffect(() => {
    if (profilePreferencesData != null) {
      setSalary(profilePreferencesData.profilePreferences.salary);
    }
  }, [profilePreferencesData]);

  const stepCallback = useCallback(async () => {
    if (salary.trim() === '') return false;

    try {
      await apiUpdateExpectedSalary({ answer: { answer: salary } });
      createSuccessToast({ title: 'Compensation' });
      return true;
    } catch {
      createErrorToast({ title: 'Compensation' });
      return false;
    }
  }, [salary]);

  useEffect(() => {
    setNextDisabled(salary.trim() === '');
  }, [setNextDisabled, salary]);

  useEffect(() => {
    setStepCallback(() => stepCallback);
  }, [setStepCallback, stepCallback]);

  return (
    <div className="rich-text text-hpblack w-full">
      <h1>What are your salary expectations?</h1>
      <TextInput
        label=""
        value={salary}
        onChange={(e) => setSalary(e.target.value)}
        placeholder="Type your desired compensation (e.g., combination of salary, equity, bonus, etc.)"
      />
    </div>
  );
};

export default SalaryStep;
