import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Job } from '#src/types';
import { useGetJobsList } from './data/JobsData';
import JobsList from './elements/navigation/JobsList';
import JobsFilters from './elements/navigation/JobsFilters';
import Sidebar from './elements/sidebar/Sidebar';
import EmptyJobsContent from './elements/content/EmptyJobsContent';
import JobContent from './elements/content/JobContent';
import { Spinner } from '#src/components/Index';
import { ErrorPage } from '#pages/root/ErrorPage';

const MyActivity: FC = () => {
  const [searchParams] = useSearchParams();
  const [jobsListOpenOnMobile, setJobsListOpenOnMobile] = useState(true); // On mobile we want to display navigation OR content
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const { data, isLoading } = useGetJobsList({ filter: selectedFilter });

  const displayJobDetails = useCallback((job: Job) => {
    setSelectedJob(job);
    setJobsListOpenOnMobile(false);
  }, []);

  const jobsListContainerClasses = useMemo(() => {
    return jobsListOpenOnMobile ? 'flex' : 'hidden lg:flex';
  }, [jobsListOpenOnMobile]);

  const mainContentClasses = useMemo(() => {
    return jobsListOpenOnMobile ? 'hidden lg:flex' : 'flex';
  }, [jobsListOpenOnMobile]);

  const opportunityIdFromParams = useMemo(() => {
    if (searchParams.get('opportunityId') == null) return null;

    return parseInt(searchParams.get('opportunityId') ?? '');
  }, [searchParams]);

  // Initial job selection
  useEffect(() => {
    if (data == null || selectedJob != null) return;

    // If there is no selected job and there is job with opportunityId matching the one from the params - select it
    if (opportunityIdFromParams != null) {
      const jobFromParams = data.jobs.find((job) => job.opportunity.id === opportunityIdFromParams);

      if (jobFromParams != null) {
        setSelectedJob(jobFromParams);
        return;
      }
    }

    // Automatically selects the first job from the list
    if (selectedJob == null) {
      setSelectedJob(data.jobs[0]);
    }
  }, [opportunityIdFromParams, selectedJob, data]);

  // Update the selected job after the data is re-fetched
  useEffect(() => {
    if (data == null || selectedJob == null) return;

    const selectedJobFromData = data.jobs.find((job) => job.id === selectedJob.id);

    // Select the first job if the previously selected job is not in the list of jobs (e.g. after filters changed)
    if (selectedJobFromData == null) {
      setSelectedJob(data.jobs[0]);
      return;
    }

    // Update the selected job if its data has changed (e.g. after a new message)
    if (selectedJobFromData !== selectedJob) {
      setSelectedJob(selectedJobFromData);
    }
  }, [selectedJob, data]);

  const activityContent = useMemo(() => {
    if (isLoading) {
      return <Spinner className="min-h-full" />;
    }

    if (selectedJob != null) {
      return <JobContent selectedJob={selectedJob} setJobsListOpenOnMobile={setJobsListOpenOnMobile} />;
    }

    return <EmptyJobsContent currentFilter={selectedFilter} />;
  }, [selectedJob, selectedFilter, isLoading]);

  if (!isLoading && data == null) {
    return <ErrorPage className="min-h-full flex flex-col justify-center" />;
  }

  return (
    <>
      <div className="flex flex-col lg:flex-row">
        <div
          className={`bg-white border-r border-gray-300 min-h-full-content flex-col lg:basis-1/5 ${jobsListContainerClasses}`}
        >
          <div className="px-4 lg:hidden">
            <h3 className="text-display-xs font-serif mt-4">My active jobs</h3>
          </div>

          <JobsFilters selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
          <JobsList jobs={data?.jobs} setSelectedJob={displayJobDetails} />
        </div>

        <main className={`flex-col lg:basis-3/5 ${mainContentClasses}`}>{activityContent}</main>

        <div className="bg-white border-l border-gray-300 p-4 flex-col hidden lg:flex lg:basis-1/5 lg:min-h-full-content">
          <Sidebar selectedJob={selectedJob} isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default MyActivity;
