import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: never;
  success: {
    employmentBranches: Array<{
      id: number;
      name: string;
      children: Array<{
        id: number;
        name: string;
        parent: string; // Parent must match containing branch name
      }>;
    }>;
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/public/employment_branches');
