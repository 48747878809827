import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { CircleGraphic, Icon } from '#components/Index';
import { paths } from '#pages/paths';

const ImageContent = ({ image, altText }: { image?: string; altText: string }) => (
  <div className="grid grid-cols-2 relative isolate max-w-[442px] m-auto">
    <img
      loading="eager"
      src={image}
      height="auto"
      width="200"
      className="rounded-full max-w-[100%] self-center isolate translate-x-1/2 z-[1] object-contain"
      alt={altText}
    />
    <CircleGraphic rows={5} className="opacity-25 max-w-[100%] h-full self-center max-h-[290px]">
      <CircleGraphic.Quarter x={1} y={1} color="hpblack" rotate={180} />
      <CircleGraphic.Quarter x={2} y={1} color="green-900" rotate={90} />
      <CircleGraphic.Circle x={3} y={1} color="green-500" radius={0.5} />
      <CircleGraphic.Quarter x={1} y={2} color="green-500" rotate={0} />
      <CircleGraphic.Quarter x={2} y={2} color="hpblack" rotate={-90} />
      <CircleGraphic.Quarter x={3} y={2} color="hpblack" rotate={-90} />
      <CircleGraphic.Quarter x={2} y={3} color="green-500" rotate={0} />
      <CircleGraphic.Quarter x={3} y={3} color="hpblack" rotate={-90} />
      <CircleGraphic.Half x={4} y={3} color="green-900" rotate={-90} />
      <CircleGraphic.Half x={2} y={4} color="green-900" rotate={90} />
    </CircleGraphic>
  </div>
);

interface Props {
  companyImage: string;
  company: string;
  position: string;
  member?: { image?: string; fullName?: string };
}
const Header: FC<Props> = ({ companyImage, position, member, company }) => {
  const isMemberPhoto = member?.image != null && member?.image !== '';
  return (
    <>
      <div className="flex justify-center">
        <div className="py-6 lg:py-8 max-w-screen-lg mx-variable-lg grow">
          <Link to={paths.stories()} className="large-link inline-flex items-center gap-2 mt-1">
            <Icon.ArrowLeftLine size={5} />
            Back to Success Stories
          </Link>
        </div>
      </div>

      <div className="flex justify-center lg:mx-variable-md">
        <div className="bg-gray-50 py-12 max-w-screen-xl relative sm:flex lg:px-16 xl:px-28 max-lg:px-variable-lg w-full">
          <CircleGraphic className="absolute right-0 bottom-0 w-12 h-12">
            <CircleGraphic.Quarter x={1} y={1} color="green-500" rotate={90} />
          </CircleGraphic>
          <div className={isMemberPhoto ? '' : 'flex flex-col justify-center m-auto'}>
            <img
              loading="eager"
              src={companyImage}
              height="100"
              width="auto"
              className="mix-blend-multiply max-h-[100px] m-auto sm:m-0 object-contain"
              alt={`company logo: ${company}`}
            />
            <div className="text-display-xs font-serif lg:text-display-sm xl:text-display-md my-6 text-center sm:text-left">
              {position}
            </div>
          </div>
          {isMemberPhoto && (
            <ImageContent
              image={member?.image}
              altText={member.fullName != null ? `Photo of ${member.fullName}` : ''}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
