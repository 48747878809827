import React, { FC } from 'react';

import BrowseCoaches from '#assets/homepage/interviewPrep/browse-coaches.png';
import PanelTextSection from '../../elements/PanelTextSection';
import ReplicateInterview from '#assets/homepage/interviewPrep/replicate-interview.webp';
import FilteredCoachesButtons from './../elements/FilteredCoachesButtons';

const Coaching: FC = () => {
  return (
    <div className="py-16">
      <h2 className="text-display-md font-serif px-variable-lg text-center">Coaching that fits around your schedule</h2>

      <PanelTextSection
        image={
          <img loading="lazy" src={BrowseCoaches} alt="booking a visit with Alesander R." width="422" height="260" />
        }
        header="Browse coaches & book a time "
        description="Pick a coach and select a time in their calendar that suits you. We will connect you with your coach via email so that you can outline your objectives and share any questions. "
      />

      <PanelTextSection
        className="sm:rtl"
        image={<FilteredCoachesButtons />}
        header={
          <>
            Tailored sessions for each step <br className="sm:max-md:hidden" />
            of your journey
          </>
        }
        description={
          <>
            <p>
              Choose a coaching session that fits your needs. Whether you need guidance on your options and career plan,
              interview prep and practice, or help negotiating an offer, our coaches can help you every step of the way.
            </p>
          </>
        }
      />

      <PanelTextSection
        image={
          <img
            loading="lazy"
            src={ReplicateInterview}
            alt="interview simulation over the webcam"
            width="414"
            height="276"
          />
        }
        header={
          <>
            Replicate a real interview, <br className="sm:max-md:hidden" />
            get personalized feedback
          </>
        }
        description={
          <>
            <p>Each session lasts an hour and usually involves a 45 min mock interview, followed by feedback.</p>
            <p>
              Your experienced coach makes it as realistic as possible, and you’ll leave with a clear idea of exactly
              where your strengths are and where you need to focus.
            </p>
          </>
        }
      />
    </div>
  );
};

export default Coaching;
