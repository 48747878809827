import React, { FC, useMemo, useCallback, ChangeEvent, useState } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { parseErrors } from '#src/lib/parseFormsErrors';
import { TalentProfile } from '#src/types';
import { request as apiUpdateAvatar } from '#api/endpoints/talents/profile/updateAvatar';
import { Button } from '#components/Index';
import { TextInput, RHF } from '#components/Form/Index';
import { useProfileDetailsController } from './FormController';

export interface ProfileDetailsFormProps {
  initialProfile: TalentProfile;
  setEditMode: (editMode: boolean) => void;
}

const ProfileDetailsForm: FC<ProfileDetailsFormProps> = ({ initialProfile, setEditMode }) => {
  const [profile, setProfile] = useState<TalentProfile>(initialProfile); // Local state

  const { form, submitForm } = useProfileDetailsController({
    profile,
    closeEditMode: () => setEditMode(false),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const onSubmit = handleSubmit((values) => submitForm(values));

  const { educations, workHistory, avatarUrl } = profile;

  const employments = useMemo(() => {
    return workHistory.map((workHistoryEntry) => workHistoryEntry.employments).flat();
  }, [workHistory]);

  const handleFileChange = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile == null) return;

    try {
      await apiUpdateAvatar({ avatar: selectedFile });

      setProfile((prevProfile) => ({
        ...prevProfile,
        avatarUrl: URL.createObjectURL(selectedFile),
      }));
      createSuccessToast({ title: 'Avatar' });
    } catch (error) {
      createErrorToast({ title: 'Avatar', text: parseErrors(error).showFirst('avatar') });
    }
  }, []);

  return (
    <form
      onSubmit={onSubmit}
      className="flex flex-col lg:flex-row lg:gap-x-32 lg:gap-y-0 py-8 gap-y-8 relative items-center"
    >
      <div className="lg:absolute right-0 top-8">
        <Button type="submit" className="flex">
          Save changes
        </Button>
      </div>

      <div className="flex justify-center min-w-60 max-w-60 xl:min-w-72 xl:max-w-72">
        <label>
          <input type="file" className="hidden" onChange={handleFileChange} />
          <div className="min-h-60 min-w-60 max-w-60 max-h-60 rounded-full overflow-hidden flex justify-center items-center group hover:cursor-pointer relative">
            <img src={avatarUrl} alt="Profile image" className="min-w-full min-h-full relative" />

            <div className="absolute inset-0 bg-[#101A2C66] opacity-0 group-hover:opacity-100 transition-opacity"></div>

            <div className="text-md px-10 py-3 inline-flex items-center justify-center gap-2 rounded-full leading-tight font-semibold outline-white ring-2 ring-inset ring-white text-white absolute invisible group-hover:visible">
              Change
            </div>
          </div>
        </label>
      </div>

      <div className="flex flex-col gap-y-4 flex-grow">
        <TextInput
          className="max-w-96"
          label="First Name"
          {...register('firstName')}
          error={errors.firstName?.message}
        />

        <TextInput className="max-w-96" label="Last Name" {...register('lastName')} error={errors.lastName?.message} />

        <RHF.Select
          control={control}
          name="currentEmploymentId"
          label="Current Employment"
          options={employments.map((employment) => ({
            text: `${employment.position} at ${employment.company}`,
            value: employment.id,
          }))}
        />

        <RHF.Select
          control={control}
          name="currentEducationId"
          label="Latest Education"
          options={educations.map((education) => ({
            text: `${education.degree} in ${education.field} from ${education.college}, ${education.graduationYear}`,
            value: education.id,
          }))}
        />

        <TextInput className="max-w-96" label="Location" {...register('location')} error={errors.location?.message} />
      </div>
    </form>
  );
};

export default ProfileDetailsForm;
