import { createAction, PUT, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    talent: {
      marketingEmailConsent: boolean;
      emailNotificationFrequency: 'as_they_come' | 'never';
    };
  };
  success: never;
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(PUT, '/talents/settings/notifications');
