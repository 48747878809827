import React, { FC, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { TextInput, PhoneInput, LocationInput, Checkbox } from '#components/Form/Index';
import { Button, ErrorAlert } from '#components/Index';
import { useSignUpController } from './FormController';
import { paths, parseParamsToLinkSearch, signInRedirectUrl } from '#pages/paths';
import ActiveYourAccountMessage from '../../password/activateAccount/Message';

const SignUpForm: FC = () => {
  const [searchParams] = useSearchParams();
  const redirectUrlFromQueryParams = searchParams.get('redirectUrl');

  const { form, submitForm, baseErrors } = useSignUpController();
  const [isNotActivated, setIsNotActivated] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = form;
  const email = watch('email');

  const onSubmit = handleSubmit((values) =>
    submitForm(values).catch((e?: { status?: number }) => {
      if (e != null && e.status === 418) {
        setIsNotActivated(true);
      }
    })
  );

  if (isNotActivated) {
    return <ActiveYourAccountMessage email={email} />;
  }

  return (
    <form onSubmit={onSubmit} className="w-full grid gap-6 text-sm text-gray-700 font-medium">
      <div className="grid sm:grid-cols-2 gap-6">
        <TextInput required label="First name" error={errors.firstName?.message} {...register('firstName')} />
        <TextInput required label="Last name" error={errors.lastName?.message} {...register('lastName')} />
      </div>
      <TextInput
        required
        label="Personal email"
        type="email"
        autoComplete="username"
        description="A personal email is best to make sure you always have access to Hiperpool, throughout your career."
        error={errors.email?.message}
        {...register('email')}
      />
      <TextInput
        required
        label="Choose a password"
        type="password"
        autoComplete="new-password"
        description="Min. 8 characters"
        error={errors.password?.message}
        {...register('password')}
      />
      <LocationInput
        required
        label="Location"
        error={errors.location?.message}
        {...register('location')}
        placeholder="City and Country"
      />
      <PhoneInput
        label="Phone"
        type="tel"
        error={errors.phonePrefix != null ? errors.phonePrefix.message : errors.phone?.message}
        setValue={form.setValue}
        register={form.register}
        {...register('phone')}
      />
      <Checkbox {...register('marketingEmailConsent')}>
        Yes, keep me up to date with career insights, Hiperpool news and new products and services.
      </Checkbox>
      <p>
        By signing up, I agree to Hiperpool’s{' '}
        <Link to={paths.terms()} className="link">
          Terms
        </Link>
        ,{' '}
        <Link to={paths.cookies()} className="link">
          Cookies
        </Link>{' '}
        and{' '}
        <Link to={paths.privacy()} className="link">
          Privacy Policy
        </Link>
        .
      </p>
      <ErrorAlert text={baseErrors} />
      <Button type="submit" className="xs:mx-auto" size="large">
        Get started
      </Button>
      <p className="text-center">
        If you are already a member of Hiperpool{' '}
        <Link
          to={paths.signIn() + parseParamsToLinkSearch({ redirectUrl: signInRedirectUrl(redirectUrlFromQueryParams) })}
          className="link"
        >
          Sign in here
        </Link>
      </p>
    </form>
  );
};

export default SignUpForm;
