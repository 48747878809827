import React, { FC } from 'react';
import { Button, Icon } from '#components/Index';
import WideCard from '../WideCard/WideCard';
import { expiresIn } from '#root/src/lib/sessionTimes';

interface Props {
  name: string;
  coachLink: string;
  image?: React.ReactNode;
  description?: string;
  createdDatetime?: string;
}

const SessionCardPending: FC<Props> = ({ name, coachLink, image, description, createdDatetime }) => {
  return (
    <WideCard
      title={name}
      image={image}
      subsubtitle={description}
      footerContent={
        <>
          <div className="h-8 flex items-center text-gray-500 text-xs md:pl-2">
            Pending ({expiresIn(createdDatetime)})
          </div>
          <div className="grid 2xs:flex gap-y-2 gap-x-4 items-center 2xs:ml-auto max-2xs:w-full">
            {/* TODO: Get cancel link */}
            {/* <Button size="small" to={'placeholder'} variant="secondary" className="relative">
              <Icon.X size={4} aria-hidden="true" className="shrink-0 shrink-0 max-2xs:absolute left-5" /> Cancel booking
            </Button> */}
            <Button size="small" to={coachLink} className="relative">
              Continue booking{' '}
              <Icon.ArrowRightLine size={4} aria-hidden="true" className="shrink-0 max-2xs:absolute right-5" />
            </Button>
          </div>
        </>
      }
    />
  );
};

export default SessionCardPending;
