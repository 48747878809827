import React, { FC } from 'react';
import { Button, HeroBanner, CircleGraphic } from '#components/Index';
import LinkedinSalesSolutions from '#assets/layout/linkedin-sales-solutions.webp';
import CherrydeckPNG from '#assets/layout/cherrydeck.webp';
import { paths } from '#pages/paths';

const HeroBannerTextContent: FC = () => (
  <div className="xl:mt-8">
    <h1 className="text-display-md font-serif lg:text-display-lg xl:text-display-2xl">
      Success stories<span className="text-green-500">.</span>
    </h1>
    <p className="my-7 sm:my-10 lg:text-xl max-w-lg">
      We’re proud to have helped place hundreds of candidates in mission-critical roles for some of the world’s leading
      companies and startups.
    </p>
    <Button to={paths.signUp()}>Join 50,000+ professionals</Button>
  </div>
);

const HeroBannerImageContent: FC = () => (
  <CircleGraphic className="relative sm:-right-[10%] h-full max-h-[336px] self-center max-w-full">
    <CircleGraphic.Quarter x={3} y={1} rotate={-90} color="green-500" />
    <CircleGraphic.CircleImage x={4} y={1} src={CherrydeckPNG} alt="woman in gray sweater sitting at desk" />
    <CircleGraphic.Half x={6} y={1} rotate={90} color="green-900" />
    <CircleGraphic.Quarter x={7} y={1} rotate={180} color="green-500" />
    <CircleGraphic.Circle x={7} y={2} radius={0.5} color="green-500" />
    <CircleGraphic.CircleImage x={1} y={2} radius={1.5} src={LinkedinSalesSolutions} alt="man writing on white paper" />
    <CircleGraphic.Quarter x={4} y={3} rotate={-90} color="green-900" />
    <CircleGraphic.Quarter x={4} y={4} rotate={-90} color="green-500" />
    <CircleGraphic.Half x={5} y={4} rotate={-90} color="green-900" />
  </CircleGraphic>
);

const Header: FC = () => {
  return (
    <HeroBanner imageContent={<HeroBannerImageContent />} textContent={<HeroBannerTextContent />} direction="ltr" />
  );
};

export default Header;
