import React, { FC } from 'react';

import { Button, CircleGraphic } from '#components/Index';
import GuidesPNG from '#assets/homepage/guides.png';
import OpportunitiesPNG from '#assets/homepage/opportunities.png';
import CoachingPNG from '#assets/homepage/coaching.png';
import { paths } from '#root/src/pages/paths';
import PanelTextSection from '../../elements/PanelTextSection';

const LearnMore: FC = () => {
  return (
    <div className="mb-8 sm:mb-24">
      <PanelTextSection
        button={
          <Button variant="secondary" to={paths.blog()}>
            Learn more
          </Button>
        }
        image={
          <img
            loading="lazy"
            src={GuidesPNG}
            alt="two articles: 'Our 2022 Annual Salary Report' and 'Boutiques vs large consulting firms-which is right for you'"
            width="380"
            height="274"
          />
        }
        indicatorIcon={
          <CircleGraphic className="w-12 h-12 max-sm:hidden">
            <CircleGraphic.Quarter x={1} y={1} color="hpblack" rotate={90} />
            <CircleGraphic.Circle x={2} y={1} color="green-500" radius={0.5} />
            <CircleGraphic.Quarter x={1} y={2} color="green-900" />
            <CircleGraphic.Quarter x={2} y={2} color="hpblack" rotate={-90} />
          </CircleGraphic>
        }
        header="Navigate your career with insider guides"
        description="From salary benchmarks to role rundowns at your ideal companies, sign up to get the latest on career topics
        you care about."
      />

      <PanelTextSection
        className="sm:rtl"
        button={
          <Button variant="secondary" to={paths.stories()}>
            Learn more
          </Button>
        }
        image={
          <img
            loading="lazy"
            src={OpportunitiesPNG}
            alt="two jobs opportunities: at Bain&Company and at deliveroo"
            width="360"
            height="260"
          />
        }
        indicatorIcon={
          <CircleGraphic className="w-12 h-12 max-sm:hidden">
            <CircleGraphic.Quarter x={1} y={1} color="green-500" rotate={180} />
            <CircleGraphic.Quarter x={2} y={1} color="green-900" rotate={90} />
            <CircleGraphic.Quarter x={1} y={2} color="green-500" />
            <CircleGraphic.Quarter x={2} y={2} color="hpblack" rotate={-90} />
          </CircleGraphic>
        }
        header="Find your next opportunity"
        description="Browse impactful roles at innovative startups, private equity, consulting and established corporates; or view hand-picked opportunities our team thinks are a great match for you."
      />

      <PanelTextSection
        image={
          <img
            loading="lazy"
            src={CoachingPNG}
            alt="two coaching profile images: man in gray suit jacket sitting on gray concrete bench and woman in blue dress sitting on black chair"
            width="380"
            height="262"
          />
        }
        indicatorIcon={
          <CircleGraphic className="w-12 h-12 max-sm:hidden">
            <CircleGraphic.Quarter x={1} y={1} color="green-500" rotate={-90} />
            <CircleGraphic.Quarter x={2} y={1} color="hpblack" rotate={0} />
            <CircleGraphic.Quarter x={1} y={2} color="hpblack" rotate={180} />
            <CircleGraphic.Quarter x={2} y={2} color="green-900" rotate={90} />
          </CircleGraphic>
        }
        header="Interview prep and coaching"
        description="Get offers from top companies and MBA programs with 1-on-1 coaching. Learn what questions to expect in interviews, how to answer them, and feedback you can apply immediately."
      />
    </div>
  );
};

export default LearnMore;
