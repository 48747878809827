import React, { FC } from 'react';
import ArticleCard from '#components/ArticleCard/ArticleCard';
import { paths } from '#pages/paths';

interface Props {
  posts: Array<{
    slug: string;
    title: string;
    image: string;
  }>;
}
const RelatedPosts: FC<Props> = ({ posts }) => {
  return (
    <div className="bg-gray-50 py-16 px-variable-sm grid gap-12 justify-center">
      <h2 className="text-center max-w-2xl lg:max-w-6xl text-display-xs font-serif lg:text-display-sm xl:text-display-md m-auto">
        Related posts
      </h2>
      <div className="grid xs:grid-cols-2 md:grid-cols-3 gap-x-7 lg:gap-x-8 gap-y-12 lg:gap-y-14 max-w-4xl">
        {posts.map((post) => (
          <ArticleCard
            key={post.slug}
            title={post.title}
            image={post.image}
            link={paths.blogPost({ slug: post.slug })}
            simple={true}
          />
        ))}
      </div>
    </div>
  );
};

export default RelatedPosts;
