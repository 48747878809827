import React, { FC } from 'react';
import { Button, DateTimeMeta, Icon } from '#components/Index';
import WideCard from '../WideCard/WideCard';
import { timeToSession } from '#root/src/lib/sessionTimes';

interface Props {
  name: string;
  coachLink: string;
  image?: React.ReactNode;
  description?: string;
  datetime?: string;
}

const SessionCardUpcoming: FC<Props> = ({ name, coachLink, image, description, datetime }) => {
  return (
    <WideCard
      title={name}
      image={image}
      meta={<>{datetime != null && !isNaN(Date.parse(datetime)) && <DateTimeMeta datetime={datetime} />}</>}
      subsubtitle={description}
      footerContent={
        <>
          <div className="h-8 flex items-center text-gray-500 text-xs md:pl-2">
            {datetime != null && !isNaN(Date.parse(datetime)) && timeToSession(datetime)}
          </div>
          <div className="grid 2xs:flex gap-y-2 gap-x-4 items-center 2xs:ml-auto max-2xs:w-full">
            {/* TODO: Get cancel link */}
            {/* <Button size="small" to={'placeholder'} variant="secondary" className="relative">
              <Icon.X size={4} aria-hidden="true" className="shrink-0 max-2xs:absolute left-5" /> Cancel session
            </Button> */}
            <Button size="small" to={coachLink} className="relative">
              Coach info{' '}
              <Icon.ArrowRightLine size={4} aria-hidden="true" className="shrink-0 max-2xs:absolute right-5" />
            </Button>
          </div>
        </>
      }
    />
  );
};

export default SessionCardUpcoming;
