import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Icon } from '../Index';

interface Props {
  link: LinkProps['to'];
  position: string;
  logo: React.ReactElement;
  location: string;
  backgrounds?: Array<{ image: string; alt: string }>;
}

const SuccessCard: FC<Props> = ({ link, position, logo, location, backgrounds = [] }) => (
  <Link
    to={link}
    className="flex flex-col bg-white text-center transition group focus-visible-outline scroll-m-2 rounded-lg border-4 border-gray-50 hover:border-opacity-60"
    aria-label={`${position}. ${logo.props.alt as string}. ${location}`}
  >
    <div className="flex flex-col items-center justify-between py-7 px-6 gap-2.5 h-full">
      <h3 className="font-serif text-display-xs">{position}</h3>
      <div className="flex justify-center max-h-24 py-2 mt-auto">{logo}</div>
      <div className="text-gray-500 text-sm leading-tight">
        <Icon.MapPinFill size={4} className="text-green-600 -mt-1 mr-1" aria-label="Location" />
        {location}
      </div>
    </div>
    {backgrounds.length > 0 && (
      <div className="bg-gray-50 w-full p-7 text-gray-500 text-xs font-medium transition group-hover:bg-gray-50/60">
        <span className="uppercase">backgrounds of hired candidates</span>
        <div className="flex gap-5 bg-gray-50 w-fit mx-auto h-10 mt-3">
          {backgrounds.map((background) => (
            <img
              key={background.image}
              src={background.image}
              className="max-h-10"
              height="40"
              width="40"
              loading="lazy"
              alt={background.alt}
            />
          ))}
        </div>
      </div>
    )}
  </Link>
);

export default SuccessCard;
