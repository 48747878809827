import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import contentList from './content';

const Terms: FC = () => {
  return (
    <>
      <Helmet>
        <title>Hiperpool - Terms and Conditions</title>
      </Helmet>
      <div className="px-variable-lg py-16 flex items-start justify-center gap-14 text-sm">
        <aside className="sticky max-sm:hidden top-0 pt-10 min-w-[10rem] max-w-[18rem] max-h-screen overflow-y-auto">
          <h2 className="uppercase text-gray-600 mb-5 leading-6">List of contents</h2>
          <ol>
            {contentList.map((content) => (
              <li key={content.id} className="my-2">
                <Link to={`#${content.id}`} className="large-link flex !leading-5">
                  {content.heading}
                </Link>
              </li>
            ))}
          </ol>
        </aside>
        <div className="max-w-xl rich-text rich-text-lists">
          <div className="pb-10">
            <h1 className="mb-4 !leading-10">Terms and Conditions</h1>
            <p className="font-medium text-gray-600">Last updated March 2023</p>
          </div>
          {contentList.map((sec) => (
            <section key={sec.id} id={sec.id} className="border-t-2 border-gray-200 py-10 -scroll-mt-0.5">
              <h2>{sec.heading}</h2>
              {sec.content}
            </section>
          ))}
        </div>
      </div>
    </>
  );
};

export default Terms;
