import React, { FC, useMemo } from 'react';
import { FunctionTypeTree } from '#src/types';
import ProfileBadge from '../../elements/ProfileBadge';
import MissingAnswerCard from './MissingAnswerCard';

interface FirmsTreeProps {
  functionTypes: FunctionTypeTree[];
  type: 'interest' | 'experience';
  openModal: () => void;
}

const FirmsTree: FC<FirmsTreeProps> = ({ functionTypes, type, openModal }) => {
  const selectedFunctionTypes = useMemo(() => {
    return functionTypes.filter((functionType) => functionType.selected);
  }, [functionTypes]);

  const title = type === 'experience' ? 'I have full-time experience in' : 'I’m interested in';

  if (selectedFunctionTypes.length === 0) return <MissingAnswerCard onClick={openModal} />;

  return (
    <div>
      <div className="flex flex-wrap gap-2 mb-2">
        {selectedFunctionTypes.map((functionType) => (
          <ProfileBadge key={functionType.id}>{functionType.name}</ProfileBadge>
        ))}
      </div>

      <div>
        {selectedFunctionTypes.map((functionType) => (
          <div key={functionType.id} className="pl-6 py-2 border-l border-gray-300">
            <h5 className="text-gray-500 text-sm pb-2">
              Functions in <span className="text-green-900 font-semibold">{functionType.name}</span> {title}
            </h5>
            <div className="flex flex-wrap gap-2">
              {functionType.branches
                .filter((branch) => branch.selected)
                .map((branch) => (
                  <ProfileBadge key={branch.id}>{branch.name}</ProfileBadge>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FirmsTree;
