import React from 'react';
import { Step, TitleSlideBody } from '../../CurrentStep';
import { useStepController } from '../../StepControllerContext';
import { Button, Icon } from '#root/src/components/Index';

const Intro: Step = {
  id: 'history-into',
  onDarkBackground: true,
  Body: () => <TitleSlideBody surtitle="Experience" title="Work History" />,
  Footer: () => {
    const { previous, next } = useStepController();

    return (
      <>
        <Button size="large" variant="secondary-light" onClick={previous} circle={true} className="max-2xs:!px-8">
          <Icon.ArrowLeftLine size={5} aria-label="Back" />
        </Button>
        <Button size="large" variant="primary" onClick={next} circle={true} className="max-2xs:!px-8">
          <Icon.ArrowRightLine size={5} aria-label="Next" />
        </Button>
      </>
    );
  },
};

export default Intro;
