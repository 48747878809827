import React, { FC } from 'react';

import NextHire from './elements/NextHire';
import StrategicRoles from './elements/StrategicRoles';
import RecordOfSuccess from './elements/RecordOfSuccess';
import YourSuccessIsOurSuccess from './elements/YourSuccessIsOurSuccess';
import TestimonialCarousel from './elements/TestimonialCarousel';
import WantToLearnMore from './elements/WantToLearnMore';
import EmployerHeroBanner from './elements/EmployerHeroBanner';
import PricingPlan from './elements/PricingPlan';

const EmployerIndex: FC = () => {
  return (
    <>
      <EmployerHeroBanner />
      <RecordOfSuccess />
      <NextHire />
      <StrategicRoles />
      <PricingPlan />
      <TestimonialCarousel />
      <YourSuccessIsOurSuccess />
      <WantToLearnMore />
    </>
  );
};

export default EmployerIndex;
