import * as apiGetPostDetails from '#api/endpoints/posts/getPostDetails';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  blog = 'blog',
  posts = 'posts',
  show = 'show',
}

type GetApiPayload = apiGetPostDetails.Type['success']['payload'];
export const useGetPostDetails = (slug: string, options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.blog, KeysEnum.posts, KeysEnum.show, slug],
    async () => await apiGetPostDetails.request({ slug }),
    {
      ...options,
    }
  );
