import React, { FC, useEffect, useState, useMemo } from 'react';
import { OpportunitySearchParams } from '#src/types';
import { useGetOpportunityFilterOptions } from './data/FilterOptionsData';
import { useGetPreselectedOpportunityFilters } from './data/PreselectedOpportunityFiltersData';
import { Button, Icon } from '#components/Index';
import HowItWorksCard from './elements/HowItWorksCard';
import OpportunityFilters from './elements/OpportunityFilters';
import { OpportunitiesList } from './elements/OpportunitiesList';
import { useGetFilteredOpportunities } from './data/FilteredOpportunitiesData';

export const NoMatchingOpportunities: FC<{ clearSearch: () => void }> = ({ clearSearch }) => {
  return (
    <div>
      <div className="bg-white py-8 flex justify-center">
        <div className="w-1/2 text-center">
          <Icon.EmptyBookmarked size={12} />
          <h3 className="text-display-sm font-serif text-hpblack">No matching opportunities</h3>
          <p className="text-sm mb-4">
            The filters you have applied do not match any of the current roles on Hiperpool. You can try clearing them
            to see all the live opportunities.
          </p>
          <Button variant="secondary" onClick={() => clearSearch()}>
            Clear filters
          </Button>
        </div>
      </div>
      <div className="flex flex-col space-y-8 mt-8">
        <div className="bg-white h-32 w-full opacity-60"></div>
        <div className="bg-white h-32 w-full opacity-45"></div>
        <div className="bg-white h-32 w-full opacity-30"></div>
        <div className="bg-white h-32 w-full opacity-20"></div>
      </div>
    </div>
  );
};

const emptySearchState = { filters: {}, keywords: [] };

const AllOpportunities: FC = () => {
  const [search, setSearch] = useState<OpportunitySearchParams>(emptySearchState);
  const [filtersLoaded, setFiltersLoaded] = useState(false);

  const { data: filterOptions, isLoading: filtersLoading } = useGetOpportunityFilterOptions();
  const { data: preselectedFilters, isLoading: preselectedFiltersLoading } = useGetPreselectedOpportunityFilters();
  const {
    data: opportunitiesData,
    isLoading,
    refetch,
  } = useGetFilteredOpportunities(search, { enabled: filtersLoaded });

  // filterOptions are generated dynamically based on the opportunities for the user
  // We need to find common filters to prevent situations where the user has preferences that are not available as a filter
  const commonFilters = useMemo(() => {
    if (preselectedFilters == null || filterOptions == null) return null;

    const commonValues: OpportunitySearchParams['filters'] = {};

    const getCommonElements = (arr1: string[] | undefined, arr2: string[] | undefined): string[] => {
      if (arr1 == null || arr2 == null) return [];

      return arr1.filter((value) => arr2.includes(value));
    };

    commonValues.firms = getCommonElements(preselectedFilters.firms, filterOptions.firms);
    commonValues.regions = getCommonElements(preselectedFilters.regions, filterOptions.regions);
    commonValues.workPolicies = getCommonElements(preselectedFilters.workPolicies, filterOptions.workPolicies);
    commonValues.contractTypes = getCommonElements(preselectedFilters.contractTypes, filterOptions.contractTypes);

    return commonValues;
  }, [preselectedFilters, filterOptions]);

  // Load preselected filters from API
  useEffect(() => {
    if (commonFilters == null) return;

    const newSearchValue = {
      filters: commonFilters,
      keywords: [],
    };

    setSearch(newSearchValue);
    setFiltersLoaded(true);
  }, [commonFilters]);

  return (
    <div className="flex flex-col lg:flex-row gap-x-8 px-4">
      <div className="basis-1/4">
        <HowItWorksCard />
        <OpportunityFilters
          search={search}
          setSearch={setSearch}
          regions={filterOptions?.regions ?? []}
          firms={filterOptions?.firms ?? []}
          workPolicies={filterOptions?.workPolicies ?? []}
          contractTypes={filterOptions?.contractTypes ?? []}
        />
      </div>
      <OpportunitiesList
        isLoading={isLoading || filtersLoading || preselectedFiltersLoading}
        opportunities={opportunitiesData?.opportunities ?? []}
        emptyState={<NoMatchingOpportunities clearSearch={() => setSearch(emptySearchState)} />}
        refetchOpportunities={refetch}
      />
    </div>
  );
};

export default AllOpportunities;
