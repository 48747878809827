export function animateNumber(obj: HTMLElement, initVal: number, lastVal: number, duration: number) {
  let startTime: number = NaN;

  const step = (time: number) => {
    if (isNaN(startTime)) {
      startTime = time;
    }

    // calculate the value to be used in calculating the number to be displayed
    const progress = Math.min((time - startTime) / duration, 1);

    // calculate what to be displayed using the value gotten above
    obj.innerHTML = Math.floor(progress * (lastVal - initVal) + initVal).toString();

    // checking to make sure the counter does not exceed the last value (lastVal)
    if (progress < 1) {
      window.requestAnimationFrame(step);
    } else {
      window.cancelAnimationFrame(window.requestAnimationFrame(step));
    }
  };

  window.requestAnimationFrame(step);
}
