import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { TalentPreferences } from '#root/src/types';

interface Payload {
  init: never;
  success: { profilePreferences: TalentPreferences };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/talents/profiles/preferences');
