import React, { FC } from 'react';
import { uniqueId } from 'lodash-es';

interface Props {
  id: string;
  className?: string;
  checked?: boolean;
  name: string;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const RadioItem: FC<Props> = ({
  id = uniqueId('radioItem'),
  className = '',
  checked,
  name,
  value,
  onChange,
  disabled,
  children,
}) => {
  return (
    <div className={`flex items-center`}>
      <input
        type="radio"
        id={id}
        name={name}
        className="w-5 h-5"
        value={value}
        onChange={(event) => onChange?.(event, id)}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={id} className={`ms-2 ${className}`}>
        {children}
      </label>
    </div>
  );
};

export default RadioItem;
