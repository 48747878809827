import React, { FC } from 'react';
import searchSelectStyles from '#components/Form/SearchSelect/SearchSelect.module.css';
import { Icon } from '#components/Index';
import { LocationOptionProps } from '#root/src/components/Form/Index';

const MultiLocationSelectOption: FC<LocationOptionProps> = ({
  placeId,
  name,
  open = false,
  checked = false,
  onChange,
}) => {
  return (
    <div className={`${searchSelectStyles.option}`}>
      <input
        type="checkbox"
        id={`checkbox-${placeId}`}
        checked={checked}
        onChange={onChange}
        className={searchSelectStyles.option__input}
        tabIndex={open ? undefined : -1}
        data-id={placeId}
      />
      <label
        htmlFor={`checkbox-${placeId}`}
        className={searchSelectStyles.option__label}
        // tabIndex required so that event.relatedTarget is not null when clicked: https://stackoverflow.com/a/42764495
        tabIndex={-1}
      >
        {name}
      </label>
      <div className={searchSelectStyles.option__icon}>
        <Icon.CheckLine size={5} className="mr-4" />
      </div>
    </div>
  );
};

export default MultiLocationSelectOption;
