import React, { FC } from 'react';
import { ExpandableSection } from '#components/Index';

const sections: Array<{ heading: string; content: React.ReactNode }> = [
  {
    heading: 'Why should I use Hiperpool?',
    content:
      'We deliver a customised, pre-screened pipeline of candidates so you can focus on interviewing and hiring. Our approach and industry knowledge enables us to reach the best possible candidates. Plus, there are no upfront costs or hidden fees, only pay if you hire. Still have doubts? Get in touch and we’ll be happy to discuss.',
  },
  {
    heading: 'What type of professionals are on Hiperpool?',
    content:
      'Our members are experienced professionals with backgrounds in strategy, consulting, M&A, PE / VC and similar disciplines. We seek professionals with excellent track records – graduates of top schools and employees of reputable companies.',
  },
  {
    heading: 'What kind of support do you offer?',
    content:
      'Every employer on Hiperpool is assigned a dedicated Client Success Manager who will assist and support them throughout their recruiting processes. Once your account is approved, a Client Success Manager will reach out to discuss hiring needs, requirements and preferences to ensure the best and most efficient service.',
  },
  {
    heading: 'How do I get in contact with you?',
    content: (
      <>
        Contact us anytime at{' '}
        <a href="mailto:employer@hiperpool.com" className="link" onClick={(evt) => evt.stopPropagation()}>
          employer@hiperpool.com
        </a>{' '}
        to set up a meeting or phone call with a Client Success Manager.
      </>
    ),
  },
];

const EmployerFAQ: FC = () => {
  return (
    <>
      {sections.map((section) => (
        <ExpandableSection key={section.heading} {...section} />
      ))}
    </>
  );
};

export default EmployerFAQ;
