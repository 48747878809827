import React, { FC, useState, ChangeEvent, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { request as apiUpdateCv } from '#api/endpoints/talents/profile/updateCv';
import { parseErrors } from '#src/lib/parseFormsErrors';
import { KeysEnum as ProfileDetailsKeys } from '../../data/ProfileDetails';
import { Button, Icon } from '#components/Index';
import buttonStyles from '#components/Button/Button.module.css';

const queryKeys = [ProfileDetailsKeys.talents, ProfileDetailsKeys.profile, ProfileDetailsKeys.details];

interface EditableCvProps {
  setEditMode: (editMode: boolean) => void;
}

const EditableCv: FC<EditableCvProps> = ({ setEditMode }) => {
  const queryClient = useQueryClient();

  const handleFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0];

      if (selectedFile == null) return;

      try {
        await apiUpdateCv({ cv: selectedFile });
        void queryClient.invalidateQueries(queryKeys);
        setEditMode(false);
        createSuccessToast({ title: 'CV', text: 'Your CV has been successfully updated.' });
      } catch (error) {
        const errorMessage = parseErrors(error).showFirst('cv');
        createErrorToast({ title: 'CV', text: errorMessage });
      }
    },
    [queryClient, setEditMode]
  );

  const className = [buttonStyles.button, buttonStyles[`button--secondary`], buttonStyles[`button--medium`]].join(' ');

  return (
    <div>
      <p className="text-hpblack text-lg font-semibold mb-4">Upload your CV in PDF format</p>
      <label className={className}>
        Choose File
        <input type="file" className="hidden" onChange={handleFileChange} />
      </label>
    </div>
  );
};

const DefaultCv: FC<ProfileCvProps> = ({ resumeUrl, firstName, lastName }) => {
  if (resumeUrl == null) return null;

  const fileName = `${firstName}_${lastName}_CV.pdf`.toLowerCase();

  return (
    <a href={resumeUrl} className="flex gap-x-4" target="_blank" rel="noreferrer">
      <Icon.PDF size={8} />
      <span className="text-hpblack text-lg font-semibold mb-4">{fileName}</span>
    </a>
  );
};

interface ProfileCvProps {
  resumeUrl: string | null;
  firstName: string;
  lastName: string;
}

const ProfileCv: FC<ProfileCvProps> = ({ resumeUrl, firstName, lastName }) => {
  const [editMode, setEditMode] = useState(false);

  const shouldShowEditable = resumeUrl == null || editMode;

  return (
    <div className="flex flex-grow relative">
      {!editMode && resumeUrl != null && (
        <div className="hidden md:block">
          <Button
            variant="secondary"
            size="small"
            className="absolute right-0 top-0"
            onClick={() => {
              setEditMode(!editMode);
            }}
          >
            <Icon.EditPencil size={3} />
            Edit
          </Button>
        </div>
      )}

      {shouldShowEditable ? (
        <EditableCv setEditMode={setEditMode} />
      ) : (
        <DefaultCv resumeUrl={resumeUrl} firstName={firstName} lastName={lastName} />
      )}
    </div>
  );
};

export default ProfileCv;
