import React, { FC, useState, useMemo, useCallback } from 'react';
import { transformToSnakeCase } from '#src/lib/caseTransformObject';
import { request as apiOpportunityNotInterested } from '#api/endpoints/talents/opportunities/replies/notInterested';
import { Icon, Modal, Button } from '#components/Index';
import { TextArea } from '#root/src/components/Form/Index';
import FilterBadgeGroup from '#components/FilterBadgeGroup/FilterBadgeGroup';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  opportunityId: number;
  refetch: () => void;
}

const initialFeedbackOptions: Record<string, boolean> = {
  'Location': false,
  'Industry': false,
  'Company Type': false,
  'Specific Company': false,
  'Seniority': false,
  'Function': false,
  'Salary': false,
  'Other': false,
};

const NotInterestedModal: FC<Props> = ({ isOpen, onClose, opportunityId, refetch }) => {
  const [feedbackOptions, setFeedbackOptions] = useState(initialFeedbackOptions);
  const [feedbackField, setFeedbackField] = useState('');

  const parsedFeedbackOptions = useMemo(() => {
    return transformToSnakeCase(feedbackOptions);
  }, [feedbackOptions]);

  const handleConfirm = useCallback(async () => {
    await apiOpportunityNotInterested({
      opportunityId,
      feedback: {
        ...parsedFeedbackOptions,
        feedbackField,
      },
    });
    refetch();
    onClose();
  }, [opportunityId, parsedFeedbackOptions, feedbackField, refetch, onClose]);

  const selectedOptionsCount = useMemo(() => {
    return Object.keys(feedbackOptions).filter((option) => feedbackOptions[option]).length;
  }, [feedbackOptions]);

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="p-16 pt-8 flex flex-col items-center w-screen max-w-2xl gap-8">
        <button
          onClick={onClose}
          className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline self-center"
          aria-label="Close"
        >
          <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
        </button>

        <div className="w-full grid gap-6 text-sm  font-medium">
          <div className="flex flex-col gap-y-3 text-center">
            <h2 className="text-display-xs font-serif hp-black">We’re sorry this isn’t the one!</h2>
            <p className="hp-black font-light text-md">
              Help us send you more relevant opportunities by telling us why this wasn’t the right fit
            </p>
          </div>

          <FilterBadgeGroup
            withoutAllButton
            id="feedbackOptions"
            filters={feedbackOptions}
            setFilter={setFeedbackOptions}
            className="justify-center !gap-3 px-4"
          />

          <TextArea
            label=""
            placeholder="Enter your reason for not being interested..."
            rows={3}
            onChange={(e) => {
              setFeedbackField(e.target.value);
            }}
          />

          <div className="flex items-center justify-center">
            <Button variant="link" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirm} disabled={selectedOptionsCount === 0}>
              Confirm and remove opportunity
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NotInterestedModal;
