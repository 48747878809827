import { Errors } from '#root/src/types';
import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: { talent: { email: string } | { token: string } };
  success: { redirectUrl: string; notice: string };
  fail: { errors: Errors<'base' | 'email'>; status: number };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/activate_account');
