import React, { FC } from 'react';

interface Props {
  imageContent: React.ReactNode;
  textContent: React.ReactNode;
  bgContent?: React.ReactNode;
  direction?: 'rtl' | 'ltr';
  colorScheme?: 'dark' | 'light';
  className?: string;
}

const HeroBanner: FC<Props> = ({
  imageContent,
  textContent,
  bgContent,
  direction = 'ltr',
  colorScheme = 'dark',
  className = '',
}) => {
  const isFlipped = direction === 'rtl';
  const isDark = colorScheme === 'dark';

  return (
    <div
      className={`relative isolate z-10 grid sm:grid-cols-2 ${
        isDark ? 'bg-hpblack text-white' : 'bg-gray-50'
      } ${className}`}
    >
      <div
        className={`mt-8 mb-16 sm:mb-24 sm:mt-16 ${
          isFlipped ? 'mx-variable-md sm:ml-0 order-1' : 'mx-variable-lg sm:mr-0'
        }`}
      >
        <div className={`max-w-2xl ${isFlipped ? '2xl:ml-8 mr-auto' : 'ml-auto sm:pr-8'}`}>{textContent}</div>
      </div>

      <div className={`px-variable-sm max-sm:order-first overflow-x-clip ${isFlipped ? 'sm:pr-0' : 'sm:pl-0'}`}>
        <div className={`flex h-full max-w-2xl ${isFlipped ? 'ml-auto justify-start' : 'mr-auto justify-end'}`}>
          {imageContent}
        </div>
      </div>

      {bgContent !== undefined && <div className="absolute inset-0 -z-10">{bgContent}</div>}
    </div>
  );
};

export default HeroBanner;
