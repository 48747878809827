import React, { FC, ReactNode } from 'react';

import { Icon, GreenBadge, CircleGraphic } from '#components/Index';
import JasonPNG from '#assets/layout/jason-goodman.webp';
import CherrydeckPNG from '#assets/layout/cherrydeck.webp';
import SalesSolutionsPNG from '#assets/layout/linkedin-sales-solutions.webp';

interface Props {
  children?: ReactNode;
}
const DarkBackgroundWithPeopleLayout: FC<Props> = ({ children }) => {
  return (
    <div className="relative isolate bg-hpblack text-white min-h-full-content h-full overflow-hidden flex justify-center">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <CircleGraphic className="absolute -bottom-12">
          <CircleGraphic.Half x={1} y={2} color="green-900" rotate={-90} />
          <CircleGraphic.Quarter x={2} y={2} color="green-900" rotate={90} />
          <CircleGraphic.Quarter x={3} y={2} color="green-500" rotate={180} />
          <CircleGraphic.Quarter x={1} y={3} color="green-500" rotate={-90} />
          <CircleGraphic.Quarter x={2} y={3} color="green-500" rotate={0} />
          <CircleGraphic.Half x={4} y={3} color="green-900" rotate={180} />
          <CircleGraphic.Quarter x={2} y={4} color="green-900" rotate={90} />
          <CircleGraphic.Circle x={3} y={4} color="green-500" radius={0.5} />
        </CircleGraphic>

        <div
          className="absolute h-[290px] w-[380px] right-0 mr-variable-sm bottom-0 mb-variable-sm whitespace-nowrap"
          aria-hidden="true"
        >
          <img
            loading="eager"
            className="absolute rounded-full left-[0px]"
            src={SalesSolutionsPNG}
            height="157"
            width="157"
          />
          <div className="absolute z-10 top-[35px] left-[125px]">
            <GreenBadge>
              <Icon.Notification size={3.5} className="text-green-600" />
              Match found
            </GreenBadge>
          </div>
          <img
            loading="eager"
            className="absolute rounded-full left-[150px] top-[95px]"
            src={CherrydeckPNG}
            height="105"
            width="105"
          />
          <div className="absolute z-10 top-[170px] left-[210px]">
            <GreenBadge>
              <Icon.CalendarChecked size={3.5} className="text-green-600" />
              Interview scheduled
            </GreenBadge>
          </div>
          <img
            loading="eager"
            className="absolute rounded-full top-[165px] left-[40px]"
            src={JasonPNG}
            height="125"
            width="125"
          />
          <div className="absolute z-10 top-[250px] left-[100px]">
            <GreenBadge>
              <Icon.CheckboxCircle size={3.5} className="text-green-600" />
              Position filled
            </GreenBadge>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default DarkBackgroundWithPeopleLayout;
