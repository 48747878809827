import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: never;
  success: {
    talent: {
      email: string;
      marketingEmailConsent: boolean;
      emailNotificationFrequency: 'as_they_come' | 'never';
    };
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/talents/settings');
