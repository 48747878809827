import React, { FC, useEffect, useState, useCallback } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { AutocompleteOption, TalentPreferences } from '#root/src/types';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { request as apiUpdatePreferredCompanies } from '#api/endpoints/talents/profile/companyPreferences/updatePreferredCompanies';
import * as apiAutocompletePrivacyCompanies from '#api/endpoints/autocomplete/privacyCompanies';
import { useSteps } from '../../BaseProfileEditModal';
import { AutocompleteInput } from '#components/Form/Index';
import OptionBadge from '#components/Form/Inputs/MultiStringInput/OptionBadge';

const InterestedCompaniesStep: FC = () => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { setStepCallback, setNextDisabled } = useSteps();

  const [companyPreferences, setCompanyPreferences] = useState<TalentPreferences['companyPreferences']>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<string>('');

  useEffect(() => {
    if (profilePreferencesData != null) {
      setCompanyPreferences(profilePreferencesData.profilePreferences.companyPreferences);
    }
  }, [profilePreferencesData]);

  const stepCallback = useCallback(async () => {
    try {
      await apiUpdatePreferredCompanies({
        companyPreference: { companyNames: companyPreferences.map((company) => company.name) },
      });
      createSuccessToast({ title: 'Interested companies' });
      return true;
    } catch {
      createErrorToast({ title: 'Interested companies' });
      return false;
    }
  }, [companyPreferences]);

  useEffect(() => {
    setNextDisabled(false); // This step is optional
    setStepCallback(() => stepCallback);
  }, [setStepCallback, setNextDisabled, stepCallback]);

  const handleBadgeClick = (id: number) => {
    setCompanyPreferences(companyPreferences.filter((company) => company.id !== id));
  };

  const selectHandler = (selected: AutocompleteOption) => {
    setAutocompleteValue('');

    if (companyPreferences.find((company) => company.id === selected.id) != null) {
      setCompanyPreferences(companyPreferences.filter((company) => company.id !== selected.id));
      return;
    }

    setCompanyPreferences([
      ...companyPreferences,
      {
        id: selected.id,
        name: selected.name,
      },
    ]);
  };

  return (
    <div className="rich-text text-hpblack w-full">
      <h1>What companies are you most interested in?</h1>

      <AutocompleteInput
        label="Company"
        placeholder="E. g. ACME Corp."
        value={autocompleteValue}
        onChange={(name) => setAutocompleteValue(name)}
        autocompleteRequest={apiAutocompletePrivacyCompanies.request}
        selectHandler={selectHandler}
        recordsLimit={5}
      />

      <div className="flex flex-wrap max-w-full py-8">
        {companyPreferences.map((company) => (
          <OptionBadge
            key={company.id}
            option={company.name}
            handleBadgeClick={() => handleBadgeClick(company.id)}
            negative={false}
          />
        ))}
      </div>
    </div>
  );
};

export default InterestedCompaniesStep;
