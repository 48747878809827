import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { TalentProfile } from '#src/types';

import { KeysEnum as ProfileDetailsKeysEnum } from '#src/pages/talentPages/profileAndPreferences/profile/data/ProfileDetails';
import * as updateProfileDetailsAPI from '#api/endpoints/talents/profile/updateProfileDetails';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';

const queryKeys = [ProfileDetailsKeysEnum.talents, ProfileDetailsKeysEnum.profile, ProfileDetailsKeysEnum.details];

export interface FormValues extends FieldValues {
  firstName: string;
  lastName: string;
  currentEmploymentId: number | null;
  currentEducationId: number | null;
  location: string;
}

const defaultFormValues = (profile: TalentProfile): FormValues => ({
  firstName: profile.firstName,
  lastName: profile.lastName,
  currentEmploymentId: profile.currentEmploymentId,
  currentEducationId: profile.currentEducationId,
  location: profile.location,
});

const validationSchema = object().shape({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  location: string().required('Location is required'),
});

interface ControllerProps {
  profile: TalentProfile;
  closeEditMode: () => void;
}

export const useProfileDetailsController = ({ profile, closeEditMode }: ControllerProps) => {
  const queryClient = useQueryClient();

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues(profile),
  });

  const { parseErrors } = useParseFormErrors<updateProfileDetailsAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    await updateProfileDetailsAPI
      .request({
        profile: {
          firstName: values.firstName,
          lastName: values.lastName,
          location: values.location,
          currentEducationId: values.currentEducationId,
          currentEmploymentId: values.currentEmploymentId,
        },
      })
      .then(() => {
        closeEditMode();
        void queryClient.invalidateQueries(queryKeys);
        createSuccessToast({ title: 'Profile details' });
      })
      .catch((errors) => {
        createErrorToast({ title: 'Profile details' });
        parseErrors(errors);
      });
  };

  return { form: formMethods, submitForm };
};
