import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from 'react';
import styles from './Button.module.css';
import { NavLink, Link, LinkProps, NavLinkProps, To } from 'react-router-dom';

interface CommonButtonProps {
  variant?:
    | 'primary'
    | 'secondary'
    | 'secondary-light'
    | 'outline-white'
    | 'tertiary'
    | 'negative'
    | 'link'
    | 'alternate'
    | 'negative-full';
  size?: 'small' | 'medium' | 'large';
  circle?: boolean;
  className?: string;
  children?: React.ReactNode;
}

interface ButtonProps extends ButtonHTMLAttributes<unknown>, CommonButtonProps {}

interface HrefButtonProps extends AnchorHTMLAttributes<unknown>, CommonButtonProps {}

interface LinkButtonProps extends LinkProps, CommonButtonProps {}

interface NavLinkButtonProps extends Omit<NavLinkProps, 'children' | 'className'>, CommonButtonProps {
  navTo: To;
}

function isHrefButtonProps(obj: any): obj is HrefButtonProps {
  return 'href' in obj && (obj as HrefButtonProps).href !== undefined;
}

function isLinkButtonProps(obj: any): obj is LinkButtonProps {
  return 'to' in obj && (obj as LinkButtonProps).to !== undefined;
}
function isNavLinkButtonProps(obj: any): obj is NavLinkButtonProps {
  return 'navTo' in obj && (obj as NavLinkButtonProps).navTo !== undefined;
}

export const Button: FC<ButtonProps | HrefButtonProps | LinkButtonProps | NavLinkButtonProps> = ({
  variant = 'primary',
  size = 'medium',
  circle = false,
  className: classNameProp = '',
  children,
  ...rest
}) => {
  const className = [
    styles.button,
    styles[`button--${variant}`],
    styles[`button--${size}`],
    circle ? styles[`button--circle`] : null,
    classNameProp,
  ].join(' ');

  if (isNavLinkButtonProps(rest)) {
    const { navTo, ...restOfRest } = rest;
    return (
      <NavLink className={className} {...restOfRest} to={navTo}>
        {children}
      </NavLink>
    );
  }

  if (isLinkButtonProps(rest)) {
    return (
      <Link className={className} {...rest} to={rest.to}>
        {children}
      </Link>
    );
  }

  if (isHrefButtonProps(rest)) {
    return (
      <a className={className} {...rest} href={rest.href}>
        {children}
      </a>
    );
  }

  return (
    <button className={className} type="button" {...(rest as ButtonProps)}>
      {children}
    </button>
  );
};
