import React, { FC } from 'react';
import { useGetAccountSettings } from './data/SettingsData';
import { Spinner } from '#components/Index';
import UpdateYourPreferences from './elements/UpdateYourPreferences';
import AccountSettingsForm from './elements/AccountSettingsForm/AccountSettingsForm';
import EmailPreferencesForm from './elements/EmailPreferencesForm';
import NotFound from '#pages/NotFound';

const Settings: FC = () => {
  const { data, isLoading } = useGetAccountSettings();

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  return (
    <div className="grow flex flex-col max-w-6xl xl:max-w-8xl mx-auto py-6 px-8 2xl:px-0">
      <h1 className="hp-black font-serif text-display-xs mb-4">Account Settings</h1>
      <div className="w-full p-8 bg-white rounded-lg shadow-md-dark mb-12">
        <AccountSettingsForm email={data.talent.email} />
      </div>

      <h1 className="hp-black font-serif text-display-xs mb-4">Email Preferences</h1>
      <div className="w-full p-8 bg-white rounded-lg shadow-md-dark mb-16">
        <EmailPreferencesForm
          initialMarketingEmailConsent={data.talent.marketingEmailConsent}
          initialEmailNotificationFrequency={data.talent.emailNotificationFrequency}
        />
      </div>

      <UpdateYourPreferences />
    </div>
  );
};

export default Settings;
