import React, { FC, useState } from 'react';
import { TalentPreferences } from '#src/types';
import { FormContentGroup } from '#components/Index';
import CompensationModal from './modals/CompensationModal';
import MissingAnswerCard from './MissingAnswerCard';
import SingleLineAnswer from './SingleLineAnswer';

interface CompensationAndCompaniesProps {
  profilePreferences: TalentPreferences;
}

const CompensationAndCompanies: FC<CompensationAndCompaniesProps> = ({ profilePreferences }) => {
  const [isCompensationModalOpen, setIsCompensationModalOpen] = useState(false);

  const { salary, earnings } = profilePreferences;

  const earningsOrMissingCard = () => {
    if (earnings.trim() === '') return <MissingAnswerCard onClick={() => setIsCompensationModalOpen(true)} />;

    return <SingleLineAnswer>{earnings}</SingleLineAnswer>;
  };

  const salaryOrMissingCard = () => {
    if (salary.trim() === '') return <MissingAnswerCard onClick={() => setIsCompensationModalOpen(true)} />;

    return <SingleLineAnswer>{salary}</SingleLineAnswer>;
  };

  return (
    <FormContentGroup
      title="Compensation"
      groupName="Preferences"
      description="What are your compensation expectations?"
      editSectionHandler={() => setIsCompensationModalOpen(!isCompensationModalOpen)}
    >
      <CompensationModal isOpen={isCompensationModalOpen} close={() => setIsCompensationModalOpen(false)} />

      <div className="flex flex-col gap-y-6">
        <div>
          <h5 className="text-gray-500 text-sm pb-2">What I’m currently earning</h5>
          {earningsOrMissingCard()}
        </div>

        <div>
          <h5 className="text-gray-500 text-sm pb-2">Total compensation I’m looking for in my next role</h5>
          {salaryOrMissingCard()}
        </div>
      </div>
    </FormContentGroup>
  );
};

export default CompensationAndCompanies;
