import * as apiGetEmploymentBranches from '#api/endpoints/onboarding/employmentBranches';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  employmentBranches = 'employment-branches',
}

type GetApiPayload = apiGetEmploymentBranches.Type['success']['payload'];
export const useGetInterestedFirmsOptions = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>([KeysEnum.employmentBranches], async () => await apiGetEmploymentBranches.request(), {
    ...options,
  });
