import React, { useEffect, useState, Dispatch, SetStateAction, useCallback } from 'react';
import { useTalentDataController } from '../../../TalentDataController';
import { useStepController } from '../../../StepControllerContext';
import { Step, StepBackButton, StepNextButton } from '../../../CurrentStep';
import { request as apiRemoveInterestGroups } from '#api/endpoints/talents/profile/employmentBranches/removeInterestGroups';
import Startups from './Startup';
import Corporate from './Corporate';
import ManagementConsulting from './ManagementConsulting';
import PrivateEquity from './PrivateEquity';
import InvestmentBanking from './InvestmentBanking';
import InterestedFirmsForm from '#components/TalentSharedForms/InterestedFirms/Form';

// Links our steps, with their IDs, to their displayNames, which match the talentData.functionTypesInterest names
const stepIdsAndNames: { [x: string]: string } = {};
const elements = [Startups, Corporate, ManagementConsulting, PrivateEquity, InvestmentBanking];

elements.forEach((element) => {
  if (element.displayName != null) {
    stepIdsAndNames[element.id] = element.displayName;
  } else {
    console.error('Missing displayName', element);
  }
});

const getIdFromName = (name: string) => {
  const entry = Object.entries(stepIdsAndNames).find(([_, displayName]) => displayName === name);

  if (entry == null) return null;
  return entry[0];
};

const InterestedFirms: Step = {
  id: 'interested-firms',
  Body: ({
    setNextDisabled,
    setHandleNextClick,
  }: {
    setNextDisabled?: Dispatch<SetStateAction<boolean>>;
    setHandleNextClick?: Dispatch<SetStateAction<() => void>>;
  }) => {
    const { talentData, refetch } = useTalentDataController();
    const { next } = useStepController();
    const { stepTree } = useStepController(); // List of steps, with IDs
    const [initialSelectionApplied, setInitialSelectionApplied] = useState(false);

    // Set which options are initially checked based on the stepTree visible/hidden-ness (for handling back/forth)
    const currentlyVisibleOptions = Object.keys(stepIdsAndNames)
      .map((id) => {
        if (stepTree.find(id)?.isVisible ?? false) {
          return id;
        }

        return '';
      })
      .filter((id) => id !== '');

    const [selectedStepIds, setSelectedStepIds] = useState<string[]>(currentlyVisibleOptions);

    // Set which steps are visible/hidden based on talentData (executes only once when talentData is loaded)
    useEffect(() => {
      if (talentData == null || initialSelectionApplied) return;

      talentData.functionTypesInterest.forEach((typeInterest) => {
        const stepId = getIdFromName(typeInterest);

        if (typeof stepId !== 'string') {
          console.error(`Failed to lookup typeId from typeInterest ${typeInterest}`);
          return;
        }
        stepTree.setStepVisibility(stepId, false);

        if (!selectedStepIds.includes(stepId)) {
          setSelectedStepIds((prevSelectedStepIds) => [...prevSelectedStepIds, stepId]);
        }
      });

      setInitialSelectionApplied(true);
    }, [stepTree, talentData, selectedStepIds, initialSelectionApplied]);

    // Prevent the user from moving to the next step if they haven't selected any value
    useEffect(() => {
      if (setNextDisabled == null) return;

      setNextDisabled(selectedStepIds.length === 0);
    }, [selectedStepIds, setNextDisabled]);

    // Set the next button to remove the unselected interest groups
    useEffect(() => {
      const handleNext = async () => {
        const selectedParentEmploymentBranches = selectedStepIds.map((id) => stepIdsAndNames[id]);

        await apiRemoveInterestGroups({ selectedParentEmploymentBranches });
        refetch();
        next();
      };

      if (setHandleNextClick != null) {
        setHandleNextClick(() => handleNext);
      }
    }, [setHandleNextClick, next, refetch, selectedStepIds]);

    const handleChange = useCallback(
      (stepId: string) => {
        const node = stepTree.find(stepId);
        if (node == null) {
          throw new Error('Step was not found');
        }
        stepTree.setStepVisibility(stepId, node.hidden === false);

        if (selectedStepIds.includes(stepId)) {
          setSelectedStepIds(selectedStepIds.filter((id) => id !== stepId));
        } else {
          setSelectedStepIds([...selectedStepIds, stepId]);
        }
      },
      [stepTree, selectedStepIds]
    );

    return (
      <InterestedFirmsForm
        selectedStepIds={selectedStepIds}
        firmTypeIdsAndNames={stepIdsAndNames}
        handleChange={handleChange}
      />
    );
  },
  Footer: ({ nextDisabled, onNextClick }: { nextDisabled?: boolean; onNextClick?: () => void }) => (
    <>
      <StepBackButton />
      <StepNextButton disabled={nextDisabled} onClick={onNextClick} />
    </>
  ),
};

export default InterestedFirms;
