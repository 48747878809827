import React, { FC } from 'react';
import { useGetProfilePreferences } from './data/ProfilePreferences';
import { Spinner } from '#components/Index';
import NotFound from '#pages/NotFound';
import ProfileSubNavigation from '../elements/ProfileSubNavigation';
import PreferencesVisibilityInfo from './elements/PreferencesVisibilityInfo';
import CurrentSituation from './elements/CurrentSituation';
import WorkHistory from './elements/WorkHistory';
import Interests from './elements/Interests';
import Locations from './elements/Locations';
import Companies from './elements/Companies';
import Compensation from './elements/Compensation';
import ExtraDetails from './elements/ExtraDetails';

const Preferences: FC = () => {
  const { data, isLoading } = useGetProfilePreferences();

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  const { profilePreferences } = data;

  return (
    <div className="grow flex flex-col max-w-6xl xl:max-w-8xl mx-auto py-6">
      <ProfileSubNavigation />

      <div className="px-8 2xl:px-0">
        <PreferencesVisibilityInfo profilePreferences={profilePreferences} />

        <h1 className="hp-black font-serif text-display-xs mb-4">My Experience</h1>
        <div className="w-full px-8 bg-white rounded-lg shadow-md-dark mb-8 divide-y-2">
          <CurrentSituation profilePreferences={profilePreferences} />

          <WorkHistory profilePreferences={profilePreferences} />
        </div>

        <h1 className="hp-black font-serif text-display-xs mb-4">My Preferences</h1>
        <div className="w-full px-8 bg-white rounded-lg shadow-md-dark mb-8 divide-y-2">
          <Interests profilePreferences={profilePreferences} />

          <Locations profilePreferences={profilePreferences} />

          <Companies profilePreferences={profilePreferences} />

          <Compensation profilePreferences={profilePreferences} />

          <ExtraDetails profilePreferences={profilePreferences} />
        </div>
      </div>
    </div>
  );
};

export default Preferences;
