import React, { FC, useState } from 'react';
import { Button, Icon } from '#components/Index';
import EditableLinkedin from './EditableLinkedin/EditableLinkedin';

interface ProfileLinkedinProps {
  linkedinUrl: string | null;
}

const DefaultLinkedinContent: FC<ProfileLinkedinProps> = ({ linkedinUrl }) => {
  if (linkedinUrl == null) {
    return <span className="text-md text-hpblack font-light">No link added yet</span>;
  }

  return (
    <a
      href={linkedinUrl}
      className="flex gap-x-2 items-center text-md hp-black font-light"
      target="_blank"
      rel="noreferrer"
    >
      <Icon.FeaturedCircleLight size={5} />
      {linkedinUrl}
    </a>
  );
};

const ProfileLinkedin: FC<ProfileLinkedinProps> = ({ linkedinUrl }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <div className="flex flex-grow relative">
      {!editMode && (
        <div className="hidden md:block">
          <Button
            variant="secondary"
            size="small"
            className="absolute right-0 top-0"
            onClick={() => {
              setEditMode(!editMode);
            }}
          >
            <Icon.EditPencil size={3} />
            Edit
          </Button>
        </div>
      )}

      <div className="flex flex-col">
        <p className="text-hpblack text-lg font-semibold">Your Linkedin profile URL</p>

        {editMode ? (
          <EditableLinkedin linkedinUrl={linkedinUrl} setEditMode={setEditMode} />
        ) : (
          <DefaultLinkedinContent linkedinUrl={linkedinUrl} />
        )}
      </div>
    </div>
  );
};

export default ProfileLinkedin;
