import React, { FC } from 'react';
import { Button, Icon } from '#components/Index';

interface FormContentGroupProps {
  title: string;
  groupName?: string;
  description?: string;
  editSectionHandler?: () => void;
  editSectionText?: string;
  children?: React.ReactNode;
}

const FormContentGroup: FC<FormContentGroupProps> = ({
  title,
  children = null,
  groupName = '',
  description = '',
  editSectionHandler = null,
  editSectionText = 'Edit section',
}) => {
  return (
    <div className="flex flex-col lg:flex-row lg:gap-x-32 lg:gap-y-0 py-8 gap-y-8 relative">
      {editSectionHandler != null && (
        <Button className="absolute right-0" variant="secondary" size="small" onClick={editSectionHandler}>
          <Icon.EditPencil size={3} />
          {editSectionText}
        </Button>
      )}
      <div className="min-w-60 mt-4 sm:mt-0 max-w-60 xl:min-w-72 xl:max-w-72">
        <h2 className="text-xs uppercase font-bold text-green-600 tracking-widest">{groupName}</h2>
        <h3 className="hp-black font-serif text-display-sm mb-2">{title}</h3>
        <p className="text-md font-light text-gray-500">{description}</p>
      </div>
      <div className="flex flex-col grow">{children}</div>
    </div>
  );
};

export default FormContentGroup;
