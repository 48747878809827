import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { CircleGraphic, Button, Icon } from '#components/Index';
import { paths } from '#pages/paths';

const Block: FC = () => {
  return (
    <div className="relative text-center">
      <div className="absolute bottom-0 w-full grid justify-items-center px-variable-sm pt-3 pb-10 bg-white">
        <div className="absolute top-0 w-full -translate-y-full h-64 bg-gradient-to-t from-white" />
        <div className="absolute -mt-2 -translate-y-full grid place-items-center">
          <CircleGraphic className="w-12 h-12">
            <CircleGraphic.Quarter x={1} y={1} color="green-300" rotate={90} />
            <CircleGraphic.Quarter x={2} y={1} color="green-200" rotate={90} />
            <CircleGraphic.Quarter x={1} y={2} color="green-400" rotate={0} />
            <CircleGraphic.Quarter x={2} y={2} color="green-300" rotate={-90} />
          </CircleGraphic>
          <Icon.Padlock size={6} className="absolute" aria-label="Locked" />
        </div>
        <h2 className="text-lg font-serif">This premium content is for members only</h2>
        <p className="text-sm text-gray-600 mt-2">
          Sign up to access our full library of resources. <br className="max-2xs:hidden" />
          It’s completely free and only takes a few minutes!
        </p>
        <Button to={paths.signUp()} className="mt-5">
          Get started
          <Icon.ArrowRightLine size={5} />
        </Button>
        <span className="text-gray-700 rich-text mt-5">
          Already have an account?{' '}
          <Link to={paths.signIn()} className="whitespace-nowrap">
            Sign in
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Block;
