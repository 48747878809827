import React, { FC, useEffect, useState, useCallback } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { request as apiUpdateCurrentEarnings } from '#api/endpoints/talents/profile/answers/updateCurrentEarnings';
import { useSteps } from '../../BaseProfileEditModal';
import { TextInput } from '#components/Form/Index';

const EarningsStep: FC = () => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { setStepCallback, setNextDisabled } = useSteps();

  const [earnings, setEarnings] = useState<string>('');

  useEffect(() => {
    if (profilePreferencesData != null) {
      setEarnings(profilePreferencesData.profilePreferences.earnings);
    }
  }, [profilePreferencesData]);

  const stepCallback = useCallback(async () => {
    if (earnings.trim() === '') return false;

    try {
      await apiUpdateCurrentEarnings({ answer: { answer: earnings } });
      createSuccessToast({ title: 'Compensation' });
      return true;
    } catch {
      createErrorToast({ title: 'Compensation' });
      return false;
    }
  }, [earnings]);

  useEffect(() => {
    setNextDisabled(earnings.trim() === '');
  }, [setNextDisabled, earnings]);

  useEffect(() => {
    setStepCallback(() => stepCallback);
  }, [setStepCallback, stepCallback]);

  return (
    <div className="rich-text text-hpblack w-full">
      <h1>What do you currently earn?</h1>
      <TextInput label="" value={earnings} onChange={(e) => setEarnings(e.target.value)} />
    </div>
  );
};

export default EarningsStep;
