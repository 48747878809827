import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTalentDataController } from '../../../TalentDataController';
import { useStepController } from '../../../StepControllerContext';
import { Step, StepBackButton, StepNextButton } from '../../../CurrentStep';
import { OptionProps } from '#components/Index';
import ExperienceIndustriesForm from '#components/TalentSharedForms/ExperienceIndustries/Form';

const ExperienceIndustries: Step = {
  id: 'experience-industries',
  Body: ({
    setNextDisabled,
    setHandleNextClick,
  }: {
    setNextDisabled?: Dispatch<SetStateAction<boolean>>;
    setHandleNextClick?: Dispatch<SetStateAction<() => void>>;
  }) => {
    const { talentData, updateTalentData } = useTalentDataController();
    const { next } = useStepController();

    const [options, setOptions] = useState<OptionProps[]>([]);
    const initiallySelectedIds = talentData?.industryTypeExperienceIds ?? [];

    // Prevent the user from moving to the next step if they haven't selected any value
    useEffect(() => {
      if (setNextDisabled == null) return;

      setNextDisabled(!options.some((o: OptionProps) => o.checked));
    }, [options, setNextDisabled]);

    useEffect(() => {
      const handleNext = async () => {
        if (talentData == null) return;

        const selectedIndustryTypeIds = options.filter((option) => option.checked).map((option) => parseInt(option.id));

        const newTalentData = { ...talentData, industryTypeExperienceIds: selectedIndustryTypeIds };
        const result = await updateTalentData(newTalentData, 'industry_experience');

        if (result.success === true) {
          next();
        }
      };

      if (setHandleNextClick != null) {
        setHandleNextClick(() => handleNext);
      }
    }, [setHandleNextClick, options, talentData, next, updateTalentData]);

    return (
      <ExperienceIndustriesForm options={options} setOptions={setOptions} initiallySelectedIds={initiallySelectedIds} />
    );
  },
  Footer: ({ nextDisabled, onNextClick }: { nextDisabled?: boolean; onNextClick?: () => void }) => (
    <>
      <StepBackButton />
      <StepNextButton disabled={nextDisabled} onClick={onNextClick} />
    </>
  ),
};

export default ExperienceIndustries;
