import React, { FC, useCallback } from 'react';
import StateCardItem from './StateCardItem';

const stateCardItemData = [
  {
    title: 'Pending',
    description: 'Our team is reviewing your profile, you will hear from us soon',
  },
  {
    title: 'Put Forward',
    description: 'Your profile has been submitted to the employer, we will update you as soon as we have feedback',
  },
  {
    title: 'Interviewing',
    description: 'You are currently in the interview process',
  },
  {
    title: 'Decision',
    description: '',
  },
];

const StateCard: FC<{ currentStep: number }> = ({ currentStep }) => {
  const determineState = useCallback(
    (index: number) => {
      if (index < currentStep) {
        return 'completed';
      }

      if (index === currentStep) {
        return 'current';
      }

      return 'next';
    },
    [currentStep]
  );

  return (
    <div className="flex flex-col bg-gray-50 p-8 rounded-lg">
      <ul role="list">
        {stateCardItemData.map((item, index) => (
          <StateCardItem
            key={item.title}
            index={index + 1}
            title={item.title}
            description={item.description}
            state={determineState(index + 1)}
            isLast={index === stateCardItemData.length - 1}
          />
        ))}
      </ul>
    </div>
  );
};

export default StateCard;
