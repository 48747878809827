import React, { FC, ReactNode } from 'react';
import { ReactComponent as People } from '#assets/homepage/jobs/people.svg';
import { ReactComponent as Mail } from '#assets/homepage/jobs/mail.svg';
import { ReactComponent as Message } from '#assets/homepage/jobs/message.svg';
import { ReactComponent as Checklist } from '#assets/homepage/jobs/checklist.svg';

interface ItemProps {
  orderNumber: number;
  circleGraphic: ReactNode;
  title: string;
  text: string;
}

const Item: FC<ItemProps> = ({ circleGraphic, title, text, orderNumber }) => (
  <li>
    {circleGraphic}
    <div className="font-serif text-display-xs mt-5 mb-4">
      <div className="text-green-900">{orderNumber}</div>
      <div className="inline-block max-w-[14rem]">{title}</div>
    </div>
    <div className="mt-5 text-gray-600 text-sm">{text}</div>
  </li>
);

const FindYourNextRole: FC = () => {
  return (
    <div className="py-16 px-variable-md text-center">
      <h2 className="text-display-md font-serif max-w-xl mx-auto">How to find your next role on Hiperpool</h2>
      <ol className="mx-auto mt-14 max-w-5xl grid xs:grid-cols-2 md:grid-cols-4 gap-y-12 gap-x-4">
        <Item
          orderNumber={1}
          circleGraphic={<Checklist className="mx-auto" aria-hidden="true" />}
          title="Become a member for free"
          text="Answer a few questions about your background and career aspirations to become a member of the Hiperpool community. It's completely confidential and free."
        />
        <Item
          orderNumber={2}
          circleGraphic={<Mail className="mx-auto" aria-hidden="true" />}
          title="Receive hand-picked opportunities"
          text="Log in at your convenience to explore exciting jobs, while our team works in the background to send you hand-picked opportunities."
        />
        <Item
          orderNumber={3}
          circleGraphic={<Message className="mx-auto" aria-hidden="true" />}
          title="Get connected directly"
          text="When you’re interested in a role, let us know and we’ll connect you directly to the hiring manager."
        />
        <Item
          orderNumber={4}
          circleGraphic={<People className="mx-auto" aria-hidden="true" />}
          title="Interview and get hired"
          text="We’ll provide tailored resources to help you prepare for interviews, and a dedicated Talent Sponsor is always there to support you."
        />
      </ol>
    </div>
  );
};

export default FindYourNextRole;
