import React, { FC } from 'react';

import { CircleGraphic } from '#components/Index';
import AskKate from '#assets/homepage/jobs/ask-kate.webp';
import RelevantRole from '#assets/homepage/jobs/relevant-roles.png';
import HandPicked from '#assets/homepage/jobs/hand-picked.png';
import PanelTextSection from '../../elements/PanelTextSection';

const SetsApart: FC = () => {
  return (
    <>
      <div className="pt-24 bg-gray-50">
        <h2 className="text-display-md font-serif px-variable-lg text-center">
          What sets
          <br className="xs:hidden" /> Hiperpool apart
        </h2>
        <PanelTextSection
          image={<img loading="lazy" src={RelevantRole} alt="" width="330" height="218" aria-hidden />}
          indicatorIcon={
            <CircleGraphic className="w-12 h-12 max-sm:hidden">
              <CircleGraphic.Quarter x={1} y={1} color="hpblack" rotate={90} />
              <CircleGraphic.Circle x={2} y={1} color="green-500" radius={0.5} />
              <CircleGraphic.Quarter x={1} y={2} color="green-900" />
              <CircleGraphic.Quarter x={2} y={2} color="hpblack" rotate={-90} />
            </CircleGraphic>
          }
          header="Only relevant jobs ready to browse and filter based on your preferences"
          description="We're experts in this space and we continuously uncover a variety of exciting roles across a wide range of company types."
        />
      </div>
      <PanelTextSection
        className="sm:rtl"
        image={<img loading="lazy" src={HandPicked} alt="" width="430" height="236" aria-hidden />}
        indicatorIcon={
          <CircleGraphic className="w-12 h-12 max-sm:hidden">
            <CircleGraphic.Quarter x={1} y={1} color="green-500" rotate={180} />
            <CircleGraphic.Quarter x={2} y={1} color="green-900" rotate={90} />
            <CircleGraphic.Quarter x={1} y={2} color="green-500" />
            <CircleGraphic.Quarter x={2} y={2} color="hpblack" rotate={-90} />
          </CircleGraphic>
        }
        header="Hand-picked opportunities directly in your inbox"
        description="Our team manually identifies the best opportunities for you. We share all the job details upfront so you don't have to guess."
      />

      <div className="bg-gray-50">
        <PanelTextSection
          className="mb-16"
          image={<img loading="lazy" src={AskKate} alt="" height="231" width="310" aria-hidden />}
          indicatorIcon={
            <CircleGraphic className="w-12 h-12 max-sm:hidden">
              <CircleGraphic.Quarter x={1} y={1} color="green-500" rotate={-90} />
              <CircleGraphic.Quarter x={2} y={1} color="hpblack" rotate={0} />
              <CircleGraphic.Quarter x={1} y={2} color="hpblack" rotate={180} />
              <CircleGraphic.Quarter x={2} y={2} color="green-900" rotate={90} />
            </CircleGraphic>
          }
          header="Expert guidance and support at every stage"
          description="A dedicated Talent Sponsor will answer questions, provide feedback and support you throughout the interview process"
        />
      </div>
    </>
  );
};

export default SetsApart;
