import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { paths } from '#pages/paths';
import { Button, CircleGraphic, Icon } from '#src/components/Index';

const HowItWorksCard: FC = () => {
  const [isHidden, setIsHidden] = useState(false);

  return (
    <div className={`bg-green-200 mb-8 p-6 rounded-lg relative ${isHidden ? 'hidden' : ' hidden lg:block'}`}>
      <div className="flex items-start">
        <p>
          Your default filters are based on the preferences on your profile. You can update your preferences{' '}
          <Link className="underline" to={paths.talentPreferences()}>
            here
          </Link>
        </p>
        <button onClick={() => setIsHidden(true)} className="w-6 h-6" aria-label="Close">
          <Icon.X size={6} />
        </button>
      </div>

      <Button variant="secondary-light" size="small" className="bg-white mt-3" onClick={() => setIsHidden(true)}>
        Got it
      </Button>

      <CircleGraphic className="absolute right-0 bottom-0 w-16 h-16">
        <CircleGraphic.Quarter x={2} y={1} color="white" rotate={90} />
        <CircleGraphic.Quarter x={3} y={1} color="white" rotate={180} />
        <CircleGraphic.Quarter x={1} y={2} color="white" rotate={90} />

        <CircleGraphic.Quarter x={2} y={2} color="white" rotate={180} />
        <CircleGraphic.Quarter x={3} y={2} color="white" rotate={-90} />

        <CircleGraphic.Circle x={2} y={3} color="white" radius={0.5} />
        <CircleGraphic.Quarter x={3} y={3} color="white" rotate={180} />
      </CircleGraphic>
    </div>
  );
};

export default HowItWorksCard;
