import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Toaster } from 'react-hot-toast';

import './index.css';
import { AppRoutes } from './AppRoutes';
import CookieConsent from './components/CookieConsent/CookieConsent';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <HelmetProvider>
    <Helmet>
      <title>Hiperpool</title>
      <meta
        name="description"
        content="Empowering extraordinary careers for business leaders and changemakers. Discover Hiperpool's dedicated platform for management consulting, strategy, and investment professionals. Join us to help shape your career journey with tailored opportunities and expert guidance."
      />
    </Helmet>
    <React.StrictMode>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 2500,
        }}
      />
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
        <ReactQueryDevtools initialIsOpen={false} />
        <CookieConsent />
      </QueryClientProvider>
    </React.StrictMode>
  </HelmetProvider>
);
