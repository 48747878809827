import React, { FC } from 'react';
import { Button, Icon } from '#components/Index';
import { paths } from '#pages/paths';

const ProfileVisibilityInfo: FC = () => {
  return (
    <div className="flex flex-col items-center gap-y-4 sm:flex-row sm:justify-between mb-6">
      <div className="w-2/3 flex gap-x-2">
        <div className="flex mt-0.5">
          <Icon.EyeOffFill size={5} className="text-green-600" />
        </div>

        <p className="font-light text-gray-500">
          <span className="font-medium">Your profile is completely private.</span> We will only share your profile with
          a specific employer once you have confirmed interest in their role.
        </p>
      </div>

      <div>
        <Button variant="secondary" to={paths.talentProfileEmployerView()}>
          <Icon.EyeFill size={5} className="text-blue-500" /> View as Employer
        </Button>
      </div>
    </div>
  );
};

export default ProfileVisibilityInfo;
