import React, { FC, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import range from 'lodash/range';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { Education } from '#src/types';
import { useEducationController } from './FormController';
import { request as apiAutocompleteEducationInstitutes } from '#api/endpoints/autocomplete/educationInstitutes';
import { request as apiRemoveEducation } from '#root/src/api/endpoints/talents/profile/educations/removeEducation';
import { KeysEnum as ProfileDetailsKeys } from '../../../data/ProfileDetails';
import { TextInput, RHF } from '#components/Form/Index';
import { Button, Modal, Icon } from '#components/Index';

const queryKeys = [ProfileDetailsKeys.talents, ProfileDetailsKeys.profile, ProfileDetailsKeys.details];

const years = () => {
  const currentYear = new Date().getFullYear();
  return range(1970, currentYear + 6);
};

interface EducationModalProps {
  isOpen: boolean;
  closeModal: () => void;
  initialEducationValues: Education;
}

const EducationModal: FC<EducationModalProps> = ({ isOpen, closeModal, initialEducationValues }) => {
  const queryClient = useQueryClient();

  const successCallback = useCallback(() => {
    void queryClient.invalidateQueries(queryKeys);
    closeModal();
  }, [closeModal, queryClient]);

  const { form, submitForm } = useEducationController({
    ...initialEducationValues,
    successCallback,
  });

  const removeEducation = async (educationId: number) => {
    try {
      await apiRemoveEducation({ id: educationId });
      successCallback();
      createSuccessToast({ title: 'Education', text: 'Education has been successfully deleted.' });
    } catch {
      createErrorToast({ title: 'Education', text: 'Failed to delete education.' });
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const onSubmit = handleSubmit((values) => submitForm(values));

  const isExistingEducation = initialEducationValues.id !== 0;
  const headerText = isExistingEducation ? 'Edit Education entry' : 'Add Education entry';

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={onSubmit}>
        <div className="flex flex-col bg-gray-50 lg:w-[72rem]">
          <div className="pt-8 px-8">
            <div className="flex justify-around items-center pb-2">
              <div className="flex items-center gap-x-8 text-gray-500 text-sm">Education</div>

              <button
                onClick={closeModal}
                className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline cursor-pointer"
                aria-label="Close"
              >
                <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
              </button>
            </div>

            <div className="flex items-center justify-between">
              <h1 className="text-hpblack text-display-sm font-serif mb-4">{headerText}</h1>
              {isExistingEducation && (
                <div>
                  <Button
                    variant="negative-full"
                    size="small"
                    onClick={async () => await removeEducation(initialEducationValues.id)}
                  >
                    Remove <Icon.DeleteBinLine size={5} />
                  </Button>
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-y-4 flex-col px-8 pb-12">
            <RHF.AutocompleteInput
              label="College/University"
              placeholder="College/University"
              autocompleteRequest={apiAutocompleteEducationInstitutes}
              control={control}
              error={errors.college?.message}
              name="college"
            />

            <TextInput
              label="Degree"
              type="text"
              placeholder="E.g. Bachelor’s"
              error={errors.degree?.message}
              {...register('degree')}
            />

            <TextInput
              label="Field of Study"
              type="text"
              placeholder="E.g. Finance"
              error={errors.field?.message}
              {...register('field')}
            />

            <RHF.Select
              label="Year of graduation"
              control={control}
              error={errors.graduationYear?.message}
              name="graduationYear"
              options={[
                { text: 'Select year of graduation', value: '', disabled: true, hidden: true },
                ...years().map((year) => ({
                  text: year.toString(),
                  value: year.toString(),
                })),
              ]}
            />
          </div>
        </div>

        <div className="bg-white flex gap-x-8 justify-center px-8 py-6">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>

          <Button type="submit" variant="primary">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EducationModal;
