import { useEffect, useRef } from 'react';

const checkVisibility = (entries: IntersectionObserverEntry[], callback: (visible: boolean) => void) => {
  entries.forEach((entry) => {
    const isVisible = entry.isIntersecting;
    callback(isVisible);
  });
};

export const useIsRefOnScreen = (
  ref: React.RefObject<HTMLElement | null>,
  callback: (visible: boolean) => void,
  options?: IntersectionObserverInit | undefined
) => {
  useEffect(
    function observeVisibility() {
      const verifyVisibility = (entries: IntersectionObserverEntry[]) => checkVisibility(entries, callback);
      const observer = new IntersectionObserver(verifyVisibility, options);
      const currentRef = ref.current;

      if (currentRef != null) observer.observe(currentRef);
      return () => {
        if (currentRef != null) observer.unobserve(currentRef);
        observer.disconnect();
      };
    },
    [ref, callback, options]
  );
};

export const useIsRefOnScreenOnce = (
  ref: React.RefObject<HTMLElement | null>,
  callback: (visible: boolean) => void,
  options?: IntersectionObserverInit | undefined
) => {
  const wasVisible = useRef(false);

  const wrappedCallback = (visible: boolean) => {
    if (!wasVisible.current) {
      callback(visible);
    }
    if (visible) {
      wasVisible.current = true;
    }
  };
  useIsRefOnScreen(ref, wrappedCallback, options);
};
