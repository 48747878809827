import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors } from '#src/types';

interface Payload {
  init: {
    candidateId: number;
    feedback: {
      feedback_field: string;
      education: boolean;
      level_of_experience: boolean;
      type_of_experience: boolean;
      other_employer: boolean;
    };
  };
  success: never;
  fail: {
    errors: Errors<'base' | 'candidate'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(POST, `/employers/candidates/${params.candidateId}/reject`)(params);
