import React, { FC } from 'react';
import DOMPurify from 'dompurify';

interface EmployerMessageProps {
  date: string;
  message: string;
  isLast: boolean;
  companyLogoUrl: string;
}

const EmployerMessage: FC<EmployerMessageProps> = ({ date, message, isLast, companyLogoUrl }) => {
  return (
    <li>
      <div className="relative pb-4">
        {!isLast && <span className="absolute left-2.5 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true"></span>}
        <div className="relative flex space-x-3">
          <div>
            <div className="h-10 w-10 -mt-2 -ml-2 bg-white flex items-center justify-center rounded-full">
              <img src={companyLogoUrl} className="w-6 h-6 mix-blend-multiply" />
            </div>
          </div>
          <div className="flex flex-col text-md -mt-1">
            <div className="flex flex-col max-w-lg">
              <div
                className="bg-[#CBEDE7] text-hpblack font-light rounded-md p-4 rich-text rich-text-lists flex flex-col"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
              />
              <span className="text-sm font-light text-gray-500">{date}</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default EmployerMessage;
