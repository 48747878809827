import { Errors } from '#root/src/types';
import { createAction, PUT, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    linkedinUrl: string;
  };
  success: never;
  fail: {
    errors: Errors<'linkedinUrl'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(PUT, '/talents/profiles/linkedin');
