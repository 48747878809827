import * as apiGetLanguages from '#api/endpoints/onboarding/languages';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  languages = 'languages',
}

type GetApiPayload = apiGetLanguages.Type['success']['payload'];
export const useGetLanguages = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>([KeysEnum.languages], async () => await apiGetLanguages.request(), {
    ...options,
  });
