import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './elements/HeaderTopCoaches';
import SessionsAndCoaches from './elements/SessionsAndCoaches';
import HowItWorks from './elements/HowItWorks';
import Bundles from './elements/Bundles';
import BoughtPackagesConfirmationModal from './elements/BoughtPackagesConfirmationModal';

const Coaches: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Hiperpool - Coaching</title>
        <meta
          name="description"
          content="Hiperpool's Interview Prep offers personal coaching and exclusive content from top interviewers at firms like McKinsey, BCG, and Bain, starting at just $150 per session. Don't risk going unprepared – whether you're new or experienced in consulting, our top coaches provide up-to-date insights and exclusive resources to ensure you excel. Secure your future by investing in coaching that adapts to your schedule and equips you to land your dream job."
        />
      </Helmet>
      <Header />
      <SessionsAndCoaches />
      <Bundles />
      <HowItWorks />
      <BoughtPackagesConfirmationModal />
    </div>
  );
};

export default Coaches;
