import React, { FC, useMemo, useState } from 'react';
import { OpportunityThumbnail } from '#root/src/types';
import { OpportunityCard, Spinner } from '#components/Index';
import OpportunityRepliesModalManager, { OpportunityReplyModalType } from '../../modals/OpportunityRepliesModalManager';

interface OpportunityListProps {
  opportunities: OpportunityThumbnail[];
  isLoading: boolean;
  emptyState: JSX.Element;
  refetchOpportunities: () => void;
}

export const OpportunitiesList: FC<OpportunityListProps> = ({
  opportunities,
  emptyState,
  isLoading,
  refetchOpportunities,
}) => {
  const [selectedOpportunity, setSelectedOpportunity] = useState<OpportunityThumbnail | null>(null);
  const [selectedModal, setSelectedModal] = useState<OpportunityReplyModalType>(null);

  const handleNotInterested = (opportunity: OpportunityThumbnail) => {
    setSelectedOpportunity(opportunity);
    setSelectedModal('notInterested');
  };

  const sortedOpportunities = useMemo(() => {
    return opportunities.sort((a, b) => {
      const aType = a.candidacy.state.type;
      const bType = b.candidacy.state.type;

      const aDate = new Date(a.candidacy.state.date);
      const bDate = new Date(b.candidacy.state.date);

      if (aType === 'archived' || aType === 'not_interested') {
        return 1;
      }
      if (bType === 'archived' || bType === 'not_interested') {
        return -1;
      }

      return bDate.getTime() - aDate.getTime();
    });
  }, [opportunities]);

  if (isLoading) {
    return (
      <div className="lg:basis-3/4 flex flex-col gap-y-8 px-2">
        <Spinner className="py-32" />
      </div>
    );
  }

  return (
    <div className="lg:basis-3/4 flex flex-col gap-y-8 px-2">
      <OpportunityRepliesModalManager
        opportunity={selectedOpportunity}
        selectedModal={selectedModal}
        setSelectedModal={setSelectedModal}
        refetch={refetchOpportunities}
      />

      {sortedOpportunities.length > 0 ? (
        <>
          {sortedOpportunities.map((opportunity) => (
            <OpportunityCard
              key={opportunity.id}
              opportunity={opportunity}
              handleNotInterested={handleNotInterested}
              isClickable
            />
          ))}
        </>
      ) : (
        emptyState
      )}
    </div>
  );
};

export default OpportunitiesList;
