import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors } from '#root/src/types';
interface Payload {
  init: { csId: string };
  success: {
    packages: Array<{ name: string; quantity: number }>;
  };
  fail: {
    errors: Errors<'base'>; // "You are not allowed to access this resource"
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(GET, `/talents/checkouts/${params.csId}/bought_packages`)(params);
