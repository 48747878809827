import React, { FC } from 'react';
import { Button, CallToActionSection } from '#components/Index';
import { paths } from '#pages/paths';

const ReadyToStart: FC = () => {
  return (
    <CallToActionSection
      text={<>Ready to get started?</>}
      button={
        <Button size="large" to={paths.coaches()}>
          Browse coaches
        </Button>
      }
    />
  );
};

export default ReadyToStart;
