import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCandidateDetails } from './CandidateDetails';
import { Spinner } from '#components/Index';
import NotFound from '#pages/NotFound';
import ProfileForEmployer from '#pages/talentPages/profileAndPreferences/profile/elements/ProfileForEmployer';
import ResponseBox from './elements/ResponseBox';
import CandidateRepliesModalManager, { CandidateReplyModalType } from '../candidateModals/CandidateRepliesModalManager';

const CandidatePreview: FC = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetCandidateDetails(Number(id));

  const [selectedModal, setSelectedModal] = useState<CandidateReplyModalType>(null);

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  const { profile, candidate } = data;

  return (
    <div>
      <ResponseBox candidate={candidate} setSelectedModal={setSelectedModal} />

      <ProfileForEmployer profile={profile} />

      <CandidateRepliesModalManager
        profile={profile}
        candidateId={candidate.id}
        selectedModal={selectedModal}
        setSelectedModal={setSelectedModal}
        refetch={refetch}
      />
    </div>
  );
};

export default CandidatePreview;
