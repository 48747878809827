import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors, OpportunityThumbnail, OpportunitySearchParams } from '#src/types';

interface Payload {
  init: {
    search: OpportunitySearchParams;
  };
  success: { opportunities: OpportunityThumbnail[] };
  fail: { errors: Errors<'base' | 'search'>; status: number };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init'], signal: AbortSignal | undefined) =>
  await createAction<Payload>(POST, '/talents/opportunities')(params, { signal });
