import React, { FC } from 'react';
import { useGetDummyOpportunities } from './data/DummyOpportunitiesData';
import OpportunitiesList from './elements/OpportunitiesList';
import OpportunityNavbar from './elements/OpportunityNavbar';
import LockedLimited from './elements/LockedLimited';
import LockedRejected from './elements/LockedRejected';

interface LockedOpportunitiesProps {
  lockType: 'limited' | 'rejected';
}

const LockedOpportunities: FC<LockedOpportunitiesProps> = ({ lockType }) => {
  const { data: dummyOpportunities, isLoading } = useGetDummyOpportunities();

  const lockedComponent = lockType === 'limited' ? <LockedLimited /> : <LockedRejected />;

  return (
    <div className="relative">
      {lockedComponent}

      <div className="grow flex flex-col max-w-8xl mx-auto py-12 blur-sm z-10">
        <OpportunityNavbar opportunitySection={'all'} changeOpportunitySection={() => {}} />

        <div className="flex flex-row gap-x-8 justify-center px-4">
          <OpportunitiesList
            isLoading={isLoading}
            opportunities={dummyOpportunities?.opportunities ?? []}
            emptyState={<></>}
            refetchOpportunities={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default LockedOpportunities;
