import React, { FC } from 'react';

import { Button } from '#components/Index';
import AntonioLeone from '#assets/people/antonioLeoneGray.webp';
import MohamadElRafei from '#assets/people/mohamadElRafeiGray.webp';
import KateClapp from '#assets/people/kateClappGray.webp';
import { paths } from '#pages/paths';

interface Props {
  header: string;
  description: string;
}
const HiperpoolFounders: FC<Props> = ({ header, description }) => {
  return (
    <div className="isolate px-variable-lg">
      <div className="max-w-4xl mx-auto items-center grid lg:grid-cols-2 gap-8 xl:gap-36">
        <div>
          <h2 className="text-display-xs font-serif">{header}</h2>
          <p className="mt-3.5 mb-6">{description}</p>
          <Button variant="secondary" to={paths.about()}>
            Who is Hiperpool?
          </Button>
        </div>

        <div className="flex max-lg:grid justify-start gap-6 lg:gap-8 xl:gap-10 grid-cols-[repeat(auto-fit,minmax(100px,auto))]">
          <img src={AntonioLeone} width="200" height="200" loading="lazy" alt="Antonio Leone Co-Founder of Hiperpool" />
          <img
            src={MohamadElRafei}
            width="200"
            height="200"
            loading="lazy"
            alt="Mohamad El Rafei Co-Founder of Hiperpool"
          />
          <img src={KateClapp} width="200" height="200" loading="lazy" alt="Kate Clapp, Talent Sponsor at Hiperpool" />
        </div>
      </div>
    </div>
  );
};

export default HiperpoolFounders;
