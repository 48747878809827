import React, { FC, Dispatch, SetStateAction } from 'react';
import FilterBadge from '#components/FilterBadge/FilterBadge';

const activityFilterOptions = [
  {
    label: 'All Active',
    value: 'all',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Put Forward',
    value: 'put-forward',
  },
  {
    label: 'Interviewing',
    value: 'interviewing',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
];

interface SidebarProps {
  selectedFilter: string;
  setSelectedFilter: Dispatch<SetStateAction<string>>;
}

const JobsFilters: FC<SidebarProps> = ({ selectedFilter, setSelectedFilter }) => {
  return (
    <div className="p-4 lg:mt-4">
      <div className="flex flex-wrap gap-2">
        {activityFilterOptions.map((filter) => (
          <FilterBadge
            key={filter.value}
            handleChange={() => setSelectedFilter(filter.value)}
            checked={selectedFilter === filter.value}
            secondary
          >
            {filter.label}
          </FilterBadge>
        ))}
      </div>
    </div>
  );
};

export default JobsFilters;
