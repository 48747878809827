import React, { FC } from 'react';

interface PutForwardHistoryEntryProps {
  date: string;
  message: string;
  isLast: boolean;
}

const PutForwardHistoryEntry: FC<PutForwardHistoryEntryProps> = ({ date, message, isLast }) => {
  return (
    <>
      <li>
        <div className="relative pb-8">
          {!isLast && <span className="absolute left-2.5 -ml-px h-full w-0.5 bg-gray-300" aria-hidden="true"></span>}
          <div className="relative flex flex-col space-x-3">
            <div className="flex gap-x-2 items-center w-fit bg-green-200 py-0.5 px-2 -ml-2 rounded-full">
              <span className="flex h-3 w-3 ml-1 text-xs items-center justify-center rounded-full bg-green-900 text-white"></span>
              <span className="text-green-900 uppercase font-semibold text-sm"> Put Forward</span>
            </div>

            <div className="flex flex-col text-md pl-8 max-w-2xl">
              <p className="text-hpblack text-md font-light mt-2"> {message}</p>
              <p className="text-sm text-gray-500 font-light">{date}</p>
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default PutForwardHistoryEntry;
