import * as apiGetWorkAuthorizationGroups from '#api/endpoints/onboarding/workAuthorizationGroups';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  workAuthorizations = 'workAuthorizations',
}

type GetApiPayload = apiGetWorkAuthorizationGroups.Type['success']['payload'];
export const useGetWorkAuthorizationGroups = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>([KeysEnum.workAuthorizations], async () => await apiGetWorkAuthorizationGroups.request(), {
    ...options,
  });
