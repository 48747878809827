import { MemoVoidDictionaryIterator } from 'lodash';
import { snakeCase, camelCase, isArray, isObjectLike, isPlainObject, map, transform, set } from 'lodash-es';

type NodeType = any;

type Converter = (string?: string) => string;
function createIteratee(
  converter: Converter,
  self: (node: NodeType) => NodeType
): MemoVoidDictionaryIterator<NodeType, string, any> {
  return (result, value, key) => set(result, converter(key), isObjectLike(value) ? self(value) : value);
}

function createHumps(keyConverter: Converter) {
  return function humps(node: NodeType): NodeType {
    if (isArray(node)) {
      return map(node, humps);
    }
    if (isPlainObject(node)) {
      return transform(node, createIteratee(keyConverter, humps));
    }
    return node;
  };
}

export const transformToCamelCase = createHumps(camelCase);
export const transformToSnakeCase = createHumps(snakeCase);
