import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { TalentProfile } from '#root/src/types';

interface Payload {
  init: never;
  success: { profile: TalentProfile };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/talents/profiles');
