import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

import * as apiGetListOfBundles from '#api/endpoints/public/bundleSessionPackages';

export enum KeysEnum {
  components = 'components',
  coachBundles = 'coach-bundles',
}

type GetApiPayload = apiGetListOfBundles.Type['success']['payload'];
export const useBundleList = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.components, KeysEnum.coachBundles],
    async () => await apiGetListOfBundles.request(),
    {
      ...options,
    }
  );
