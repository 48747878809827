import React, { FC } from 'react';

interface Props {
  image?: React.ReactNode;
  name?: string;
  jobDescription?: string;
  jobLogo?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

const BlockQuote: FC<Props> = ({ image, name, jobDescription, jobLogo, className = '', children }) => {
  const hasImage = image != null;
  const hasJobDescription = jobDescription !== null && jobDescription !== '';

  return (
    <figure
      className={`grid grid-rows-[auto_auto_1fr] items-center gap-y-5 gap-x-12 md:gap-x-16 lg:gap-x-24 ${
        hasImage ? 'sm:grid-cols-2' : ''
      } ${className}`}
    >
      {hasImage && <div className="row-start-1 row-span-full flex sm:justify-end">{image}</div>}

      <div className="font-serif text-display-2xl text-green-500 h-[.5em]" aria-hidden="true">
        “
      </div>

      <blockquote className="rich-text text-xl">{children}</blockquote>

      <figcaption className="flex gap-4">
        {jobLogo != null && <div className="shrink-0 self-center">{jobLogo}</div>}

        <div className="grid gap-1 items-center py-1 leading-snug">
          <div className="font-semibold text-lg">{name}</div>
          {hasJobDescription && <div className="text-sm font-medium">{jobDescription}</div>}
        </div>
      </figcaption>
    </figure>
  );
};

export default BlockQuote;
