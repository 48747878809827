import React, { FC } from 'react';
import { ExpandableSection } from '#components/Index';

const sections: Array<{ heading: string; content: React.ReactNode }> = [
  {
    heading: 'What makes Hiperpool different?',
    content:
      'Hiperpool is the leading career development and recruitment platform for top tier business professionals. Our expertise allows us to source only the best opportunities and handpick the right ones for you based on your preferences and experience.',
  },
  {
    heading: 'Why should I interview through Hiperpool?',
    content:
      'When you’re interested in a role, we put you forward directly to the hiring manager or relevant contact at the company. This process shortcuts an online application and guarantees that your candidacy is reviewed in detail. Plus, we’ll support you throughout your interview process with prep materials, specific insights, salary negotiations and anything else you might need.',
  },
  {
    heading: 'Will my employer see my profile?',
    content:
      'No, Hiperpool is completely confidential and your profile is not visible to your employer or any other employer. We only share your profile with an employer once you have expressed interest in a specific role.',
  },
  {
    heading: 'What type of professionals are on Hiperpool?',
    content:
      'Our members are experienced professionals with backgrounds in strategy, consulting, M&A, PE / VC and similar disciplines.We seek professionals with excellent track records – graduates of top schools and employees of reputable companies.',
  },
  {
    heading: 'What companies use Hiperpool?',
    content:
      'Employers on Hiperpool vary from high-growth startups and exciting corporates, to prestigious advisory and investment management firms.',
  },
  {
    heading: 'How long will it take for my profile to be approved?',
    content:
      'We review and approve profiles as quickly as possible. If you have the background and experience we’re looking for, typically your profile will be approved within 48 hours.',
  },
  {
    heading: 'What locations are employers recruiting for?',
    content:
      'Employers on Hiperpool are recruiting for roles across all major international business hubs, with a strong presence in the United States, United Kingdom and Germany.',
  },
  {
    heading: 'What happens when I’m interested in an opportunity?',
    content:
      'When you’re interested in a role and are ready to start interviewing, let us know and we’ll connect you directly to the hiring manager. If the employer is also interested, they will reach out to you directly with next steps. Our Talent Sponsor team is always there to support you and content headings.',
  },
  {
    heading: 'Are there any internships or freelance project opportunities?',
    content: 'Currently, employers on the Hiperpool platform are only looking to hire for permanent positions.',
  },

  {
    heading: 'Are companies screened before being granted access to the platform?',
    content:
      'Both candidates and employers are screened before being granted access to the platform. Companies must have a clear need to hire, and be able to provide roles and compensation packages attractive to the candidates. Our screening process aims to make sure that approved candidates and companies can add value to the platform.',
  },
  {
    heading: 'As a professional do I have to pay for any services?',
    content: 'Nope! Hiperpool is completely free to use for professional members of the community.',
  },
];

const CandidateFAQ: FC = () => {
  return (
    <>
      {sections.map((section) => (
        <ExpandableSection key={section.heading} {...section} />
      ))}
    </>
  );
};

export default CandidateFAQ;
