import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors } from '#src/types';

interface Payload {
  init: {
    opportunityId: number;
    feedback: {
      location: boolean;
      industry: boolean;
      company_type: boolean;
      specific_company: boolean;
      seniority: boolean;
      function: boolean;
      salary: boolean;
      other: boolean;
      feedback_field: string;
    };
  };
  success: never;
  fail: { errors: Errors<'base' | 'candidate'>; status: number };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(POST, `/talents/opportunities/${params.opportunityId}/replies/not_interested`)(params);
