import { DateTime } from 'luxon';

export function timeBetween(datetimeFrom: DateTime, datetimeTo: DateTime) {
  const diff = datetimeTo.diff(datetimeFrom, ['days', 'hours', 'minutes']);

  const humanDiffArray = diff
    .toHuman({ maximumFractionDigits: 0 })
    .split(', ')
    .filter((s) => !s.startsWith('0 '));

  return humanDiffArray.length === 0 || humanDiffArray[0].startsWith('-0') ? '' : humanDiffArray[0];
}

export function expiresIn(createdDatetime: string | undefined) {
  if (createdDatetime === undefined || isNaN(Date.parse(createdDatetime))) {
    return 'expires after 60 days';
  }

  const expireyDatetime = DateTime.fromISO(createdDatetime, { locale: 'en-US' }).plus({ days: 60 });
  const diff = timeBetween(DateTime.now(), expireyDatetime);

  return diff === '' || diff.startsWith('-') ? 'expires soon' : `expires in ${diff}`;
}

export function timeToSession(datetime: string) {
  const diff = timeBetween(DateTime.now(), DateTime.fromISO(datetime, { locale: 'en-US' }));

  return diff !== '' ? `In ${diff}` : '';
}
