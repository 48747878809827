import { createFormDataAction, POST, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors, TalentData } from '#root/src/types';

interface Payload {
  init: TalentData;
  success: TalentData;
  fail: {
    // TODO: add more error fields
    errors: Errors<
      | 'base'
      | 'workInterestSituationId'
      | 'desiredEmploymentTypeIds'
      | 'employmentBranchInterestIds'
      | 'linkedinUrl'
      | 'position'
      | 'company'
      | 'location'
      | 'startDate'
      | 'endDate'
    >;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createFormDataAction<Payload>(POST, '/talents/onboarding')(params);
