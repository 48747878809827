import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { TextInput, PhoneInput, Success, RHF } from '#components/Form/Index';
import { Button, ErrorAlert, Icon } from '#components/Index';
import { useSignUpController, FormValues } from './FormController';
import { paths } from '#pages/paths';
interface Props {
  defaultValues?: Partial<FormValues>;
  countries: string[];
}
const SignUpForm: FC<Props> = ({ defaultValues, countries }) => {
  const { form, submitForm, baseErrors } = useSignUpController(defaultValues);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
  } = form;

  if (isSubmitSuccessful) {
    return (
      <Success title="Thank you for reaching out." icon={<Icon.MailCheckLine size={6} />}>
        <div className="rich-text">
          <p>Your request has been submitted successfully. A member of our team will be in touch with you shortly.</p>
        </div>
        <div className="mt-8">
          <Button to={paths.employer()} size="large">
            Back to homepage
          </Button>
        </div>
      </Success>
    );
  }

  return (
    <form onSubmit={handleSubmit(submitForm)} className="w-full grid gap-6 text-sm text-gray-700 font-medium">
      <div className="grid sm:grid-cols-2 gap-6">
        <TextInput
          required
          label="First name"
          type="text"
          error={errors.firstName?.message}
          {...register('firstName')}
        />
        <TextInput required label="Last name" type="text" error={errors.lastName?.message} {...register('lastName')} />
      </div>
      <div className="grid sm:grid-cols-2 gap-6">
        <TextInput required label="City" type="text" error={errors.city?.message} {...register('city')} />
        <RHF.Select
          control={control}
          name="country"
          label="Country"
          options={countries.map((country) => ({ text: country, value: country }))}
        />
      </div>
      <TextInput required label="Job title" type="text" error={errors.position?.message} {...register('position')} />
      <PhoneInput
        label="Phone"
        type="tel"
        error={errors.phonePrefix != null ? errors.phonePrefix.message : errors.phone?.message}
        setValue={form.setValue}
        register={form.register}
        {...register('phone')}
      />
      <TextInput required label="Work email" type="email" error={errors.email?.message} {...register('email')} />
      <TextInput
        required
        label="Choose a password"
        type="password"
        autoComplete="new-password"
        description="Min. 8 characters"
        error={errors.password?.message}
        {...register('password')}
      />
      <p>
        By signing up, I agree to Hiperpool’s{' '}
        <Link to={paths.terms()} className="link">
          Terms
        </Link>
        ,{' '}
        <Link to={paths.cookies()} className="link">
          Cookies
        </Link>{' '}
        and{' '}
        <Link to={paths.privacy()} className="link">
          Privacy Policy
        </Link>
        .
      </p>
      <ErrorAlert text={baseErrors} />
      <Button type="submit" className="xs:mx-auto" size="large">
        Sign up
      </Button>
    </form>
  );
};

export default SignUpForm;
