import React, { FC } from 'react';

export interface StateCardItemProps {
  title: string;
  description: string;
  index: number;
  state: 'completed' | 'current' | 'next';
  isLast?: boolean;
}

const StateCardItem: FC<StateCardItemProps> = ({ index, title, description, state, isLast = false }) => {
  const indexOrIcon = () => {
    switch (state) {
      case 'completed':
        return (
          <span className="flex h-4 w-4 text-xs items-center justify-center rounded-full bg-green-500 text-white">
            <svg className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path
                fillRule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        );
      case 'current':
        return (
          <span className="flex h-4 w-4 text-xs items-center justify-center rounded-full bg-green-500 text-white">
            {index}
          </span>
        );
      case 'next':
        return (
          <span className="flex h-4 w-4 text-xs items-center justify-center rounded-full bg-gray-300 text-white">
            {index}
          </span>
        );
    }
  };

  const shouldDisplayDescription = () => {
    return state === 'current' && description.length > 0;
  };

  const titleClasses = () => {
    switch (state) {
      case 'completed':
        return 'text-hpblack';
      case 'current':
        return 'text-hpblack font-semibold';
      case 'next':
        return 'text-gray-400';
    }
  };

  return (
    <li>
      <div className={`relative ${isLast ? '' : 'pb-4'}`}>
        {!isLast && <span className="absolute left-2 top-2 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>}
        <div className="relative flex space-x-3">
          <div>{indexOrIcon()}</div>
          <div className="flex flex-col text-md -mt-1">
            <p className={titleClasses()}>{title}</p>
            {shouldDisplayDescription() && <p className="text-gray-400 font-light">{description}</p>}
          </div>
        </div>
      </div>
    </li>
  );
};

export default StateCardItem;
