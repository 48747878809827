import React, { FC } from 'react';
import { Icon } from '#src/components/Index';

interface EmptyJobsContentProps {
  currentFilter: string;
}

const humanizeFilterName = (filter: string) => {
  switch (filter) {
    case 'all':
      return 'active';
    case 'pending':
      return 'pending';
    case 'put-forward':
      return 'put forward';
    case 'interviewing':
      return 'interviewing';
    case 'archived':
      return 'archived';
    default:
      return 'all';
  }
};

const EmptyJobsContent: FC<EmptyJobsContentProps> = ({ currentFilter }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <Icon.EmptyBookmarked size={12} />
      <h3 className="text-display-sm font-serif text-hpblack mt-4">
        No opportunities in {humanizeFilterName(currentFilter)}
      </h3>
    </div>
  );
};

export default EmptyJobsContent;
