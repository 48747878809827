import * as apiGetExperienceOptions from '#api/endpoints/onboarding/experienceOptions';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  experienceOptions = 'experience-options',
}

type GetApiPayload = apiGetExperienceOptions.Type['success']['payload'];
export const useGetExperienceOptions = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>([KeysEnum.experienceOptions], async () => await apiGetExperienceOptions.request(), {
    ...options,
  });
