import { Errors } from '#root/src/types';
import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    slug: string;
    timezone: string;
    selectedTime: string; // "selected_time":"2023-04-17T19:00:00.000000Z", // it should be UTC format
  };
  success: { schedulingLink: string; coachingSessionId: number };
  fail: { errors: Errors<'calendly'>; status: number };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(POST, `/talents/coaches/${params.slug}/schedule_session`)(params);
