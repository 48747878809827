/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { uniqueId } from 'lodash-es';
import textBoxStyles from '#components/Form/styles/TextBox.module.css';
import formStyles from '#components/Form/styles/FormInput.module.css';

interface Props
  extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label: string;
  error?: string;
  description?: string;
}
const TextArea = forwardRef<HTMLTextAreaElement, Props>(({ error, description, label, ...rest }, ref) => {
  const uniqId = uniqueId('textarea');
  const hasError = error !== undefined && error !== '';
  const hasDescription = description !== undefined && description !== '';

  const renderDetails = () => {
    if (hasError) {
      return (
        <span className={formStyles.error} data-testid="error">
          {error}
        </span>
      );
    }
    if (hasDescription) {
      return (
        <span className={formStyles.description} data-testid="description">
          {description}
        </span>
      );
    }
    return <></>;
  };

  return (
    <div className={formStyles['input-wrapper']}>
      <label htmlFor={uniqId} className={formStyles.label} data-testid="label">
        {label}
      </label>
      <textarea
        {...rest}
        id={uniqId}
        className={`${textBoxStyles.input} ${hasError ? textBoxStyles[`input--error`] : ''}`}
        ref={ref}
      />
      {renderDetails()}
    </div>
  );
});

export default TextArea;
