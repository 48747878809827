import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Step, StepBackButton, StepNextButton } from '../../CurrentStep';
import { useTalentDataController } from '../../TalentDataController';
import { useStepController } from '../../StepControllerContext';
import WorkPoliciesForm from '#components/TalentSharedForms/WorkPolicies/Form';

const WorkPolicy: Step = {
  id: 'work-policy',
  Body: ({
    setNextDisabled,
    setHandleNextClick,
  }: {
    setNextDisabled?: Dispatch<SetStateAction<boolean>>;
    setHandleNextClick?: Dispatch<SetStateAction<() => void>>;
  }) => {
    const { talentData, updateTalentData } = useTalentDataController();
    const { next } = useStepController();

    const [selectedWorkPolicies, setSelectedWorkPolicies] = useState<number[]>([]);

    // Set the selected work policies based on the talent data
    useEffect(() => {
      if (talentData == null) return;

      setSelectedWorkPolicies(talentData.workPolicyIds);
    }, [talentData]);

    // Prevent the user from moving to the next step if they haven't selected any value
    useEffect(() => {
      if (setNextDisabled == null) return;

      setNextDisabled(selectedWorkPolicies.length === 0);
    }, [selectedWorkPolicies, setNextDisabled]);

    useEffect(() => {
      const handleNext = async () => {
        if (talentData == null) {
          return;
        }

        const result = await updateTalentData({ ...talentData, workPolicyIds: selectedWorkPolicies }, 'work_policies');

        if (result.success === true) {
          next();
        }
      };

      if (setHandleNextClick != null) {
        setHandleNextClick(() => handleNext);
      }
    }, [setHandleNextClick, selectedWorkPolicies, talentData, next, updateTalentData]);

    return (
      <WorkPoliciesForm setSelectedWorkPolicies={setSelectedWorkPolicies} selectedWorkPolicies={selectedWorkPolicies} />
    );
  },
  Footer: ({ nextDisabled, onNextClick }: { nextDisabled?: boolean; onNextClick?: () => void }) => (
    <>
      <StepBackButton />
      <StepNextButton disabled={nextDisabled} onClick={onNextClick} />
    </>
  ),
};

export default WorkPolicy;
