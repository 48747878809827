import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { OptionProps, SearchSelect } from '#components/Index';
import { Step, StepBackButton, StepNextButton } from '../../../CurrentStep';
import { useGetInterestedFirmsOptions } from './ExperienceFirmsOptions';
import { useTalentDataController } from '../../../TalentDataController';
import { useStepController } from '../../../StepControllerContext';

const displayName = 'Corporate';

const Corporate: Step = {
  id: 'experience-corporate',
  displayName,
  Body: ({
    setNextDisabled,
    setHandleNextClick,
  }: {
    setNextDisabled?: Dispatch<SetStateAction<boolean>>;
    setHandleNextClick?: Dispatch<SetStateAction<() => void>>;
  }) => {
    const [options, setOptions] = useState<OptionProps[]>([]);
    const { talentData, updateTalentData } = useTalentDataController();
    const { next } = useStepController();
    const { data } = useGetInterestedFirmsOptions();

    useEffect(() => {
      const industryTypes = data?.employmentBranches.find((d) => d.name === displayName)?.children;

      if (industryTypes != null) {
        setOptions(
          industryTypes.map((option) => ({
            id: option.id.toString(),
            name: option.name,
            checked: talentData?.employmentBranchExperienceIds.includes(option.id) ?? false,
          }))
        );
      }
    }, [talentData, data]);

    // Prevent the user from moving to the next step if they haven't selected any value
    useEffect(() => {
      if (setNextDisabled != null) {
        setNextDisabled(options.every((option) => option.checked === false));
      }
    }, [options, setNextDisabled]);

    const handleChange = (newOptions: OptionProps[]) => {
      setOptions(newOptions);
    };

    useEffect(() => {
      const handleNext = async () => {
        if (talentData == null) {
          return;
        }

        const optionIds = options.map((option) => parseInt(option.id));
        const selectedBranchIds = options.filter((option) => option.checked).map((option) => parseInt(option.id));

        // Remove the unselected options from current step,then add the selected options
        const newEmploymentBranchExperienceIds = talentData.employmentBranchExperienceIds.filter(
          (id) => !optionIds.includes(id)
        );
        newEmploymentBranchExperienceIds.push(...selectedBranchIds);

        const newTalentData = { ...talentData, employmentBranchExperienceIds: newEmploymentBranchExperienceIds };

        const result = await updateTalentData(newTalentData, 'types_of_functions_experience');

        if (result.success === true) {
          next();
        }
      };

      if (setHandleNextClick != null) {
        setHandleNextClick(() => handleNext);
      }
    }, [setHandleNextClick, options, talentData, next, updateTalentData]);

    return (
      <>
        <div className="rich-text">
          <h1>
            What types of functions in <span className="text-green-900">Corporate</span> do you have full-time
            experience in?
          </h1>
          <p>Select all that apply</p>
        </div>

        <div className="flex flex-col mt-8">
          <SearchSelect label="Select an industry" handleChange={handleChange} options={options} />
        </div>
      </>
    );
  },
  Footer: ({ nextDisabled, onNextClick }: { nextDisabled?: boolean; onNextClick?: () => void }) => (
    <>
      <StepBackButton />
      <StepNextButton disabled={nextDisabled} onClick={onNextClick} />
    </>
  ),
};

export default Corporate;
