import { ReactQueryOptions } from '#src/types';
import { useQuery } from 'react-query';

import * as apiGetOpportunityFilterOptions from '#api/endpoints/talents/opportunities/filterOptions';

export enum KeysEnum {
  talents = 'talents',
  opportunities = 'opportunities',
  filterOptions = 'filter-options',
}

type GetApiPayload = apiGetOpportunityFilterOptions.Type['success']['payload'];

export const useGetOpportunityFilterOptions = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.talents, KeysEnum.opportunities, KeysEnum.filterOptions],
    async () => await apiGetOpportunityFilterOptions.request(),
    {
      ...options,
    }
  );
