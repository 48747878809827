import React, { FC, useState } from 'react';
import { toast } from 'react-hot-toast';
import ErrorAlert from '#components/Notifications/ErrorAlert';
import SuccessAlert from '#components/Notifications/SuccessAlert';

interface ToastWrapperProps {
  type: 'error' | 'success';
  title?: string;
  message: string | null;
  withoutClose?: boolean;
}

const ToastWrapper: FC<ToastWrapperProps> = ({ type, title, message, withoutClose = false }) => {
  const [text, setText] = useState<string | null>(message);

  const notificationComponent = type === 'error' ? ErrorAlert : SuccessAlert;

  const onClose = () => {
    if (withoutClose) {
      return null;
    }

    setText(null);
  };

  return <div className="min-w-96">{notificationComponent({ title, text, onClose })}</div>;
};

interface ToastProps {
  type: ToastWrapperProps['type'];
  title?: string;
  text?: string | null;
  onClose?: () => void;
}

const createToast = ({ type, title, text }: ToastProps) => {
  if (text == null) return;

  toast.custom(<ToastWrapper type={type} title={title} message={text} />);
};

export const createSuccessToast = ({ title, text = 'Data updated successfully.' }: Omit<ToastProps, 'type'>) => {
  createToast({ type: 'success', title, text });
};

export const createErrorToast = ({ title, text = 'Unable to save. Please try again.' }: Omit<ToastProps, 'type'>) => {
  createToast({ type: 'error', title, text });
};
