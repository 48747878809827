import React, { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import DOMPurify from 'dompurify';
import { OpportunityCard, TruncatedContent, TeamMemberCard, Spinner } from '#src/components/Index';
import { useGetOpportunityDetails } from './elements/OpportunityDetailsData';
import { KeysEnum as handpickedOpportunitiesKeys } from '#pages/talentPages/opportunities/index/data/HandpickedOpportunitiesData';
import { KeysEnum as filteredOpportunitiesKeys } from '#pages/talentPages/opportunities/index/data/FilteredOpportunitiesData';
import TalentSponsorCard from './elements/TalentSponsorCard/TalentSponsorCard';
import Header from './elements/Header';
import NotFound from '#pages/NotFound';
import OpportunityRepliesModalManager, {
  OpportunityReplyModalType,
} from '#pages/talentPages/opportunities/modals/OpportunityRepliesModalManager';

const filteredOpportunitiesQueryKeys = [
  filteredOpportunitiesKeys.talents,
  filteredOpportunitiesKeys.filteredOpportunities,
];

const handpickedOpportunitiesQueryKeys = [
  handpickedOpportunitiesKeys.talents,
  handpickedOpportunitiesKeys.opportunities,
  handpickedOpportunitiesKeys.handpickedOpportunities,
];

const OpportunityDetails: FC = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { data, isLoading, refetch } = useGetOpportunityDetails(Number(id));

  const [selectedModal, setSelectedModal] = useState<OpportunityReplyModalType>(null);

  const shouldDisplayTalentSponsor = useMemo(() => {
    const talentSponsorVisibleCardStates = ['interested', 'awaiting_response', 'handpicked'];

    return talentSponsorVisibleCardStates.includes(data?.opportunity.candidacy.state.type ?? '');
  }, [data?.opportunity.candidacy.state.type]);

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  const refetchData = async () => {
    await refetch();
    await queryClient.invalidateQueries(filteredOpportunitiesQueryKeys);
    await queryClient.invalidateQueries(handpickedOpportunitiesQueryKeys);
  };

  return (
    <div className="min-h-full">
      <OpportunityRepliesModalManager
        opportunity={data.opportunity}
        selectedModal={selectedModal}
        setSelectedModal={setSelectedModal}
        refetch={refetchData}
      />

      <div className="bg-hpblack">
        <Header withLink />
      </div>
      <div className="grow flex flex-col max-w-9xl mx-auto -mt-24 pb-32 px-4">
        <div className={`flex flex-col lg:flex-row gap-x-8 px-4 ${shouldDisplayTalentSponsor ? '' : 'justify-center'}`}>
          <div className="basis-3/4 flex flex-col gap-y-8">
            <OpportunityCard opportunity={data.opportunity} stateless />

            <div className={`block lg:hidden ${shouldDisplayTalentSponsor ? '' : '!hidden'}`}>
              <TalentSponsorCard
                talentSponsor={data.opportunity.talentSponsor}
                opportunity={data.opportunity}
                openModal={setSelectedModal}
              />
            </div>

            <div className="xl:px-16 divide-y-2">
              <div className="py-8">
                <h2 className="font-serif text-display-xs text-green-900 mb-4">
                  {data.opportunity.whyInterestingTitle}
                </h2>
                <div
                  className="rich-text rich-text-lists rich-text-without-paragraph-margin text-gray-500 font-light text-md"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.opportunity.whyInterestingBody) }}
                />
              </div>

              <div className="py-8">
                <h2 className="font-serif text-display-xs text-green-900 mb-4">{data.opportunity.aboutCompanyTitle}</h2>
                <TruncatedContent gradientClass="from-gray-50">
                  <div
                    className="rich-text rich-text-lists rich-text-without-paragraph-margin text-gray-500 font-light text-md"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.opportunity.aboutCompanyBody) }}
                  />
                </TruncatedContent>
              </div>

              <div className="py-8">
                <h2 className="font-serif text-display-xs text-green-900 mb-4">{data.opportunity.aboutRoleTitle}</h2>
                <TruncatedContent gradientClass="from-gray-50">
                  <div
                    className="rich-text rich-text-lists rich-text-without-paragraph-margin text-gray-500 font-light text-md"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.opportunity.aboutRoleBody) }}
                  />
                </TruncatedContent>
              </div>

              <div className="py-8">
                <h2 className="font-serif text-display-xs text-green-900 mb-4">{data.opportunity.teamMembersTitle}</h2>

                <div className="grid lg:grid-cols-2 gap-8">
                  {data.opportunity.teamMembers.map((colleague) => (
                    <TeamMemberCard {...colleague} key={colleague.name} />
                  ))}
                </div>

                <p className="text-gray-500 font-light mt-8">
                  Note: These are examples of people you might work with. Please refrain from contacting them for
                  recruitment purposes, as it might negatively affect your candidacy. If you have any questions, the
                  best way to learn more is by asking a member of our team.
                </p>
              </div>
            </div>
          </div>

          <div className={`basis-1/4 hidden lg:block ${shouldDisplayTalentSponsor ? '' : '!hidden'}`}>
            <TalentSponsorCard
              talentSponsor={data.opportunity.talentSponsor}
              opportunity={data.opportunity}
              openModal={setSelectedModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;
