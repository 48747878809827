import React, { FC } from 'react';
import { Button } from '../Index';
import { paths } from '#pages/paths';
import Base from './CallToActionSection';

const BuildCareer: FC = () => {
  return (
    <Base
      text={
        <>
          Build a career you’ll love <br className="max-md:hidden" /> in businesses where you can make an impact
        </>
      }
      button={
        <Button size="large" to={paths.signUp()}>
          Join Today
        </Button>
      }
    />
  );
};

export default BuildCareer;
