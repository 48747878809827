import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { OpportunityCard, TruncatedContent, TeamMemberCard, Spinner } from '#src/components/Index';
import { useGetOpportunityOverviewData } from './elements/OpportunityOverviewData';
import Header from '#src/pages/talentPages/opportunities/show/elements/Header';
import NotFound from '#pages/NotFound';

const OpportunityOverview: FC = () => {
  const { secretId } = useParams();
  const { data, isLoading } = useGetOpportunityOverviewData(secretId ?? '');

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data == null) {
    return <NotFound className="min-h-full" />;
  }

  return (
    <div className="min-h-full">
      <div className="bg-hpblack">
        <Header />
      </div>

      <div className="grow flex flex-col max-w-6xl mx-auto -mt-24 pb-32">
        <OpportunityCard opportunity={data.opportunity} stateless />

        <div className="xl:px-16 divide-y-2">
          <div className="py-8">
            <h2 className="font-serif text-display-xs text-green-900 mb-4">{data.opportunity.whyInterestingTitle}</h2>
            <div
              className="rich-text rich-text-lists rich-text-without-paragraph-margin text-gray-500 font-light text-md"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.opportunity.whyInterestingBody) }}
            />
          </div>

          <div className="py-8">
            <h2 className="font-serif text-display-xs text-green-900 mb-4">{data.opportunity.aboutCompanyTitle}</h2>
            <TruncatedContent gradientClass="from-gray-50">
              <div
                className="rich-text rich-text-lists rich-text-without-paragraph-margin text-gray-500 font-light text-md"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.opportunity.aboutCompanyBody) }}
              />
            </TruncatedContent>
          </div>

          <div className="py-8">
            <h2 className="font-serif text-display-xs text-green-900 mb-4">{data.opportunity.aboutRoleTitle}</h2>
            <TruncatedContent gradientClass="from-gray-50">
              <div
                className="rich-text rich-text-lists rich-text-without-paragraph-margin text-gray-500 font-light text-md"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.opportunity.aboutRoleBody) }}
              />
            </TruncatedContent>
          </div>

          <div className="py-8">
            <h2 className="font-serif text-display-xs text-green-900 mb-4">{data.opportunity.teamMembersTitle}</h2>

            <div className="grid lg:grid-cols-2 gap-8">
              {data.opportunity.teamMembers.map((colleague) => (
                <TeamMemberCard {...colleague} key={colleague.name} />
              ))}
            </div>

            <p className="text-gray-500 font-light mt-8">
              Note: These are examples of people you might work with. Please refrain from contacting them for
              recruitment purposes, as it might negatively affect your candidacy. If you have any questions, the best
              way to learn more is by asking a member of our team.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityOverview;
