import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { BuildCareer } from '#components/Index';
import CoreValues from './elements/CoreValues';
import Founders from './elements/Founders';
import Header from './elements/Header';
import JoinTeam from './elements/JoinTeam';
import Team from './elements/Team';

const About: FC = () => {
  return (
    <div>
      <Helmet>
        <title>Hiperpool - About us</title>
        <meta
          name="description"
          content="Discover the story behind and it's ex-Bain and ex-Booz Founders. Started in 2015 in London by former strategy consultants, Hiperpool's mission is to revolutionize recruitment and career development. We blend the best of traditional recruitment with innovative technology to offer transparent, effective solutions for both candidates and employers. Learn how Hiperpool transcends conventional platforms, providing exceptional experiences and results in major business hubs worldwide."
        />
      </Helmet>
      <Header />
      <Founders />
      <CoreValues />
      <Team />
      <JoinTeam />
      <BuildCareer />
    </div>
  );
};

export default About;
