import React, { FC, useMemo } from 'react';
import { Job } from '#src/types';
import StateCard from './StateCard';
import TalentSponsorCard from './TalentSponsorCard';

const stageMapper = {
  Pending: 1,
  PutForward: 2,
  Interviewing: 3,
  Decision: 4,
};

interface SidebarProps {
  selectedJob: Job | null;
  isLoading?: boolean;
}

const Sidebar: FC<SidebarProps> = ({ selectedJob, isLoading = false }) => {
  const mappedStage = useMemo(() => {
    if (selectedJob == null) {
      return 0;
    }

    return stageMapper[selectedJob.currentStage];
  }, [selectedJob]);

  if (selectedJob == null) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-8">
      <div>
        <h5 className="uppercase text-gray-500 font-semibold text-xs tracking-widest mb-4">Current Stage</h5>
        <StateCard currentStep={mappedStage} />
      </div>

      <div>
        <h5 className="uppercase text-gray-500 font-semibold text-xs tracking-widest mb-4">Your Hiperpool Contact</h5>
        <TalentSponsorCard talentSponsor={selectedJob.talentSponsor} />
      </div>
    </div>
  );
};

export default Sidebar;
