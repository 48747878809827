import React, { FC } from 'react';
import { TalentProfile } from '#src/types';
import ProfileDetails from './ProfileDetails';
import EmployerSummary from './EmployerSummary';
import GroupedLanguages from './GroupedLanguages';
import EducationEntry from './EducationEntry';
import EmploymentEntry from './EmploymentEntry';

interface ProfileForEmployerProps {
  profile: TalentProfile;
}

const ProfileForEmployer: FC<ProfileForEmployerProps> = ({ profile }) => {
  return (
    <div className="grow flex flex-col max-w-6xl xl:max-w-8xl mx-auto py-6 px-8 2xl:px-0">
      <div className="w-full p-12 bg-white rounded-lg shadow-md-dark divide-y-2">
        <ProfileDetails profile={profile} withDownloadButtons />

        <div>
          <EmployerSummary summary={profile.summary} />

          <div className="flex flex-col py-8">
            <h3 className="hp-black font-serif text-display-sm pb-8">Work History</h3>
            <div className="flex flex-col gap-y-8">
              {profile.workHistory.map((workHistoryEntry) => (
                <EmploymentEntry
                  key={`${workHistoryEntry.company}-${workHistoryEntry.dateRange}`}
                  workHistoryEntry={workHistoryEntry}
                  withDescription
                  editSectionHandler={() => {}}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col py-8 gap-y-8">
          <h3 className="hp-black font-serif text-display-sm">Education</h3>

          {profile.educations.map((education) => (
            <EducationEntry key={education.id} education={education} />
          ))}
        </div>

        <div className="flex flex-col lg:flex-row gap-x-32 py-8">
          <h3 className="hp-black font-serif text-display-sm pb-8">Languages</h3>

          <GroupedLanguages languages={profile.languages} />
        </div>
      </div>
    </div>
  );
};

export default ProfileForEmployer;
