import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { Coach } from '#root/src/types';
import { isEmpty } from 'lodash';

interface Payload {
  init: {
    region: string;
    privacyCompany: string;
    coachingType: string;
  };
  success: {
    coaches: Coach[];
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

const queryParams = (params: Payload['init']) => {
  const url = new URL('/public/coaches', window.location.origin);
  if (!isEmpty(params.region)) url.searchParams.append('region[]', params.region);
  if (!isEmpty(params.privacyCompany)) url.searchParams.append('privacy_company_ids[]', params.privacyCompany);
  if (!isEmpty(params.coachingType)) url.searchParams.append('coaching_type_ids[]', params.coachingType);

  return url.search;
};

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(GET, `/public/coaches${queryParams(params)}`)();
