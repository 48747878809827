import React, { FC, ReactNode, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useContactUsController, defaultFormValues } from './FormController';
import { TextInput, TextArea, Success } from '#components/Form/Index';
import { Button, Icon } from '#components/Index';

interface Props {
  title: string;
  description: ReactNode;
  subject?: string;
  email?: string;
  message?: string;
}

const ContactForm: FC<Props> = ({ title, description, subject = '', message = '', email = '' }) => {
  const initialValues = { subject, message, email };
  const {
    form: {
      register,
      handleSubmit,
      reset,
      formState: { errors, isSubmitSuccessful },
    },
    submitForm,
  } = useContactUsController({ defaultValues: initialValues });

  useEffect(
    function resetFormOnChange() {
      reset({ ...defaultFormValues, ...initialValues });
    },
    [title, description, subject, message] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (isSubmitSuccessful) {
    return (
      <Success title="Thank you for reaching out." icon={<Icon.MailCheckLine size={6} />}>
        A member of our team will be in touch with you shortly.
      </Success>
    );
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Hiperpool - Contact us</title>
          <meta
            name="description"
            content="Get in touch with Hiperpool on our Contact Us page. Whether you're a candidate seeking career opportunities or an employer looking to fill strategic roles, our team is here to assist. Find all the information you need to connect with us, including phone, email, and office locations. Reach out today for support, inquiries, or more information about how Hiperpool can help you achieve your professional or recruitment goals."
          />
        </Helmet>
        <div className="grid gap-2 text-center mx-auto max-w-[26rem]">
          <h1 className="text-display-sm font-serif">{title}</h1>
          <p className="text-sm font-medium text-gray-700">{description}</p>
        </div>
        <form onSubmit={handleSubmit(submitForm)} className="w-full grid gap-6 text-sm text-gray-700 font-medium">
          <TextInput label="Your email address" type="email" error={errors.email?.message} {...register('email')} />
          <TextInput label="Subject" type="text" error={errors.subject?.message} {...register('subject')} />
          <TextArea
            label="How can we help?"
            placeholder="Enter your message..."
            error={errors.message?.message}
            rows={5}
            {...register('message')}
          />
          <Button type="submit" size="large" className="xs:mx-auto">
            Send message
          </Button>
        </form>
      </HelmetProvider>
    </>
  );
};

export default ContactForm;
