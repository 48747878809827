import React, { FC, useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import FilterBadge from '../FilterBadge/FilterBadge';

interface Props {
  id: string;
  label?: React.ReactNode;
  filters: Record<string, boolean>;
  setFilter?: Dispatch<SetStateAction<Record<string, boolean>>>;
  withoutAllButton?: boolean;
  className?: string;
}

const FilterBadgeGroup: FC<Props> = ({ label, id, filters, setFilter, withoutAllButton = false, className = '' }) => {
  const [values, setValues] = useState(filters);
  const [allChecked, setAllChecked] = useState(false);
  const filterComponentContainer = useRef(null);

  const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = { ...values };
    Object.keys(newValues).forEach((v) => (newValues[v] = event.target.checked));
    setValues(newValues);
    setFilter?.(newValues);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldId: string) => {
    const newValues = { ...values };
    newValues[fieldId.replace(`${id}-`, '')] = event.target.checked;
    setValues(newValues);
    setFilter?.(newValues);
  };

  useEffect(() => {
    setAllChecked(Object.keys(values).every((id) => values[id]));
  }, [values]);

  return (
    <fieldset className="flex flex-wrap gap-x-6 gap-y-4 items-center" id={id}>
      <legend className="contents">
        <span className="uppercase text-gray-500 font-semibold text-xs leading-tight">{label}</span>
      </legend>
      <div className={`flex flex-wrap gap-x-6 gap-y-4 ${className}`}>
        {!withoutAllButton && (
          <>
            <FilterBadge id={`${id}-all`} checked={allChecked} handleChange={handleAllChange}>
              All
            </FilterBadge>
            <span className="border-gray-300 border-x" />
          </>
        )}
        <div className="contents" ref={filterComponentContainer}>
          {Object.keys(filters).map((filterId) => (
            <FilterBadge id={`${id}-${filterId}`} key={filterId} handleChange={handleChange} checked={values[filterId]}>
              {filterId}
            </FilterBadge>
          ))}
        </div>
      </div>
    </fieldset>
  );
};

export default FilterBadgeGroup;
