import { ReactQueryOptions } from '#src/types';
import { useQuery } from 'react-query';

import * as apiGetDummyJobsList from '#api/endpoints/talents/activity/dummyJobsList';

export enum KeysEnum {
  talents = 'talents',
  activity = 'activity',
  jobs = 'dummy-jobs',
}

type GetApiPayload = apiGetDummyJobsList.Type['success']['payload'];

export const useGetDummyJobsList = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.talents, KeysEnum.activity, KeysEnum.jobs],
    async () => await apiGetDummyJobsList.request(),
    {
      ...options,
    }
  );
