import React, { FC } from 'react';
import { TalentProfile } from '#src/types';
import { Button, Icon } from '#components/Index';

interface ProfileDetailsProps {
  profile: TalentProfile;
  withDownloadButtons?: boolean;
  editSectionHandler?: () => void;
}

const ProfileDetails: FC<ProfileDetailsProps> = ({
  profile,
  withDownloadButtons = false,
  editSectionHandler = null,
}) => {
  return (
    <div className="flex flex-col lg:flex-row lg:gap-x-32 lg:gap-y-0 py-8 gap-y-8 relative items-center">
      {editSectionHandler != null && (
        <div className="lg:absolute right-0 top-8">
          <Button variant="secondary" size="small" className="flex" onClick={editSectionHandler}>
            <Icon.EditPencil size={3} />
            Edit section
          </Button>
        </div>
      )}

      <div className="flex justify-center min-w-60 max-w-60 xl:min-w-72 xl:max-w-72">
        <div className="min-h-60 min-w-60 max-w-60 max-h-60 rounded-full overflow-hidden flex justify-center items-center">
          <img src={profile.avatarUrl} alt="Profile image" className="min-w-full min-h-full" />
        </div>
      </div>

      <div className="flex flex-col items-center lg:items-start gap-y-4 flex-grow">
        <h2 className="font-serif text-display-md text-hpblack pt-4 lg:pt-0">
          {profile.firstName} {profile.lastName}
        </h2>

        {profile.currentPosition !== '' && <h3 className="text-display-xs">{profile.currentPosition}</h3>}

        <div className="flex flex-col gap-y-2">
          {profile.latestEducation !== '' && (
            <div className="flex items-center gap-x-1">
              <div>
                <Icon.School size={5} className="text-green-500" />
              </div>
              <p className="text-gray-500 text-md">{profile.latestEducation}</p>
            </div>
          )}

          <div className="flex items-center gap-x-1">
            <Icon.MapPinFill size={5} className="text-green-500" />
            <p className="text-gray-500 text-md">{profile.location}</p>
          </div>
        </div>

        {withDownloadButtons && (
          <div className="flex flex-col md:flex-row gap-2 mt-2">
            {profile.resumeUrl != null && (
              <Button variant="secondary" href={profile.resumeUrl} target="_blank">
                <div>
                  <Icon.DownloadFill size={5} />
                </div>
                Download CV
              </Button>
            )}

            {profile.linkedinUrl != null && (
              <Button variant="secondary" href={profile.linkedinUrl} target="_blank">
                <div>
                  <Icon.Linkedin size={5} />
                </div>
                View on LinkedIn
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileDetails;
