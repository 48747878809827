import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { Coach } from '#root/src/types';

interface Payload {
  init: { slug: string };
  success: {
    coach: Coach;
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(GET, `/public/coaches/${params.slug}`)(params);
