import * as apiGetOpportunityOverview from '#api/endpoints/public/getOpportunityOverviewDetails';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  talents = 'talents',
  opportunities = 'opportunities',
  show = 'show',
}

type GetApiPayload = apiGetOpportunityOverview.Type['success']['payload'];
export const useGetOpportunityOverviewData = (secretId: string, options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.talents, KeysEnum.opportunities, KeysEnum.show, secretId],
    async () => await apiGetOpportunityOverview.request({ secretId }),
    {
      ...options,
    }
  );
