import React, { FC, useCallback } from 'react';
import { request as apiWithdrawInterest } from '#api/endpoints/talents/opportunities/replies/withdrawInterest';
import { Icon, Modal, Button } from '#components/Index';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  opportunityId: number;
  refetch: () => void;
  invalidateJobsQueries: () => void;
}

const InterestedModal: FC<Props> = ({ isOpen, onClose, opportunityId, refetch, invalidateJobsQueries }) => {
  const withdrawInterest = useCallback(async () => {
    await apiWithdrawInterest({ opportunityId });
    invalidateJobsQueries();
    refetch();
    onClose();
  }, [opportunityId, refetch, onClose, invalidateJobsQueries]);

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="p-16 pt-8 flex flex-col items-center w-screen max-w-2xl gap-8">
        <button
          onClick={onClose}
          className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline self-center"
          aria-label="Close"
        >
          <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
        </button>

        <div className="w-full grid gap-6 text-sm  font-medium">
          <div className="flex flex-col gap-y-4 text-center">
            <h2 className="text-display-xs font-serif hp-black">Thanks for expressing your interest in this role!</h2>

            <p className="hp-black font-light text-md">
              Your Talent Sponsor will check your profile to make sure everything is in order. We will reach out if
              there are any questions.
            </p>

            <p className="hp-black font-light text-md">
              If all is okay, we’ll share your profile with the employer in 24 hours. Employers move quickly once they
              start an interview process. If you are not ready to go through an hiring process just yet, please let your
              Talent Sponsor know.
            </p>

            <p className="hp-black font-light text-md">
              To speed up the process, please make sure a recent resume is uploaded on your profile - you can upload
              your CV here.
            </p>
          </div>

          <div className="flex flex-col items-center justify-center">
            <Button className="!px-12" variant="primary" onClick={onClose}>
              Got it
            </Button>
            <Button className="!text-xs !font-normal" variant="link" onClick={withdrawInterest}>
              Made a mistake? Withdraw your interest
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InterestedModal;
