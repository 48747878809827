import { useMemo } from 'react';
import { string, object, boolean } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { generateOriginAppUrl } from '#src/lib/generateOriginAppUrl';
import * as candidateSignUpAPI from '#api/endpoints/session/signUpCandidate';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';

export interface FormValues extends FieldValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  location: string;
  phone: string;
  phonePrefix: string;
  marketingEmailConsent: boolean;
}

const defaultFormValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  location: '',
  phone: '',
  phonePrefix: '',
  marketingEmailConsent: false,
};

const validationSchema = object().shape({
  firstName: string().required('First name is required'),
  lastName: string().required('Last name is required'),
  email: string().required('Email is required'),
  password: string().trim().required('Password is required'),
  phone: string().optional(),
  phonePrefix: string().required('Phone prefix is required'),
  marketingEmailConsent: boolean().optional(),
});

export const useSignUpController = () => {
  const [searchParams] = useSearchParams();
  const redirectPath = useMemo(() => searchParams.get('redirectUrl') ?? '', [searchParams]);
  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues,
  });

  const { parseErrors, baseErrors } = useParseFormErrors<candidateSignUpAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) =>
    await candidateSignUpAPI
      .request({
        talent: {
          ...values,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          phone: values.phone !== '' ? '' + values.phonePrefix + values.phone : undefined,
        },
        redirectPath,
      })
      .then((res) => {
        window.location.href = generateOriginAppUrl({ path: res.redirectUrl });
      })
      .catch(parseErrors);

  return { form: formMethods, submitForm, baseErrors };
};
