import { Errors } from '#root/src/types';
import { createAction, PUT, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: { talent: { resetPasswordToken: string; password: string } };
  success: { redirectUrl: string };
  fail: { errors: Errors<'base' | 'resetPasswordToken' | 'password'>; status: number };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(PUT, '/activate_account');
