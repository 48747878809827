import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { BuildCareer } from '#components/Index';
import Header from './Header';
import Posts from './Posts';
const InsightsAndResources: FC = () => {
  return (
    <>
      <Helmet>
        <title>Hiperpool - Insights & Resources</title>
        <meta
          name="description"
          content="Exclusive tips, tools and industry insights to help you make your next career move, or push the boundaries in your current role in consulting, strategy, and investment. Our insights are designed for busy professionals seeking to excel and lead in their fields."
        />
      </Helmet>
      <Header />
      <Posts />
      <BuildCareer />
    </>
  );
};

export default InsightsAndResources;
