import { useState } from 'react';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';

import * as updateAccountSettingsAPI from '#api/endpoints/talents/settings/updateAccountSettings';
import { useParseFormErrors } from '#src/lib/parseFormsErrors';

export interface FormValues extends FieldValues {
  email: string;
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

const defaultFormValues = (email: string): FormValues => ({
  email,
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
});

const validationSchema = object().shape({
  email: string().required('Email is required'),
  currentPassword: string().required('Current password is required'),
  password: string().required('Password is required'),
  passwordConfirmation: string().required('Confirmation is required'),
});

interface ControllerProps {
  email?: string;
}

export const useAccountSettingsController = ({ email = '' }: ControllerProps) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const closeSuccessMessage = () => setSuccessMessage(null);

  const formMethods = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: defaultFormValues(email),
  });

  const { parseErrors } = useParseFormErrors<updateAccountSettingsAPI.Type['fail']['payload'], FormValues>({
    setError: formMethods.setError,
  });

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) =>
    await updateAccountSettingsAPI
      .request({
        talent: {
          ...values,
        },
      })
      .then(() => setSuccessMessage('Account settings updated successfully'))
      .catch((errors) => {
        parseErrors(errors);
        setSuccessMessage(null);
      });

  return { form: formMethods, submitForm, successMessage, closeSuccessMessage };
};
