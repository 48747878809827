import React, { FC, useMemo, Dispatch, SetStateAction } from 'react';
import { parseISO, formatDistance } from 'date-fns';
import { Job } from '#src/types';

interface JobsListItemProps {
  job: Job;
  setSelectedJob: Dispatch<SetStateAction<Job | null>>;
}

const JobsListItem: FC<JobsListItemProps> = ({ job, setSelectedJob }) => {
  const parsedDate = useMemo(() => {
    if (job.history.lastActivityAt == null) return '';

    const now = new Date();
    const targetDate = parseISO(job.history.lastActivityAt);

    return formatDistance(targetDate, now, { addSuffix: true });
  }, [job.history.lastActivityAt]);

  return (
    <div
      className="flex gap-x-4 items-center py-1 px-4 cursor-pointer hover:bg-gray-50"
      onClick={() => setSelectedJob(job)}
    >
      <div className="bg-gray-50 rounded-full overflow-clip justify-self-start shrink-0">
        <img
          src={job.opportunity.companyLogoUrl}
          alt={job.opportunity.companyName}
          className="mix-blend-multiply max-h-[50px] m-auto sm:m-0 object-contain block"
        />
      </div>
      <div className="flex flex-col justify-center grow">
        <div className="flex justify-between items-center">
          <h4 className="text-lg text-hpblack font-serif"> {job.opportunity.position}</h4>
          {job.history.withNotification && <span className="w-3 h-3 rounded-full bg-green-500"></span>}
        </div>
        <div className="flex justify-between items-center">
          <span className="text-md font-light text-gray-500">{job.opportunity.companyName}</span>
          <span className="text-gray-400 text-sm">{parsedDate}</span>
        </div>
      </div>
    </div>
  );
};

export default JobsListItem;
