import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCoachProfile } from './CoachData';

import styles from './Coach.module.css';
import Header from './elements/Header';
import { DateTimeMeta, Button, Icon, Spinner } from '#components/Index';
import { expiresIn } from '#src/lib/sessionTimes';
import { StarRating } from '#components/StarRating/StarRating';
import useInfiniteScrollPagination from '#src/lib/useInfiniteScrollPagination';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet-async';
import NotFound from '#pages/NotFound';

const Coach: FC = () => {
  const { slug } = useParams();

  const { data: coachData, isLoading } = useGetCoachProfile(slug);

  const reviews = coachData?.coach.coachReviews ?? [];
  const { list, isMore, loadMore } = useInfiniteScrollPagination({ list: reviews, perPage: 3 });

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (coachData === undefined) {
    return <NotFound className="min-h-full" />;
  }

  return (
    <div className={`${styles.layout} bg-gray-50 pb-16`}>
      <Helmet>
        <title>Hiperpool - {coachData.coach.displayName}</title>
        <meta name="description" content={coachData.coach.description} />
      </Helmet>
      <Header
        coachSlug={slug != null ? slug : ''}
        displayName={coachData.coach.displayName}
        avatar={<img src={coachData.coach.avatar} alt={`Photo of ${coachData.coach.displayName}`} />}
        subtitle={coachData.coach.subtitle}
        workLocation={coachData.coach.workLocation}
        currentLocation={coachData.coach.currentLocation}
        firstName={coachData.coach.firstName}
        rating={coachData.coach.ratings.average}
        reviewCount={coachData.coach.ratings.count}
      />

      <div className={styles.content}>
        {(coachData.coach.upcomingSessions.length > 0 || coachData.coach.pendingSessions.length > 0) && (
          <div className="mb-10">
            <h2 className="font-serif text-xl md:text-display-xs text-gray-700 mb-4">
              {`Upcoming session${coachData.coach.upcomingSessions.length > 1 ? 's' : ''} with ${
                coachData.coach.firstName
              }`}
            </h2>
            <div className="grid gap-3">
              {coachData.coach.pendingSessions.map((session) => (
                <div className="p-4 rounded-lg overflow-clip shadow-lg bg-white  max-w-xl" key={session.id}>
                  <div className="flex gap-1.5 justify-start">
                    <Icon.AlertCircle size={5} className="shrink-0 text-green-500 mt-0.5" />
                    <div className="text-gray-500">
                      <span>
                        You have a pending session with {coachData.coach.firstName}.<br />
                        Choose a date and time to continue booking{' '}
                        {session.utcCreatedAt != null && `(${expiresIn(session.utcCreatedAt)})`}
                      </span>
                    </div>
                  </div>
                </div>
              ))}

              {coachData.coach.upcomingSessions.map((session) => (
                <div className="p-4 rounded-lg overflow-clip shadow-lg bg-white max-w-xl" key={session.id}>
                  <DateTimeMeta datetime={session.utcSessionStart} />
                </div>
              ))}
            </div>
          </div>
        )}
        {coachData.coach.companyImages.length > 0 && (
          <div className="flex flex-wrap gap-8">
            {coachData.coach.companyImages.map((companyImage) => (
              <img
                src={companyImage.image}
                alt={companyImage.name}
                key={companyImage.name}
                className="max-h-[80px] m-auto sm:m-0 object-contain"
              />
            ))}
          </div>
        )}
        <div
          className="text-md text-gray-500 mt-10 rich-text rich-text-lists"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(coachData.coach.bio) }}
        />

        <div className="mt-8">
          <h2 className="font-serif text-xl md:text-display-xs text-green-900 mb-4">How I can help you</h2>
          <div
            className="text-md text-gray-500 rich-text rich-text-lists"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(coachData.coach.description) }}
          />
        </div>

        {list.length > 0 && (
          <div className="mt-8">
            <h2 className="font-serif text-xl md:text-display-xs text-green-900 mb-4">
              Reviews from previous sessions
            </h2>

            {list.map((review) => (
              <div key={review.id} className="flex flex-col gap-1 mb-8">
                <p className="font-medium">{review.author}</p>

                <div className="flex gap-2 items-center text-sm text-gray-400">
                  <StarRating rating={review.rating} />
                  {review.reviewDate}
                </div>
                <div
                  className="text-gray-500 rich-text rich-text-lists"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(review.content) }}
                />
              </div>
            ))}

            {isMore && (
              <div className="flex justify-center mb-12">
                <Button variant="secondary" size="large" onClick={loadMore}>
                  View more
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Coach;
