import React, { FC } from 'react';
import { Button, CircleGraphic } from '#components/Index';
import { paths } from '#pages/paths';

const NotFound: FC<{ className?: string }> = ({ className = '' }) => {
  return (
    <>
      <div className={`flex flex-col justify-center px-variable-lg py-28 ${className}`}>
        <h2 className="font-serif font-normal text-display-lg md:text-display-2xl text-center mb-4 text-green-700">
          404
        </h2>
        <h1 className="font-serif font-normal text-display-sm md:text-display-lg mb-8 text-center">
          The page you were looking for {"doesn't"} exist.
        </h1>
        <p className="text-center text-sx md:text-sm font-medium text-gray-700">
          You may have mistyped the address or the page may have moved.
        </p>
        <Button to={paths.root()} className="place-self-center my-6 text-center">
          Back to homepage
        </Button>
      </div>
      <div className="relative isolate">
        <CircleGraphic className="absolute max-w-[24vw] min-w-[120px] w-full h-auto left-0 bottom-0">
          <CircleGraphic.Quarter x={2} y={1} color="green-500" rotate={0} />
          <CircleGraphic.Quarter x={1} y={2} color="green-500" rotate={90} />
          <CircleGraphic.Quarter x={2} y={2} color="green-900" rotate={0} />
          <CircleGraphic.Quarter x={1} y={3} color="green-900" rotate={0} />
          <CircleGraphic.Quarter x={2} y={3} color="green-500" rotate={180} />
          <CircleGraphic.Circle x={3} y={3} radius={0.5} color="green-500" />
          <CircleGraphic.Quarter x={4} y={3} color="green-900" rotate={90} />
        </CircleGraphic>
      </div>
    </>
  );
};

export default NotFound;
