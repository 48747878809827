import * as apiGetListOfStories from '#api/endpoints/stories/getList';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  successStories = 'success-stories',
  stories = 'stories',
  list = 'list',
}

type GetApiPayload = apiGetListOfStories.Type['success']['payload'];
export const useGetStories = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.successStories, KeysEnum.stories, KeysEnum.list],
    async () => await apiGetListOfStories.request(),
    {
      ...options,
    }
  );
