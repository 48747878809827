import React, { FC } from 'react';
import { Icon, Modal, Button } from '#components/Index';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  requirementsTitle?: string;
  requirementsBody?: string;
  requirements?: string[];
  confirmAction: () => void;
}

const RequirementsModal: FC<Props> = ({
  isOpen,
  onClose,
  requirementsTitle = 'Must-have requirements',
  requirementsBody = 'The following are all necessary requirements for this position. Could you please confirm that you meet them all before proceeding',
  requirements = [],
  confirmAction,
}) => {
  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="p-16 pt-8 flex flex-col items-center w-screen max-w-2xl gap-8">
        <button
          onClick={onClose}
          className="w-8 h-8 bg-gray-100 hover:bg-gray-200 ml-auto rounded focus-visible-outline self-center"
          aria-label="Close"
        >
          <Icon.X size={6} aria-hidden="true" className="text-gray-700" />
        </button>

        <div className="w-full grid gap-6 text-sm  font-medium">
          <div className="flex flex-col gap-y-3 text-center">
            <h2 className="text-display-xs font-serif hp-black">{requirementsTitle}</h2>
            <p className="hp-black font-light text-md">{requirementsBody}</p>
          </div>

          <div className="grid gap-2">
            <ul className="ml-16 list-disc rich-text-lists">
              {requirements.map((requirement) => (
                <li key={requirement} className="hp-black font-light text-md">
                  {requirement}
                </li>
              ))}
            </ul>
          </div>

          <div className="flex items-center justify-center">
            <Button variant="link" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={confirmAction}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RequirementsModal;
