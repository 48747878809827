import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import CompaniesCarousel from '../elements/CompaniesCarousel';
import JobsHeroBanner from './elements/JobsHeroBanner';
import FindYourNextRole from './elements/FindYourNextRoles';
import { BuildCareer } from '#components/Index';
import { ReactComponent as BainCompanyLogo } from '#assets/homepage/logo/bainCompanyGrey.svg';
import { ReactComponent as ThreeMLogo } from '#assets/homepage/logo/3m.svg';
import { ReactComponent as AptivLogo } from '#assets/homepage/logo/aptiv.svg';
import { ReactComponent as BainCapLogo } from '#assets/homepage/logo/baincap.svg';
import { ReactComponent as HomeDepLogo } from '#assets/homepage/logo/homedep.svg';
import { ReactComponent as MaerskLogo } from '#assets/homepage/logo/maersk.svg';
import { ReactComponent as OccLogo } from '#assets/homepage/logo/occ.svg';
import { ReactComponent as OliverWyLogo } from '#assets/homepage/logo/oliverwy.svg';
import { ReactComponent as PayPalLogo } from '#assets/homepage/logo/paypal.svg';
import { ReactComponent as ScaleLogo } from '#assets/homepage/logo/scale.svg';
import { ReactComponent as VirginGLogo } from '#assets/homepage/logo/virging.svg';
import { ReactComponent as WalmartLogo } from '#assets/homepage/logo/walmart.svg';
import { ReactComponent as OcadoLogo } from '#assets/homepage/logo/ocado.svg';
import { ReactComponent as BasfLogo } from '#assets/homepage/logo/basf.svg';

import SetsApart from './elements/SetsApart';

const JobsIndex: FC = () => {
  return (
    <>
      <Helmet>
        <title>Hiperpool - Jobs</title>
        <meta
          name="description"
          content="Use Hiperpool's career platform to access exclusive jobs in management consulting, strategy, and investment. Our platform offers hand-picked job opportunities, expert guidance, and resources tailored to ambitious professionals. Become a member for free and explore roles that align with your aspirations, receive personalized support from a dedicated Talent Sponsor, and access insightful resources for a career that makes an impact."
        />
      </Helmet>
      <JobsHeroBanner />
      <CompaniesCarousel
        title="Find opportunities at top companies"
        logoList={[
          <BainCompanyLogo aria-label="Bain and Company" key="Bain and Company" />,
          <OccLogo aria-label="OC&C" key="OC&C" />,
          <PayPalLogo aria-label="PayPal" key="PayPal" />,
          <VirginGLogo aria-label="Virgin Galactic" key="Virgin Galactic" />,
          <AptivLogo aria-label="Aptiv" key="Aptiv" />,
          <HomeDepLogo aria-label="The Home Depot" key="The Home Depot" />,
          <BainCapLogo aria-label="Bain Capital" key="Bain Capital" />,
          <OliverWyLogo aria-label="Oliver Wyman" key="Oliver Wyman" />,
          <WalmartLogo aria-label="Walmart" key="Walmart" />,
          <ThreeMLogo aria-label="3M" key="3M" />,
          <ScaleLogo aria-label="Scale AI" key="Scale AI" />,
          <MaerskLogo aria-label="Maersk" key="Maersk" />,
          <OcadoLogo aria-label="Ocado Solutions" key="Ocado Solutions" />,
          <BasfLogo aria-label="BASF" key="BASF" />,
        ]}
      />
      <FindYourNextRole />
      <SetsApart />
      <BuildCareer />
    </>
  );
};

export default JobsIndex;
