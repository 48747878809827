import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { CustomProfileWorkAuthorization } from '#src/types';
import { LocationOptionProps } from '#components/Form/Index';
import { Step, StepBackButton, StepNextButton } from '../../../CurrentStep';
import { useTalentDataController } from '../../../TalentDataController';
import { useStepController } from '../../../StepControllerContext';
import WhereToWorkForm from '#components/TalentSharedForms/WhereToWork/Form';

const WhereToWork: Step = {
  id: 'where-to-work',
  Body: ({
    setNextDisabled,
    setHandleNextClick,
  }: {
    setNextDisabled?: Dispatch<SetStateAction<boolean>>;
    setHandleNextClick?: Dispatch<SetStateAction<() => void>>;
  }) => {
    const [selectedLocations, setSelectedLocations] = useState<LocationOptionProps[]>([]);
    const [selectedWorkAuthorizations, setSelectedWorkAuthorizations] = useState<number[]>([]);
    const [invalidWorkAuthorizations, setInvalidWorkAuthorizations] = useState<boolean>(false);
    const [customProfileWorkAuthorization, setCustomProfileWorkAuthorization] =
      useState<CustomProfileWorkAuthorization>({
        name: null,
        workAuthorizationId: null,
      });

    const { talentData, updateTalentData } = useTalentDataController();
    const { next } = useStepController();

    // Set the selections based on the talent data
    useEffect(() => {
      if (talentData == null) return;

      setSelectedLocations(talentData.locations.map((location) => ({ ...location, checked: true })));
      setSelectedWorkAuthorizations(talentData.workAuthorizationIds);
      setCustomProfileWorkAuthorization(talentData.customProfileWorkAuthorization);
    }, [talentData]);

    // Prevent the user from moving to the next step if they haven't selected any value or if the work authorizations are invalid (missing)
    useEffect(() => {
      if (setNextDisabled == null) return;

      setNextDisabled(selectedLocations.length === 0 || invalidWorkAuthorizations);
    }, [selectedLocations, selectedWorkAuthorizations, setNextDisabled, invalidWorkAuthorizations]);

    useEffect(() => {
      const handleNext = async () => {
        if (talentData == null) {
          return;
        }

        const newTalentData = {
          ...talentData,
          placeIds: selectedLocations.map((location) => location.placeId),
          workAuthorizationIds: selectedWorkAuthorizations,
          customProfileWorkAuthorization,
        };

        const result = await updateTalentData(newTalentData, 'locations_and_authorizations');

        if (result.success === true) {
          next();
        }
      };

      if (setHandleNextClick != null) {
        setHandleNextClick(() => handleNext);
      }
    }, [
      setHandleNextClick,
      selectedLocations,
      selectedWorkAuthorizations,
      talentData,
      next,
      updateTalentData,
      customProfileWorkAuthorization,
    ]);

    return (
      <WhereToWorkForm
        selectedLocations={selectedLocations}
        setSelectedLocations={setSelectedLocations}
        selectedWorkAuthorizations={selectedWorkAuthorizations}
        setSelectedWorkAuthorizations={setSelectedWorkAuthorizations}
        customProfileWorkAuthorization={customProfileWorkAuthorization}
        setCustomProfileWorkAuthorization={setCustomProfileWorkAuthorization}
        setInvalidWorkAuthorizations={setInvalidWorkAuthorizations}
      />
    );
  },
  Footer: ({ nextDisabled, onNextClick }: { nextDisabled?: boolean; onNextClick?: () => void }) => (
    <>
      <StepBackButton />
      <StepNextButton disabled={nextDisabled} onClick={onNextClick} />
    </>
  ),
};

export default WhereToWork;
