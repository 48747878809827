import React, { FC } from 'react';
import { Success } from '#components/Form/Index';
import { Button, Icon } from '#components/Index';
import { paths } from '#pages/paths';

const NotApprovedMessage: FC = () => {
  return (
    <Success title="Your application to Hiperpool" icon={<Icon.UserFollowLine size={6} />}>
      <div className="rich-text">
        <p>
          Thank you for your interest in joining Hiperpool. Although we receive applications every day from highly
          accomplished professionals, at this time our service is suited to only a small percentage of them. For this
          reason, we have been unable to approve your application.
        </p>
        <p>
          Our decision is not a reflection of your accomplishments, but rather recognises that we can provide
          outstanding service to only a limited number of professionals.
        </p>
        <p>
          If you believe you have gained additional relevant experience, we welcome you to reapply within 6-12 months by
          emailing <a href="mailto:info@hiperpool.com">info@hiperpool.com</a>.
        </p>
      </div>
      <div className="mt-8">
        <Button to={paths.root()} size="large">
          Back to homepage
        </Button>
      </div>
    </Success>
  );
};

export default NotApprovedMessage;
