import Appsignal from '@appsignal/javascript';
import { plugin as pathDecoratorPlugin } from '@appsignal/plugin-path-decorator';
import { plugin as consoleBreadcrumbsPlugin } from '@appsignal/plugin-breadcrumbs-console';
import { plugin as networkBreadcrumbsPlugin } from '@appsignal/plugin-breadcrumbs-network';

const body = document.body;

const appsignal = new Appsignal({
  key: import.meta.env.VITE_APPSIGNAL_FRONTEND_KEY,
  revision: body.dataset.appRevision,
});

export default appsignal;

appsignal.use(pathDecoratorPlugin());
appsignal.use(consoleBreadcrumbsPlugin());
appsignal.use(networkBreadcrumbsPlugin());
