import React, { FC } from 'react';
import { Icon } from '#components/Index';

interface ProfileBadgeProps {
  children: React.ReactNode;
  withoutIcon?: boolean;
  negative?: boolean;
}

const ProfileBadge: FC<ProfileBadgeProps> = ({ children, negative = false, withoutIcon = false }) => {
  const colorClasses = negative ? 'text-red-700 bg-red-50' : 'text-blue-500 bg-blue-100';

  return (
    <span
      className={`flex items-center gap-x-2 rounded-lg leading-tight font-medium text-sm px-4 py-2.5 ${colorClasses}`}
    >
      {!withoutIcon && <Icon.CheckLineThin size={4} />}
      {children}
    </span>
  );
};

export default ProfileBadge;
