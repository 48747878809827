import { ReactQueryOptions } from '#src/types';
import { useQuery } from 'react-query';

import * as apiGetAccountSettings from '#api/endpoints/talents/settings/getAccountSettings';

export enum KeysEnum {
  talents = 'talents',
  settings = 'settings',
}

type GetApiPayload = apiGetAccountSettings.Type['success']['payload'];

export const useGetAccountSettings = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>([KeysEnum.talents, KeysEnum.settings], async () => await apiGetAccountSettings.request(), {
    ...options,
  });
