import { createAction, PATCH, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: never;
  success: never;
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(PATCH, '/talents/onboarding/full_onboarding');
