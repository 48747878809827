import { map } from 'lodash-es';

type AnyObject = Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any

interface GeneratorProps {
  params?: AnyObject;
  path?: string;
}

export function generateOriginAppUrl({ params, path = '' }: GeneratorProps) {
  const splitter = '://';
  const originAppUrl = import.meta.env.VITE_ORIGIN_APP_URL;
  if (typeof originAppUrl !== 'string') {
    throw Error('Undefined env origin app url');
  }
  const [prefix, address] = originAppUrl.split(splitter);

  const preparedPath = path.startsWith('/') ? path.substring(1) : path;
  const preparedAddress = address.endsWith('/') ? address.slice(0, -1) : address;

  const paramsPart =
    params != null
      ? `?${map(params, (val, key) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&')}`
      : '';

  return [prefix, splitter, preparedAddress, '/', preparedPath, paramsPart].join('');
}
