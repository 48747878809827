import React, { FC, useEffect, useState, useRef } from 'react';
import { kebabCase } from 'lodash-es';
import { Icon } from '#components/Index';
import styles from './ExpandableSection.module.css';

interface Props {
  heading: string;
  content: React.ReactNode;
}

const ExpandableSection: FC<Props> = ({ heading, content }) => {
  const [isExpanded, setExpanded] = useState(false);
  const toggleOpen = () => setExpanded(!isExpanded);
  const contentElementRef = useRef<HTMLDivElement | null>(null);
  const contentId = kebabCase(`${heading}-content`);

  const setExpandedHeight = () => {
    if (contentElementRef.current != null) {
      contentElementRef.current.style.height = '';
      contentElementRef.current.style.height = `${contentElementRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    setExpandedHeight();
    window.addEventListener('resize', setExpandedHeight);
    return () => window.removeEventListener('resize', setExpandedHeight);
  }, []);

  return (
    <div className={styles.wrapper}>
      <h3>
        <button className={styles.button} aria-expanded={isExpanded} aria-controls={contentId} onClick={toggleOpen}>
          <span className={styles.button__text}>{heading}</span>
          <div className={styles.button__icon}>
            <Icon.ArrowRightSLine size={5} aria-hidden="true" />
          </div>
        </button>
      </h3>
      <div className={styles.content} id={contentId} ref={contentElementRef} aria-hidden={!isExpanded}>
        <p className={styles.content__inner}>{content}</p>
      </div>
    </div>
  );
};

export default ExpandableSection;
