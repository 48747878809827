import React, { FC } from 'react';
import styles from './ProgressBar.module.css';

interface Props {
  now: number;
  max: number;
  className?: string;
  onDarkBackground?: boolean;
}

const ProgressBar: FC<Props> = ({ now, max, className: classNameProp = '', onDarkBackground = false }) => (
  <div
    role="progressbar"
    aria-valuenow={now}
    aria-valuemax={max}
    aria-label="Progress"
    style={{ ['--progress-percent' as string]: `${(now * 100) / max}%` }}
    className={`${styles['progress-bar']} ${classNameProp} ${onDarkBackground ? 'bg-gray-700' : 'bg-gray-100'}`}
  />
);

export default ProgressBar;
