import React, { FC, useMemo } from 'react';

import { CircleGraphic, OfferCard, Icon } from '#components/Index';
import { paths } from '#pages/paths';
import { useBundleList } from './CoachBundlesData';

const getSessionDetails = (sessionType: 'regular' | 'popular' | 'best_value') => {
  switch (sessionType) {
    case 'popular':
      return {
        hint: (
          <>
            <span>Most popular</span>
            <Icon.Star size={3} className="text-green-800 -mr-1" aria-hidden="true" />
          </>
        ),
        href: paths.signIn(),
        background: (
          <CircleGraphic className="w-full h-full bg-gray-700" cols={7} rows={4} preserveAspectRatio="xMaxYMin">
            <CircleGraphic.Quarter x={6} y={1} color="green-800" rotate={180} className="opacity-30" />
            <CircleGraphic.Quarter x={7} y={1} color="green-500" rotate={90} className="opacity-30" />
            <CircleGraphic.Quarter x={7} y={2} color="green-800" rotate={-90} className="opacity-30" />
          </CircleGraphic>
        ),
      };
    case 'best_value':
      return {
        hint: (
          <>
            <span>Best value</span>
            <Icon.Award size={3} className="text-green-800 -mr-1" aria-hidden="true" />
          </>
        ),
        href: paths.signIn(),
        background: (
          <CircleGraphic className="w-full h-full bg-hpblack" cols={7} rows={4} preserveAspectRatio="xMaxYMin">
            <CircleGraphic.Quarter x={6} y={1} color="green-800" rotate={90} className="opacity-30" />
            <CircleGraphic.Quarter x={7} y={1} color="green-500" rotate={0} className="opacity-30" />
            <CircleGraphic.Quarter x={6} y={2} color="green-500" rotate={0} className="opacity-30" />
            <CircleGraphic.Quarter x={7} y={2} color="green-800" rotate={-90} className="opacity-30" />
            <CircleGraphic.Quarter x={7} y={3} color="green-500" rotate={0} className="opacity-30" />
          </CircleGraphic>
        ),
      };

    case 'regular':
    default:
      return {
        hint: <>Great for ad-hoc help</>,
        href: paths.signIn(),
        background: (
          <CircleGraphic className="w-full h-full bg-gray-600" cols={7} rows={4} preserveAspectRatio="xMaxYMin">
            <CircleGraphic.Quarter x={7} y={1} color="green-500" rotate={-90} className="opacity-30" />
          </CircleGraphic>
        ),
      };
  }
};
interface Props {
  onClick?: (sessionPackageId: number) => void;
}
const CoachBundles: FC<Props> = ({ onClick }) => {
  const { data } = useBundleList();

  const sessionInfos = useMemo(() => {
    if (data?.sessionPackages == null) {
      return [];
    }
    return data.sessionPackages.map((s) => ({
      ...s,
      subtitle: s.description,
      onClick: () => (onClick != null ? onClick(s.id) : undefined),
      ...getSessionDetails(s.sessionType),
    }));
  }, [data, onClick]);

  return (
    <div className="grid md:grid-cols-3 gap-8 max-w-2xs md:max-w-4xl mx-auto w-full">
      {sessionInfos.map((sessionInfo) => (
        <OfferCard {...sessionInfo} key={sessionInfo.title} />
      ))}
    </div>
  );
};

export default CoachBundles;
