import React, { FC, useEffect, useState, useCallback } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { request as apiUpdateTotalExperience } from '#api/endpoints/talents/profile/updateTotalExperience';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { useSteps } from '../../BaseProfileEditModal';
import ExperienceYearsForm from '#components/TalentSharedForms/ExperienceYears/Form';

const ExperienceYearsStep: FC = () => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { setStepCallback, setNextDisabled } = useSteps();

  const [selectedExperienceYears, setSelectedExperienceYears] = useState<number | null>(null);

  // Set the selected experience years based on the talent data
  useEffect(() => {
    if (profilePreferencesData == null) return;

    setSelectedExperienceYears(profilePreferencesData.profilePreferences.experienceTimeId);
  }, [profilePreferencesData]);

  // Prevent the user from moving to the next step if they haven't selected any value
  useEffect(() => {
    setNextDisabled(selectedExperienceYears === null);
  }, [selectedExperienceYears, setNextDisabled]);

  const stepCallback = useCallback(async () => {
    if (selectedExperienceYears === null) return false;

    try {
      await apiUpdateTotalExperience({ totalExperienceId: selectedExperienceYears });
      createSuccessToast({ title: 'Total experience' });
      return true;
    } catch {
      createErrorToast({ title: 'Total experience' });
      return false;
    }
  }, [selectedExperienceYears]);

  useEffect(() => {
    setStepCallback(() => stepCallback);
  }, [setStepCallback, stepCallback]);

  return (
    <ExperienceYearsForm
      setSelectedExperienceYears={setSelectedExperienceYears}
      selectedExperienceYears={selectedExperienceYears}
    />
  );
};

export default ExperienceYearsStep;
