import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: never;
  success: {
    prospect: {
      email: string;
      alreadySent: boolean;
    };
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/talents/hiring');
