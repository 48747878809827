import React, { FC, useState } from 'react';
import { InlineWidget } from 'react-calendly';
import { User } from '#src/types';
import styles from '../Coach.module.css';

import 'react-datepicker/dist/react-datepicker.css';

import { Modal } from '#components/Index';
import CalendarForm from './CalendarForm';

interface Props {
  coachSlug: string;
  firstName: string;
  isLoggedIn: boolean;
  user: User | null;
}

const FloatingBookSessionSection: FC<Props> = ({ firstName, coachSlug, isLoggedIn = false, user }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [calendlyUrl, setCalendlyUrl] = useState<string>();

  const openModal = (calendlyUrl: string) => {
    setCalendlyUrl(calendlyUrl);
    setModalOpen(true);
  };
  const closeModal = () => {
    setCalendlyUrl(undefined);
    setModalOpen(false);
  };

  return (
    <div className={styles.card}>
      <h2 className="text-display-xs font-serif">{`Book a session with ${firstName}`}</h2>
      <hr className="my-2" />
      <CalendarForm openModalAndSetCalendlyUrl={openModal} coachSlug={coachSlug} isLoggedIn={isLoggedIn} user={user} />
      {calendlyUrl != null && (
        <Modal isOpen={isModalOpen} closeModal={closeModal} contentLabel="Calendly">
          <InlineWidget
            styles={{
              height: '1000px',
              minWidth: '45vw',
              maxWidth: '880px',
            }}
            url={calendlyUrl}
            prefill={{
              email: user != null ? user.email : '',
              name: user != null ? `${user.firstName} ${user.lastName}` : '',
            }}
            // TODO: add here scheduledSessionId from payload
            utm={{ utmContent: user != null ? user.email : 'User not provided' }}
          />
        </Modal>
      )}
    </div>
  );
};

export default FloatingBookSessionSection;
