import { createAction, PATCH, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors } from '#root/src/types';

interface Payload {
  init: { id: number };
  success: never;
  fail: {
    errors: Errors<'base'>;
    status: number;
  };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(PATCH, `/talents/opportunities/${params.id}/bookmark`)();
