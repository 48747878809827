import React, { FC } from 'react';
import { CircleGraphic } from '#components/Index';

const Header: FC = () => {
  return (
    <div className="bg-hpblack relative isolate overflow-hidden">
      <CircleGraphic className="absolute right-0 opacity-20 -top-12 -z-10">
        <CircleGraphic.Circle x={2} y={1} color="green-500" radius={0.5} />
        <CircleGraphic.Quarter x={3} y={1} color="green-900" rotate={-90} />
        <CircleGraphic.Half x={1} y={2} color="green-900" />
        <CircleGraphic.Quarter x={3} y={2} color="green-500" rotate={180} />
        <CircleGraphic.Quarter x={4} y={2} color="green-500" rotate={90} />
        <CircleGraphic.Quarter x={2} y={3} color="green-500" rotate={0} />
        <CircleGraphic.Quarter x={3} y={3} color="green-900" rotate={-90} />
        <CircleGraphic.Half x={4} y={3} color="green-900" rotate={90} />
        <CircleGraphic.Quarter x={1} y={4} color="hpblack" rotate={-90} />
      </CircleGraphic>
      <div className="text-white text-center py-24 px-variable-lg">
        <h1 className="font-serif text-display-lg">Browse top coaches</h1>
        <p className="max-w-3xl m-auto pt-6">
          Hiperpool coaches are experts and ex-interviewers from top-tier consulting firms, prestigious startups,
          leading corporations, and private equity firms. Whether you need help exploring career options, preparing for
          interviews, or negotiating an offer, our expert coaches are here to assist you every step of the way.
        </p>
      </div>
    </div>
  );
};

export default Header;
