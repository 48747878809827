import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { BuildCareer, Spinner } from '#components/Index';
import Header from './Header';
import OtherStories from './OtherStories';
import Stats from './Stats';
import { useGetStory } from './StoryData';
import TextSegment from './TextSegment';
import NotFound from '#pages/NotFound';

const Story: FC = () => {
  const { slug } = useParams();

  const { data, isLoading } = useGetStory(slug == null ? undefined : slug);

  if (isLoading) {
    return <Spinner className="min-h-full" />;
  }

  if (data === undefined) {
    return <NotFound className="min-h-full" />;
  }

  const filteredStats = data.successStory.statistics.filter((stat) => stat.title !== '' && stat != null);
  return (
    <>
      <Helmet>
        <title>{data.successStory.metaTitle}</title>
      </Helmet>
      <Header {...data.successStory} />
      {filteredStats.length > 0 && <Stats stats={filteredStats} />}
      {[
        { header: 'The story', text: data.successStory.story },
        { header: 'About the company', text: data.successStory.aboutCompany },
        { header: 'About the role', text: data.successStory.aboutRole },
        { header: 'Requirements', text: data.successStory.requirements },
      ]
        .filter((elm) => elm.text)
        .map((elm, idx) => (
          <div key={elm.header} className={idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
            <TextSegment header={elm.header} text={elm.text != null ? elm.text : ''} />
          </div>
        ))}
      {data.successStory.otherSuccessStories != null && data.successStory.otherSuccessStories.length > 0 && (
        <OtherStories stories={data.successStory.otherSuccessStories} />
      )}
      <BuildCareer />
    </>
  );
};

export default Story;
