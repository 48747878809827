import React, { FC } from 'react';
import { uniqueId } from 'lodash-es';
import styles from './Selectable.module.css';

interface Props {
  id: string;
  type: 'checkbox' | 'radio';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  checked?: boolean;
  name?: string;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const Selectable: FC<Props> = ({
  id = uniqueId('filterBadge'),
  type,
  size = 'md',
  className: classNameProp = '',
  checked,
  name,
  value,
  onChange,
  disabled,
  children,
}) => {
  const className = [styles.selectable, styles[`selectable--${size}`], classNameProp].join(' ');

  if (type === 'radio' && name === undefined) {
    throw new Error("Selectables with a type of 'radio' must have a 'name' attribute");
  }

  return (
    <div className={className}>
      <input
        type={type}
        id={id}
        name={name}
        className={styles.input}
        value={value}
        onChange={(event) => onChange?.(event, id)}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={id} className={styles.label}>
        {children}
      </label>
    </div>
  );
};

export default Selectable;
