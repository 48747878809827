import { ReactQueryOptions } from '#src/types';
import { useQuery } from 'react-query';

import * as apitGetOpenPositions from '#api/endpoints/public/openPositions';

export enum KeysEnum {
  contactUs = 'contact-us',
  joinOurTeam = 'join-our-team',
}

type GetApiPayload = apitGetOpenPositions.Type['success']['payload'];
export const useGetOpenPositions = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.contactUs, KeysEnum.joinOurTeam],
    async () => await apitGetOpenPositions.request(),
    {
      ...options,
    }
  );
