import React, { FC } from 'react';
import { Icon } from '../Index';

interface Props {
  title?: string;
  text?: string | undefined | null;
  onClose?: () => void;
}
const SuccessAlert: FC<Props> = ({ title, text, onClose }) => {
  if (text == null) {
    return <></>;
  }

  const shouldDisplayDescription = title != null;
  const headerText = shouldDisplayDescription ? title : text;

  return (
    <div className="border border-green-600 rounded-lg p-4 bg-green-50 grid grid-cols-[20px_2fr_20px] gap-3">
      <div>
        <Icon.CheckCircle size={5} className="text-green-600 -mt-0.5" />
      </div>
      <div className="text-green-900">
        <div className="font-semibold">{headerText}</div>
        {shouldDisplayDescription && <div className="font-normal">{text}</div>}
      </div>
      {onClose != null && (
        <button className="contents" aria-label="Close" type="button" onClick={onClose}>
          <Icon.X size={5} className="text-green-900" />
        </button>
      )}
    </div>
  );
};

export default SuccessAlert;
