import React, { FC, useState } from 'react';
import { TalentPreferences } from '#src/types';
import { FormContentGroup } from '#components/Index';
import ExtraDetailsModal from './modals/ExtraDetailsModal';
import MissingAnswerCard from './MissingAnswerCard';

interface ExtraDetailsProps {
  profilePreferences: TalentPreferences;
}

const ExtraDetails: FC<ExtraDetailsProps> = ({ profilePreferences }) => {
  const [isExtraDetailsModalOpen, setIsExtraDetailsModalOpen] = useState(false);

  const { extraDetails } = profilePreferences;

  const extraDetailsOrMissingCard = () => {
    if (extraDetails.trim() === '') return <MissingAnswerCard onClick={() => setIsExtraDetailsModalOpen(true)} />;

    return <p className="text-hpblack text-md font-light">‘{extraDetails}’</p>;
  };

  return (
    <FormContentGroup
      title="Extra details"
      groupName="Preferences"
      description="Anything else you want to tell us that could help us find your perfect role?"
      editSectionHandler={() => setIsExtraDetailsModalOpen(!isExtraDetailsModalOpen)}
    >
      <ExtraDetailsModal isOpen={isExtraDetailsModalOpen} close={() => setIsExtraDetailsModalOpen(false)} />
      <div className="mt-4">
        <h5 className="text-gray-500 text-sm pb-2">Additional Information</h5>

        {extraDetailsOrMissingCard()}
      </div>
    </FormContentGroup>
  );
};

export default ExtraDetails;
