import React from 'react';

export default [
  {
    id: 'about-us',
    heading: '1. About us',
    content: (
      <>
        <p>
          We are Hiperpool Ltd, registered in England and Wales with company number 09281067 whose registered address is
          Wework, 123 Buckingham Palace Road, London, United Kingdom, SW1W 9SH (<strong>Hiperpool</strong> /{' '}
          <strong>we</strong> / <strong>us</strong> / <strong>our</strong>).
        </p>
        <p>
          We provide recruitment services to businesses seeking to fill job vacancies (<strong>Clients</strong>) and
          individuals seeking employment (<strong>Professionals</strong>). Professionals are either individuals who have
          registered their details with Hiperpool directly or who we approach because they have indicated they are open
          to opportunities or meet the suitability criteria for a Client’s job vacancy. Clients and Professionals can
          create accounts to use our web portal which simplifies the recruitment process.
        </p>
        <p>
          We are the <strong>controller</strong> for this information (which means we decide what information we
          collect, how it is used and who we share it with).
        </p>
      </>
    ),
  },
  {
    id: 'information-we-collect-about-you',
    heading: '2. Information we collect about you',
    content: (
      <>
        <p>
          <strong>Personal data</strong> means any information which does (or could be used to) identify a living
          person. We have grouped together the types of personal data that we collect and where we receive it from
          below:
        </p>

        <table>
          <thead>
            <tr>
              <th>Professionals&apos; personal data</th>
              <th className="w-[9em] lg:w-[15em]">Received from</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <strong>Identity Data</strong> – first name, last name, job title, (if applicable) current employer.
              </td>
              <td>
                <ul>
                  <li>You</li>
                  <li>Social media platform</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Contact Data</strong> – email address, telephone number, social media handle.
              </td>
              <td>
                <ul>
                  <li>You</li>
                  <li>Social media platform</li>
                  <li>Lusha</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Recruitment</strong> – job titles, employment history, preferred working hours, qualifications
                or accreditations, professional memberships, CV, cover letter, outcome of recruitment process, current
                and desired salary.
              </td>
              <td>
                <ul>
                  <li>You</li>
                  <li>Social media platform</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Sensitive Data</strong> – information about your racial or ethnic origin, political opinions,
                religious or philosophical beliefs, trade union membership, data concerning (mental or physical) health,
                sex life or sexual orientation, trade union membership and/or information about criminal convictions and
                offences.
                <p>
                  <i>
                    Note: typically we only receive this if you voluntarily complete diversity and inclusion
                    questionnaires or surveys or inform us you require reasonable adjustment. Limited and unusual
                    examples of when we might receive this type of data from other sources includes law enforcement
                    agencies or trade union representatives contacting us directly.
                  </i>
                </p>
              </td>
              <td>
                <ul>
                  <li>You</li>
                  <li>External providers (in limited circumstances only)</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Web Portal Data</strong> – login credentials, email address, profile picture (optional), chat
                logs, audit trail of systems used and profiles / files accessed and downloaded, internet protocol (IP)
                address, browser type and version, time zone setting and generic location, browser plug-in types and
                versions, operating system and platform on the devices you use to access our portal.
              </td>
              <td>
                <ul>
                  <li>You (including via cookies and other technologies)</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Feedback</strong> - information and responses you provide when completing surveys and
                questionnaires
              </td>
              <td>
                <ul>
                  <li>You</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Marketing Data</strong> - includes your preferences in receiving marketing from us, how you have
                interacted with our communications and your communication preferences.
              </td>
              <td>
                <ul>
                  <li>You (including via cookies and other technologies)</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>Clients’ staff personal data</th>
              <th className="w-[9em] lg:w-[15em]">Received from</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <strong>Identity Data</strong> – first name, last name, employer, job role
              </td>
              <td>
                <ul>
                  <li>You</li>
                  <li>Client</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Contact Data</strong> – work email address, work telephone number
              </td>
              <td>
                <ul>
                  <li>You</li>
                  <li>Client</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Web Portal Data</strong> – login credentials, email address, profile picture (optional), chat
                logs, audit trail of systems used and profiles / files accessed and downloaded, internet protocol (IP)
                address, browser type and version, time zone setting and generic location, browser plug-in types and
                versions, operating system and platform on the devices you use to access our portal.
              </td>
              <td>
                <ul>
                  <li>You (including via cookies and other technologies)</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Marketing Data</strong> - includes your preferences in receiving marketing from us, how you have
                interacted with our communications and your communication preferences.
              </td>
              <td>
                <ul>
                  <li>You (including via cookies and other technologies)</li>
                  <li>Clients</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Feedback</strong> - information and responses you provide when completing surveys and
                questionnaires
              </td>
              <td>
                <ul>
                  <li>You</li>
                  <li>Clients</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>Website Visitors’ personal data</th>
              <th className="w-[9em] lg:w-[15em]">Received from</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <strong>Identity Data</strong> – first name, last name
              </td>
              <td>
                <ul>
                  <li>You</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Contact Data</strong> – email address, telephone number (optional)
              </td>
              <td>
                <ul>
                  <li>You</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Technical Data</strong> - internet protocol (IP) address, browser type and version, time zone
                setting and generic location, browser plug-in types and versions, operating system and platform on the
                devices you use to access our systems
              </td>
              <td>
                <ul>
                  <li>You (via cookies and other similar technologies)</li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Enquiries and Feedback</strong> - information and responses you provide when completing our
                webforms, surveys and questionnaires
              </td>
              <td>
                <ul>
                  <li>You</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>Other categories</th>
              <th className="w-[9em] lg:w-[15em]">Received from</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <strong>Social media users</strong> - Social media handle, profile picture, interactions and comments
                with our corporate social media accounts
              </td>
              <td>
                <ul>
                  <li>You</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
  {
    id: 'how-we-use-your-information',
    heading: '3. How we use your information',
    content: (
      <>
        <p>
          Under UK data protection law, we need a legal reason (known as a <strong>lawful basis</strong>) for holding,
          collecting and using your personal data. There are 6 main legal reasons which organisations can rely on. The
          most relevant are:
        </p>
        <ul className="!mt-0">
          <li>
            to enter into and perform our <strong>contract</strong> with you;
          </li>
          <li>
            pursue our <strong>legitimate interests</strong> (our justifiable business aims) but only if those interests
            are not outweighed by your other rights and freedoms (e.g. your right to privacy);
          </li>
          <li>
            to comply with a <strong>legal obligation</strong> that we have;
          </li>
          <li>
            where you have <strong>consented</strong> to us using your personal data a certain way.{' '}
          </li>
        </ul>
        <p>
          The following table sets out when we rely on each lawful basis. If we intend to use your personal data for a
          new reason that is not listed in the table, we will update our privacy notice and notify you.
        </p>

        <table>
          <thead>
            <tr>
              <th>Purposes</th>
              <th>Lawful Basis</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                Taking steps to enter into the contract with a Professional or Client (and fulfil the terms of that
                contract)
              </td>
              <td>
                Performance of contract (for Professionals) Legitimate interests (for Clients, as necessary to conclude
                our contract with them and obtain contact details for key contracts)
              </td>
            </tr>

            <tr>
              <td>Processing payments and collecting and recovering monies owed to us</td>
              <td>
                Legitimate interests (for Clients, as necessary to receive payment for our recruitment services and
                settle outstanding sums)
              </td>
            </tr>

            <tr>
              <td>Responding to enquiries</td>
              <td>
                Performance of contract (where enquiry relates to the contract and is received from a Professional)
                Legitimate interests (where the enquiry relates to the contract and is received from a Client)
              </td>
            </tr>

            <tr>
              <td>Administering and protecting our Website, web portal, services and systems</td>
              <td>
                Legitimate interests (necessary to provide our products and services, monitor and improve network
                security and prevent fraud)
              </td>
            </tr>

            <tr>
              <td>Providing insight on how our Website, services and products are being used</td>
              <td>
                Legitimate interests (necessary to identify areas for improvement and inform service, product and
                content development)
              </td>
            </tr>

            <tr>
              <td>
                Investigating and responding to complaints (including allegations of bullying or unacceptable conduct)
              </td>
              <td>Legitimate interest (to safeguard other Professionals, Clients and our staff)</td>
            </tr>

            <tr>
              <td>Facilitating request for reasonable adjustment</td>
              <td>Legal obligation (necessary to comply with our obligations of non-discrimination)</td>
            </tr>

            <tr>
              <td>Sending you marketing communications by email</td>
              <td>
                Consent (where you are an individual) Legitimate interest (where your email address belongs to a
                corporate body
                <i>Please see section 4 below.</i>
              </td>
            </tr>

            <tr>
              <td>Asking you to participate in surveys and other types of feedback</td>
              <td>Legitimate interest (necessary for us to improve our products and services)</td>
            </tr>

            <tr>
              <td>Issuing, responding or defending legal claims</td>
              <td>
                Legitimate interest (in defending ourselves against legal claims and responding to breaches of contract)
              </td>
            </tr>

            <tr>
              <td>Notifying you about changes to our privacy notice</td>
              <td>Legal obligation (necessary to comply with our obligations under data protection law).</td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
  {
    id: 'when-we-send-you-marketing-messages',
    heading: '4. When we send you marketing messages',
    content: (
      <>
        <p>
          Where you are an individual, we always ask for consent before we send your marketing information. If you work
          for a Client (existing or prospective) we market on a business-to-business basis – so we make sure we only
          ever send marketing communications to work contact details. We always include a link in our emails so you can
          unsubscribe at any time.
        </p>
        <p>
          We use SendGrid to help us deliver and monitor the communications we send. Their digital tools let us see
          whether a recipient has clicked any of the links in our email, which help us understand what content that
          recipient appears to be interested in and allow us to personalise the content of future of our messages.
        </p>
        <p>Pixels (which are a similar technology to cookies) within those emails enable us to see:</p>
        <ul className="!mt-0">
          <li>if the email was opened</li>
          <li>where the device opening the email was located (based on the device’s IP address)</li>
          <li>the type of email service (e.g. Outlook) that was used</li>
          <li>if the email (or its content) were shared on social media</li>
          <li>if the email was flagged as spam</li>
        </ul>
      </>
    ),
  },
  {
    id: 'who-we-share-your-information-with',
    heading: '5. Who we share your information with',
    content: (
      <>
        <p>We may share your personal data with:</p>
        <ul className="!mt-0">
          <li>
            <strong>Our staff:</strong> our employees (or other types of workers) who have contracts containing
            confidentiality and data protection obligations.{' '}
          </li>
          <li>
            <strong>Our Clients:</strong> we have a contract in place with all Clients which sets out what information
            we provide to them as part of our services. Clients{' '}
          </li>
          always act as <strong>independent controllers</strong> (which means they may use that personal data for their
          own purposes). For more information about what information they use, you should check their privacy notice.
          <li>
            <strong>Our supply chain:</strong> other organisations that help us provide our services, such as our
            website hosting provider, IT infrastructure provider and{' '}
          </li>
          third party marketing support. We ensure these organisations only have access to the information required to
          provide the support we use them for and have a contract with them that contains confidentiality and data
          protection obligations.
          <li>
            <strong>Social media platforms:</strong> we have corporate accounts on multiple social media platforms.{' '}
          </li>
          <li>
            <strong>Regulatory authorities:</strong> such as HM Revenue & Customs.
          </li>
          <li>
            <strong>Our professional advisers:</strong> such as our accountants or legal advisors where we require
            specialist advice to help us conduct our business.
          </li>
          <li>
            Any <strong>actual</strong> or <strong>potential buyer</strong> of our business.
          </li>
        </ul>
        <p>
          If we were asked to provide personal data in response to a court order or legal request (e.g. from the
          police), we would seek legal advice before disclosing any information and carefully consider the impact on
          your rights when providing a response.
        </p>
      </>
    ),
  },
  {
    id: 'where-your-information-is-located-or-transferred-to',
    heading: '6. Where your information is located or transferred to',
    content: (
      <>
        <p>
          We store company information (including your personal data) in servers located in the US. We have proper
          mechanisms in place to ensure that transfer to that country is compliant with relevant data protection laws.
        </p>
        <p>
          Otherwise, we will only transfer information outside of the UK where we have a valid legal mechanism in place
          (e.g. by using contracts approved by the UK Secretary of State).
        </p>
      </>
    ),
  },
  {
    id: 'how-we-keep-your-information-safe',
    heading: '7. How we keep your information safe',
    content: (
      <>
        <p>
          We have put in place appropriate security and safety measures to prevent your personal data from being lost or
          illegally accessed by those who do not have permission. These measures include:
        </p>
        <ul className="!mt-0">
          <li>access controls and user authentication (including multi-factor authentication);</li>
          <li>internal IT and network security; </li>
          <li>regular testing and review of our security measures;</li>
          <li>staff policies and training;</li>
          <li>incident and breach reporting processes;</li>
          <li>business continuity and disaster recovery processes;</li>
        </ul>
        <p>
          In the event that there is an event or incident affecting your personal data, we will keep you informed. We
          may also need to notify the regulator (where required under data protection law).
        </p>
        <p>
          If you notice any unusual activity on your web portal account (or believe your account have been otherwise
          compromised), please let us know by emailing <a href="mailto:info@hiperpool.com">info@hiperpool.com</a>.
        </p>
      </>
    ),
  },
  {
    id: 'how-long-we-keep-your-information',
    heading: '8. How long we keep your information',
    content: (
      <>
        <p>
          Where we are responsible for making decisions about how to collect and use your personal data, we will only
          keep your personal data for as long as necessary to fulfil the purposes we collected it for or as long as
          required to fulfil our legal obligations.
        </p>
        <p>
          When we consider how long to keep your personal data, we will consider whether it is still necessary to keep
          it for the purpose which we collected it or whether the same purpose could be achieved by holding less
          personal data. We will also consider the volume, nature, and sensitivity of the personal data and the
          potential harm to you if there was an incident affecting your personal data.
        </p>
        <p>
          We may keep identity and contact details and certain other data (specifically, any exchanges between us by
          email or any other means) for up to seven years after the end of our contractual relationship with you.
        </p>
        <p>
          If you browse our Website, we keep personal data collected through our analytics tools for only as long as
          necessary to fulfil the purposes we collected it for (see our cookie notice for further information).
        </p>
        <p>
          If you have asked for information from us or you have subscribed to our mailing list, we keep your details for
          a reasonable time or until you ask us to stop contacting you.
        </p>
      </>
    ),
  },
  {
    id: 'your-legal-rights',
    heading: '9. Your legal rights',
    content: (
      <>
        <p>You have specific legal rights in relation to your personal data under UK data protection law:</p>
        <p>
          <strong>Access:</strong> You must be told if your personal data is being used and you can ask for a copy of
          your personal data as well as information about how we are using it to make sure we are abiding by the law.
        </p>
        <p>
          <strong>Correction:</strong> You can ask us to correct your personal data if it is inaccurate or incomplete.
          We might need to verify the new information before we make any changes.
        </p>
        <p>
          <strong>Deletion:</strong> You can ask us to delete or remove your personal data if there is no good reason
          for us to continuing holding it or if you have asked us to stop using it. If we think there is a good reason
          to keep the information you have asked us to delete (e.g. to comply with regulatory requirements), we will let
          you know and explain our decision.
        </p>
        <p>
          <strong>Restriction:</strong> You can ask us to restrict how we use your personal data and temporarily limit
          the way we use it (e.g. whilst you check that the personal data we hold for you is correct).
        </p>
        <p>
          <strong>Objection:</strong> You can object to us using your personal data if you want us to stop using it. We
          always comply with your request if you ask us to stop sending you marketing communications but in other cases,
          we decide whether we will continue. If we think there is a good reason for us to keep using the information,
          we will let you know and explain our decision.
        </p>
        <p>
          <strong>Portability:</strong> You can ask us to send you or another organisation an electronic copy of your
          personal data.
        </p>
        <p>
          <strong>Complaints:</strong> If you are unhappy with the way we collect and use your personal data, you can
          complain to the Information Commissioner or another relevant supervisory body, but we hope that we can respond
          to your concerns before it reaches that stage. Please contact us at{' '}
          <a href="mailto:info@hiperpool.com">info@hiperpool.com</a>.
        </p>
        <p>
          We can decide not to take any action in relation to a request where we have been unable to confirm your
          identity (this is one of our security processes to make sure we keep information safe) or if we feel the
          request is unfounded or excessive. We may charge a fee where we decide to proceed with a request that we
          believe is unfounded or excessive. If this happens we will always inform you in writing.
        </p>
        <p>
          If you would like to exercise any of your legal rights, please contact{' '}
          <a href="mailto:info@hiperpool.com">info@hiperpool.com</a>.
        </p>
      </>
    ),
  },
];
