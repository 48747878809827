/* eslint-disable react/display-name */
import React, { FC } from 'react';
import { uniqueId } from 'lodash-es';
import formStyles from '#components/Form/styles/FormInput.module.css';

export interface InputProps {
  label: string;
  error?: string;
  description?: string;
  required?: boolean;
}

interface Props extends InputProps {
  children: (props: { id: string; hasError: boolean; hasDescription: boolean }) => React.ReactNode;
}
const InputWrapper: FC<Props> = ({ error, description, label, required = false, children }) => {
  const uniqId = uniqueId('input');
  const hasError = error !== undefined && error !== '';
  const hasDescription = description !== undefined && description !== '';

  const renderDetails = () => {
    if (hasError) {
      return (
        <span className={formStyles.error} data-testid="error">
          {error}
        </span>
      );
    }
    if (hasDescription) {
      return (
        <span className={formStyles.description} data-testid="description">
          {description}
        </span>
      );
    }
    return <></>;
  };

  return (
    <div className={formStyles['input-wrapper']}>
      <div className="flex">
        <label htmlFor={uniqId} className={formStyles.label} data-testid="label">
          {label}
        </label>
        {required && (
          <span aria-hidden={true} className="text-gray-500 font-normal">
            *
          </span>
        )}
      </div>
      {children({ id: uniqId, hasError, hasDescription })}
      {renderDetails()}
    </div>
  );
};

export default InputWrapper;
