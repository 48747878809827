import { createAction, GET, ApiPayloadsCreator } from '#api/actionCreator';
import { Errors, User } from '#root/src/types';

interface Payload {
  init: never;
  success: {
    user: User;
  };
  fail: { errors: Errors<'base'>; status: number };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(GET, '/public/sessions/user_details');
