import React, { FC } from 'react';
import styles from './OfferCard.module.css';

interface Props {
  background: React.ReactElement;
  title: string;
  subtitle: string;
  href: string;
  onClick?: () => void;
  hint?: React.ReactElement;
}

const OfferCard: FC<Props> = ({ background, title, subtitle, href, hint, onClick }) => (
  <a
    href={onClick == null ? href : '#'}
    className={styles.card}
    onClick={(e) => {
      if (onClick != null) {
        e.preventDefault();
        onClick();
      }
    }}
  >
    <div className={styles.background}>{background}</div>
    <h3 className="font-serif text-display-md mt-8">{title}</h3>
    <div className="text-xl mt-1">{subtitle}</div>
    {hint != null && <span className={styles.hint}>{hint}</span>}
  </a>
);

export default OfferCard;
