import React, { FC } from 'react';
import { Icon } from '#components/Index';

interface MissingAnswerCardProps {
  onClick?: () => void;
  withoutTitle?: boolean;
  message?: string;
}

const MissingAnswerCard: FC<MissingAnswerCardProps> = ({
  onClick,
  message = 'Complete this question to ensure better matches',
  withoutTitle = false,
}) => {
  return (
    <span
      className="flex gap-x-3 cursor-pointer rounded-lg leading-tight text-sm p-3 text-red-700 bg-red-50 max-w-md"
      onClick={onClick}
    >
      <Icon.ExclamationMarkCircle size={4} className="mt-0.5" />
      <div className="flex flex-col gap-y-1 flex-grow">
        {!withoutTitle && <span className="font-semibold">MISSING ANSWER</span>}
        <p>{message}</p>
      </div>
      <Icon.ArrowRightSLine size={4} className="mt-0.5" />
    </span>
  );
};

export default MissingAnswerCard;
