import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    countries: string[];
  };
  success: {
    workAuthorizationGroups: string[];
  };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = async (params: Payload['init']) =>
  await createAction<Payload>(POST, '/public/work_authorization_groups/groups_by_countries')(params);
