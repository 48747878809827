import React, { FC } from 'react';
import { ReactComponent as CheckBoxCircleIcon } from '#assets/iconsToBe/checkbox-circle-fill.svg';

interface Props {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  list: string[];
}
const PlanCard: FC<Props> = ({ icon, title, subtitle, list }) => {
  return (
    <div className="bg-white p-6 w-full max-w-sm rounded-xl shadow-lg">
      {icon}
      <h3 className="text-display-xs md:text-display-sm font-serif mt-5">{title}</h3>
      <div className="text-green-900 font-semibold mt-1">{subtitle}</div>
      <ul className="text-gray-500 mt-4">
        {list.map((item) => (
          <li key={item} className="flex mt-2">
            <div className="grid place-items-center h-[1.5em] mr-2">
              <CheckBoxCircleIcon className="text-green-600 w-4 h-4" />
            </div>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlanCard;
