import React, { FC, useState } from 'react';
import { paths } from '#pages/paths';
import { TalentPreferences } from '#src/types';
import { generateOriginAppUrl } from '#root/src/lib/generateOriginAppUrl';
import { Icon, Button } from '#components/Index';
import ReviewAllModal from './modals/ReviewAllModal';

interface PreferencesVisibilityInfoProps {
  profilePreferences: TalentPreferences;
}

const PreferencesVisibilityInfo: FC<PreferencesVisibilityInfoProps> = ({ profilePreferences }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex flex-col gap-y-2 mb-6">
      <ReviewAllModal isOpen={isModalOpen} close={() => setIsModalOpen(false)} />

      {profilePreferences.moderationState === 'limited' && (
        <div className="flex gap-y-4 flex-col mb-2 md:mb-0 md:flex-row md:items-center justify-between">
          <div className="flex gap-x-2">
            <div className="flex mt-0.5">
              <Icon.QuestionMarkFill size={5} className="text-green-600" />
            </div>

            <p className="font-light text-gray-500">
              <span className="font-medium">You currently have a limited access account.</span> To access job
              opportunities please complete your onboarding.
            </p>
          </div>

          <a href={generateOriginAppUrl({ path: paths.talentStartOnboarding() })}>
            <Button variant="primary">
              Complete onboarding <Icon.ArrowRightLine size={5} />
            </Button>
          </a>
        </div>
      )}

      {profilePreferences.moderationState !== 'limited' && (
        <div className="flex gap-y-4 flex-col mb-2 md:mb-0 md:flex-row md:items-center justify-between">
          <div className="flex gap-x-2">
            <div className="flex mt-0.5">
              <Icon.QuestionMarkFill size={5} className="text-green-600" />
            </div>

            <p className="font-light text-gray-500">
              <span className="font-medium">How do we use this information?</span> Your answers here help us match you
              with the right opportunities and cut through the noise.
            </p>
          </div>

          <Button variant="primary" onClick={() => setIsModalOpen(true)}>
            Update all your information <Icon.ArrowRightLine size={5} />
          </Button>
        </div>
      )}

      <div className="flex gap-x-2">
        <div className="flex mt-0.5">
          <Icon.EyeOffFill size={5} className="text-green-600" />
        </div>

        <p className="font-light text-gray-500">
          <span className="font-medium">Who can see it?</span> It’s only visible to the Hiperpool team. Employers will
          never see these answers.
        </p>
      </div>
    </div>
  );
};

export default PreferencesVisibilityInfo;
