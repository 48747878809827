import React, { FC } from 'react';
import { BaseProfileEditModal, Step, StepsProvider, QuestionGroupModalProps } from '../BaseProfileEditModal';
import InterestedCompaniesStep from './steps/InterestedCompaniesStep';
import UnpreferredCompaniesStep from './steps/UnpreferredCompaniesStep';

const CompaniesModal: FC<QuestionGroupModalProps> = ({ isOpen, close }) => {
  const steps: Step[] = [
    {
      name: InterestedCompaniesStep.name,
      content: () => <InterestedCompaniesStep />,
      visible: true,
    },
    {
      name: UnpreferredCompaniesStep.name,
      content: () => <UnpreferredCompaniesStep />,
      visible: true,
    },
  ];

  return (
    <StepsProvider>
      <BaseProfileEditModal isOpen={isOpen} close={close} initialSteps={steps} />
    </StepsProvider>
  );
};

export default CompaniesModal;
