import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { TalentLanguage, LanguageWithProficiencyOption } from '#src/types';
import { Step, StepBackButton, StepNextButton } from '../../CurrentStep';
import { useTalentDataController } from '../../TalentDataController';
import { useStepController } from '../../StepControllerContext';
import LanguagesForm from '#components/TalentSharedForms/Languages/Form';

const Languages: Step = {
  id: 'languages',
  Body: ({
    setNextDisabled,
    setHandleNextClick,
  }: {
    setNextDisabled?: Dispatch<SetStateAction<boolean>>;
    setHandleNextClick?: Dispatch<SetStateAction<() => void>>;
  }) => {
    const [selectedLanguages, setSelectedLanguages] = useState<LanguageWithProficiencyOption[]>([]);
    const { talentData, updateTalentData } = useTalentDataController();
    const { next } = useStepController();

    // Set the selected languages to the talent's languages if they exist, otherwise set it to a default value
    useEffect(() => {
      if (talentData != null && talentData.languages.length > 0) {
        setSelectedLanguages(talentData.languages);
      } else {
        setSelectedLanguages([
          {
            language: 'English',
            proficiency: undefined,
            approvedLanguageId: 1, // Language with id '1' is 'English' although this could change
          },
        ]);
      }
    }, [talentData]);

    // Prevent the user from moving to the next step if they haven't selected any value
    useEffect(() => {
      if (setNextDisabled != null) {
        const hasEmptyLanguage = selectedLanguages.some((language) => language.language === undefined);
        const hasEmptyProficiency = selectedLanguages.some((language) => language.proficiency === undefined);

        setNextDisabled(hasEmptyLanguage || hasEmptyProficiency);
      }
    }, [selectedLanguages, setNextDisabled]);

    useEffect(() => {
      const handleNext = async () => {
        if (talentData == null) {
          return;
        }

        const newLanguages: TalentLanguage[] = [];

        // Filter out entries with undefined values before mapping to ensure only valid TalentLanguage objects are processed,
        // preventing TypeScript error: Type '{ ... }' is not assignable to type 'TalentLanguage' due to potential 'undefined' values.
        selectedLanguages.forEach(({ language, proficiency, approvedLanguageId }) => {
          if (language !== undefined && proficiency !== undefined && approvedLanguageId !== undefined) {
            newLanguages.push({
              language,
              proficiency,
              approvedLanguageId,
            });
          }
        });

        const newTalentData = { ...talentData, languages: newLanguages };

        const result = await updateTalentData(newTalentData, 'languages');

        if (result.success === true) {
          next();
        }
      };

      if (setHandleNextClick != null) {
        setHandleNextClick(() => handleNext);
      }
    }, [setHandleNextClick, selectedLanguages, talentData, next, updateTalentData]);

    return <LanguagesForm selectedLanguages={selectedLanguages} setSelectedLanguages={setSelectedLanguages} />;
  },
  Footer: ({ nextDisabled, onNextClick }: { nextDisabled?: boolean; onNextClick?: () => void }) => (
    <>
      <StepBackButton />
      <StepNextButton disabled={nextDisabled} onClick={onNextClick} />
    </>
  ),
};

export default Languages;
