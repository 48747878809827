import React, { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Button } from '#components/Index';
import { paths } from '#pages/paths';
import EmployerFAQ from './EmployerFAQ';

const SupportForEmployer: FC = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Hiperpool - Employer FAQ</title>
          <meta
            name="description"
            content="Explore our FAQ page for employers to understand how Hiperpool simplifies your hiring process. Learn about our customized, pre-screened candidate pipeline, and how our industry expertise helps you reach top talent. Discover how we focus on delivering the best candidates for strategic roles in consulting, strategy, and investing. For any further questions or to discuss your specific needs, Hiperpool is ready to assist."
          />
        </Helmet>
        <div className="bg-gray-50 px-variable-sm">
          <div className="py-14 m-auto max-w-4xl">
            <h1 className="text-display-md font-serif text-center">Frequently asked questions</h1>
            <p className="text-center mt-6 mb-8">
              Are you an employer with questions about listing your job on Hiperpool? <br />
              <Link className="link" to={paths.supportForCandidate()}>
                Visit the candidate FAQs
              </Link>
            </p>
            <EmployerFAQ />
            <div className="text-center my-12">
              <Button to={paths.contactUs()} variant="secondary">
                Still have questions? Contact us
              </Button>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};

export default SupportForEmployer;
