/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';

import textBoxStyles from '#components/Form/styles/DateInput.module.css';
import InputWrapper, { InputProps } from '../InputWrapper';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    InputProps {
  className?: string;
}

const DateInput = forwardRef<HTMLInputElement, Props>(
  ({ error, description, label, className, required, placeholder, ...rest }, ref) => {
    return (
      <InputWrapper label={label} error={error} description={description} required={required}>
        {({ id, hasError }) => (
          <input
            {...rest}
            id={id}
            type="date"
            className={[textBoxStyles.input, hasError ? textBoxStyles[`input--error`] : '', className].join(' ')}
            ref={ref}
            required={required}
          />
        )}
      </InputWrapper>
    );
  }
);

export default DateInput;
