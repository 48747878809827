import React, { FC, SetStateAction, Dispatch } from 'react';
import { TextInput, Checkbox, DateInput, LocationInput, AutocompleteInput } from '#components/Form/Index';
import { OnboardingTalentRecentPosition, Errors } from '#root/src/types';
import * as apiAutocompletePrivacyCompanies from '#api/endpoints/autocomplete/privacyCompanies';
import { useTalentDataController } from '../../TalentDataController';
import { format } from 'date-fns';

interface Props {
  recentWorkEntry: OnboardingTalentRecentPosition;
  setRecentWorkEntry: Dispatch<SetStateAction<OnboardingTalentRecentPosition>>;
  stepErrors?: Errors;
}

export const RecentPosition: FC<Props> = ({ recentWorkEntry, setRecentWorkEntry, stepErrors }) => {
  const { getError } = useTalentDataController();

  const parseDate = (isoDateString: string) => {
    if (isoDateString == null || isoDateString === '') return '';

    const date = new Date(isoDateString);
    return format(date, 'yyyy-MM-dd');
  };

  const handleCurrentChange = (current: boolean) => {
    const newEndDate = current ? '' : recentWorkEntry.endDate;

    setRecentWorkEntry({ ...recentWorkEntry, current, endDate: newEndDate });
  };

  return (
    <div className="flex flex-col">
      <div>
        <h2 className="text-display-md font-serif text-hpblack text-center">
          Enter the details for your most recent position
        </h2>
      </div>
      <div className="grid gap-y-4">
        <TextInput
          label="Position"
          type="text"
          placeholder="E.g. Consultant"
          value={recentWorkEntry.position ?? ''}
          onChange={(e) => setRecentWorkEntry({ ...recentWorkEntry, position: e.target.value })}
          error={getError('position')}
        />
        <AutocompleteInput
          label="Company"
          placeholder="E. g. ACME Corp."
          value={recentWorkEntry.company ?? ''}
          onChange={(name) => setRecentWorkEntry({ ...recentWorkEntry, company: name })}
          autocompleteRequest={apiAutocompletePrivacyCompanies.request}
          error={getError('company')}
        />
        <LocationInput
          label="Location"
          placeholder="Search locations..."
          value={recentWorkEntry.location ?? ''}
          onChange={(e) => setRecentWorkEntry({ ...recentWorkEntry, location: e.target.value })}
          error={getError('location')}
        />

        <DateInput
          label="From"
          value={parseDate(recentWorkEntry.startDate ?? '')}
          onChange={(e) => setRecentWorkEntry({ ...recentWorkEntry, startDate: e.target.value })}
          error={getError('startDate')}
        />

        <DateInput
          label="To"
          disabled={recentWorkEntry.current ?? ''}
          value={recentWorkEntry.endDate != null ? parseDate(recentWorkEntry.endDate) : ''}
          onChange={(e) => setRecentWorkEntry({ ...recentWorkEntry, endDate: e.target.value })}
          error={getError('endDate')}
        />

        <div className="flex justify-end">
          <Checkbox checked={recentWorkEntry.current} onChange={() => handleCurrentChange(!recentWorkEntry.current)}>
            I currently work here
          </Checkbox>
        </div>
      </div>
    </div>
  );
};
