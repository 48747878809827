import React, { FC, useMemo, useState } from 'react';
import { TalentPreferences } from '#src/types';
import { useGetDesiredEmploymentTypes } from '#components/TalentSharedForms/EmploymentType/DesiredEmploymentTypesData';
import { useGetWorkInterestSituationTypes } from '#components/TalentSharedForms/CurrentSituation/WorkInterestSituationTypesData';
import { FormContentGroup } from '#components/Index';
import CurrentSituationModal from './modals/CurrentSituationModal';
import MissingAnswerCard from './MissingAnswerCard';
import SingleLineAnswer from './SingleLineAnswer';
import ProfileBadge from '../../elements/ProfileBadge';

interface CurrentSituationProps {
  profilePreferences: TalentPreferences;
}

const CurrentSituation: FC<CurrentSituationProps> = ({ profilePreferences }) => {
  const [isCurrentSituationModalOpen, setIsCurrentSituationModalOpen] = useState(false);

  const { data: desiredEmploymentTypesData } = useGetDesiredEmploymentTypes();
  const { data: workSituationTypesData } = useGetWorkInterestSituationTypes();

  const { workInterestSituationId, desiredEmploymentTypeIds } = profilePreferences;

  const selectedWorkInterestSituation = useMemo(() => {
    if (workSituationTypesData == null) return null;

    return workSituationTypesData.workInterestSituations.find(
      (situation) => situation.value === workInterestSituationId
    );
  }, [workInterestSituationId, workSituationTypesData]);

  const selectedEmploymentTypes = useMemo(() => {
    if (desiredEmploymentTypesData == null) return [];

    return desiredEmploymentTypesData.desiredEmploymentTypes.filter((employmentType) =>
      desiredEmploymentTypeIds.includes(employmentType.id)
    );
  }, [desiredEmploymentTypeIds, desiredEmploymentTypesData]);

  if (desiredEmploymentTypesData == null || workSituationTypesData == null) return null;

  const jobSeekingStatusOrMissingCard = () => {
    if (selectedWorkInterestSituation == null)
      return <MissingAnswerCard onClick={() => setIsCurrentSituationModalOpen(true)} />;

    return <SingleLineAnswer>{selectedWorkInterestSituation.label}</SingleLineAnswer>;
  };

  const selectedEmploymentTypesOrMissingCard = () => {
    if (selectedEmploymentTypes.length === 0)
      return <MissingAnswerCard onClick={() => setIsCurrentSituationModalOpen(true)} />;

    return (
      <div className="flex flex-wrap gap-2">
        {selectedEmploymentTypes.map((employmentType) => (
          <ProfileBadge key={employmentType.id}>{employmentType.name}</ProfileBadge>
        ))}
      </div>
    );
  };

  return (
    <FormContentGroup
      title="Current situation"
      groupName="Experience"
      description="How would you describe your current situation?"
      editSectionHandler={() => setIsCurrentSituationModalOpen(!isCurrentSituationModalOpen)}
    >
      <CurrentSituationModal isOpen={isCurrentSituationModalOpen} close={() => setIsCurrentSituationModalOpen(false)} />

      <div className="flex flex-col gap-y-6">
        <div>
          <h5 className="text-gray-500 text-sm pb-2">My current situation</h5>
          {jobSeekingStatusOrMissingCard()}
        </div>

        <div>
          <h5 className="text-gray-500 text-sm pb-2">Types of employment I’m interested in</h5>
          {selectedEmploymentTypesOrMissingCard()}
        </div>
      </div>
    </FormContentGroup>
  );
};

export default CurrentSituation;
