import React, { FC, useEffect, useState, useCallback } from 'react';
import { createSuccessToast, createErrorToast } from '#src/lib/Toasts';
import { request as apiUpdateDesiredEmploymentTypes } from '#api/endpoints/talents/profile/updateDesiredEmploymentTypes';
import { useGetProfilePreferences } from '../../../data/ProfilePreferences';
import { useSteps } from '../../BaseProfileEditModal';
import TypeOfEmploymentForm from '#components/TalentSharedForms/EmploymentType/Form';

const EmploymentTypeStep: FC = () => {
  const { data: profilePreferencesData } = useGetProfilePreferences();
  const { setStepCallback, setNextDisabled } = useSteps();

  const [selectedDesiredEmploymentTypes, setSelectedDesiredEmploymentTypes] = useState<number[]>([]);

  // Set the selected desired type of employment type based on the talent data
  useEffect(() => {
    if (profilePreferencesData == null) return;

    setSelectedDesiredEmploymentTypes(profilePreferencesData.profilePreferences.desiredEmploymentTypeIds);
  }, [profilePreferencesData]);

  // Prevent the user from moving to the next step if they haven't selected any value
  useEffect(() => {
    setNextDisabled(selectedDesiredEmploymentTypes.length === 0);
  }, [selectedDesiredEmploymentTypes, setNextDisabled]);

  const stepCallback = useCallback(async () => {
    if (selectedDesiredEmploymentTypes.length === 0) return false;

    try {
      await apiUpdateDesiredEmploymentTypes({ desiredEmploymentTypeIds: selectedDesiredEmploymentTypes });
      createSuccessToast({ title: 'Desired employment types' });
      return true;
    } catch {
      createErrorToast({ title: 'Desired employment types' });
      return false;
    }
  }, [selectedDesiredEmploymentTypes]);

  useEffect(() => {
    setStepCallback(() => stepCallback);
  }, [setStepCallback, stepCallback]);

  return (
    <TypeOfEmploymentForm
      setSelectedDesiredEmploymentTypes={setSelectedDesiredEmploymentTypes}
      selectedDesiredEmploymentTypes={selectedDesiredEmploymentTypes}
    />
  );
};

export default EmploymentTypeStep;
