import React, { FC, useMemo } from 'react';

import BlockQuote from '#components/BlockQuote/BlockQuote';
import Carousel from '#components/Carousel/Carousel';

import DanielGittsovichJPEG from '#assets/people/danielGittsovich.jpeg';
import ConnorFeeJPEG from '#assets/people/connorFee.jpeg';
import MarkThomasJPEG from '#assets/people/markThomas.jpeg';
import MarcoDeCurtisJPEG from '#assets/people/marcoDeCurtis.jpeg';

import ThreeMLogo from '#assets/logo/3m.webp';
import C2FoLogo from '#assets/logo/c2fo.jpeg';
import ClearbitLogo from '#assets/logo/clearbit.webp';
import ManualLogo from '#assets/logo/manual.jpeg';

const TestimonialCarousel: FC = () => {
  const people = useMemo(
    () => [
      {
        src: DanielGittsovichJPEG,
        logoSrc: ThreeMLogo,
        name: 'Daniel Gittsovich',
        jobDescription: 'Head of Corporate Strategy at 3M',
        quote:
          'Seamless experience in identifying and attracting high quality candidates for tough to hire-for positions. Look forward to continuing to work with Hiperpool on upcoming strategy searches.',
      },
      {
        src: MarkThomasJPEG,
        logoSrc: C2FoLogo,
        name: 'Mark Thomas',
        jobDescription: 'COO EMEA at C2FO',
        quote:
          'Hiperpool quickly provided an extremely relevant list of high quality candidates and we hired one of them within a month. Their customer service and speed of execution was impressive and we are using them for several other searches as a result.',
      },
      {
        src: ConnorFeeJPEG,
        logoSrc: ClearbitLogo,
        name: 'Connor Fee',
        jobDescription: 'Chief Revenue Officer at Clearbit',
        quote:
          "Hiperpool has helped me hire not once but twice for a very unique and specialty role. They really helped me dial in the type of candidate I'm looking for and consistently execute against finding it.",
      },
      {
        src: MarcoDeCurtisJPEG,
        logoSrc: ManualLogo,
        name: 'Marco De Curtis',
        jobDescription: 'Commercial Director at Manual',
        quote:
          'I have been hiring for two roles in the last year and in both cases the candidates hired were from Hiperpool. If you want to reduce time and improve quality when hiring, I recommend Hiperpool.',
      },
    ],
    []
  );
  return (
    <Carousel
      className="py-16 px-variable-sm max-md:[@media(hover:none)]:px-0 bg-gray-50 max-w-screen"
      slideClassName="max-md:[@media(hover:none)]:px-variable-md place-self-center"
      components={people.map((elm) => (
        <BlockQuote
          key={elm.name}
          image={<img loading="lazy" src={elm.src} height="207" width="207" alt="" />}
          name={elm.name}
          jobDescription={elm.jobDescription}
          jobLogo={
            elm.logoSrc != null ? <img src={elm.logoSrc} alt="" height="83" width="83" loading="lazy" /> : undefined
          }
        >
          <p>{elm.quote}</p>
        </BlockQuote>
      ))}
    />
  );
};

export default TestimonialCarousel;
