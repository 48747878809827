import React, { FC } from 'react';
import { CircleGraphic } from '#components/Index';

export const ErrorPage: FC<{ error?: any; className?: string }> = ({ error = null, className = '' }) => {
  if (error != null) console.error(error);

  return (
    <div className={className}>
      <div>
        <div className="flex flex-col justify-center px-variable-lg py-28">
          <h2 className="font-serif font-normal text-display-lg md:text-display-2xl text-center mb-4 text-green-700">
            500
          </h2>
          <h1 className="font-serif font-normal text-display-sm md:text-display-lg mb-8 text-center">
            Sorry, an unexpected error has occurred.
          </h1>
          <p className="text-center text-sx md:text-sm font-medium text-gray-700">
            Try to refresh this page or feel free to contact us if the problem persists
          </p>
        </div>
        <div className="relative isolate">
          <CircleGraphic className="absolute max-w-[24vw] min-w-[120px] w-full h-auto left-0 bottom-0">
            <CircleGraphic.Quarter x={2} y={1} color="green-500" rotate={0} />
            <CircleGraphic.Quarter x={1} y={2} color="green-500" rotate={90} />
            <CircleGraphic.Quarter x={2} y={2} color="green-900" rotate={0} />
            <CircleGraphic.Quarter x={1} y={3} color="green-900" rotate={0} />
            <CircleGraphic.Quarter x={2} y={3} color="green-500" rotate={180} />
            <CircleGraphic.Circle x={3} y={3} radius={0.5} color="green-500" />
            <CircleGraphic.Quarter x={4} y={3} color="green-900" rotate={90} />
          </CircleGraphic>
        </div>
      </div>
    </div>
  );
};
