import React, { FC, ReactNode } from 'react';
import ReactModal, { Styles } from 'react-modal';
import styles from './Modal.module.css';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
  contentLabel?: string;
}

const Modal: FC<Props> = ({ isOpen, closeModal, children, contentLabel = 'Modal' }) => {
  return (
    <ReactModal
      appElement={document.getElementById('root') as HTMLElement}
      ariaHideApp={process.env.NODE_ENV !== 'test'}
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles()}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={true}
      className={styles.modal}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;

const customStyles = (): Styles => ({
  overlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(52, 64, 84, 0.6)',
    backdropFilter: 'blur(1.5px)',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 21,
  },
});
