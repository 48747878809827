import React, { FC } from 'react';
import styles from './WideCard.module.css';

interface Props {
  image?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  subsubtitle?: React.ReactNode;
  meta?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  footerContent?: React.ReactNode;
  highlighted?: boolean;
  disabled?: boolean;
}

const WideCard: FC<Props> = ({
  image,
  title,
  subtitle,
  subsubtitle,
  meta,
  description,
  children,
  footerContent,
  highlighted,
  disabled,
}) => (
  <div
    className={[
      styles.card,
      highlighted === true ? styles[`card--highlighted`] : '',
      disabled === true ? styles[`card--disabled`] : '',
    ].join(' ')}
  >
    <div className="grid xs:flex p-6 gap-x-8 gap-y-6 items-start">
      <div className="bg-gray-50 rounded-full overflow-clip justify-self-start shrink-0">{image}</div>

      <div>
        <h3 className="font-serif text-display-sm">{title}</h3>

        {subtitle != null && <div className="text-display-sm">{subtitle}</div>}

        {subsubtitle != null && <p className="text-gray-500 mt-1">{subsubtitle}</p>}

        {meta != null && <div className="flex flex-wrap gap-x-6 gap-y-2 mt-3">{meta}</div>}

        {description != null && <p className="text-gray-500 mt-4">{description}</p>}
      </div>
    </div>

    <div>{children}</div>

    {footerContent != null && <div className={styles.footer}>{footerContent}</div>}
  </div>
);

export default WideCard;
