import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    coachSlug: string;
    calendly: {
      date: string; // '20230412-1509';
      timezone: string; // 'Europe/Warsaw';
    };
    sessionPackageId: number;
  };
  success: { redirectUrl: string };
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/talents/checkouts');
