export default {
  inherit: 'inherit',
  current: 'currentColor',
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',
  hpblack: '#1f1f1f',
  gray: {
    25: '#fcfcfd',
    50: '#f7f8fa',
    100: '#f0f2f6',
    200: '#e6e9ef',
    300: '#d1d7e0',
    400: '#8b96a4',
    500: '#667483',
    600: '#566272',
    700: '#404c5b',
    800: '#1e3041',
    900: '#101a2c',
  },
  blue: {
    25: '#fefcff',
    50: '#f5f9ff',
    100: '#ebf3fe',
    200: '#cce1ff',
    300: '#94bfff',
    400: '#5499fd',
    450: '#0f6df5',
    500: '#0365f4',
    600: '#0355cf',
    700: '#0243a1',
    800: '#0e3b7c',
    900: '#0b2750',
  },
  green: {
    25: '#fafffd',
    50: '#f6fefb',
    100: '#ecfdf7',
    200: '#c2fae5',
    300: '#9bf3d3',
    400: '#6de9bb',
    500: '#34e0a1',
    600: '#1ec391',
    700: '#09aa8d',
    800: '#079279',
    900: '#067561',
  },
  red: {
    25: '#fffbfa',
    50: '#fef3f2',
    100: '#fee4e2',
    200: '#fecdca',
    300: '#fda29b',
    400: '#f97066',
    500: '#f04438',
    600: '#d92d20',
    700: '#b42318',
    800: '#912018',
    900: '#7a271a',
  },
  yellow: {
    25: '#fffcf5',
    50: '#fffaeb',
    100: '#fef0c7',
    200: '#fedf89',
    300: '#fec84b',
    400: '#fdb022',
    500: '#f79009',
    600: '#dc6803',
    700: '#b54708',
    800: '#93370d',
    900: '#7a2e0e',
  },
};
