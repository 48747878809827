import React, { FC, useState } from 'react';
import { TalentPreferences } from '#src/types';
import { FormContentGroup } from '#components/Index';
import InterestsModal from './modals/InterestsModal';
import FirmsTree from './FirmsTree';

interface InterestsProps {
  profilePreferences: TalentPreferences;
}

const Interests: FC<InterestsProps> = ({ profilePreferences }) => {
  const [isInterestsModalOpen, setIsInterestsModalOpen] = useState(false);

  const { functionTypesInterest } = profilePreferences;

  return (
    <FormContentGroup
      title="Interests"
      groupName="Preferences"
      description="What kinds of firms, roles are you interested in working in?"
      editSectionHandler={() => setIsInterestsModalOpen(!isInterestsModalOpen)}
    >
      <InterestsModal isOpen={isInterestsModalOpen} close={() => setIsInterestsModalOpen(false)} />

      <div className="flex flex-col gap-y-6">
        <div>
          <h5 className="text-gray-500 text-sm pb-2">Types of firms I’m interested in</h5>
          <FirmsTree
            functionTypes={functionTypesInterest}
            type="interest"
            openModal={() => setIsInterestsModalOpen(true)}
          />
        </div>
      </div>
    </FormContentGroup>
  );
};

export default Interests;
