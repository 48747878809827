import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '#pages/paths';

export default [
  {
    id: 'about-us',
    heading: '1. About us',
    content: (
      <>
        <h3>1.1. Who we are</h3>
        <p>We have two legal entities:</p>
        <p>
          1.1.1. Hiperpool Ltd, registered in England and Wales with company number 09281067 whose registered address is
          Wework, 123 Buckingham Palace Road, London, United Kingdom, SW1W 9SH; and
        </p>
        <p>
          1.1.2. Hiperpool US Inc, a Delaware corporation (in the United States of America), collectively, (
          <strong>Hiperpool</strong> / <strong>we</strong> / <strong>us</strong> / <strong>our</strong>).
        </p>

        <h3>1.2. Services we provide to businesses</h3>
        <p>
          Hiperpool is an employment agency. Our clients are organisations (<strong>Employers</strong>) that use our
          recruitment service and online platform to shortlist candidates. Employers instruct us to source suitable
          candidates on their behalf from individuals that have created an account on our platform or from the wider job
          market.
        </p>

        <h3>1.3. Services we provide to individuals (free of charge)</h3>
        <p>
          We also provide our recruitment services to individuals (<strong>Professionals</strong>, <strong>you</strong>,{' '}
          <strong>your</strong>) who are open to job opportunities. We might contact you about a specific vacancy with
          an Employer or you can register with us directly. In both cases you need to create an account on our website
          and provide some basic details about yourself and the type of role you are looking for (your{' '}
          <strong>Profile</strong>). Alternatively, with your permission, Hiperpool will create a Profile on your
          behalf.
        </p>

        <h3>1.4. Services we provide to individuals (for a fee)</h3>
        <p>
          Professionals can also instruct us to provide interview coaching services. Information about our prices and
          services are available online at{' '}
          <Link to={paths.interviewPrep()}>{`hiperpool.com${paths.interviewPrep()}`}</Link> and are payable via an
          online payment processing platform (e.g. Stripe).
        </p>

        <h4>We do…</h4>
        <ul className="list-positive !mt-0">
          <li>Provide our services to Employers and Professionals located around the world.</li>
          <li>Hire for permanent and fixed contract assignments.</li>
          <li>Always check with you before we put you forward for a specific vacancy.</li>
        </ul>

        <h4>We don&apos;t…</h4>
        <ul className="list-negative !mt-0">
          <li>Use lengthy application forms.</li>
          <li>Provide our services to individuals who work via a personal services company.</li>
          <li>
            Guarantee that we can place you in any role. We try our best to find you the perfect role but ultimately the
            choice is up to the Employer.
          </li>
        </ul>

        <h3>1.5. Getting in touch</h3>
        <p>
          We contact you using the details you provide when you sign up to our platform, in your CV, via social media or
          using contact details you have made publicly available. You can contact us by:
        </p>
        <p>
          <strong>Email:</strong> <a href="mailto:info@hiperpool.com">info@hiperpool.com</a>
          <br />
          <strong>Telephone:</strong> <a href="tel:+447437755084">+44 743 7755084</a>
          <br />
          <strong>Post:</strong> 123 Buckingham Palace Road, London, United Kingdom, SW1W 9SH
        </p>
      </>
    ),
  },
  {
    id: 'about-terms',
    heading: '2. About these terms',
    content: (
      <>
        <h3>2.1. What these terms do</h3>
        <p>
          These terms set out how we provide our recruitment services and online platform to you. The terms explain what
          happens when you create an account, your responsibilities and how to stop using our services.
        </p>

        <h3>2.2. When these terms apply</h3>
        <p>
          These terms take effect on the earlier of you: visiting our website; indicating your interest in applying for
          a role with an Employer; creating an account to use our online platform; or otherwise indicating your interest
          in being registered as a Professional by Hiperpool. These terms create a legal relationship between you and
          Hiperpool, so you should read them carefully.
        </p>

        <h3>2.3. Help understanding these terms</h3>
        <p>
          In these terms a reference to <strong>writing</strong> or <strong>written</strong> includes email. Any words
          following the phrases <strong>include or e.g.</strong> or any similar expression are intended to be
          illustrative only. Use of the singular shall include the plural and vice versa.
        </p>

        <h3>2.4. How Hiperpool uses your information</h3>
        <p>
          You should read our privacy notice as it explains how we use your personal information and who we share it
          with. The latest version of our privacy notice is available on our website here{' '}
          <Link to={paths.privacy()}>{`hiperpool.com${paths.privacy()}`}</Link>.
        </p>
      </>
    ),
  },
  {
    id: 'your-account',
    heading: '3. Your account',
    content: (
      <>
        <h3>3.1. You need an account to access our services</h3>
        <p>
          You register with your name and personal email address. We ask you to set up a secure password. You do not
          need an account to browse our website and it is free to set up an account with us.
        </p>

        <h3>3.2. You may be asked to confirm your age and identity</h3>
        <p>
          Hiperpool may ask you to confirm your age as we only recruit for roles for Professionals who are [16 years]
          old or older. Employers must perform right to work checks as they have a legal obligation to ensure you are
          permitted to work in the territory where their vacancy is located.
        </p>

        <h3>3.3. Keep your password secret</h3>
        <p>
          It is your responsibility to keep your password secure and you should let us know as soon as possible if you
          think your account has been accessed by someone else (or compromised in another way). You should email{' '}
          <a href="mailto:info@hiperpool.com">info@hiperpool.com</a>.
        </p>

        <h3>3.4. You can close your account at any time.</h3>
        <p>
          You can close your account by emailing <a href="mailto:info@hiperpool.com">info@hiperpool.com</a>. This
          notifies us to remove your account and any documents you have uploaded, which we endeavour to action within
          [30 days] of receiving a request. You must unsubscribe from our mailing list separately to stop receiving
          communications from us. If there is any reason that we are unable to delete your information, we will let you
          know in writing and explain our decision.
        </p>
      </>
    ),
  },
  {
    id: 'your-responsibilities',
    heading: '4. Your responsibilities',
    content: (
      <>
        <h3>4.1. Keep your information up to date and accurate</h3>
        <p>
          We rely on your information being up-to-date and accurate so we can properly assess your suitability for a
          role. You may miss out on great opportunities if you do not add new qualifications, skills or experience you
          have gained since first creating your Profile. If you have provided any information which is later found to be
          inaccurate, an Employer may withdraw you from their recruitment process or withdraw any job offer they have
          made you. It is also important to keep your information up-to-date so you do not miss any important
          notifications.
        </p>

        <h3>4.2. Protect your own equipment and data</h3>
        <p>
          Whilst we have technical security measures in place, we cannot guarantee that our website or platform is free
          from malicious content or actors. You should be especially careful when clicking links to external website or
          links provided by third parties. It is your responsibility to take precautionary steps (e.g. make sure you
          have installed anti-virus software and the latest security updates to your device).
        </p>

        <h3>4.3. Treat others, our property and our systems with respect</h3>
        <p>
          Hiperpool has a zero tolerance policy on unacceptable behaviour. Hiperpool offers an inclusive global platform
          and we expect everyone (whether our staff, Employers, Professionals or website visitors) to act respectfully
          and lawfully. We may close accounts and stop providing services to those we consider to have breached our
          policies. If you feel you have encountered any unacceptable behaviour whilst using our services or platform,
          please let us know so we can investigate and take appropriate action. We also value any feedback or
          suggestions from you on how Hiperpool can improve our inclusivity.
        </p>

        <h3>4.4. No unauthorised use</h3>
        <p>
          You must not attempt to gain unauthorised access to our services, our server or any other service, computer or
          database connected to Hiperpool. You must not attack our services via a denial-of-service or distributed
          denial-of-service attack. If we discover you have attempted to attack or bypass our security, we may report
          you to law enforcement authorities and immediately stop providing services to you. You must not scrape
          information from our website or use our intellectual property, or the intellectual property of any Employer,
          without written permission from the relevant owner or rights holder. If we discover you have used our data or
          intellectual property without authorisation, we may instruct legal advisors to defend our intellectual
          property rights and stop providing services to you.
        </p>
      </>
    ),
  },
  {
    id: 'if-something-goes-wrong',
    heading: '5. If something goes wrong',
    content: (
      <>
        <h3>5.1. How to complain</h3>
        <p>
          If you are unhappy or have a concern, please let us know so we can try and help. Please email{' '}
          <a href="mailto:info@hiperpool.com">info@hiperpool.com</a>
        </p>

        <h3>5.2. Our liability to you</h3>
        <p>
          To the extent permitted by law, Hiperpool is only liable to you for any losses that were reasonably
          foreseeable at the time of us providing our services to you. Whilst this is not intended to be an exhaustive
          list, some instances in which Hiperpool has no liability include:
        </p>

        <ol className="!list-[lower-latin]">
          <li>
            Where we do not select you as a prospective candidate for an Employer because we do not consider you to meet
            the suitability criteria for a vacancy.
          </li>
          <li>Where you have paid a fee for coaching but do not secure an interview.</li>
          <li>Where an Employer does not make, or withdraws, a job offer.</li>
          <li>Arising from any act or omission of an Employer, their staff, a coach or any other third party.</li>
        </ol>

        <h3>
          5.3. We do not guarantee access to our website or platform will be uninterrupted or error-free, or that
          content is correct or up to date
        </h3>
        <p>
          Hiperpool relies on Employers and Professionals to provide accurate information for us to publish on our
          website and online portal. We endeavour to ensure content remains up to date (e.g. by removing vacancies when
          an Employer notifies us the position has been filled or withdrawn) but we cannot guarantee information is
          error-free, up to date or accurate. There may also be occasions where you cannot access our website or
          platform (e.g. it is offline because we are performing necessary maintenance or updates). We aim to provide
          advance warning where we know access will be interrupted or limited, but this is not always possible (e.g. if
          there has been a technical fault that we have no notice of).
        </p>
      </>
    ),
  },
  {
    id: 'other-information',
    heading: '6. Other important information',
    content: (
      <>
        <h3>6.1. Rights of third parties</h3>
        <p>
          For the purposes of the Contracts (Rights of Third Parties) Act 1999 and despite any other provision of these
          terms, these terms are not intended to and do not give any person who is not a party to them any right to
          enforce any of these terms.
        </p>

        <h3>6.2. These terms are subject to English law</h3>
        <p>
          These terms are governed by English law. Any dispute that results in proceedings will be determined by the
          courts of England and Wales. This does not affect your legal rights as a consumer under local law to bring any
          proceedings against us in the courts of another country.
        </p>

        <h3>6.3. If a court decides that part of these terms are illegal, the remainder continue to apply</h3>
        <p>Each paragraph is separate so the remaining content will still be effective.</p>

        <h3>6.4. We may assign these terms</h3>
        <p>
          We may decide to sell our business or assign (transfer) these terms to another person or organisation. We will
          notify you in writing if this happens.
        </p>

        <h3>6.5. We may modify our platform, website and these terms</h3>
        <p>
          We can make minor amendments to ensure we comply with updates in the law and regulations, or where we need to
          implement a technical adjustment (e.g. a security update). We will notify you in writing if we make any
          significant changes to these terms.
        </p>
      </>
    ),
  },
];
