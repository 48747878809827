import { useMemo, useState, useEffect } from 'react';
import { take } from 'lodash-es';

interface Props<T> {
  list?: T[];
  perPage?: number;
  currentPage?: number;
}
const useInfiniteScrollPagination = <T,>({
  list = [],
  perPage = 10,
  currentPage: currentPageFromProps = 1,
}: Props<T>) => {
  const [currentPage, setCurrentPage] = useState(currentPageFromProps);
  const [pages, setPages] = useState(currentPage);

  useEffect(
    function setPagesCount() {
      setPages(Math.ceil(list.length / perPage));
    },
    [list, perPage]
  );

  const loadMore = () => {
    setCurrentPage((state) => state + 1);
  };

  const chunkedList = useMemo(() => take(list, currentPage * perPage), [list, currentPage, perPage]);

  const isMore = perPage * currentPage - list.length < 0;

  return { list: chunkedList, perPage, currentPage, loadMore, pages, isMore };
};

export default useInfiniteScrollPagination;
