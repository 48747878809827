import * as apiGetWorkInterestSituationTypes from '#api/endpoints/onboarding/workInterestSituationTypes';
import { useQuery } from 'react-query';
import { ReactQueryOptions } from '#src/types';

export enum KeysEnum {
  workInterestSituationTypes = 'work-interest-situation',
}

type GetApiPayload = apiGetWorkInterestSituationTypes.Type['success']['payload'];
export const useGetWorkInterestSituationTypes = (options?: ReactQueryOptions<GetApiPayload>) =>
  useQuery<GetApiPayload>(
    [KeysEnum.workInterestSituationTypes],
    async () => await apiGetWorkInterestSituationTypes.request(),
    {
      ...options,
    }
  );
