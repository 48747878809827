import React, { FC, useMemo } from 'react';
import { useGetProfilePreferences } from '../../data/ProfilePreferences';
import { BaseProfileEditModal, Step, StepsProvider, QuestionGroupModalProps } from '../BaseProfileEditModal';
import ExperienceYearsStep from './steps/ExperienceYearsStep';
import ExperienceFirmsStep from './steps/ExperienceFirmsStep';
import ExperienceIndustriesStep from './steps/ExperienceIndustriesStep';
import StartupBranchesStep from './steps/firms/StartupBranchesStep';
import CorporateBranchesStep from './steps/firms/CorporateBranchesStep';
import ManagementConsultingBranchesStep from './steps/firms/ManagementConsultingBranchesStep';
import PrivateEquityBranchesStep from './steps/firms/PrivateEquityBranchesStep';
import InvestmentBankingBranchesStep from './steps/firms/InvestmentBankingBranchesStep';

export const firmTypeIdsAndNames = {
  [`${StartupBranchesStep.name}Experience`]: 'Start-up/Scale-up',
  [`${CorporateBranchesStep.name}Experience`]: 'Corporate',
  [`${ManagementConsultingBranchesStep.name}Experience`]: 'Management Consulting',
  [`${PrivateEquityBranchesStep.name}Experience`]: 'Private Equity',
  [`${InvestmentBankingBranchesStep.name}Experience`]: 'Investment Banking',
};

const WorkHistoryModal: FC<QuestionGroupModalProps> = ({ isOpen, close }) => {
  const { data: profilePreferencesData } = useGetProfilePreferences();

  const initiallySelectedFunctionTypeNames = useMemo(() => {
    if (profilePreferencesData == null) return [];

    return profilePreferencesData.profilePreferences.functionTypesExperience
      .filter((functionType) => functionType.selected)
      .map((functionType) => functionType.name);
  }, [profilePreferencesData]);

  const steps: Step[] = [
    {
      name: ExperienceYearsStep.name,
      content: () => <ExperienceYearsStep />,
      visible: true,
    },
    {
      name: ExperienceFirmsStep.name,
      content: () => <ExperienceFirmsStep />,
      visible: true,
    },
    {
      name: `${StartupBranchesStep.name}Experience`,
      content: () => <StartupBranchesStep category="experience" />,
      visible: initiallySelectedFunctionTypeNames.includes('Start-up/Scale-up'),
    },
    {
      name: `${CorporateBranchesStep.name}Experience`,
      content: () => <CorporateBranchesStep category="experience" />,
      visible: initiallySelectedFunctionTypeNames.includes('Corporate'),
    },
    {
      name: `${ManagementConsultingBranchesStep.name}Experience`,
      content: () => <ManagementConsultingBranchesStep category="experience" />,
      visible: initiallySelectedFunctionTypeNames.includes('Management Consulting'),
    },
    {
      name: `${PrivateEquityBranchesStep.name}Experience`,
      content: () => <PrivateEquityBranchesStep category="experience" />,
      visible: initiallySelectedFunctionTypeNames.includes('Private Equity'),
    },
    {
      name: `${InvestmentBankingBranchesStep.name}Experience`,
      content: () => <InvestmentBankingBranchesStep category="experience" />,
      visible: initiallySelectedFunctionTypeNames.includes('Investment Banking'),
    },
    {
      name: ExperienceIndustriesStep.name,
      content: () => <ExperienceIndustriesStep />,
      visible: true,
    },
  ];

  return (
    <StepsProvider>
      <BaseProfileEditModal isOpen={isOpen} close={close} initialSteps={steps} />
    </StepsProvider>
  );
};

export default WorkHistoryModal;
