import { Errors } from '#root/src/types';
import { createAction, POST, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: { contact: { email: string; subject: string; message: string } };
  success: never;
  fail: { errors: Errors<'email' | 'subject' | 'message'>; status: number };
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(POST, '/public/contacts');
