import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { paths, parseParamsToLinkSearch, signInRedirectUrl } from '#pages/paths';
import { useUserDetails } from '#src/AppData';
import { AccountButtonItem, Navbar, NavItem } from '#components/Navbar/Navbar';
import { Button } from '#components/Index';

// Unsigned user navigation items
export const CandidateNavItems: FC = () => (
  <>
    <NavItem to={paths.jobs()}>Jobs</NavItem>
    <NavItem to={paths.blog()}>Insights & Resources</NavItem>
    <NavItem to={paths.stories()}>Success Stories</NavItem>
    <NavItem to={paths.interviewPrep()}>Coaching</NavItem>
    <div className="xl:hidden">
      <NavItem to={paths.employer()}>For Employers</NavItem>
    </div>
  </>
);

export const TalentNavItems: FC = () => (
  <>
    <NavItem to={paths.talentOpportunities()}>Jobs</NavItem>
    <NavItem to={paths.talentActivity()}>My Activity</NavItem>
    <NavItem to={paths.talentProfile()}>My Profile</NavItem>
    <NavItem to={paths.coaches()}>Coaching</NavItem>
    <NavItem to={paths.blog()}>Insights & Resources</NavItem>
    <NavItem to={paths.talentHiring()}>Are you Hiring?</NavItem>
    <div className="xl:hidden">
      <NavItem to={paths.employer()}>For Employers</NavItem>
    </div>
  </>
);

export const CandidateButtonItems = () => {
  const [searchParams] = useSearchParams();
  const redirectUrlFromQueryParams = searchParams.get('redirectUrl');

  return (
    <>
      <div className="max-xl:hidden">
        <Button variant="link" size="small" navTo={paths.employer()}>
          For Employers
        </Button>
      </div>
      <Button
        variant="link"
        size="small"
        navTo={paths.signIn() + parseParamsToLinkSearch({ redirectUrl: signInRedirectUrl(redirectUrlFromQueryParams) })}
      >
        Sign in
      </Button>
      <Button
        variant="primary"
        size="small"
        navTo={paths.signUp() + parseParamsToLinkSearch({ redirectUrl: signInRedirectUrl(redirectUrlFromQueryParams) })}
      >
        Join today, free
      </Button>
    </>
  );
};

export const SignedInButtonItems = () => (
  <>
    <div className="max-xl:hidden">
      <Button variant="link" size="small" navTo={paths.employer()}>
        For Employers
      </Button>
    </div>
    <AccountButtonItem />
  </>
);

export const CandidateNavbar: FC = () => {
  const { isLoggedIn, user } = useUserDetails() ?? {};

  return (
    <Navbar
      testid="candidate-navbar"
      navItems={isLoggedIn && user?.type === 'Talent' ? <TalentNavItems /> : <CandidateNavItems />}
      buttonItems={isLoggedIn ? <SignedInButtonItems /> : <CandidateButtonItems />}
    />
  );
};
