import { createAction, PUT, ApiPayloadsCreator } from '#api/actionCreator';

interface Payload {
  init: {
    industryTypeIds: number[];
  };
  success: never;
  fail: never;
}

export type Type = ApiPayloadsCreator<Payload>;

export const request = createAction<Payload>(PUT, '/talents/profile/industry_types/update_experience');
