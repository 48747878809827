import React, { FC } from 'react';
import { ReactComponent as Experience } from '#assets/about/experience.svg';
import { ReactComponent as Trust } from '#assets/about/trust.svg';
import { ReactComponent as Passion } from '#assets/about/passion.svg';
import { ReactComponent as Achievement } from '#assets/about/achievement.svg';
import { ReactComponent as Team } from '#assets/about/team.svg';
import { ReactComponent as Communication } from '#assets/about/communication.svg';

const Card: FC<{ icon: React.ReactNode; text: string }> = ({ icon, text }) => (
  <div className="flex flex-col items-center">
    {icon}
    <p className="text-xl lg:text-display-xs text-gray-500 mt-5 max-w-xs">{text}</p>
  </div>
);

const CoreValues: FC = () => {
  return (
    <div className="bg-gray-50 px-variable-sm">
      <div className="py-20 max-w-4xl mx-auto font-serif text-center">
        <h2 className="text-display-md">Our values are at the core of everything we do</h2>
        <div className="grid xs:grid-cols-2 md:grid-cols-3 gap-x-8 md:gap-x-12 gap-y-16 mt-16">
          {[
            { icon: <Passion />, text: 'We are passionate about our mission' },
            { icon: <Communication />, text: 'We communicate with transparency, honesty and candor' },
            { icon: <Team />, text: 'We work as a team, not as a collection of individuals' },
            { icon: <Trust />, text: 'We are trusted advisors to our members and clients' },
            { icon: <Experience />, text: 'We create great experiences' },
            { icon: <Achievement />, text: 'We always strive to do better' },
          ].map((elm) => (
            <Card key={elm.text} {...elm} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
