import React, { FC, useState, useCallback } from 'react';
import { request as apiUpdateNotificationsSettings } from '#api/endpoints/talents/settings/updateNotificationsSettings';
import { Checkbox, RadioItem } from '#components/Form/Index';
import { Button, FormContentGroup, SuccessAlert } from '#components/Index';

interface EmailPreferencesFormProps {
  initialMarketingEmailConsent: boolean;
  initialEmailNotificationFrequency: 'as_they_come' | 'never';
}

const EmailPreferencesForm: FC<EmailPreferencesFormProps> = ({
  initialMarketingEmailConsent,
  initialEmailNotificationFrequency,
}) => {
  const [marketingEmailConsent, setMarketingEmailConsent] = useState(initialMarketingEmailConsent);
  const [emailNotificationFrequency, setEmailNotificationFrequency] = useState(initialEmailNotificationFrequency);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const closeSuccessMessage = () => setSuccessMessage(null);

  const updateEmailNotificationPreference = useCallback(async () => {
    await apiUpdateNotificationsSettings({
      talent: {
        marketingEmailConsent,
        emailNotificationFrequency,
      },
    });

    setSuccessMessage('Email notification preferences updated successfully');
  }, [marketingEmailConsent, emailNotificationFrequency]);

  return (
    <>
      <SuccessAlert text={successMessage} onClose={closeSuccessMessage} />

      <div className="flex divide-y-2 divide-gray-50 flex-col">
        <FormContentGroup title="Insights & Resources">
          <div className="px-16">
            <Checkbox
              checked={marketingEmailConsent}
              onChange={(event) => setMarketingEmailConsent(event.currentTarget.checked)}
            >
              <span className="text-sm">
                Keep me updated on the most recent career insights, resources
                <br /> and relevant company news
              </span>
            </Checkbox>
          </div>
        </FormContentGroup>

        <FormContentGroup
          title="Hand-picked Opportunities"
          description="Receive opportunities specifically hand-picked based on your background and preferences"
        >
          <div className="flex flex-col gap-y-6 px-16">
            <RadioItem
              id="as_they_come"
              name="emailNotificationPreference"
              className="text-sm"
              onChange={() => setEmailNotificationFrequency('as_they_come')}
              checked={emailNotificationFrequency === 'as_they_come'}
            >
              Send me these as they come
            </RadioItem>

            <RadioItem
              id="never"
              name="emailNotificationPreference"
              className="text-sm"
              onChange={() => setEmailNotificationFrequency('never')}
              checked={emailNotificationFrequency === 'never'}
            >
              Never send me these
            </RadioItem>
          </div>
        </FormContentGroup>

        <div className="flex justify-end pt-6">
          <Button onClick={updateEmailNotificationPreference}>Save changes</Button>
        </div>
      </div>
    </>
  );
};

export default EmailPreferencesForm;
