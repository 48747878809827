import React, { FC } from 'react';
import DOMPurify from 'dompurify';
import { ReactComponent as SummaryCircleIcon } from '#assets/icons/summary-circle.svg';

interface EmployerSummaryProps {
  summary: string | null;
}

const EmployerSummary: FC<EmployerSummaryProps> = ({ summary }) => {
  if (summary == null) return null;

  return (
    <div className="flex flex-col py-8">
      <h3 className="hp-black font-serif text-display-sm pb-8">Summary</h3>

      <div className="flex gap-x-8">
        <div className="hidden lg:block">
          <SummaryCircleIcon className="h-[100px] w-[100px]" />
        </div>

        <div
          className="flex flex-col grow text-gray-500 font-light rich-text rich-text-lists"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(summary) }}
        />
      </div>
    </div>
  );
};

export default EmployerSummary;
