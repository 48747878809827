import * as apiGetOpportunityDetails from '#api/endpoints/talents/opportunities/getOpportunityDetails';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ReactQueryOptions } from '#src/types';
import { paths } from '#pages/paths';

export enum KeysEnum {
  talents = 'talents',
  opportunities = 'opportunities',
  show = 'show',
}

type GetApiPayload = apiGetOpportunityDetails.Type['success']['payload'];
export const useGetOpportunityDetails = (id: number, options?: ReactQueryOptions<GetApiPayload>) => {
  const navigate = useNavigate();
  const isValidId = !isNaN(id);

  const query = useQuery<GetApiPayload>(
    [KeysEnum.talents, KeysEnum.opportunities, KeysEnum.show, id],
    async () => {
      return await apiGetOpportunityDetails.request({ id }).catch(async (e) => {
        if (e.status === 403) {
          navigate(paths.talentOpportunities());
        }
        return await Promise.reject(e);
      });
    },
    {
      enabled: isValidId,
      ...options,
    }
  );

  if (!isValidId) {
    return {
      data: undefined,
      isLoading: false,
      refetch: async () => {},
    };
  }

  return query;
};
