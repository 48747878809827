import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from '#components/Index';
import { CandidateNavbar } from './elements/CandidateNavbar';
import { useUserDetails } from '#src/AppData';

interface Props {
  withFooter?: boolean;
}
const CandidateLayout: FC<Props> = ({ withFooter = true }) => {
  const context = useUserDetails();

  return (
    <>
      <CandidateNavbar />
      <Outlet context={context} />
      {withFooter && <Footer />}
    </>
  );
};

export default CandidateLayout;
