import React, { FC, useRef } from 'react';
import { animateNumber } from '#src/lib/animatedNumber';
import { useIsRefOnScreenOnce } from '#src/lib/respondToVisibility';
import { random } from 'lodash-es';

interface TStat {
  title: string;
  value: number | null;
}
const Stat: FC<TStat> = (props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useIsRefOnScreenOnce(ref, (visible) => {
    if (visible && ref.current != null) {
      animateNumber(ref.current, 0, props.value != null ? props.value : 0, random(900, 1600));
    }
  });

  return (
    <div className="w-1/3">
      <div className="text-green-600 text-display-2xl font-serif text-center xs:text-left" ref={ref}>
        {props.value}
      </div>
      <p className="text-xs text-gray-500 uppercase font-bold tracking-widest mt-1 text-center xs:text-left">
        {props.title}
      </p>
    </div>
  );
};

interface Props {
  stats: TStat[];
}
const Stats: FC<Props> = (props) => {
  return (
    <div className="flex justify-center mx-variable-lg py-12">
      <div className="w-full max-w-2xl flex flex-col xs:flex-row items-center xs:items-baseline justify-around gap-6 ">
        {props.stats.map((stat) => stat.value != null && <Stat {...stat} key={stat.title} />)}
      </div>
    </div>
  );
};

export default Stats;
