import React, { FC } from 'react';

import { CircleGraphic } from '#components/Index';
import HiperpoolFounders from '../../elements/HiperpoolFounders';

const WeHaveBeenThere: FC = () => {
  return (
    <div className="relative flex py-16 md:py-32 lg:py-40 justify-center overflow-hidden">
      <CircleGraphic color="gray-50" className="absolute right-0 bottom-0 z-1 w-[32rem] h-auto">
        <CircleGraphic.Half x={3} y={1} rotate={90} />
        <CircleGraphic.Half x={2} y={3} />
        <CircleGraphic.Quarter x={4} y={3} />
        <CircleGraphic.Half x={2} y={4} rotate={180} />
        <CircleGraphic.Quarter x={3} y={4} rotate={180} />
        <CircleGraphic.Quarter x={4} y={4} rotate={90} />
      </CircleGraphic>

      <HiperpoolFounders
        header="We’ve been there"
        description="Hiperpool was founded by two alumni from Bain and Booz. Since then, we’ve made it our mission to help ambitious professionals like you to use their skills to build extraordinary careers."
      />
    </div>
  );
};

export default WeHaveBeenThere;
