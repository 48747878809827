import React, { FC } from 'react';
import { TextInput } from '#components/Form/Index';
import { useAccountSettingsController } from './FormController';
import { Button, FormContentGroup, SuccessAlert } from '#components/Index';

interface AccountSettingsFormProps {
  email: string;
}

const AccountSettingsForm: FC<AccountSettingsFormProps> = ({ email }) => {
  const { form, submitForm, successMessage, closeSuccessMessage } = useAccountSettingsController({
    email,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = handleSubmit((values) => submitForm(values));

  return (
    <form onSubmit={onSubmit} className="flex divide-y-2 divide-gray-50 flex-col">
      <SuccessAlert text={successMessage} onClose={() => closeSuccessMessage()} />

      <FormContentGroup title="Contact Details">
        <div className="px-16">
          <TextInput
            label="Email"
            description="A personal email is best to make sure you always have access to Hiperpool, throughout your career. "
            type="email"
            error={errors.email?.message}
            {...register('email')}
          />
        </div>
      </FormContentGroup>

      <FormContentGroup title="Password">
        <div className="flex flex-col gap-y-6 px-16">
          <TextInput
            label="Current Password"
            type="password"
            {...register('currentPassword')}
            error={errors.currentPassword?.message}
          />

          <TextInput
            label="New password"
            description="Min. 8 characters"
            type="password"
            {...register('password')}
            error={errors.password?.message}
          />

          <TextInput
            label="Confirm new password"
            type="password"
            {...register('passwordConfirmation')}
            error={errors.passwordConfirmation?.message}
          />
        </div>
      </FormContentGroup>

      <div className="flex justify-end pt-6">
        <Button type="submit">Save changes</Button>
      </div>
    </form>
  );
};

export default AccountSettingsForm;
